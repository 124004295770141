import '../Home/Home.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import { useState } from 'react';

function Home() {
    let navigate = useNavigate();
    const[teamMemberLogin,setteamMemberLogin]=useState(true);
    useEffect(()=>{
        const items= JSON.parse(localStorage.getItem('UserClaims'));

        if(items!=null){
        
         if(items.Member_Type==2){
           setteamMemberLogin(false)
         }
        }
    },[])
    //const UserClaims=JSON.parse(localStorage.getItem('UserClaims'));

    return (
        <div className='container-fluid landing-bg '>
            <div className='container'>
                <div className='maincontainer'>
                    <div className='row center-content'>
                       {teamMemberLogin &&( <div className='col-md-4 col-sm-12'>
                            <figure className='bg-content' onClick={() => navigate('/businessjournal')}>
                                <img src="https://d2a7u30t6wbkim.cloudfront.net/assets/hampton_connect/assets/images/bussinessjournal.png" width="50" height="50" className="img-cls" alt=""

                                />
                            </figure>
                            <p className='service-info-title'>Business Outlook</p>
                        </div>)}

                        <div className='col-md-4 col-sm-12'>
                            <figure className='bg-content' onClick={() => navigate('/snapshot')}>
                                <img src="https://d2a7u30t6wbkim.cloudfront.net/assets/hampton_connect/assets/images/snapshot.png" width="50" height="50" className="img-cls" alt=""

                                />
                            </figure>
                            <p className='service-info-title'>At a Glance</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
export default Home;