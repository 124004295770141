import "bootstrap/dist/css/bootstrap.min.css";
import "./SnapshotTable.css";
import Table from "react-bootstrap/Table";
import { Open } from "react-ionicons";

const category = [
  { id: 1, name: "Labor" },
  { id: 2, name: "COGS" },
  { id: 3, name: "Expense" },
];
const testingData = [
  {
    id: 1,
    catname: "Inside Golf Ops Hourly",
    mtdbuget: 93570,
    mtdactual: 0,
    balance: 93570,
    departmentname: "Golf Operations",
  },
  {
    id: 1,
    catname: "Outside Golf Ops Hourly",
    mtdbuget: 17994,
    mtdactual: 0,
    balance: 17994,
    departmentname: "",
  },
  {
    id: 1,
    catname: "Front",
    mtdbuget: 10067,
    mtdactual: 60,
    balance: 10007,
    departmentname: "Food & Beverage",
  },
  {
    id: 1,
    catname: "Kitchen",
    mtdbuget: 9973,
    mtdactual: 60,
    balance: 9913,
    departmentname: "",
  },
  {
    id: 1,
    catname: "Hourly Turf Labor",
    mtdbuget: 2652,
    mtdactual: 0,
    balance: 2652,
    departmentname: "Turf & Maintenance",
  },
  {
    id: 1,
    catname: "Admin Hourly",
    mtdbuget: 265,
    mtdactual: 0,
    balance: 265,
    departmentname: "Administration",
  },
  {
    id: 2,
    catname: "Golf Merchandise",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "Golf Operations",
  },
  {
    id: 2,
    catname: "Food",
    mtdbuget: 19699,
    mtdactual: 0,
    balance: 19699,
    departmentname: "Food & Beverage",
  },
  {
    id: 2,
    catname: "Wine",
    mtdbuget: 4972,
    mtdactual: 0,
    balance: 4972,
    departmentname: "",
  },
  {
    id: 2,
    catname: "Beer",
    mtdbuget: 2121,
    mtdactual: 0,
    balance: 2121,
    departmentname: "",
  },
  {
    id: 2,
    catname: "Liquor",
    mtdbuget: 2159,
    mtdactual: 0,
    balance: 2159,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Dues & Subscriptions",
    mtdbuget: 115,
    mtdactual: 1100,
    balance: -985,
    departmentname: "Golf Operations",
  },
  {
    id: 3,
    catname: "Employee Training/Education",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Events",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Leases - Carts",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Member Expense",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Postage",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Repairs & Maintenance",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Supplies",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Training",
    mtdbuget: 115,
    mtdactual: 0,
    balance: 115,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Uniforms",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Contract Services",
    mtdbuget: 9471,
    mtdactual: 10,
    balance: 9461,
    departmentname: "Food & Beverage",
  },
  {
    id: 3,
    catname: "Dish machine Rent and Chemicals",
    mtdbuget: 9471,
    mtdactual: 0,
    balance: 9471,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Events and Tournaments",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Glassware & Chinaware",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Linen & Laundry",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Office Expense",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Paper Supplies",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Pest Control",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Plants & Decoration",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Promotion",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Supplies - Kitchen",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Supplies - Restaurant",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Supplies - To Go",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Alarm",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "Turf & Maintenance",
  },
  {
    id: 3,
    catname: "Auto Expense",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Bunker Sand",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Cell Phone",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Chemicals",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Employee Training",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Fertilizer",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Flowers,Shrubs and trees",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Fuel & Oil",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Leases",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Mulch ",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Pesticides",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Rental Expense",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Safety Equipment",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Safety Supplies",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Sand",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Seed",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Signage",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Small Tool",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Top dressing",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Travel & Entertainment",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Waste Removal",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Water & Sewage",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Water Course",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Alarm/Security",
    mtdbuget: 77,
    mtdactual: 0,
    balance: 77,
    departmentname: "Administration",
  },
  {
    id: 3,
    catname: "Contract Labor",
    mtdbuget: 767,
    mtdactual: 100,
    balance: 667,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Cust Serv Center Supplies",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Employee Education",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Employee Recruiting and Screening",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Janitorial Services",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Member Events",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Music & Cable Services",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Office Supplies",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Printing",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "",
  },
  {
    id: 3,
    catname: "Mulch ",
    mtdbuget: 0,
    mtdactual: 0,
    balance: 0,
    departmentname: "Turf / Revenue",
  },
];
function SnapshotTable2_NonBinded({ Opentobuy,OpentobuyExpense }) {
  const LaborData = Opentobuy?.filter((x) => x.id == 1);
  const COGSData = Opentobuy?.filter((x) => x.id == 2);
  // const Expense = Opentobuy?.filter((x) => x.id == 3);

const Expense = OpentobuyExpense;


// const Expense=OpentobuyExpense;

  console.log(LaborData, "LaborData");
  console.log(COGSData, "COGSData");
  console.log(Expense, "ExpenseData");

  //  console.log(Opentobuy,"DatafromOpenTOBUY")
  return (
    <div class="table-scroll snap-shot-table te_new">
      <Table className="table">
        <thead>
          <tr>
            <th className="bg_table">
              <p style={{ textAlign: "-webkit-center" }}>Category </p>{" "}
            </th>
            <th className="bg_table">
              {" "}
              <p style={{ textAlign: "-webkit-center" }}> Item </p>{" "}
            </th>
            <th className="bg_table">
              {" "}
              <p style={{ textAlign: "-webkit-center" }}>MTD Actual </p>{" "}
            </th>
            <th className="bg_table">
              {" "}
              <p style={{ textAlign: "-webkit-center" }}>
                Total Monthly Budget{" "}
              </p>{" "}
            </th>
            <th className="bg_table">
              {" "}
              <p style={{ textAlign: "-webkit-center" }}>Remaining </p>
            </th>
          </tr>
        </thead>

        <tbody>
          {LaborData?.length>0?<>
          <tr>
            <td
              rowSpan={(LaborData?.length * 2) + 1}
              className="bg_table linetable"
            >
              <div class="tab-card-new-val-1 table_grey  tble_width">Labor</div>
            </td>
          </tr>
          {LaborData?.map((x) => {
            return (
              <>
                <tr>
                  {x.departmentname != "" ? (
                    <>
                      <td colspan="5">
                        <div>
                          <h2
                            class="text-center"
                            style={{ color: "white", fontSize: "22px" }}
                          >
                            {x.departmentname}
                          </h2>
                        </div>
                      </td>
                    </>
                  ) : null}
                </tr>
                <tr style={{ backgroundColor: "#ffff" }}>
                  <td>
                    <div class="tab-card-new-val-1 tbl_sub table_grey">
                      {x.catname}
                    </div>
                  </td>
                  <td>
                    <div class="tab-card-new-val-1 table_grey tbl_sub">
                      {Math.round(x.mtdactual)}
                    </div>
                  </td>
                  <td>
                    <div class="tab-card-new-val-1 tbl_sub table_grey">
                      {Math.round(x.mtdbuget)}
                    </div>
                  </td>
                  <td>
                    <div  class={
                            x?.balance < 0
                              ? "tab-card-new-val-1 val-2 tbl_sub"
                              : x?.balance >= 0
                              ? "tab-card-new-val-1 tbl_sub"
                              : "tab-card-new-val-1 tbl_sub table_grey"
                          }>{Math.round(x.balance)}</div>
                  </td>
                </tr>
              </>
            );
          })}
          </>:null
          }
          {COGSData?.length>0?<>
          <tr>
            <td
              rowSpan={COGSData?.length * 2 + 1}
              className="bg_table linetable"
            >
              <div class="tab-card-new-val-1 table_grey  tble_width">COGS</div>
            </td>
          </tr>
          {COGSData?.map((x) => {
            return (
              <>
                <tr>
                  {x.departmentname != "" ? (
                    <>
                      <td colspan="5">
                        <div>
                          <h2
                            class="text-center"
                            style={{ color: "white", fontSize: "22px" }}
                          >
                            {x.departmentname}
                          </h2>
                        </div>
                      </td>
                    </>
                  ) : null}
                </tr>
                <tr style={{ backgroundColor: "#ffff" }}>
                  <td>
                    <div class="tab-card-new-val-1 tbl_sub table_grey">
                      {x.catname}
                    </div>
                  </td>
                  <td>
                    <div class="tab-card-new-val-1 table_grey tbl_sub">
                      {Math.round(x.mtdactual)}
                    </div>
                  </td>
                  <td>
                    <div class="tab-card-new-val-1 tbl_sub table_grey">
                      {Math.round(x.mtdbuget)}
                    </div>
                  </td>
                  <td>
                    <div class={
                            x?.balance < 0
                              ? "tab-card-new-val-1 val-2 tbl_sub"
                              : x?.balance >= 0
                              ? "tab-card-new-val-1 tbl_sub"
                              : "tab-card-new-val-1 tbl_sub table_grey"
                          }>{Math.round(x.balance)}</div>
                  </td>
                </tr>
              </>
            );
          })}
          </>:null
          }
          { Expense?.length>0?<>
          <tr>
            <td rowSpan={Expense?.length * 2 + 1} className="bg_table linetable">
              <div class="tab-card-new-val-1 table_grey  tble_width">
                Expense
              </div>
            </td>
          </tr>
          {Expense?.map((x) => {
            return (
              <>
                <tr>
                  {x.Department != "" ? (
                    <>
                      <td colspan="5">
                        <div>
                          <h2
                            class="text-center"
                            style={{ color: "white", fontSize: "22px" }}
                          >
                            {x.Department}
                          </h2>
                        </div>
                      </td>
                    </>
                  ) : null}
                </tr>
                <tr style={{ backgroundColor: "#ffff" }}>
                  <td>
                    <div class="tab-card-new-val-1 tbl_sub table_grey">
                     Summary
                    </div>
                  </td>
                  <td>
                    <div class="tab-card-new-val-1 table_grey tbl_sub">
                      {Math.round(x.mtdactual)}
                    </div>
                  </td>
                  <td>
                    <div class="tab-card-new-val-1 tbl_sub table_grey">
                      {Math.round(x.mtdbuget)}
                    </div>
                  </td>
                  <td>
                    <div class={
                            x?.balance < 0
                              ? "tab-card-new-val-1 val-2 tbl_sub"
                              : x?.balance >= 0
                              ? "tab-card-new-val-1 tbl_sub"
                              : "tab-card-new-val-1 tbl_sub table_grey"
                          }>{Math.round(x.balance)}</div>
                  </td>
                </tr>
              </>
            );
          })}
     </>:null     }

          {/* <tr style={{ backgroundColor: "#ffff" }}>
            <td>
              <div class="tab-card-new-val-1 tbl_sub table_grey">Kitchen</div>
            </td>
            <td>
              <div class="tab-card-new-val-1 table_grey tbl_sub">$ 0</div>
            </td>
            <td>
              <div class="tab-card-new-val-1 tbl_sub table_grey">$ 8832877</div>
            </td>
            <td>
              <div class="tab-card-new-val-1 tbl_sub ">$ 8832877</div>
            </td>
          </tr> */}
          {/* <tr>
            <td colspan="5">
              <div>
                <h2 class="text-center" style={{ color: 'white', fontSize: '22px' }}>Food and Beverages</h2>
              </div>
            </td>
          </tr>
          <tr style={{ backgroundColor: '#ffff' }}>
            <td><div class="tab-card-new-val-1 tbl_sub table_grey">Front</div></td>
            <td><div class="tab-card-new-val-1 table_grey tbl_sub">$ 0</div></td>
            <td><div class="tab-card-new-val-1 tbl_sub table_grey">$ 8916760</div></td>
            <td><div class="tab-card-new-val-1 tbl_sub ">$ 8916760</div></td>
          </tr>
          <tr style={{ backgroundColor: '#ffff' }}>
            <td><div class="tab-card-new-val-1 tbl_sub table_grey">Kitchen</div></td>
            <td><div class="tab-card-new-val-1 table_grey tbl_sub">$ 0</div></td>
            <td><div class="tab-card-new-val-1 tbl_sub table_grey">$ 8832877</div></td>
            <td><div class="tab-card-new-val-1 tbl_sub ">$ 8832877</div></td>
          </tr> */}
        </tbody>
      </Table>
    </div>
  );
}
export default SnapshotTable2_NonBinded;
