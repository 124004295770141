import '../Footer/footer.css'


const  Footer = () => {
    return ( 
        <div className='containerfluid footer-sec'>
        <div className='container'>
    <div className='row ft_rw' >

<div className="col-md-4 col-sm-4 col-xs-12 ftr-left">

<address>
                    <p className="numb"><i className="bi bi-telephone"></i><span><a href="tel:904-538-0780">904-538-0780</a></span></p>
                    <p className="icon"><i className="bi bi-envelope"></i><span className="support">support@fobegolf.com</span></p>
                    
                    <p>©2022 – FobeGolf, Inc. - <span key="reserved"> All Rights Reserved</span></p>
                    <div className="btn-group btn-toggle buttontoggle ">
                        <a type="button" id="en" onclick="" className=" btn-md en">English</a>
                        <a type="button" id="es" onclick="" className=" btn-md active es">Español</a>
                    </div>
                </address>
</div>

<div className="col-md-4 col-sm-4 col-xs-12 ftr-brand">
                <a /*href="./index.html"*/ style={{ Cursor:'pointer'}} className="navbar-brand">
                    <img className="footer-logo" src="https://d2a7u30t6wbkim.cloudfront.net/assets/fobegolf/image/footer-logo.png"/>
                </a>
            </div>

            <br/>
            <div    className="col-md-4 col-sm-4 col-xs-12 footer-links" >
                <div className="row">
                    <ul>
                        <li><a /*href="./services.html"*/ >Services</a></li>
                        <li><a /*href="/Blog" key="blog"*/>Blog</a></li>
                        <li><a /*href="./about-us.html"*/ key="aus">About Us</a></li>
                        {/* <li><a href="./login.html" key="lgn">Login</a></li> */}
                    </ul>
                    <div className="buttondiv">
                        <a /*href="./contact-us.html"*/ className="button" key="sch">Schedule Demo</a>
                        &nbsp;
                        <a /*href="#"*/ className="button" key="requestinfo3">Request Info</a>
                    </div>
                </div>
                <br/>
            </div>

<br/>
</div>
</div>
</div>
    );
};

export default Footer;