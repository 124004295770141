import React, { Component, useEffect, useState } from 'react';
// import Multiselect from 'multiselect-react-dropdown';
import 'bootstrap/dist/css/bootstrap.min.css'
import { MultiSelect } from "react-multi-select-component";
import { useRef } from 'react';
import Select from 'react-select';
import { useNavigate } from "react-router-dom";
import './Signup.css';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import { mainAxios } from '../middleware/interceptor';
import { Spinner } from "../Component/Spinner";
import $ from 'jquery';
const SignUp = () => {
  const [NextStep, setNextStep] = useState(0);
  const [PayByCheck, setPayByCheck] = useState();
  const [Exist, setExist] = useState(false);
  const [PrimaryEmailExist, setPrimaryEmailExist] = useState(false);
  let navigate = useNavigate();
  // const StepToConfirmation = () => {
  //   setNextStep(NextStep + 1);
  //   console.log(NextStep);
  // }

  const [selectedValue, setSelectedValue] = useState([]);
  const [SelectYear, setSelectYear] = useState([]);
  const state = {
    options: [{ name: 'Option 1', id: 1 }, { name: 'Option 2', id: 2 }]
  };

  const [selected, setSelected] = useState([]);
  // console.log("selected", selected.length);
  const options = [
    {
      "id": 1,
      "value": "9ce42304-b732-4791-9731-6f299b6df8c7",
      "label": "Administration"
    }, {
      "id": 2,
      "value": "90419f06-7d07-45c8-bcec-d675096fe27f",
      "label": "Administration Without Revenue"
    }, {
      "id": 3,
      "value": "a23521da-0a48-4ef6-baa2-d727704f65c2",
      "label": "Fitness"
    }, {
      "id": 4,
      "value": "34b2b58a-0123-49e2-b2de-1eef0922139b",
      "label": "Food & Beverage"
    }, {
      "id": 5,
      "value": "0fceaec7-fb46-4aef-994b-863a97130168",
      "label": "Golf Operations"
    }, {
      "id": 6,
      "value": "b68c6b76-bbe0-49a6-8a90-209b8d88d353",
      "label": "Other With Revenue"
    }, {
      "id": 7,
      "value": "e2829c11-0960-490c-b19b-5c5bb26a18ce",
      "label": "Other Without Revenue"
    }, {
      "id": 9,
      "value": "841e6f9f-1e19-4501-8b75-cd153282ed21",
      "label": "Tennis"
    }, {
      "id": 10,
      "value": "9346ba4b-8b3e-4c06-b930-cb3d98c58890",
      "label": "Turf & Maintenance"
    }, {
      "id": 11,
      "value": "7294d507-d6c5-47bf-8ef3-c291c273b647",
      "label": "Turf / Revenue"
    }
  ];
  const signInSchema = Yup.object().shape({
    Primary_EMail: Yup.string().required("Email is required").email('Invalid email format').matches("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$", "Email Id must be valid"),
    FName: Yup.string().required("First Name is required"),
    LName: Yup.string().required("Last Name is required"),
    Email2: Yup.string().required("User Name is required"),
    ContactNumber: Yup.string().required("Contact Number is required").min(10, "Contact Number should be 10 digit").max(10).matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Invalid phone number"
    ),
    CourseName: Yup.string().required("Course Name is required"),
    Password: Yup.string().required("Password is required").min(4, "Password minimum should be 4 digit").max(16, "Password maximum should be 16 digit"),
    CPassword: Yup.string().required("Confirm Password is required").min(4, "Password minimum should be 4 digit").max(16, "Password maximum should be 16 digit").oneOf([Yup.ref("Password")], "Passwords do not match"),
  });

  const initialValues = {
    FName: "", LName: "", Email2: "", Primary_EMail: "", ContactNumber: "", CourseName: "", Password: "", CPassword: ""
  };
  const [UserDetails, setUserDetails] = useState(initialValues);
  const StepBillingInfo = (Values) => {
    console.log("Values Step 1", Values, UserDetails);
    if (Values.FName === "" || Values.LName === "" || Values.Email2 === "" || Values.Primary_EMail === "" || Values.ContactNumber === "" || Values.CourseName === ""
      || Values.Password === "" || Values.CPassword === "") {
      toast.error("Please Fill Mandatory Fields");
    }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(Values.Primary_EMail)) {
      console.log("Length", Values.ContactNumber.length)
      // toast.error('Email Invalid')
    }
    else if (Values.ContactNumber.length < 10) {
      console.log("Length", Values.ContactNumber.length)
      // toast.error('Contact Number Invalid')
    }
    else if (Values.Password.length < 4) {
      console.log("Length", Values.Password.length)
      // toast.error('Password minimum should be 5 digit')
    }
    else if (Values.Password !== Values.CPassword) {
      console.log("Length", Values.Password.length)
      // toast.error("Password Not match");
    }
    else if (Exist) {
      console.log("Length", Values.Password.length);
      // toast.error("Email already exist");
    }
    else {
      mainAxios.get('Api/User/CheckEmailValid/' + Values.Primary_EMail).then(res => {
        console.log(res.data, "EmailValid");
        if (res.data !== 0) {
          toast.error('Email already exists')
          setPrimaryEmailExist(true);
        } else if (res.data === 0) {
          setUserDetails(Values);
          setNextStep(NextStep + 1);
          PaymentMethod(1);
          setPayByCheck(true);
          setPrimaryEmailExist(false);
          getYear();
          getState();
          getModules();
          getBudget();
        }
      })
    }

  }

  const getYear = async () => {
    mainAxios.get(`/Api/Hampton/GetNextYears/`).then(res => {
      console.log("year", res.data.totyear);
      setSelectYear(res.data.totyear);
      console.log("year", SelectYear);
    })
  };
  const [budget, setBudget] = useState([]);
  const [subscription, setSubscription] = useState([]);
  const getBudget = async () => {
    mainAxios.get(`/Api/User/UserType1/5`).then(res => {
      console.log("Budget", res.data);
      setBudget(res.data.Help_budget);
      setSubscription(res.data.Total);
      console.log("year", budget, subscription);
    })


  };
  const PreviousStepBillingInfo = () => {
    setNextStep(NextStep - 1);
    setUserDetails(UserDetails);
    setSelectedValue(0);
    console.log("Back", UserDetails);
  }


  // step 2
  const Month = [{ id: '01' }, { id: "02" }, { id: "03" }, { id: "04" }, { id: "05" }, { id: "06" }, { id: "07" }, { id: "08" }, { id: "09" }, { id: "10" }, { id: "11" }, { id: "12" }]
  const [ExpiredMonth, setExpiredMonth] = useState(Month);
  // console.log("Expired Month",ExpiredMonth);
  const [UserPaymentDetails, setUserPaymentDetails] = useState();
  const [UserPaymentSchema, setUserPaymentSchema] = useState();
  const [selectedState, setSelectedState] = useState();
  const [SelectModule, setSelectmodule] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [pdata, setpdata] = useState(1);
  const PaymentMethod = (data) => {
    console.log("Checkfrst", data, PayByCheck)
    setpdata(data);
    if (data == 1) {
      setPayByCheck(true);
      $('#pcredit').addClass("active");
      $("#pbank").removeClass("active");
      $('#pTab1').addClass('active');
    }
    else if (data == 2) {
      setPayByCheck(false);
      $('#pbank').addClass('active');
      $('#pcredit').removeClass('active');
      $('#pTab2').addClass('active');
    }

    let signInPaymentMethodSchema = Yup.object().shape({
      PaymentMethod: Yup.string(pdata),
      CardHolderName: Yup.string().when("PaymentMethod", (PaymentMethod) => {
        if (data == 1) {
          return Yup.string().required("Card Holder Name is required");
        }
        else {
          return Yup.string();
        }
      }),
      CreditCardNumber: Yup.string().when("PaymentMethod", (PaymentMethod) => {
        if (data == 1) {
          return Yup.string().required("Card Number is required").min(8, "*Minimum (8-16) digits").max(16, "Credit Card Number maximum should be 16 digit");
        }
        else {
          return Yup.string();
        }
      }),
      CardBillingZip: Yup.string().when("PaymentMethod", (PaymentMethod) => {
        if (data == 1) {
          return Yup.string().required("  ").min(3, " ");
        }
        else {
          return Yup.string();
        }
      }),
      Expiration_Month: Yup.string().when("PaymentMethod", (PaymentMethod) => {
        if (data == 1) {
          return Yup.string().required("  ");
        }
        else {
          return Yup.string();
        }
      }),
      Expiration_Year: Yup.string().when("PaymentMethod", (PaymentMethod) => {
        if (data == 1) {
          return Yup.string().required("  ");
        }
        else {
          return Yup.string();
        }
      }),
      SecurityCode: Yup.string().when("PaymentMethod", (PaymentMethod) => {
        if (data == 1) {
          return Yup.string().required("Code is required").min(3, " ").max(4, " ");
        }
        else {
          return Yup.string();
        }
      }),
      CardBillingAddress: Yup.string().when("PaymentMethod", (PaymentMethod) => {
        if (data == 1) {
          return Yup.string().required("Address is required");
        }
        else {
          return Yup.string();
        }
      }),
      CardBillingState1: Yup.array().when("PaymentMethod", (PaymentMethod) => {
        if (data == 1) {
          return Yup.array()
            .min(1, 'State is required')
            .of(
              Yup.object().shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
              })
            ).nullable()
        }
        else {
          return Yup.array().nullable();
        }
      }).nullable(),
      CardBillingCity: Yup.string().when("PaymentMethod", (PaymentMethod) => {
        if (data == 1) {
          return Yup.string().required("City is required");
        }
        else {
          return Yup.string();
        }
      }),
      BankAccountName: Yup.string().when("PaymentMethod", (PaymentMethod) => {
        if (data == 2) {
          return Yup.string().required("Account Name is required");
        }
        else {
          return Yup.string();
        }
      }),
      BankName: Yup.string().when("PaymentMethod", (PaymentMethod) => {
        if (data == 2) {
          return Yup.string().required("Bank Name is required");
        }
        else {
          return Yup.string();
        }
      }),
      BankRoutingNumber: Yup.string().when("PaymentMethod", (PaymentMethod) => {
        if (data == 2) {
          return Yup.string().required("Routing Number is required").min(9, "*Maximum 9 digits");
        }
        else {
          return Yup.string();
        }
      }),
      BankAccountNumber: Yup.string().when("PaymentMethod", (PaymentMethod) => {
        if (data == 2) {
          return Yup.string().required("Account Number is required").min(4, "*Minimum (4-17) digits");
        }
        else {
          return Yup.string();
        }
      }),
      CardBillingAddress1: Yup.string().when("PaymentMethod", (PaymentMethod) => {
        if (data == 2) {
          return Yup.string().required("Address is required");
        }
        else {
          return Yup.string();
        }
      }),
      CardBillingCity1: Yup.string().when("PaymentMethod", (PaymentMethod) => {
        if (data == 2) {
          return Yup.string().required("City is required");
        }
        else {
          return Yup.string();
        }
      }),
      CardBillingState2: Yup.array().when("PaymentMethod", (PaymentMethod) => {
        if (data == 2) {
          return Yup.array()
            .min(1, 'State is required')
            .of(
              Yup.object().shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
              })
            ).nullable()
        }
        else {
          return Yup.array().nullable();
        }
      }).nullable(),
      CardBillingZip1: Yup.string().when("PaymentMethod", (PaymentMethod) => {
        if (data == 2) {
          return Yup.string().required("Code is required").min(3, "*Minimum (3-6) digits");
        }
        else {
          return Yup.string();
        }
      }),
      Modules: Yup.array()
        .min(1, '*Modules Required')
        .of(
          Yup.object().shape({
            label: Yup.string().required(),
            value: Yup.string().required(),
          })
        )
    })
    let initialValuesPaymentMethod = {
      CardHolderName: "",
      CreditCardNumber: "",
      CardBillingZip: "",
      Expiration_Month: "",
      Expiration_Year: "",
      SecurityCode: "",
      CardBillingAddress: "",
      CardBillingCity: "",
      CardBillingState1: [],
      BankAccountName: "",
      BankName: "",
      AccountType: "5",
      NextPaymentDue: "0",
      BankRoutingNumber: "",
      BankAccountNumber: "",
      CardBillingAddress1: "",
      CardBillingState2: [],
      Modules: [],
      CardBillingCity1: "",
      CardBillingZip1: ""
    };
    setUserPaymentSchema(signInPaymentMethodSchema);
    setUserPaymentDetails(initialValuesPaymentMethod);
    console.log("Check", data, PayByCheck)
  }
  const StepToRestaurant = (Values) => {
    console.log("Values Step 2", Values, Values.CardBillingState1, Values.Modules.length, Values.Modules.length <= 0);
    if (pdata == 1) {
      var first_json = UserDetails;
      var second_json = Values;
      function jsonConcat(o1, o2) {
        for (var key in o2) {
          o1[key] = o2[key];
        }
        return o1;
      }
      var output = {};
      var mon = "1";
      output["NextPaymentDate"] = new Date(new Date().setMonth(new Date().getMonth() + parseInt(mon))).toISOString().split('T')[0];
      output["Budget"] = ((selectedValue * 50) + budget).toString();
      output["SubcriptionCharge"] = subscription;
      if (Values.CardBillingState1 !== undefined) {
        output["CardBillingState"] = Values.CardBillingState1.label;
      }
      else {
        output["CardBillingState"] = "";
      }
      output["PaymentMethod"] = "1";
      output = jsonConcat(output, first_json);
      output = jsonConcat(output, second_json);
      delete output['BankName'];
      delete output['BankRoutingNumber'];
      delete output['BankAccountName'];
      delete output['BankAccountNumber'];
      delete output['CardBillingZip1'];
      delete output['CardBillingAddress1'];
      delete output['CardBillingCity1'];
      delete output['CardBillingState2'];
      delete output['CardBillingState1'];
      console.log("output", output);
      // console.log("Values Step 2", Values);
      if (Values.CardHolderName === "" || Values.CreditCardNumber === "" || Values.CardBillingZip === ""
        || Values.Expiration_Month === "" || Values.Expiration_Year === "" || Values.SecurityCode === ""
        || Values.CardBillingAddress === "" || Values.CardBillingCity === "" || Values.Modules.length <= 0 || output['CardBillingState'] === undefined) {
        toast.error("Please Fill Mandatory Fields");
      }
      else if (Values.CreditCardNumber.length < 8) {
        // toast.error('Credit Card Number Invalid')
        console.log("creditcard", Values.CreditCardNumber)
      }
      else if (Values.CardBillingZip.length < 3) {
        // toast.error('Zip code Invaild')
        console.log("CardBillingZip", Values.CardBillingZip)
      }
      else if (Values.SecurityCode.length < 3) {
        // toast.error('Security code Invaild')
        console.log("SecurityCode", Values.SecurityCode)
      }
      else {
        setIsLoading(true);
        let Moduletemp = []
        output.Modules.map((x) => {
          Moduletemp.push(x.value)
        })
        console.log(Moduletemp, "Moduletemp")
        setTimeout(() => {
          output.Modules = Moduletemp
          console.log(output, "data");
          mainAxios.post('/api/Hampton/SaveSignupInfo', output).then(res => {
            console.log(res.data, "STEP 1 Res");
            if (res.data.status == 1) {
              toast.success('Payment Successfully')
              setIsLoading(false);
              setNextStep(NextStep + 1);
              setUserPaymentDetails(Values);
            } else if (res.data.status == 0) {
              setIsLoading(false);
              toast.error('Payment Failed! Please check your Account details')
            }
          })
        }, 500)
      }
    }
    else if (pdata == 2) {
      var first_json = UserDetails;
      var second_json = Values;
      function jsonConcat(o1, o2) {
        for (var key in o2) {
          o1[key] = o2[key];
        }
        return o1;
      }
      var output = {};
      var mon = "1";
      output["NextPaymentDate"] = new Date(new Date().setMonth(new Date().getMonth() + parseInt(mon))).toISOString().split('T')[0];
      //  output["PaymentMethod"]=pdata;
      output["Budget"] = ((selectedValue * 50) + budget).toString();
      output["SubcriptionCharge"] = subscription;
      output["PaymentMethod"] = "2";
      if (Values.CardBillingState2 !== undefined) {
        output["CardBillingState"] = Values.CardBillingState2.label;
      }
      else {
        output["CardBillingState"] = "";
      }
      output["CardBillingZip"] = Values.CardBillingZip1.label;
      output = jsonConcat(output, first_json);
      output = jsonConcat(output, second_json);
      delete output['CardHolderName'];
      delete output['CreditCardNumber'];
      delete output['CardBillingZip'];
      delete output['Expiration_Month'];
      delete output['Expiration_Year'];
      delete output['SecurityCode'];
      delete output['CardBillingAddress'];
      delete output['CardBillingCity'];
      delete output['CardBillingState2'];
      delete output['CardBillingState1'];
      console.log("output1", output);
      if (Values.BankAccountName === "" || Values.BankName === "" || Values.BankAccountNumber === ""
        || Values.BankRoutingNumber === "" || Values.CardBillingAddress1 === "" || Values.CardBillingCity1 === ""
        || Values.Modules.length <= 0 || output['CardBillingState'] === undefined
        || Values.CardBillingZip1 === "") {
        toast.error("Please Fill Mandatory Fields");
      }
      else if (Values.BankAccountNumber.length < 4) {
        // toast.error('Bank Account Number Invalid')
        console.log("BankAccountNumber", Values.BankAccountNumber)
      }
      else if (Values.CardBillingZip1.length < 3) {
        // toast.error('Zip code Invaild')
        console.log("CardBillingZip1", Values.CardBillingZip1)
      }
      else if (Values.BankRoutingNumber.length < 9) {
        // toast.error('Routing Number Invaild')
        console.log("BankRoutingNumber", Values.BankRoutingNumber)
      }
      else {
        setIsLoading(true);
        let Moduletemp = []
        output.Modules.map((x) => {
          Moduletemp.push(x.value)
        })
        setTimeout(() => {
          output.Modules = Moduletemp
          mainAxios.post('/api/Hampton/SaveSignupInfo', output).then(res => {
            console.log(res.data, "STEP 1 Res");
            if (res.data.status == 1) {
              toast.success('Payment Successfully')
              setIsLoading(false);
              setNextStep(NextStep + 1);
              setUserPaymentDetails(Values);
            } else if (res.data.status == 0) {
              setIsLoading(false);
              toast.error('Payment Failed! Please check your Account details')
            }
          })
        }, 500)

      }
    }
  }
  const getState = async () => {
    let temp = []
    mainAxios.get(`Api/Hampton/US_States`).then(res => {
      res.data.statelist.map((x, index) => {
        temp = [...temp, { "id": index, "value": x, "label": x }]
      })
      console.log("temp1", temp);
      setSelectedState(temp)
    })
  };
  const getModules = async () => {
    let temp = []
    mainAxios.get(`Api/User/GetAllUserTypes?id=5`).then(res => {
      console.log(res.data, "item")
      //setSelectmodule(res.data);  
      console.log(res.data.AllUserTypes, "fromdATABYD")

      res.data.AllUserTypes.map((x, index) => {
        temp = [...temp, { "id": index, "value": x.Account_id, "label": x.Account_name }]
      })
      setSelectmodule(temp)
    })
  };

  // Step 3
  const StepTodDashboard = () => {
    navigate("/")
  }
  return (
    <>
      <ToastContainer autoClose={2000} />
      <div className="container-fluid page-head-signup">
        <div className="row">
          <div className='container'>
            <div className='row'>
              <div className="col-xs-12">
                <h1 className="text-center">Sign Up</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="signup-wrapper signup-res">
        <div class="container">
          <div class=" col-md-2" style={{ padding: "0em" }} />
          <div class="col-xs-12 col-md-8 signres1" style={{ padding: "0em" }}>
            <div class="col-xs-12 p-0">
              <div class="container-fluid" id="grad1">
                <div class="card px-0 pt-4 pb-0 mt-3 mb-3">
                  <div class="row">
                    <div class="col-md-12 mx-0 p-0" id="msform">
                      <ul id="progressbar">
                        <li class="active" id="account"><span class="frn-stp">Step 1</span><strong>BASIC INFO</strong></li>
                        <li id="personal" className={NextStep == 1 || NextStep == 2 || NextStep == 3 ? "active" : null}><span class="frn-stp">Step 2</span><strong>BILLING</strong></li>
                        {/* <li id="payment" className={NextStep == 2 || NextStep == 3 ? "active" : null}><span class="frn-stp">Step 3</span><strong>RESTAURANT</strong></li> */}
                        <li id="confirm" className={NextStep == 2 ? "active" : null}><span class="frn-stp">Step 3</span><strong>CONFIRMATION</strong></li>
                      </ul>
                      {/* Step 1 */}
                      {NextStep == 0 && <fieldset>
                        <Formik
                          initialValues={UserDetails}
                          validationSchema={signInSchema}
                        >
                          {(formik) => {
                            const { values,
                              handleChange,
                              handleSubmit,
                              handleBlur,
                              errors,
                              touched,
                              isValid,
                              dirty } = formik;
                            return (
                              <Form class="form-sample">
                                <div class="form-card">
                                  <h4 class="signuph4" >Basic Info - Let's Get Started</h4>
                                  <div class="frm-hr" />
                                  <div class="row">
                                    <div class="row rw-pd">
                                      <div class="col-xs-12 col-sm-6 col-md-6 signalign">
                                        <label class="signup-lab">First Name*</label>
                                        <Field maxLength="20" value={values.FName} class="form-control" autocomplete="off"
                                          onBlur={e => {
                                            console.log("Exist1", Exist);
                                            let someValue = e.currentTarget.value;
                                            if (e.target.value != "" && values.LName != '') {
                                              values.Email2 = someValue.substring(0, 1).toLowerCase().replace(/\s/g, "").replace(/[^\w\s]/gi, "").toLowerCase() + values.LName.toLowerCase().replace(/\s/g, "").replace(/[^\w\s]/gi, "").toLowerCase() + "@fobegolf.com";
                                              mainAxios.get('Api/User/CheckEmailValid/' + values.Email2)
                                                .then((res) => {
                                                  setExist(false);
                                                  console.log(res)
                                                  if (res.data !== 0) {
                                                    setExist(true);
                                                  } else if (res.data == 0) {
                                                    values.Email2 = values.Email2;
                                                    setExist(false);
                                                  }
                                                })
                                                .catch((error) => {
                                                  if (error.response.data.content === "The email has already been taken.") {
                                                    console.log("error", error)
                                                    setExist(false);
                                                  }
                                                })
                                              console.log("Exist2", Exist);
                                            }
                                            else {
                                              values.Email2 = "";
                                              setExist(false);
                                              console.log("Exist3", Exist);
                                            }
                                          }}
                                          name="FName" type="text" placeholder="Enter your First Name"
                                          className={errors.FName && touched.FName ? "form-control actred" : "form-control"} />
                                        <ErrorMessage name="FName" component="span" className="invalid-feedback" />
                                      </div>
                                      <div class="col-xs-12 col-sm-6 col-md-6 signalign">
                                        <label class="signup-lab">Last Name*</label>
                                        <Field name="LName" maxLength="20"
                                          class="form-control" autocomplete="off"
                                          onBlur={e => {
                                            console.log("Exist1", Exist);
                                            let someValue = e.currentTarget.value;
                                            if (e.target.value != "" && values.FName != '') {
                                              values.Email2 = values.FName.substring(0, 1).toLowerCase().replace(/\s/g, "").replace(/[^\w\s]/gi, "").toLowerCase() + someValue.toLowerCase().replace(/\s/g, "").replace(/[^\w\s]/gi, "").toLowerCase() + "@fobegolf.com";
                                              mainAxios.get('Api/User/CheckEmailValid/' + values.Email2)
                                                .then((res) => {
                                                  setExist(false);
                                                  console.log(res)
                                                  if (res.data !== 0) {
                                                    setExist(true);
                                                  } else if (res.data == 0) {
                                                    values.Email2 = values.Email2;
                                                    setExist(false);
                                                  }
                                                })
                                                .catch((error) => {
                                                  if (error.response.data.content === "The email has already been taken.") {
                                                    console.log("error", error)
                                                    setExist(false);
                                                  }
                                                })
                                              console.log("Exist2", Exist);
                                            }
                                            else {
                                              values.Email2 = "";
                                              setExist(false);
                                              console.log("Exist3", Exist);
                                            }
                                          }}
                                          className={errors.LName && touched.LName ? "form-control actred" : "form-control"}
                                          type="text" placeholder="Enter your Last Name" />
                                        <ErrorMessage name="LName" component="span" className="invalid-feedback" />
                                      </div>
                                    </div>
                                    <div class="row rw-pd">
                                      <div class="col-xs-12 col-sm-6 col-md-6 signalign">
                                        <label class="signup-lab">Course Name*</label>
                                        <Field maxLength="20" class="form-control" name="CourseName" autoComplete="off"
                                          type="text" placeholder="Enter your Course Name" className={(errors.CourseName && touched.CourseName) ? "form-control actred" : "form-control"} />
                                        <ErrorMessage name="CourseName" component="span" className="invalid-feedback" />
                                      </div>
                                      <div class="col-xs-12 col-sm-6 col-md-6 signalign">
                                        <label class="signup-lab">User Name*(Auto-generated)</label>
                                        <Field name="Email2" value={values.Email2}
                                          class="form-control" autoComplete="off" readOnly={true}
                                          type="text" placeholder="{firstname}{lastname}@fobegolf.com" className={(errors.Email2 && touched.Email2) || Exist ? "form-control actred" : "form-control"} />
                                        <ErrorMessage name="Email2" component="span" className="invalid-feedback" />
                                        {!errors.Email2 && Exist && <div className="invalid-feedback">Email id already exists</div>}
                                      </div>
                                    </div>
                                    <div class="row rw-pd">
                                      <div class="col-xs-12 col-sm-6 col-md-6 signalign">
                                        <label class="signup-lab">Email*</label>
                                        <Field name="Primary_EMail" type="email" class="form-control" id="Primary_EMail" className={(errors.Primary_EMail && touched.Primary_EMail) || PrimaryEmailExist ? "form-control actred" : "form-control"}
                                          autoComplete="off" placeholder="Enter your personal/work email" />
                                        <ErrorMessage name="Primary_EMail" component="span" className="invalid-feedback" />
                                        {!errors.Primary_EMail && PrimaryEmailExist && <div className="invalid-feedback"  style={{marginTop:"0em"}}>Email id already exists</div>}
                                      </div>
                                      <div class="col-xs-12 col-sm-6 col-md-6 signalign">
                                        <label class="signup-lab">Contact Number*</label>
                                        <Field name="ContactNumber" maxLength="10" pattern="[0-9]*" type="tel" onKeyPress={(event) => {
                                          if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                          }
                                        }} class="form-control" autoComplete="off" className={errors.ContactNumber && touched.ContactNumber ? "form-control actred" : "form-control"}
                                          placeholder="Enter your Contact Number" />
                                        <ErrorMessage name="ContactNumber" component="span" className="invalid-feedback" />
                                      </div>
                                    </div>
                                    <div class="row rw-pd">
                                      <div class="col-xs-12 col-sm-6 col-md-6 signalign">
                                        <label class="signup-lab">Password*</label>
                                        <Field name="Password" maxLength="16" id="Password" autocomplete="off" onKeyPress={(event) => {
                                          if (event.charCode == 38 || event.charCode == 94 || event.charCode == 124 || event.charCode == 34) {
                                            event.preventDefault();
                                          }
                                        }}
                                          type="password" placeholder="Enter your Password" className={errors.Password && touched.Password ? "form-control actred" : "form-control"} />
                                        <ErrorMessage name="Password" component="span" className="invalid-feedback" />
                                      </div>
                                      <div class="col-xs-12 col-sm-6 col-md-6 signalign">
                                        <label class="signup-lab">Confirm Password*</label>
                                        <Field name="CPassword" maxLength="16" autoComplete="off" onKeyPress={(event) => {
                                          if (event.charCode == 38 || event.charCode == 94 || event.charCode == 124 || event.charCode == 34) {
                                            event.preventDefault();
                                          }
                                        }}
                                          type="password" placeholder="Enter your Confirm Password" className={errors.CPassword && touched.CPassword ? "form-control actred" : "form-control"} />
                                        <ErrorMessage name="CPassword" component="span" className="invalid-feedback" />
                                      </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-12 col-md-12 ul-non-li" style={{ marginLeft: "-23px" }} >
                                      <ul>
                                        <li>Password can contain Alphanumeric characters</li>
                                        <li>Use a Number or special charecter(Excluding & ^ |)</li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="frm-hrsmall" />
                                </div>
                                <button type="submit" className="next action-button" onClick={() => StepBillingInfo(values)}
                                >Continue to Step 2 <i class="fa fa-angle-double-right" aria-hidden="true"></i></button>
                              </Form>
                            );
                          }}
                        </Formik>
                      </fieldset>}
                      {/* Step 2 */}
                      {NextStep == 1 && <fieldset>
                        <Formik
                          initialValues={UserPaymentDetails}
                          validationSchema={UserPaymentSchema}
                        // onSubmit={StepToRestaurant}
                        >
                          {(formik) => {
                            const { values,
                              handleChange,
                              handleSubmit,
                              handleBlur,
                              errors,
                              touched,
                              isValid,
                              dirty } = formik;
                            return (
                              <Form class="form-sample">
                                <div class="form-card">
                                  <div class="row">
                                    <div class="col-xs-6">
                                      <div class="title_head account-conformation fill-form up-fill-form">
                                        <h4 class="signuph4" >Billing & Plan</h4>
                                      </div>
                                    </div>
                                    <div class="col-xs-6">
                                      <img src="https://d2a7u30t6wbkim.cloudfront.net/assets/img/Visa.png" alt="visaimage" width={"95px"} class="visaimg" />
                                    </div>
                                  </div>
                                  <div class="frm-hr" />
                                  <div class="main-contdiv">
                                    <ul class="prodNav" style={{ marginLeft: "-43px" }}>
                                      <a href="#pTab1" data-toggle="tab" style={{ color: "black" }}>
                                        <li className="ptItem active" id="pcredit" onClick={() => PaymentMethod(1)}>Pay By Credit Card </li>
                                      </a>
                                      <a href="#pTab2" data-toggle="tab" style={{ color: "black" }}>
                                        <li className="ptItem" id="pbank" onClick={() => PaymentMethod(2)}>Pay By eCheck/Bank</li>
                                      </a>
                                    </ul>
                                    <div class="hr-1" />
                                    <div class="col-md-8 col-sm-12 p-0">
                                      <div class="prodBody">
                                        {PayByCheck ? (<div class="prodMain active" id="pTab1">
                                          <div class="row">
                                            <div class="col-md-12 col-sm-12 pd-0">
                                              <div class="row rw-pd">
                                                <div class="col-md-8 col-sm-12 pr-1 p-0 signalign">
                                                  <label class="signup-lab">Card Number</label>
                                                  <Field type="text" name="CreditCardNumber" autocomplete="off"
                                                    id="CreditCardNumber" class="form-control" placeholder="Your Card Number" maxLength="16" onKeyPress={(event) => {
                                                      if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                      }
                                                    }}
                                                    className={errors.CreditCardNumber && touched.CreditCardNumber ? "form-control actred" : "form-control"} />
                                                  <ErrorMessage name="CreditCardNumber" component="span" className="invalid-feedback" />
                                                </div>
                                                <div class="col-md-4 col-sm-12 p-0 pr-1 signalign">
                                                  <label class="signup-lab">Security_Code</label>
                                                  <Field type="text" name="SecurityCode" autocomplete="off" placeholder="****" onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                      event.preventDefault();
                                                    }
                                                  }}
                                                    style={{ paddingRight: "30%" }} maxLength="4"
                                                    id="SecurityCode" class="form-control"
                                                    className={errors.SecurityCode && touched.SecurityCode ? "form-control actred" : "form-control"}
                                                  />
                                                  <ErrorMessage name="SecurityCode" component="span" className="invalid-feedback" />
                                                </div>
                                                <img src="https://d2a7u30t6wbkim.cloudfront.net/assets/img/Security_Code.svg"
                                                  alt="securitycode" class="securitycodecss" />
                                              </div>
                                            </div>
                                            <div class="row rw-pd">
                                              <div class="col-md-8 col-sm-12 pr-1 p-0 signalign">
                                                <label class="signup-lab">Card Holder Name*</label>
                                                <Field type="text" name="CardHolderName" autocomplete="off" placeholder="****"
                                                  style={{ paddingRight: "30%" }}
                                                  id="CardHolderName" class="form-control"
                                                  className={errors.CardHolderName && touched.CardHolderName ? "form-control actred" : "form-control"}
                                                />
                                                <ErrorMessage name="CardHolderName" component="span" className="invalid-feedback" />
                                              </div>
                                              <div class="col-md-4 col-sm-12 pr-1 p-0 signalign">
                                                <label class="signup-lab">Expiration*</label>
                                                <div class="row rw-pd">
                                                  <div class=" col-xs-12 col-md-12 p-0">
                                                    <div class=" col-xs-6 col-md-5 p-0">
                                                      {/* <Field component="select" id="Expiration_Month" name="Expiration_Month"
                                                        class="form-control select"
                                                        className={errors.Expiration_Month && touched.Expiration_Month || errors.Expiration_Month == "" ? "form-control actred" : "form-control"} aria-placeholder="MM" style={{ padding: "0px 4px", marginTop: "2px" }}>
                                                        <option value="" disabled selected>MM</option>
                                                        <option value="1"> hi</option>
                                                      </Field> */}
                                                      <Field component="select" name="Expiration_Month" class="form-control select"
                                                        className={errors.Expiration_Month && touched.Expiration_Month ? "form-control actred" : "form-control"} aria-placeholder="MM" style={{ padding: "0px 4px", marginTop: "2px" }}>
                                                        <option disabled value="" selected>MM</option>
                                                        {ExpiredMonth.map((Month) => {
                                                          return (
                                                            <option value={Month.id}>{Month.id}</option>);
                                                        })}
                                                      </Field>
                                                      <ErrorMessage name="Expiration_Month" component="span" className="invalid-feedback" />
                                                    </div>
                                                    <div class="col-xs-6 col-md-7 p-0 pl-1">
                                                      {/* <Field component="select" id="Expiration_Year" name="Expiration_Year" style={{ padding: "0px 4px", marginTop: "2px" }} aria-placeholder="YYYY"
                                                        className={errors.Expiration_Year && touched.Expiration_Year || errors.Expiration_Year == "" ? "form-control actred" : "form-control"}
                                                        class="form-control select">
                                                        <option value="" disabled selected>YYYY</option>
                                                        <option value="1"> hi</option>
                                                      </Field> */}
                                                      <Field component="select" name="Expiration_Year" class="form-control select"
                                                        className={errors.Expiration_Year && touched.Expiration_Year ? "form-control actred" : "form-control"} aria-placeholder="MM" style={{ padding: "0px 4px", marginTop: "2px" }}>
                                                        <option disabled value="" selected>YYYY</option>
                                                        {SelectYear.map((Month) => {
                                                          return (
                                                            <option value={Month.year}>{Month.year}</option>);
                                                        })}
                                                      </Field>
                                                      <ErrorMessage name="Expiration_Year" component="span" className="invalid-feedback" />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="row rw-pd">
                                              <div class="col-md-12 col-sm-12 pr-1 p-0 signalign">
                                                <label class="signup-lab">Billing Street Address*</label>
                                                <Field type="text" name="CardBillingAddress"
                                                  placeholder="Your Billing Street Address" autocomplete="off"
                                                  id="CardBillingAddress" class="form-control"
                                                  className={errors.CardBillingAddress && touched.CardBillingAddress ? "form-control actred" : "form-control"} />
                                                <ErrorMessage name="CardBillingAddress" component="span" className="invalid-feedback" />
                                              </div>
                                            </div>
                                            <div class="row rw-pd">
                                              <div class="col-md-4 col-sm-12 pr-1 p-0 signalign">
                                                <label class="signup-lab">City*</label>
                                                <Field type="text" name="CardBillingCity" placeholder="Billing City"
                                                  id="CardBillingCity" class="form-control" autocomplete="off"
                                                  className={errors.CardBillingCity && touched.CardBillingCity ? "form-control actred" : "form-control"} />
                                                <ErrorMessage name="CardBillingCity" component="span" className="invalid-feedback" />
                                              </div>
                                              <div class="col-md-5 col-sm-12 pr-1 p-0 signalign">
                                                <label class="signup-lab" style={{ marginBottom: "2px" }}> State*</label>
                                                <Select
                                                  className={errors.CardBillingState1 && touched.CardBillingState1 ? "basic-single" : null}
                                                  name="CardBillingState1"
                                                  id="CardBillingState1"
                                                  placeholder='Select State'
                                                  value={values.CardBillingState1}
                                                  onChange={selectedOption => {
                                                    console.log("selectedOption", selectedOption)
                                                    let event = { target: { name: 'CardBillingState1', value: selectedOption } }
                                                    handleChange(event)
                                                  }}
                                                  options={selectedState ? selectedState : []}
                                                />
                                                <ErrorMessage name="CardBillingState1" component="span" className="invalid-feedback" />
                                              </div>
                                              <div class="col-md-3 col-sm-12 pr-1 p-0 signalign">
                                                <label class="signup-lab">Zip*</label>
                                                <Field type="text" name="CardBillingZip" placeholder="Billing Zip Code"
                                                  id="CardBillingZip" class="form-control" maxLength="6"
                                                  onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                      event.preventDefault();
                                                    }
                                                  }}
                                                  className={errors.CardBillingZip && touched.CardBillingZip ? "form-control actred" : "form-control"}
                                                />
                                                <ErrorMessage name="CardBillingZip" style={{ color: "white" }} component="span" className="invalid-feedback" />
                                              </div>
                                            </div>
                                          </div>
                                        </div>) : (
                                          <div class="prodMain" id="pTab2">
                                            <div class="row mmr-1">
                                              <div class="col-md-12 p-0">
                                                <img src="https://d1qhh2rihbnflk.cloudfront.net/images/checkimg2.png"
                                                  width="100%" height="270px" />
                                              </div>
                                            </div>
                                          </div>)}
                                      </div>
                                    </div>
                                    <div class="col-md-4 col-sm-12 p-0">
                                      <h5 class="sub-tit">Your Plan</h5>
                                      <div class="frm-hr" />
                                      <div class="col-xs-12 p-0">
                                        <div class="col-xs-4 p-0">
                                          <label class="charge-n">Budget</label>
                                        </div>
                                        <div class="col-xs-6 colan-n"></div>
                                        <div class="col-xs-2 pd-0">
                                          <div class="charge-n" style={{marginLeft:"3px"}}>
                                            <span class="dol-1">$</span>
                                            <span name="Budget" formControlName="Budget" id="Budget">{budget}.00</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-xs-12 p-0">
                                        <div class="col-xs-4 p-0">
                                          <label class="charge-n">Subscription</label>
                                        </div>
                                        <div class="col-xs-6 colan-s"></div>
                                        <div class="col-xs-2 pd-0">
                                          <div class="charge-n">
                                            <span class="dol-1">$</span>
                                            <span name="Budget" formControlName="Budget" id="Budget">{subscription}.00</span>
                                          </div>
                                        </div>
                                        {/* <div class="frm-hr" style={{ marginTop: "2em" }} /> */}
                                      </div>
                                      {/* <div class="col-xs-12 p-0">
                                        <div class="col-xs-4 p-0">
                                          <label class="charge-n">Discount</label>
                                        </div>
                                        <div class="col-xs-6 colan-n"></div>
                                        <div class="col-xs-2 pd-0">
                                          <div class="charge-n">
                                            <span class="dol-1">$</span>
                                            <span name="Budget" formControlName="Budget" id="Budget">399.00</span>
                                          </div>
                                        </div>
                                      </div> 
                                      <div class="col-xs-6 pd-0  mt-1">
                                        <input type="text" name="AccessCode" formControlName="AccessCode" id="AccessCode" autocomplete="off"
                                          placeholder="Promo Code" style={{ padding: " 5px 5px " }} />
                                      </div>
                                      <div class="col-xs-6 pr-0 mt-1">
                                        <button class="ap-bt" color="primary" type="submit"
                                          id="apply_btn" data-loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Checking">Apply</button>
                                      </div>  */}
                                      <div class="col-md-12 col-xs-12 p-0 signalign" >
                                        <div class="signup-lab-module">Modules*</div>
                                        <MultiSelect
                                          className={errors.Modules && touched.Modules ? 'Multiselectrmsc' : null}
                                          options={SelectModule ? SelectModule : []}
                                          value={values.Modules}
                                          onChange={selectedOption => {
                                            console.log("selectedOptionmodule", selectedOption)
                                            let event = { target: { name: 'Modules', value: selectedOption } }
                                            handleChange(event)
                                            setSelectedValue(event.target.value.length)
                                            console.log("jhhgffggf", selectedValue, event.target.value.length)
                                          }}
                                          name="Modules"
                                          hasSelectAll={true}
                                          selectionType="counter"
                                          labelledBy="Select"
                                        // className='select'
                                        />
                                        {selectedValue > 0 &&
                                          <span style={{ fontSize: "0.8em" }}>{selectedValue} Module Selected</span>}
                                        <ErrorMessage name="Modules" component="span" className="invalid-feedback" />
                                      </div>
                                      <div class="col-xs-12 p-0 mT-2">
                                        <div class="col-xs-4 p-0">
                                          <label class="charge-n">Modules</label>
                                        </div>
                                        <div class="col-xs-6 colan-nm"></div>
                                        <div class="col-xs-2 pd-0">
                                          <div class="charge-n" style={{ textAlign: "right" }}>
                                            <span class="dol-1">$</span>
                                            <span name="Budget" formControlName="Budget" id="Budget">{selectedValue * 50}.00</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-12 p-0" style={{ marginTop: "0.5em" }}>
                                        <div class="col-md-12 p-0" style={{ marginTop: "9px" }}>
                                          <div class="col-xs-12  p-0">
                                            <div class="col-xs-4 p-0">
                                              <label class="charge-t">Total </label>
                                            </div>
                                            <div class="col-xs-6 colan-t"></div>
                                            <div class="col-xs-2 p-0">
                                              <div class="charge-t">
                                                <span class="dol-1" style={{ color: "#000000" }}>$</span>
                                                <span>{(selectedValue * 50) + budget + subscription}.00</span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {!PayByCheck && <div class="col-md-12" style={{ padding: "0", paddingBottom: "1em" }}>
                                      <div class="row">
                                        <div class="col-md-6 col-sm-12 mt-1 signalign">
                                          <label class="signup-lab"><span class="Routing">1</span> Routing Number*</label>
                                          <Field type="text" name="BankRoutingNumber" placeholder="" maxLength="9" minLength="9"
                                            autocomplete="off" id="BankRoutingNumber" class="form-control"
                                            onKeyPress={(event) => {
                                              if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                              }
                                            }}
                                            className={errors.BankRoutingNumber && touched.BankRoutingNumber || errors.BankRoutingNumber == "" ? "form-control actred" : "form-control"} />
                                          <ErrorMessage name="BankRoutingNumber" component="span" className="invalid-feedback" />
                                        </div>
                                        <div class="col-md-6 col-sm-12 mt-1 signalign">
                                          <label class="signup-lab"><span class="Routing">2</span> Account Number*</label>
                                          <Field type="text" name="BankAccountNumber" placeholder="" maxLength="17" minlLength="4"
                                            id="BankAccountNumber" autocomplete="off" class="form-control"
                                            onKeyPress={(event) => {
                                              if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                              }
                                            }}
                                            className={errors.BankAccountNumber && touched.BankAccountNumber || errors.BankAccountNumber == "" ? "form-control actred" : "form-control"} />
                                          <ErrorMessage name="BankAccountNumber" component="span" className="invalid-feedback" />
                                        </div>
                                        <div class="col-md-12 col-sm-12  signalign">
                                          <label class="signup-lab">Name on Account*</label>
                                          <Field type="text" name="BankAccountName" placeholder=""
                                            autocomplete="off" maxLength="22"
                                            id="BankAccountName" class="act-wd form-control"
                                            className={errors.BankAccountName && touched.BankAccountName || errors.BankAccountName == "" ? "form-control actred" : "form-control"} />
                                          <ErrorMessage name="BankAccountName" component="span" className="invalid-feedback" />
                                        </div>
                                        <div class="col-md-6 col-sm-12  signalign" >
                                          <label class="signup-lab">Bank Name*</label>
                                          <Field type="text" name="BankName" autocomplete="off" placeholder="" maxLength="50" id="BankName" class="act-wd form-control"
                                            className={errors.BankName && touched.BankName || errors.BankName == "" ? "form-control actred" : "form-control"} />
                                          <ErrorMessage name="BankName" component="span" className="invalid-feedback" />
                                        </div>
                                        <div class="col-md-6 col-sm-12 signalign">
                                          <label class="signup-lab">  Billing Street Address*</label>
                                          <Field type="text" name="CardBillingAddress1"
                                            placeholder="Your Billing Street Address" autocomplete="off"
                                            id="CardBillingAddress1" class="form-control"
                                            className={errors.CardBillingAddress1 && touched.CardBillingAddress1 || errors.CardBillingAddress1 == "" ? "form-control actred" : "form-control"}
                                          />
                                          <ErrorMessage name="CardBillingAddress1" component="span" className="invalid-feedback" />
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-md-4 col-sm-12 signalign" >
                                          <label class="signup-lab">City*</label>
                                          <Field type="text" name="CardBillingCity1" placeholder="Billing City"
                                            id="CardBillingCity1" class="form-control" autocomplete="off"
                                            className={errors.CardBillingCity1 && touched.CardBillingCity1 || errors.CardBillingCity1 == "" ? "form-control actred" : "form-control"}
                                          />
                                          <ErrorMessage name="CardBillingCity1" component="span" className="invalid-feedback" />
                                        </div>
                                        <div class="col-md-5 col-sm-12 signalign">
                                          <label class="signup-lab" style={{ marginBottom: "2px" }}>State*</label>
                                          <Select
                                            className={errors.CardBillingState2 && touched.CardBillingState2 ? "basic-single" : null}
                                            name="CardBillingState2"
                                            id="CardBillingState2"
                                            placeholder='Select State'
                                            value={values.CardBillingState2}
                                            onChange={selectedOption => {
                                              console.log("selectedOption", selectedOption)
                                              let event = { target: { name: 'CardBillingState2', value: selectedOption } }
                                              handleChange(event)
                                            }}
                                            options={selectedState ? selectedState : []}
                                          />
                                          <ErrorMessage name="CardBillingState2" component="span" className="invalid-feedback" />
                                        </div>
                                        <div class="col-md-3 col-sm-12 signalign">
                                          <label class="signup-lab">Zip*</label>
                                          <Field type="text" name="CardBillingZip1" placeholder="Billing Zip Code"
                                            onKeyPress={(event) => {
                                              if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                              }
                                            }}
                                            id="CardBillingZip1" class="form-control" maxLength="6" autocomplete="off" minLength="3"
                                            className={errors.CardBillingZip1 && touched.CardBillingZip1 || errors.CardBillingZip1 == "" ? "form-control actred" : "form-control"} />
                                          <ErrorMessage name="CardBillingZip1" component="span" className="invalid-feedback" />
                                        </div>
                                      </div>
                                    </div>}
                                    <div clas="row">
                                      <div className='col-md-12 p-0' >
                                        <div class="frm-hrsmall top-space-signup" />
                                        <div className='col-md-6 p-0' >
                                          <button class="nav-link next action-button active btn cng-pst" onClick={() => PreviousStepBillingInfo()}>
                                            <i class="fa fa-angle-double-left" aria-hidden="true"></i> Back</button>
                                        </div>
                                        <div className='col-md-6 p-0' >
                                          {isLoading ? (<><Spinner /> <button type="submit" class="next action-button" onClick={() => StepToRestaurant(values)}>Continue <i class="fa fa-angle-double-right" aria-hidden="true"></i></button></>) :
                                            (<button type="submit" class="next action-button" onClick={() => StepToRestaurant(values)}>Continue <i class="fa fa-angle-double-right" aria-hidden="true"></i></button>)}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Form>);
                          }}
                        </Formik>
                      </fieldset>}
                      {/* end */}
                      {/* {NextStep == 2 &&
                        <fieldset>
                          <form class="form-sample">
                            <div class="form-card">
                              <h4 class="signuph4">Restaurant Info</h4>
                              <div class="frm-hr" style={{ marginBottom: "10px" }} />
                              <div class="row">
                                <div class="row">
                                  <div class="col-md-6" style={{ marginLeft: "10px" }}>
                                    <div class="radio-item">
                                      <input type="radio" id="ritema" name="ritem" value="ropt1" />
                                      <label for="ritema">12 Month</label>
                                    </div>
                                    <div class="radio-item">
                                      <input type="radio" id="ritemb" name="ritem" value="ropt2" />
                                      <label for="ritemb">13 - 4 Week</label>
                                    </div>
                                  </div>
                                </div>
                                <div class="row" style={{ marginTop: "18px" }}>
                                  <div class="col-md-12">
                                    <div class="col-md-4 col-xs-12 ">
                                      <label class="signup-lab-restaturant">Expected Annual Sales(Net)</label>
                                      <div class="col-md-12 p-0">
                                        <span class="dloler-txt">$</span>
                                        <input type="text" name="budget_amount1" placeholder="Enter your annual sales" autocomplete="off" value=""
                                          formControlName="budget_amount1" maxlength="8" class="form-control"
                                          style={{ width: "93%" }} />
                                      </div>
                                      <label class="chk-tick">
                                        <input type="checkbox" formControlName="Checkbox1" className='checktick' />
                                        <span style={{ marginLeft: "0.8em" }}>Come back to this later</span></label>
                                    </div>
                                    <div class="col-md-4 col-xs-12 ">
                                      <label class="signup-lab-restaturant">Annual Manager Salaries</label>
                                      <div class="col-md-12 p-0">
                                        <span class="dloler-txt">$</span>
                                        <input type="text" name="AnnualSalaries" placeholder="Enter Your Manager Salaries"
                                          autocomplete="off" formControlName="AnnualSalaries" maxlength="8" id="AnnualSalaries" style={{ width: "93%" }} />
                                      </div>
                                      <label class="chk-tick">
                                        <input type="checkbox" formControlName="Checkbox2"
                                          value="5" className='checktick' />
                                        <span style={{ marginLeft: "0.8em" }}>Come back to this later</span></label>
                                    </div>
                                    <div class="col-md-4 col-xs-12 ">
                                      <label class="signup-lab-restaturant">Annual Rent </label>
                                      <div class="col-md-12 p-0">
                                        <span class="dloler-txt">$</span>
                                        <input type="text" name="AnnualRent" placeholder="Enter Your Annual Rent " autocomplete="off"
                                          formControlName="AnnualRent" maxlength="8" style={{ width: "93%" }} />
                                      </div>
                                      <label class="chk-tick">
                                        <input type="checkbox" formControlName="Checkbox3"
                                          value="6" className='checktick' />
                                        <span style={{ marginLeft: "0.8em" }}>Come back to this later</span></label>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-12">
                                    <div class="border">
                                      <p><img src="https://d2a7u30t6wbkim.cloudfront.net/assets/img/file-icon.png" style={{ textAlign: "center", margin: "auto" }} /></p>
                                      <div class="row">
                                        <div class="col-md-12">
                                          <form>

                                            <input class="file-upload-input" style={{ cursor: "pointer" }} type='file' id="browse" name="Budgetfile" multiple
                                              accept="application/pdf,image/jpeg,application/msword,application/vnd.ms-excel,image/jpeg, image/jpg,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/png,.docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                                            <p class="spn-gr1" target="#browse">Browse.</p>
                                            <p class="spn-gr2"> Supports: .PDF, .DOC, .DOCX, .JPG, .PNG, .XLSX</p>

                                          </form>
                                          <div >
                                          <div class="upld-sec">
                                            <div class="row all-sec-up">
                                              <div class="col-md-10">
                                                <div style={{ paddingTop: "3px" }}>
                                                  <span><i class="fa fa-file-text-o fil-icn-upld" ></i></span>

                                                </div>
                                              </div>
                                              <div class="col-md-2  progrss-cls" >
                                                <span style={{ float: "left" }}>

                                                  <div class="progress blue">
                                                    <span class="progress-left">
                                                      <span class="progress-bar"></span>
                                                    </span>
                                                    <span class="progress-right">
                                                      <span class="progress-bar"></span>
                                                    </span>
                                                    <div class="progress-value"><i class="fa fa-check"></i></div>
                                                  </div>
                                                </span>
                                                <span id="" style={{ float: "right", paddingTop: "3px", cursor: "pointer" }}> <i class="material-icons upld-cls" >close</i></span>
                                              </div>
                                            </div>
                                          </div> 
                                  </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="frm-hrsmall" />
                            </div>
                            <button type="submit" name="make_payment" id="finish" class="next fin-btn action-button" onClick={() => StepToConfirmation()} >Finish <i class="fa fa-thumbs-o-up" aria-hidden="true"></i></button>
                          </form>
                        </fieldset>} */}

                      {/* Final Step 3 */}
                      {NextStep == 2 &&
                        <fieldset>
                          <div class="form-card">
                            <div class="row">
                              <h4 class="signuph4">Thanks for Signing Up in FobeGolf!
                              </h4>
                              <div class="frm-hr" />
                            </div>
                            <div class="row justify-content-center">
                              <div class="col-md-12 pd-0">
                                <p style={{ fontSize: "16px", color: "black", textAlign: "left" }}> Our budget specialist will be in touch with you in the next 24 hours. In the meantime, you can visit your
                                  <span class="spn-gr" style={{ cursor: "pointer" }} onClick={() => StepTodDashboard()}> login </span>and begin familiarizing yourself with your new software.</p>
                                <p style={{ fontSize: "16px", color: "black", textAlign: "left" }}>Please check your email for your username and password.
                                </p>
                              </div>
                            </div>
                            <div class="row" style={{ marginTop: "3em" }}>
                              <div class="frm-hrsmall" />
                            </div>
                            <div class="row justify-content-center">
                              <div class="col-7 text-center">
                                <button class=" action-button" type="button" style={{ marginRight: "0%" }} id="login_btn" onClick={() => StepTodDashboard()}> Go To Login
                                  <i class="fa fa-arrow-right" aria-hidden="true" style={{ marginLeft: "0.5em" }}></i></button>
                              </div>
                            </div>
                          </div>
                        </fieldset>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class=" col-md-2" style={{ padding: "0em" }} />
        </div>
      </section>
    </>
  );

}

export default SignUp;