import '../Header/header.css'
import 'bootstrap/dist/css/bootstrap.css';
import { NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from "react-router-dom";
import { useEffect ,useState } from 'react';
import { LocalStorageService } from '../Services/LocalStorageService';


const  Pageheader = () => {
  const userDetail = JSON.parse(localStorage.getItem("UserClaims"));
  const[showuploadinvoice,setshowuploadinvoice]=useState(false)
  const[name,setName]=useState('USER');
  const [token,setToken]=useState(null);
  let navigate = useNavigate();
  const nav=()=>{
    localStorage.removeItem('auth-token');
    localStorage.removeItem('UserClaims');

    navigate("/")
    
  }

  useEffect(()=>{
    const localStorageService = LocalStorageService.getService();
    setToken(localStorageService.getAccessToken("auth-token"));
    if 
    (((userDetail.Special_user == 0 || userDetail.Special_user == 1) &&  userDetail.Account_Type != 0))
   {
    setshowuploadinvoice(true);
  } 
  else{
    setshowuploadinvoice(false);
  }
  },[])
  const homeorlogin=(e)=>{
      navigate('/home')
  }
 const[teamMemberLogin,setteamMemberLogin]=useState(true);

  useEffect(() => {
    
      const items= JSON.parse(localStorage.getItem('UserClaims'));

   if(items!=null){
   
    if(items.Member_Type==2){
      setteamMemberLogin(false)
    }
  
    setName(items.Username)
   

   
  }


    
  }, []);


//   useEffect(() => {
   
//   // let obj=JSON.parse(items);

  
 
  
   
    
// }, []);


const [activeItem, setActiveItem] = useState('Profile');

const handleSelect = (eventKey) => {
 
    setActiveItem(eventKey);

};
    return (
      <>
      <nav className="navbar navbar-static-top navbar-inverse bg-inverse mt-2">
  <div className="container-fluid">
    <div className='container'>  
    <div className='row'>
        {/* <div className='col-md-12 pd-0' >
    <div class="info-cnt">
                                <div class="info-mble"><a href="tel:9045380730" style={{marginBottom:'0',fontSize:'16px',cursor:'pointer',color:'#7B7B7B'}}>904-538-0730</a></div>
                                <span class="lin-br"></span>
                                <div class="info-email">support@fobegolf.com</div>
                                <div class="info-social">
                                    <li class=""> <a href="#"><i class="bi bi-facebook social_icon" title="facebook"></i></a></li>
                                    <li class=""><a href="#"><i class="bi bi-youtube social_icon" title="youtube"></i> </a></li>
                                    <li class=""><a href="#"><i class="bi bi-linkedin social_icon" title="linkedin"></i></a></li>
                                    <li class=""> <a href="#"><i class="bi bi-instagram social_icon" title="instagram"></i></a></li>
                                   
                                
                                
                               
                                    <div><i class="fab fa-facebook-f"></i></div>
                                    <div><i class="fab fa-youtube"></i></div>
                                    <div><i class="fab fa-linkedin-in"></i></div>
                                    <div><i class="fab fa-instagram"></i></div>
                                </div>
                            </div>
                            </div> */}
    <div className="navbar-header">
      <button className="navbar-toggle" type="button" data-toggle="collapse" data-target=".navbarSupportedContent">    
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>   
      </button>
    
      <div class="col-md-12 col-sm-3 col-xs-12 brand-logo hdrogo">
                        <a  title="Fobegolf-Logo"  onClick={homeorlogin}>
                            <img src="https://d2a7u30t6wbkim.cloudfront.net/assets/fobegolf/image/logo.png" class="img100" alt="FobeGolf-Logo" title="FobeGolf-Logo"/>
                        </a>
                    </div>
    </div>
   
    <div className="collapse navbar-collapse navbarSupportedContent">
    <ul class="nav navbar-nav">
                                        <li><a className='link 'onClick={() => navigate("/home") } >Home</a></li>
                                    {  teamMemberLogin &&(  <li><a className='link'onClick={() => navigate("/businessjournal") } >Business Outlook</a></li>)}
                                        <li><a className='link'onClick={() => navigate("/snapshot") }> At a Glance </a></li>
    
                                        <a href="#" className="bt_bt">
    <Dropdown className="dr_op" onSelect={handleSelect} >

<Dropdown.Toggle id="dropdown-button-dark-example1" className='dr-ml' variant="dark">
  <img className="im_g" src="https://d2a7u30t6wbkim.cloudfront.net/assets/hampton_connect/assets/images/man.png" /> <span class="sp_an">{name}</span></Dropdown.Toggle>
<Dropdown.Menu variant="dark"> 
 {/* className="Me-nu"> */}

  <Dropdown.Item onClick={() => navigate("/Profile")}   eventKey="Profile" className={activeItem === 'Profile' ? 'active' : ''} ><i class="bi bi-person-fill"></i> Profile</Dropdown.Item>

  {teamMemberLogin &&(<> <Dropdown.Item onClick={() => navigate("/Manageteam")  }  eventKey="Manage Team Members" className={activeItem === 'Manage Team Members' ? 'active' : ''} ><i class="bi bi-people-fill"></i> Manage Team Members</Dropdown.Item>

  <Dropdown.Item onClick={() => navigate("/Importteam") } eventKey="Import Team Members" className={activeItem === 'Import Team Members' ? 'active' : ''} ><i class="bi bi-file-earmark-text"></i> Import Team Members</Dropdown.Item>
  {showuploadinvoice ?

<Dropdown.Item onClick={() => navigate("/InvoiceDashboard") }  eventKey="Invoice Dashboard" className={activeItem === 'Invoice Dashboard' ? 'active' : ''}><i class="bi bi-cloud-arrow-down"></i> Invoice Dashboard</Dropdown.Item>
  :null}
  </>)
}
  
  <Dropdown.Item onClick={() => nav() } className="la_st"><i class="bi bi-box-arrow-right"></i> Log Out</Dropdown.Item>

</Dropdown.Menu>

</Dropdown>
</a>

                                       
                                    </ul>
                                    </div> 
                                    </div> 
    </div>
  </div>
</nav> 
     </> 
    );
};

export default Pageheader;