import Table from "react-bootstrap/Table";
import { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import "../Datepagenator.css";
import "./TableComponent.css";
import "../../index.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { fobeAxios, mainAxios } from "../../middleware/interceptor";
import Pagination from "@mui/material/Pagination";
import { ToastContainer,toast } from "react-toastify";
import { PaginationItem } from "@mui/material";

import image from "../../folder.png";
import { WrapText } from "@material-ui/icons";
import { Container, Row, Col } from 'react-bootstrap';
import numeral from 'numeral';

const TableComponent1 = ({
  user_up,
  set_user_up,
  header,
  data,
  nestedColumn,
  searchFunction,
  Search,
  pagination,
  getPage,
  isFooter,
  editUser,
  loader,
  DeleteUser,
  searchstate,
  fromdate,
    todate,
    handleDateChange,
    getData,
    setdropdown,
    dropdown,
    handleDropdownChange
}) => {
  console.log(searchstate, "dataa");

  const [notespopup, setNotesPopup] = useState(false);
  const renderTooltip = (props) => <Tooltip {...props}>Edit</Tooltip>;
  const renderTooltip1 = (props) => <Tooltip {...props}>Delete</Tooltip>;
  const [show, setShow] = useState(false);
  const [recordPerPage, setRecordPerPage] = useState(5);
  const[payload ,setpayload]=useState()
  const [users, setUser] = useState(false);
  const [deleteitem, setitem] = useState();
  const [selectedUploadIds, setSelectedUploadIds] = useState([]);
const[notes,setNotes]=useState([])
  let [length, setlength] = useState(recordPerPage);

  useEffect(() => {
 
      setUser(data.list.slice(0, recordPerPage));
      console.log("data:", users);
      setShow(true);
    
  }, [data, length,dropdown]);

  useEffect(() => {
    setUser(
      data.list.slice(0 * recordPerPage, 0 * recordPerPage + recordPerPage)
    );
  }, [recordPerPage, length]);

const viewfile=(item)=>{
    window.open(item);
}
  const handlePageChange = (event, value) => {
    value = value - 1;
    setUser(
      data.list.slice(
        value * recordPerPage,
        value * recordPerPage + recordPerPage
      )
    );
  };

  const handlechange = (e) => {
    editUser(e);
    
  };
  
  const handleRowSelect = (uploadId, restId) => {
    if (selectedUploadIds.includes(uploadId)) {
      // If the row was already selected, remove it from the array
      setSelectedUploadIds(selectedUploadIds.filter(id => id !== uploadId));

    } else {
      // If the row was not selected, add it to the array
      setSelectedUploadIds([...selectedUploadIds, uploadId]);
  

    }
   // Create a new array of objects with the selectedUploadIds
   const uploadIdsArray = selectedUploadIds.map(id => ({ upload_id: id }));

   // Create the payload object
//    const payloadObj = { Rest_id: restId, upload_id: uploadIdsArray };
 
   // Update the payload state
   setpayload(restId);
 
   console.log(selectedUploadIds, "selectedUploadIds");
   console.log(payload, "payload");
  };
  const download=()=>{

    const uploadIdsArray = selectedUploadIds.map(id => ({ upload_id: id }));
    const payloads = { Rest_id: payload, upload_id: uploadIdsArray };
    if(selectedUploadIds.length>1){
      fobeAxios
      .post("api/Budget/download_multiple_pdf", payloads)
      .then((res) => {
        if(res){
console.log(res.data,"res.data")
setSelectedUploadIds([])
    window.open(res.data); 

        }
      })
      .catch((err) => {
        console.log(err);
      });
    }
    else{
      toast.error("Please select atleast one file");
    }
  }
  const getnotes = async (record) => {
    // setNoterecord(record);
    await fobeAxios(
       "api/Daily/getNotes/" + record.Rest_id + "/" + record.Upload_id
     // "api/Daily/getNotes/" + 5843 + "/" + 2457
   
    )
      .then((d) => {
         console.log(d.data.LatestNotes,"d.data.LatestNotes");
     

        setNotes(d.data.LatestNotes);
        setNotesPopup(true);

      })
      .catch(() => {
        alert("Went Wrong");
      });
  };
  useEffect(() => {
    console.log(selectedUploadIds, "selectedUploadIds");
  }, [selectedUploadIds,payload]);

  const [shows, setShows] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(false);
  const [id, setid] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  let  filter = "";
const [data1,setsata1]=useState({})

const [showinvoiceupload, setshowinvoiceupload] = useState(false);


const handleClick =(item)=>{

    filter=item;

    setsata1(item)
    console.log(filter.Upload_id,"ID");
      //uploadFiles(item)
  console.log(item,"Filedata")
  if(item.invoice_status=='3')
  {
    setshowinvoiceupload(true)
  }

}

const invoiceuploadhandleCloses=()=>{
 console.log(data1.Upload_id,"ID");
setshowinvoiceupload(false);
}
const [logoimage, setLogoImage] = useState([]);
const compressImage = null;
const [myFiles, setMyFiles] = useState([]);
  const [filepath, setFilepath] = useState([]);
  const [filedname, setFileDName] = useState([]);
  const [size, setSize] = useState(0);
const cFile = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
const handleInputChange = (event) => {
  const file = event.target.files;
  console.log(file, "file");
  let check;
  for (let i = 0; i < cFile.length; i++)
  {
    check = false;
    if (file[0].type === cFile[i]) {
      if (file[0].size / 1000000 > 1 && ((file[0].type !== 'application/pdf') && (file[0].type !== 'image/png'))) {
        compressImage.compress(file[0]).then(data => {
          setMyFiles([...myFiles, data]);
          setFilepath([...filepath, logoimage]);
          setFileDName([...filedname, data.name]);
          setSize(size + (data.size / 1000000));
        });
        i = cFile.length;
        check = true;
      } else {
        setMyFiles([...myFiles, file[0]]);
        setFilepath([...filepath, logoimage]);
        setFileDName([...filedname, file[0].name]);
        setSize(size + (file[0].size / 1000000));
        i = cFile.length;
        check = true;
      }
    }
  }
  if (!check) {
    toast.warning ('Supported files only accepted');
  }
  console.log('file', myFiles, filepath);

};


const uploadFiles = async () => {


alert('hello')

console.log(filter,"ID");
  setIsLoading(true);
  console.log('vassssss', myFiles, filepath);
  const frmData1 = new FormData();                  
  frmData1.append('uploadimage', 'c:/temp/abc.png');
  if (id !== '') {

    console.log(filter.Upload_id,"ID");
    console.log("ID");
    frmData1.append('uploadid', data1.Upload_id);
  } else {
    
    frmData1.append('uploadid', data1.Upload_id);
  }
  for (let i = 0; i < myFiles.length; i++) {
    frmData1.append('invoicedetails', myFiles[i], myFiles[i].name);
  }
  console.log('file uploaded data', frmData1);
  alert('123456')
  await mainAxios
  .post('/Api/daily/upload_invoiceinfo/', frmData1)
   .then((resp) => {
    setIsLoading(false);
    setMyFiles([]);
    setFilepath([]);
    setSize(0);
    setshowinvoiceupload(false);
    console.log('resp', resp); 
    if(resp.data == 1){
     
    toast.success('File uploaded successfully');
     getData();
    
  }
  else{
    toast.error('File uploaded failed');
  }
  })


};

const removeFile = (i) => {
  setLogoImage([])
 if(myFiles.length == 0){
    setSize(0);}
    else{
      setSize(size - (myFiles[i].size / 1000000));}
setMyFiles (myFiles.filter((file, index) => index !== i))
  // let newPaths = this.state.filepath.filter((path, index) => index !== i);
  // let newPaths = this.state.filepath.filter((path, index) => index !== i);
  // setFilepath(filepath.filter((path, index) => index !== i));
  // setFileDName(filedname.filter((name, index) => index !== i));
  // const fileInput = document.querySelector('input[type="file"]');
  // fileInput.value = ''; 
 }

  return (
    <>
     
     <Modal show={showinvoiceupload}>
      <Modal.Header style={{backgroundColor:"#19A73F",color:"white"}} >
      <Modal.Title>Upload Invoice 
        <i
      class="fa fa-times"
      aria-hidden="true"
      style={{
        cursor: "pointer",
        color: "white",
        fontSize: "1em",
        float: "right",
        marginTop: "3px",
      }}
      onClick={invoiceuploadhandleCloses}
      ></i></Modal.Title>
      </Modal.Header>
      <Container>
            <Row className="justify-content-center">
              <Col xs={12} md={6}>
                <div className="text-center">
                <div className="upload-btn-wrapperr">
                <button class="b-tnn">
                Select file from your device
                </button>
                <input type="file" value={logoimage} multiple onChange={(e)=>{{handleInputChange(e)}} }/>
                </div>
                </div>
              </Col>
              </Row>
              <Row className="justify-content-center">
              <Col xs={12} md={6}>
                <div className="text-center">
                <div className="disclaimer_text">Supports: .PDF, .PNG, .JPG</div>
                </div>
              </Col>
      
            </Row>
          </Container>
      <Modal.Body>

      <ul>
      
      
      
      {myFiles.map((file, index) => (
      <div className="upld-sec" style={{marginLeft:"-30px"}}>
      <div className="row all-sec-up">
        <div className="col-md-10">
          <div style={{ paddingTop: '3px' }}>
            <span>
              <i className="fa fa-file-text-o fil-icn-upld" key={index}></i>
            </span>
            {file.name} - {file.type} - Size: {numeral(file.size/1000000).format('0.00')}MB
          </div>
        </div>
        <div className="col-md-2  progrss-cls">
          <span style={{ float: 'left' }}>
            <div className="progress blue">
              <span className="progress-left">
                <span className="progress-bar"></span>
              </span>
              <span className="progress-right">
                <span className="progress-bar"></span>
              </span>
              <div className="progress-value">
                <i className="fa fa-check"></i>
              </div>
            </div>
          </span>
          <span
            id=""
            style={{
              float: 'right',
              paddingTop: '3px',
              cursor: 'pointer',
            }}
          >
            <i
            style={{ color: '#898989'}}
              className="bi bi-x"
              onClick={() => removeFile(index, file.size)}
            >
            </i>
          </span>
        </div>
      </div>
      </div>
      ))}
      </ul>
      <Container>
            <Row className="justify-content-center">
              <Col xs={12} md={6}>
              <div className="file-size text-center">
      {myFiles!='' ?(<p>File Size:{size.toFixed(2)}MB</p>):null}
      </div>
      </Col>
      </Row>
      </Container>
      
      </Modal.Body>
      <Modal.Footer>
 <div className="d-flex justify-content-end w-100">
<Button type="button" style={{backgroundColor:"#f0f0f0",fontSize:"12px",  boxShadow: '1px 1px 4px rgba(0,0,0,0.4)',  padding: '6px 12px',color:"black",marginRight:"10px"}} variant="secondary" onClick={invoiceuploadhandleCloses}>
Close
</Button>
<Button style={{backgroundColor:"#03880a",  fontSize:"12px",boxShadow: '1px 1px 4px rgba(0,0,0,0.4)',  padding: '6px 12px',color:"white"}}  onClick={uploadFiles} disabled={myFiles.length==0?true:false} >
Upload
</Button>
</div>

</Modal.Footer>
    </Modal>




        <div className="container">
        <div className="justify-content-center" style={{textAlign:"center",color:"#01abb5",fontSize:"24px", marginBottom: "1em"}} >
        Invoice Dashboard
        </div>
          {show && (
            <div className="panel">
            <div className="col-md-12">
            <div class="row" style={{marginTop: "2em", marginBottom: "2em"}}>
              <div className="col-md-6">
<div className="row">
<div className="col-md-5">
           <label style={{ fontSize: "14px", color: "#666666", fontWeight: 'normal' }}>From:</label>
            &nbsp;&nbsp;
                <input
                  className="date-pck-inc"
                  type="date"
                  value={fromdate}
                  // max={currentDate}
                  name="fromdate"
                   onChange={handleDateChange}
               style={{outline: "none", height: "30px"}}
                />
           </div>
           <div className="col-md-5">
           <label style={{ fontSize: "14px", color: "#666666", fontWeight: 'normal' }}>To:</label>
           &nbsp;&nbsp;
                <input
                  className="date-pck-inc"
                  type="date"
                   value={todate}
                  // max={updatedate}
                  name="todate"
                   onChange={handleDateChange}
                style={{outline: "none", height: "30px"}}
                />
           </div>
           <div className="col-md-2">
              <button
                type="button"
                class="btn src-invc mt-4  "
                style={{
                 fontSize: "13px",
                 background: "rgb(25 167 63)",
                  color: "white",
                borderRadius: "3px",
                                  }}
                onClick={getData}
              >
                {" "}
                <i class="fa fa-search"></i>&nbsp;
                Search
              </button>
           </div>
</div>
              </div>
              <div className="col-md-6">
<div className="row">
<div className="col-md-7">
<label style={{ fontSize: "14px", color: "#666666", float: "right", marginTop: "7px", fontWeight: 'normal' }}>Filter By :</label>
</div>
<div className="col-md-5">
                <select       value={dropdown}
              
            onChange={handleDropdownChange}  style={{width:"15em",height:"2.5em",borderRadius:"3px", border: "1px solid #ddd", outline: "none"}}>
                <option value='0'>All</option>
                <option value='5'>Processing</option>
                <option value='2'>Completed</option>
                <option value='3'>Re-Scan</option>   
                    </select>
</div>
</div>
              </div>
           
          </div>
          <div className="forborder" style={{border: '2px solid #7b7b7b', paddingTop: '10px', paddingBottom: '50px'}}>
              <div class="row">
                <div class="col-sm-6" style={{ float: "left" }}>
                  <span>
                    {" "}
                    Show
                  </span>
                  <Form.Select
                    className="dr-sel"
                    style={{    outline: 'none', height: '27px'}}
                    aria-label="Default select example"
                    onChange={(e) => setRecordPerPage(+e.target.value)}
                  >
                    <option value="5">5</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                  </Form.Select>
                  <span>
                    Entries
                  </span>
                  {selectedUploadIds.length>1 && (
                <button  className="flowcheck"   onClick={() => {
                  download();
                  }}>Download</button> )}
                </div>
             
                <div class="col-sm-6">
                  {Search && (
                    <div class="row">
                      <div class="col-sm-6">
                        <span style={{float: "right", color: "#686464", marginTop: '7px'}}
                        >search: </span>
                      </div>
                      <div class="col-sm-6">
                        <input
                          id="search-box"
                          value={searchstate}
                          class="sear"
                         onChange={(e) => searchFunction(e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {users.length > 0 ? ( <div class="table-scroll" style={{border: 'none' }}>
              <Table striped bordered className="removefont">
  <thead>
    <tr>
      <th style={{ zIndex: 1 }}>
        <div className="tab-card-new" style={{textAlign: 'center', padding: '6px 6px'}}>INVOICE DATE </div>
      </th>
      <th style={{ zIndex: 1 }}>
        <div className="tab-card-new" style={{textAlign: 'center', padding: '6px 6px'}}>INVOICE NUMBER</div>
      </th>
      <th style={{ zIndex: 1 }}>
        <div className="tab-card-new" style={{textAlign: 'center', padding: '6px 6px'}}>PURVEYOR </div>
      </th>
      <th style={{ zIndex: 1 }}>
        <div className="tab-card-new" style={{textAlign: 'center', padding: '6px 6px'}}>AMOUNT</div>
      </th>
      <th style={{ zIndex: 1 }}> 
        <div className="tab-card-new" style={{textAlign: 'center', padding: '6px 6px'}}>STATUS</div>
      </th>
      <th style={{ zIndex: 1 }}>
        <div className="tab-card-new" style={{textAlign: 'center', padding: '6px 6px'}}>ACTION</div>
      </th>
    </tr>
  </thead>
                  {users?.map(item => (
                  <tbody>
            <tr key={item.id}>
        <td className="f1">
          <span style={{float: 'left'}}>
            <input
              type="checkbox"
              onChange={() => handleRowSelect(item.Upload_id, item.Rest_id)}
              checked={selectedUploadIds.includes(item.Upload_id)}
            />
          </span>&nbsp;&nbsp;&nbsp;
          <span>{new Date(item.CreatedDate).toLocaleString()}</span>
        </td>
        <td>{item.Inv_num ? item.Inv_num : '-'}</td>
        <td>{item.Purveyor_name ? item.Purveyor_name : "-"}</td>
        <td>{item.Amount}</td>
        <td>
          <button
            onClick={()=>handleClick(item)}
            style={{borderRadius:"3px", backgroundColor:(item.invoice_status =="5"||item.invoice_status =="1"||item.invoice_status =="6")?"#fff":item.invoice_status=="2"?"#158927":"#ff9800", color:(item.invoice_status =="5"||item.invoice_status =="1"||item.invoice_status =="6")?"#00c344":item.invoice_status=="2"?"#fff":"#fff", border:(item.invoice_status =="5"||item.invoice_status =="1"||item.invoice_status =="6")?"2px solid #00c344":item.invoice_status=="2"?"2px solid #158927":"2px solid #ff9800", padding:(item.invoice_status =="5"||item.invoice_status =="1"||item.invoice_status =="6")?"4px 18px 3px 16px":item.invoice_status=="2"?"4px 26px 3px 16px":"4px 27px 3px 27px"}}>
            {(item.invoice_status =="5"||item.invoice_status =="1"||item.invoice_status =="6")?"processing":item.invoice_status=="2"?"completed":"Rescan"}
          </button>
          {(item.invoice_status =="5"||item.invoice_status =="1"||item.invoice_status =="6")?null:item.invoice_status=="2"?null:<i  onClick={() => {
            getnotes(item);
          }} className="fa fa-edit ng-star-inserted" data-target="#theModal" data-toggle="modal" style={{fontSize:'20px',color:'#00adff',paddingTop:'5px',marginLeft:'10px',cursor:'pointer',position:'absolute'}}></i>} 
        </td>
        <td>
          <button
            className="badgev"
            onClick={() => {
              viewfile(item.filename);
            }}>
            view invoice
          </button>
        </td>
      </tr>


                  </tbody>
                    ))}

               
                </Table>
              </div>
              ) : (
                <div>
                
                    <div class="table-scroll" style={{ overflow: "hidden" }}>
                      <Table striped bordered hover>
                      <thead>
                      <tr>
                       <th style={{ zIndex: 1 }}>
                       <div className="tab-card-new">INVOICE DATE </div>
                       </th>
                       <th style={{ zIndex: 1 }}>
                       <div className="tab-card-new">INVOICE NUMBER</div>
                       </th>
                          <th style={{ zIndex: 1 }}>
                          <div className="tab-card-new">PURVEYOR </div></th>
                          <th style={{ zIndex: 1 }}>
                          <div className="tab-card-new">AMOUNT</div></th>
                          <th style={{ zIndex: 1 }}> 
                          <div className="tab-card-new">STATUS</div></th>
                          <th style={{ zIndex: 1 }}>
                          <div className="tab-card-new">ACTION</div></th>
                      </tr>
                    </thead>
                      </Table>
        
                      <img src={image} className="img" />
        
                      <div>
                        <span
                          style={{
                            fontWeight: "bolder",
                            marginLeft: "503px",
                            fontSize: "15px",
                            opacity: "0.6",
                            fontFamily: "FontAwesome",
                          }}
                        >
                          No Data Found
                        </span>
                      </div>
                    </div>
                  
                </div>
              )}
              <div style={{ marginBottom: "17px", float: "right" }}>
                {pagination && (
                  <Pagination
                    count={Math.ceil(data.list.length / recordPerPage)}
                    className={"paginate"}
                    //variant="outlined"
                    //shape="rounded"
                    onChange={handlePageChange}
                  ></Pagination>
                )}
              </div>
            </div>
            </div>
            </div>
          )}
        </div>
       
       {/* Add Notes Popup */}
       <Modal
       show={notespopup}
       onHide={() => {
         setNotesPopup(false);
       }}
       className="priceing"
              >
       <Modal.Header  style={{    background: "#19a73f", padding: "10px 15px"}}>
         <Modal.Title  style={{fontSize: "20px", color: "#fff", textAlign: "center"}}>Notes</Modal.Title>
         <button type="button" className="close" onClick={() => {
           setNotesPopup(false);
         }}><span aria-hidden="true" style={{fontWeight: "normal"}}>&times;</span></button>
          
       </Modal.Header>
       <Modal.Body>
         <div class="row" style={{ padding: "0px 17px" }}>
           
           <div class="row scrol-pop">
           {notes.length > 0 ? (
             <div class="history">
               <div class="col-md-2 pd-0">
                 <h5 class="h5-sz" style={{ marginLeft: "-10px" }}>
                   Notes:
                 </h5>
               </div>
               <div class="col-md-10 pd-0" style={{padding: "25px 0px"}}>
                                     <div class="hiscont">
                                     {notes.map((x) => {
                                      return (
                                             <ul class="timeline">
                         <li class="timeline-inverted">
                           <div
                             class="timeline-badge"
                             style={{ wordWrap: "break-word" }}
                           >
                           {x.date.split(" ")[0] + " " + x.date.split(" ")[1]}<br />
                           {x.date.split(" ")[2]}
                           </div>
                           <div class="timeline-panel">
                             <div class="row">
                               <div class="col-md-6 post_u">
                                 <p class="by_post"></p>
                               </div>
                               <div class="col-md-12 g_date">
                                 <p class="glyph_u" style={{float: "right"}}>
                                 <i className="glyphicon glyphicon-time"></i>
                                 {x.date.split(" ")[3] + " " + x.date.split(" ")[4]}{" "}
                                 </p>
                               </div>
                             </div>
                             <div class="flex-container flex-around">
                               <div class="box arrow-left timeline-heading">
                                 <textarea  style={{resize: "none", border: "none",  background: "transparent", outline: "none"}} readonly>
                                 {x.notes}</textarea>
                                 <p class="com"></p>
                               </div>
                              
                             </div>
                           </div>
                         </li>
                       </ul>
                        )
                      })}

                 </div>
               </div>

             </div>
           ) : (
             <div className="col-md-12 pd-0">
<p className="emptynotes" style={{textAlign: "center", color: "#666", fontSize: "13px", fontWeight: "normal", fontStyle: "italic"}}>The notes is empty</p>
</div>
            )}
           </div>
      
         </div>
       
       </Modal.Body>

     </Modal>
      


    
      
      {/*<Modal size="sm" show={shows} onHide={handleClose}>
        <Modal.Body className='p-2 text-center' style={{ fontSize: "1.2em" }}> Are you sure you want to delete account?</Modal.Body>
        <Modal.Footer style={{ border: "none", textAlign: "center" }}>
          <Button variant="mute" style={{ marginRight: "1em", background: "red", color: "white", padding: "0.2em 1em" }} onClick={(e) => {
            handleClose(e);
            setShows(false);
            setShow(true);
          }}>
            No
          </Button>
          <Button variant="mute" style={{ marginRight: "1em", background: "green", color: "white", padding: "0.2em 1em" }} onClick={(e) => {handledelete(deleteitem); handleClose(e);   setShows(false); searchFunction(""); }} 
          >
            Yes
          </Button>
        </Modal.Footer>
        </Modal>*/}
    </>
  );
};

export default TableComponent1;
