import "bootstrap/dist/css/bootstrap.min.css";
import P_L_Table from "../Component/Tables/SnapshotDashboardTable/P_L_Table";
import DatesPaginator from "../Component/Datepagenator";
import { fobeAxios, mainAxios } from "../middleware/interceptor";
import CurrencyFormat from "react-currency-format";
import { ConstructSharp } from "react-ionicons";
// import DateRangeDropdown from '../Component/DateRangePicker/DateRangeDropdown'
import DateRangeList from "../Component/DateRangePicker/DateRangeList";
import { Spinner } from "../Component/Spinner";
import "bootstrap/dist/css/bootstrap.min.css";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Dropdown from "react-bootstrap/Dropdown";
import "../Home/Home.css";
import Moment from "moment";
import Button from "@mui/material/Button";
import { DateRange } from "react-date-range";
import YearTable from "../Component/Tables/YearTable";
import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useRef } from "react";
import "../index.css";
const revenue = [];
//   const cog = {
//     list: [{ category: 'Green and Cart Fee', dailyAmt: 5235, dailyPer: 20, monthAmt: 30, monthPer: 30, budgetAmt: 40, budgetPer: 40 },
//     { category: 'Other Golf Revenue', dailyAmt: 10, dailyPer: 20, monthAmt: 30, monthPer: 30, budgetAmt: 40, budgetPer: 40 }],
//     total: [{ dailyTotalAmt: 10, dailyTotalPer: 20, monthTotalAmt: 30, monthTotalPer: 30, budgetTotalAmt: 40, budgetTotalPer: 40 }]
//   }

//   const labor = {
//     list: [{ category: 'Salary', dailyAmt: 5235, dailyPer: 20, monthAmt: 30, monthPer: 30, budgetAmt: 40, budgetPer: 40 },
//     { category: 'Other Golf Revenue', dailyAmt: 10, dailyPer: 20, monthAmt: 30, monthPer: 30, budgetAmt: 40, budgetPer: 40 }],
//     total: { dailyTotalAmt: 10, dailyTotalPer: 20, monthTotalAmt: 30, monthTotalPer: 30, budgetTotalAmt: 40, budgetTotalPer: 40 }
//   }

//   const Controllable = {
//     list: [{ category: 'Salary', dailyAmt: 5235, dailyPer: 20, monthAmt: 30, monthPer: 30, budgetAmt: 40, budgetPer: 40 },
//     { category: 'Other Golf Revenue', dailyAmt: 10, dailyPer: 20, monthAmt: 30, monthPer: 30, budgetAmt: 40, budgetPer: 40 }],
//     total: { dailyTotalAmt: 30, dailyTotalPer: 20, monthTotalAmt: 30, monthTotalPer: 30, budgetTotalAmt: 40, budgetTotalPer: 40 }
//   }
//   const NonControllable = {
//     list: [{ category: 'Salary', dailyAmt: 5235, dailyPer: 20, monthAmt: 30, monthPer: 30, budgetAmt: 40, budgetPer: 40 },
//     { category: 'Other Golf Revenue', dailyAmt: 10, dailyPer: 20, monthAmt: 30, monthPer: 30, budgetAmt: 40, budgetPer: 40 }],
//     total: { dailyTotalAmt: 30, dailyTotalPer: 20, monthTotalAmt: 30, monthTotalPer: 30, budgetTotalAmt: 40, budgetTotalPer: 40 }
//   }
//   const PROFITLOSS = {
//     list: [{ category: 'Salary', dailyAmt: 5235, dailyPer: 20, monthAmt: 30, monthPer: 30, budgetAmt: 40, budgetPer: 40 },
//     { category: 'Other Golf Revenue', dailyAmt: 10, dailyPer: 20, monthAmt: 30, monthPer: 30, budgetAmt: 40, budgetPer: 40 }],
//     total: { dailyTotalAmt: 30, dailyTotalPer: 20, monthTotalAmt: 30, monthTotalPer: 30, budgetTotalAmt: 40, budgetTotalPer: 40 }
//   }

const header = [{ column: "YEAR TO BUDGET" }, { column: "BUDGET TO DATE" }];

function P_L({
  tab,
  setTab,
  course_id,
  setbossid,
  bossid,
  selectDepartment,
  SetselectDepartment,
  ref,
  settriggervariable,
  triggervariable,
  Budgetlength,
  setcheckingForNoBudget,
  checkingForNoBudget,
  setbudget_type,
  budget_type
}) {
  const [selectedRange, setSelectedRange] = useState(
    Moment(new Date()).format("LL") + " - " + Moment(new Date()).format("LL")
  );
  const [isOpen, setIsOpen] = useState(false);
  const[b,setb]=useState(checkingForNoBudget);
  const containerRef = useRef(null);
const[day1,setday1]=useState("1");
const[day2,setday2]=useState("1");
const[period1,setperiod1]=useState("1");
const[period2,setperiod2]=useState("1");
  const [datepickershow, setdatepickershow] = useState(false);

  const handleClick = (e) => {
    //console.log("aamada",containerRef)

    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setdatepickershow(false);
    }
  };
  //get current date

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),

      key: "selection",
    },
  ]);

  const items = JSON.parse(localStorage.getItem("UserClaims"));
  const today = new Date();

  const date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  console.log(date, "current date");
  // let date = newDate.getDate();
  // let month = newDate.getMonth() + 1;
  let year = today.getFullYear();
  // const [d, setd] = useState(date);
  // const [m, setm] = useState(month);
  const [ye, sety] = useState(year);
  const [Toltal, setToltal] = useState(revenue);

  const [data5, setData5] = useState(revenue);
  const [data1, setData1] = useState(revenue);
  const [data2, setData2] = useState(revenue);
  const [data3, setData3] = useState(revenue);
  const [data4, setData4] = useState(revenue);
  const [data, setData] = useState(revenue);
  const [sdate, setsdate] = useState(date);
  const [edate, setedate] = useState(date);
  const [loading, setLoading] = useState(true);

  const [department, setdepartment] = useState();

  let splUser = items.Special_user;
  let Acc_type = items.Account_Type;
  const [dept, setdept] = useState();

  const [showCourse, setShowCourse] = useState(false);




  const getdata = async () => {
    setLoading(true)


    ///gat label for get budget type
let GetLabelURL;
if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
  GetLabelURL = `/Api/Common/getLabel`;
} else {
  GetLabelURL = "Api/Common/getBossLabel/" + bossid + "/" + ye;
}
const { data } = await fobeAxios.get(GetLabelURL);

// setbudget_type(data.LabelAndYear[1].Year_List[0].budget_type) 
///////



    let GetCustomplReport,data_3,defaultperiod;
    //getdefaultbudgetperiod day period from service
    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      data_3  = await fobeAxios.get('/Api/Common/getLabel');
  
      defaultperiod=data_3.data.LabelAndYear[1].Year_List.find(
        (budget) => budget.Year === ye
      ); 
    } else {
      data_3 = await mainAxios.get("Api/DailyPeriod/GetPeriodDetails/" + ye+'/'+bossid);
      defaultperiod=data_3.data
 
    }
   
    if (data_3) {
      if((splUser == 0 || splUser == 1) && Acc_type != 0){
      setperiod2(defaultperiod.budget_period);
      setday2(defaultperiod.budget_period_day);
      }
      else{
if(defaultperiod.budget_period==0 && defaultperiod.budget_period_day==0){
  setperiod2("1");
  setday2("1");
}else{
        setperiod2(defaultperiod.budget_period);
        setday2(defaultperiod.budget_period_day);
      }
      }
   
//getdata
    if(budget_type==1){

      //fortotal
      
      ////
      
      if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
        GetCustomplReport = "Api/DailyPeriod/GetCustomplReport/"+period1+"/"+period2+"/"+day1+"/"+day2+"/"+ ye ;
      } else {
        GetCustomplReport = "Api/Boss/GetCustomplReport/"+period1+"/"+period2+"/"+day1+"/"+day2+"/" +ye+ "/" + bossid;
      }
    }
    else{
    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      GetCustomplReport = "Api/Daily/GetCustomplReport/" + sdate + "/" + edate;
    } else {
      GetCustomplReport =
        "Api/Boss/GetBossCustomplReport/" + sdate + "/" + edate + "/" + bossid;
    }
  }
  }
    fobeAxios.get(GetCustomplReport).then((res) => {
      console.log(res.data, "P&L");
      if (res.data.Profitloss.length > 0) {

        setb(true);
        setData(res.data.Profitloss);
        setToltal(res.data);
        setData5(res.data.Revenue);
        setData1(res.data.Cost_of_goods);
        setData2(res.data.Labor);
        setData3(res.data.Controllable);
        setData4(res.data.Non_controllable);
        setLoading(false);
      } else {
        setb(false);
        setData(revenue);
        setToltal(revenue);
        setData5(revenue);
        setData1(revenue);
        setData2(revenue);
        setData3(revenue);
        setData4(revenue);
        setLoading(false);
      }
    });
  };
  const getdataforperiod = async () => {
    let GetCustomplReport
//getdata
    if(budget_type==1){
      
      if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
        GetCustomplReport = "Api/DailyPeriod/GetCustomplReport/"+period1+"/"+period2+"/"+day1+"/"+day2+"/"+ ye ;
      } else {
        GetCustomplReport = "Api/Boss/GetCustomplReport/"+period1+"/"+period2+"/"+day1+"/"+day2+"/" +ye+ "/"+ bossid;
      }
    }
    else{
    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      GetCustomplReport = "Api/Daily/GetCustomplReport/" + sdate + "/" + edate;
    } else {
      GetCustomplReport =
        "Api/Boss/GetBossCustomplReport/" + sdate + "/" + edate + "/" + bossid;
    }
  }
  
    fobeAxios.get(GetCustomplReport).then((res) => {
      console.log(res.data, "P&L");
      if (res.data.Profitloss.length > 0) {
        setb(true);
        setData(res.data.Profitloss);
        setToltal(res.data);
        setData5(res.data.Revenue);
        setData1(res.data.Cost_of_goods);
        setData2(res.data.Labor);
        setData3(res.data.Controllable);
        setData4(res.data.Non_controllable);
        setLoading(false);
      } else {
        setb(false);
        setData(revenue);
        setToltal(revenue);
        setData5(revenue);
        setData1(revenue);
        setData2(revenue);
        setData3(revenue);
        setData4(revenue);
        setLoading(false);
      }
    });
  };

  useEffect(() => {




    setLoading(true);
    getdata();
    getdeptdata();
    if (
      items.Member_Type == 4 ||
      ((items.Special_user == 0 || items.Special_user == 2) &&
        items.Account_Type == 0)
    ) {
      setShowCourse(true);
    } else {
      setShowCourse(false);
    }


  }, [tab, sdate, edate, course_id, bossid, selectedRange, triggervariable,budget_type]);

  const getdeptdata = async () => {



    const localItems = JSON.parse(localStorage.getItem("UserClaims"));
    if (localItems.Member_Type != "4") {
      course_id = localItems.Restaurant_id;
    }
    // setSelectedDept(localItems?.Account_Name, "localItems?.Account_id");
    mainAxios.get(`Api/User/GetAllUserTypes?id=5`).then((res) => {
      setdept(res.data.AllUserTypes);
    });
    mainAxios
      .get("api/Common/getRestaurantList/" + course_id + "/" + ye)
      .then((res) => {
        console.log(res.data.Restaurants, "Restaurants");
        setdepartment(res.data.Restaurants);
      });
  };
  const [selectDepartment_active, setselectedDepartment_active] =
    useState(null);
  const handleSelectdrp = (eventKey, event) => {
    setcheckingForNoBudget(false);
    setselectedDepartment_active(eventKey);
    SetselectDepartment(eventKey.split("`")[1]);
    setbossid(eventKey.split("`")[0]);
  };
  const datepicker = (e) => {
    console.log(e, "eeee");
    console.log(e[0].startDate, "k");
    console.log(e[0].endDate, "k");
    var date = new Date(e[0].startDate),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    console.log([date.getFullYear(), mnth, day].join("-"), "formatted date");
    setsdate([date.getFullYear(), mnth, day].join("-"));

    var datee = new Date(e[0].endDate), 
      mnth1 = ("0" + (datee.getMonth() + 1)).slice(-2),
      day1 = ("0" + datee.getDate()).slice(-2);
    console.log(
      [datee.getFullYear(), mnth1, day1].join("-"),
      "formatted staetdate"
    );
    setedate([datee.getFullYear(), mnth1, day1].join("-"));

    setSelectedRange(
      Moment([date.getFullYear(), mnth, day].join("-")).format("LL") +
        " - " +
        Moment([datee.getFullYear(), mnth1, day1].join("-")).format("LL")
    );
  };

///13 period///
const perioddropdown = (event, keyName) => {
  if (keyName === "d1") {
    setday1(event.target.value);
  } else if (keyName === "d2") {
    setday2(event.target.value);
  } else if (keyName === "p1") {
    setperiod1(event.target.value);
  }else if (keyName === "p2") {
    setperiod2(event.target.value);
  }
};
///////////////////

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-4"></div>
          {showCourse && (
            <div className="col-md-6 col-sm-8" style={{ width: "47%" }}>
              <Form>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Col md="7" sm="8">
                    <Form.Label style={{ float: "right", marginTop: "6px" }}>
                      Select Department
                    </Form.Label>
                  </Col>
                  <Col md="5" sm="4">
                    <Dropdown onSelect={handleSelectdrp}>
                      <Dropdown.Toggle
                        id="dropdown-button-dark-example1"
                        className="drop-ml "
                        variant="secondary"
                      >
                        {selectDepartment
                          ? selectDepartment.slice(0, 23)
                          : "All Department"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu variant="dark" className="responsedrop">
                        <Dropdown.Item
                          eventKey={items.Boss_id + "`" + "All Department"}
                        >
                          All Department
                        </Dropdown.Item>
                        {department?.map((x) => (
                          <Dropdown.Item
                            eventKey={x.Rest_id + "`" + x.Rest_Name}
                            className={
                              selectDepartment_active ===
                              x.Rest_id + "`" + x.Rest_Name
                                ? "active"
                                : ""
                            }
                          >
                            {x.Rest_Name}
                          </Dropdown.Item>
                        ))}

                        {/* <Dropdown.Item  active>Metro West-Golf Operations</Dropdown.Item>
          <Dropdown.Item>Metro West-Administration</Dropdown.Item>
          <Dropdown.Item>Metro West-Turf & Maintenance</Dropdown.Item>
          <Dropdown.Item>Metro West-Food & Beverage</Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Form.Group>
              </Form>
            </div>
          )}
        </div>
        {budget_type ==1 ? (
          <div className='col-xs-12 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2 R-list'>
          <div className='container-d'>
            <div className='col-md-12 col-sm-12 col-xs-12'>
              <div className='row'>
                <div className='col-md-6 col-sm-6 col-xs-12'>
                <div className="row">
                <div class="col-md-5 col-sm-6 col-xs-6">
                          <p style={{ marginTop: '10px', float: 'right', fontWeight: "bold" }}>Select Period Start :</p></div>
                        <div class="col-md-6 col-sm-6 col-xs-6">
                        <select  variant="secondary" value={period1} onChange={(e) => perioddropdown(e, "p1")} style={{width: "100%",background:"#ffffff",border:"2px solid #f5f5f5",height:"31px",borderRadius:"6px"}} >
                        <option value="1">Period 1</option>
                        <option value="2">Period 2</option>
                        <option value="3">Period 3</option>
                        <option value="4">Period 4</option>
                        <option value="5">Period 5</option>
                        <option value="6">Period 6</option>
                        <option value="7">Period 7</option>
                        <option value="8">Period 8</option>
                        <option value="9">Period 9</option>
                        <option value="10">Period 10</option>
                        <option value="11">Period 11</option>
                        <option value="12">Period 12</option>
                        <option value="13">Period 13</option>
                       
                        
                      </select>
                        </div>
                        </div>
                        <div className="row">
                <div class="col-md-5 col-sm-6 col-xs-6">
                          <p style={{ marginTop: '10px', float: 'right', fontWeight: "bold" }}>Select Day From :</p></div>
                        <div class="col-md-6 col-sm-6 col-xs-6">
                        <select  variant="secondary" value={day1} onChange={(e) => perioddropdown(e, "d1")} style={{width: "100%",background:"#ffffff",border:"2px solid #f5f5f5",height:"31px",borderRadius:"6px"}} >
                        <option value="1">Day 1</option>
                        <option value="2">Day 2</option>
                        <option value="3">Day 3</option>
                        <option value="4">Day 4</option>
                        <option value="5">Day 5</option>
                        <option value="6">Day 6</option>
                        <option value="7">Day 7</option>
                        <option value="8">Day 8</option>
                        <option value="9">Day 9</option>
                        <option value="10">Day 10</option>
                        <option value="11">Day 11</option>
                        <option value="12">Day 12</option>
                        <option value="13">Day 13</option>
                        <option value="14">Day 14</option>
                        <option value="15">Day 15</option>
                        <option value="16">Day 16</option>
                        <option value="17">Day 17</option>
                        <option value="18">Day 18</option>
                        <option value="19">Day 19</option>
                        <option value="20">Day 20</option>
                        <option value="21">Day 21</option>
                        <option value="22">Day 22</option>
                        <option value="23">Day 23</option>
                        <option value="24">Day 24</option>
                        <option value="25">Day 25</option>
                        <option value="26">Day 26</option>
                        <option value="27">Day 27</option>
                        <option value="28">Day 28</option>
                        
                      </select>
                        </div>
                        </div>
                </div>
                <div className='col-md-4 col-sm-4 col-xs-12'>
                <div className="row">
                <div class="col-md-3 col-sm-4 col-xs-6">
                          <p style={{ marginTop: '10px', float: 'right', fontWeight: "bold" }}>End :</p></div>
                        <div class="col-md-9 col-sm-8 col-xs-6">
                        <select  variant="secondary" value={period2} onChange={(e) => perioddropdown(e, "p2")} style={{width: "100%",background:"#ffffff",border:"2px solid #f5f5f5",height:"31px",borderRadius:"6px"}} >
                        <option value="1">Period 1</option>
                        <option value="2">Period 2</option>
                        <option value="3">Period 3</option>
                        <option value="4">Period 4</option>
                        <option value="5">Period 5</option>
                        <option value="6">Period 6</option>
                        <option value="7">Period 7</option>
                        <option value="8">Period 8</option>
                        <option value="9">Period 9</option>
                        <option value="10">Period 10</option>
                        <option value="11">Period 11</option>
                        <option value="12">Period 12</option>
                        <option value="13">Period 13</option>
                       
                        
                      </select>
                        </div>
                        </div>
                        <div className="row">
                <div class="col-md-3 col-sm-4 col-xs-6">
                          <p style={{ marginTop: '10px', float: 'right', fontWeight: "bold" }}>To :</p></div>
                        <div class="col-md-9 col-sm-8 col-xs-6">
                        <select  variant="secondary" value={day2} onChange={(e) => perioddropdown(e, "d2")} style={{width: "100%",background:"#ffffff",border:"2px solid #f5f5f5",height:"31px",borderRadius:"6px"}} >
                        <option value="1">Day 1</option>
                        <option value="2">Day 2</option>
                        <option value="3">Day 3</option>
                        <option value="4">Day 4</option>
                        <option value="5">Day 5</option>
                        <option value="6">Day 6</option>
                        <option value="7">Day 7</option>
                        <option value="8">Day 8</option>
                        <option value="9">Day 9</option>
                        <option value="10">Day 10</option>
                        <option value="11">Day 11</option>
                        <option value="12">Day 12</option>
                        <option value="13">Day 13</option>
                        <option value="14">Day 14</option>
                        <option value="15">Day 15</option>
                        <option value="16">Day 16</option>
                        <option value="17">Day 17</option>
                        <option value="18">Day 18</option>
                        <option value="19">Day 19</option>
                        <option value="20">Day 20</option>
                        <option value="21">Day 21</option>
                        <option value="22">Day 22</option>
                        <option value="23">Day 23</option>
                        <option value="24">Day 24</option>
                        <option value="25">Day 25</option>
                        <option value="26">Day 26</option>
                        <option value="27">Day 27</option>
                        <option value="28">Day 28</option>
                        
                      </select>
                        </div>
                        </div>
                </div>
                <div className='col-md-2 col-sm-2 col-xs-12'>
                  <button className='btn btn-prim' style={{backgroundColor: "#2196f3", marginTop: "25px", color: "#fff"}}  onClick={getdataforperiod} >GET P & L</button>
                </div>
              </div>
            </div>
            {/* <h4>Select Time Period : </h4>
            <div className='p&l-Dropdown rangedate'>
    
    
    
    
              <Dropdown>
                <Dropdown.Toggle id="dropdown-autoclose-outside" className='drop-ml ' variant="secondary">
                  <i class="bi bi-calendar3"></i> <span>{selectedRange}</span>
                 
    
                </Dropdown.Toggle>
    
                <Dropdown.Menu variant="dark">
                 
                  <Dropdown.Item onClick={() => setPeriod(1)}  className={selectedDepartment_active===1?'active':""}>Today</Dropdown.Item>
                  <Dropdown.Item onClick={() => setPeriod(2)} className={selectedDepartment_active===2?'active':""}>Yesterday</Dropdown.Item>
                  
                  <Dropdown.Item onClick={() => setPeriod(3)} className={selectedDepartment_active===3?'active':""}>Last 7 Days</Dropdown.Item>
                  <Dropdown.Item onClick={() => setPeriod(4)} className={selectedDepartment_active===4?'active':""}>Last 30 Days</Dropdown.Item>
                  <Dropdown.Item onClick={() => setPeriod(5)} className={selectedDepartment_active===5?'active':""}>This Month</Dropdown.Item>
                  <Dropdown.Item onClick={() => setPeriod(6)} className={selectedDepartment_active===6?'active':""}>Last Month</Dropdown.Item>
                  <Dropdown.Item   onClick={() => setdatepickershow(true)
       } >Custom Range</Dropdown.Item>
                  
    
             
       
                </Dropdown.Menu>
              </Dropdown>
    
    
    
    
              
            </div> */}
          </div>
        </div>
      ):(
        <DateRangeList
          setSelectedRange={setSelectedRange}
          selectedRange={selectedRange}
          setsdate={setsdate}
          setedate={setedate}
          setdatepickershow={setdatepickershow}
          state={state}
        />   )}

        {datepickershow && (
          <div
            ref={containerRef}
            className="calender"
            style={{
              marginBottom: "-6em",
              width: "333px",
              marginLeft: "580px",
            }}
          >
            <DateRange
              editableDateInputs={true}
              onChange={(item) => {
                setState([item.selection]);
                datepicker([item.selection]);
              }}
              moveRangeOnFirstSelection={false}
              ranges={state}
            ></DateRange>
          </div>
        )}
        <br />
        {/* <DateRangeDropdown /> */}
        {/* <DatesPaginator setsdate={setsdate} setedate={setedate} dt={""} dm={""}  ds={""} /> */}
        {b  ? (
          <>
            <div className="row" style={{ justifyContent: "center" }}>
              <div
                className="col-md-12"
                style={{ zIndex: "-1", textAlign: "center" }}
              >
                <span>
                  <b>Period : </b>
                  {budget_type==2 ? (
                  <span>{sdate}</span>  ) : (
                    <span>{period1}</span>  )}
                   <b>&nbsp;&nbsp;To&nbsp;&nbsp;</b>
                   {budget_type==2 ? (
                    <span>{edate}&nbsp;&nbsp;</span> ) : (
                      <span>{period2}&nbsp;&nbsp;</span> )}
                  <b>P&nbsp;&&nbsp;L</b>=
                  <CurrencyFormat
                    prefix={"$"}
                    style={{
                      backgroundColor:
                        Math.sign(Toltal.YearToDay) < 0 ? "#d63737" : "#1c9f6f",
                      color: "white",
                      borderRadius: "5px",
                      width: "100px",
                      fontSize: "13px",
                      marginLeft: "4px",
                    }}
                    fixedDecimalScale={2}
                    className="non-edit-cs"
                    value={
                      Toltal.YearToDay == undefined ? "" : Toltal.YearToDay
                    }
                    decimalScale={2}
                  />{" "}
                  &nbsp;&nbsp;&nbsp;<b>P&nbsp;&&nbsp;L To Date</b> =
                  <CurrencyFormat
                    prefix={"$"}
                    style={{
                      marginLeft: "-42px",
                      color: "White",
                      backgroundColor:
                        Math.sign(Toltal.MonthMTD) < 0 ? "#d63737" : "#1c9f6f",
                      width: "100px",
                      borderRadius: "5px",
                      fontSize: "13px",
                      marginLeft: "3px",
                    }}
                    fixedDecimalScale={2}
                    className="non-edit-cs"
                    value={Toltal.MonthMTD == undefined ? "" : Toltal.MonthMTD}
                    decimalScale={2}
                  />{" "}
                </span>
              </div>
            </div>

            {loading ? (
              <Spinner />
            ) : (
              <>
                <P_L_Table
                  data={data}
                  title="PROFIT/LOSS"
                  header={header}
                  isBudget={false}
                  showexcel={true}
                />
                <P_L_Table
                  data={data5}
                  title="REVENUE"
                  header={header}
                  isBudget={false}
                  showexcel={false}
                />

                <P_L_Table
                  data={data1}
                  title="Cost Of Goods"
                  header={header}
                  isBudget={false}
                  showexcel={false}
                />
                <P_L_Table
                  data={data2}
                  title="Labor"
                  header={header}
                  isBudget={false}
                  showexcel={false}
                />
                <P_L_Table
                  data={data3}
                  title="Controllable"
                  header={header}
                  isBudget={false}
                  showexcel={false}
                />
                <P_L_Table
                  data={data4}
                  title="Non Controllable"
                  header={header}
                  isBudget={false}
                  showexcel={false}
                />
              </>
            )}
          </>
        ) : (
          <>
            <div className="col-xs-12 text-center">
              <div className="col-xs-12 ">
                <figure className="info-img">
                  <img
                    src="https://d2a7u30t6wbkim.cloudfront.net/assets/img/budget-flat.png"
                    alt="Budget Image"
                    style={{ width: "64px" }}
                  />
                </figure>
              </div>
              <div className="col-xs-12  info-title">
                <h5 className="" style={{ fontWeight: "900" }}>
                  No Budget available..
                </h5>
                <div className="col-xs-12">
                  <p className="awesome">
                    <span
                      className="info-span1"
                      style={{
                        fontSize: "13px",
                        fontStyle: "italic",
                        fontWeight: "bold",
                        color: "#1C9F6F",
                      }}
                    >
                      "Build your budget to improve your Restaurant"
                    </span>
                  </p>
                </div>
              </div>
              <p
                className="res-tag"
                style={{
                  background: "#d0d8d8",
                  padding: "7px 12px",
                  borderRadius: "7px",
                  color: "#000",
                  letterSpacing: "1px",
                  fontSize: "12px",
                  display: "inline-block",
                }}
              >
                <a
                  href="#/services/budget"
                  onClick={() => {}}
                  style={{ color: "#2196f3" }}
                >
                  Click
                </a>{" "}
                here to budget!
              </p>
            </div>
          </>
        )}
      </div>
    </>
  );
}
export default P_L;
