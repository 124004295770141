
import Table from 'react-bootstrap/Table';
import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Pagination from 'react-bootstrap/Pagination';
import Modal from 'react-bootstrap/Modal';
import { FaCheck } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import { FaInfoCircle } from "react-icons/fa";
import React from "react"
import Tooltip from "react-simple-tooltip"
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import '../Datepagenator.css'
import './TableComponent.css'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

import './Uploadexceltable.css'


const Uploadexceltable = ({ header, data, nestedColumn, searchFunction, Search, pagination, getPage, isFooter, editUser }) => {
    const renderTooltip = props => (
        <Tooltip {...props}>Edit</Tooltip>
    );
    const renderTooltip1 = props => (
        <Tooltip {...props}>Delete</Tooltip>
    );
    
    const [show, setShow] = useState(false)
    const[errordata,seterrordata]=useState([]);
    const [showmodal, setShowmodal] = useState(false)
    const [recordPerPage, setRecordPerPage] = useState(5)    
    const [users, setUser] = useState(false);
    let [length, setlength] = useState(Math.ceil(data.list.length/recordPerPage))

    useEffect(() => {
        if (data.list.length > 0) {
            setUser(data.list.slice(0,recordPerPage));
            // console.log("dataaa:", users)
            setShow(true);
        }
        console.log("varahi", data)
    }, [data,length])

    useEffect(() => {
        setUser(data.list.slice(0 * recordPerPage, (0 * recordPerPage) + recordPerPage));
       
    },[recordPerPage,length])
    

    const nextPage = (offset) => {
        setUser(data.list.slice(offset * recordPerPage, (offset * recordPerPage) + recordPerPage));
    }

    const firstPage = () => {
        setUser(data.list.slice(0, recordPerPage));
    }

    const lastPage = () => {
        setUser(data.list.slice(data.list.length - recordPerPage, data.list.length));
    }

    const func = (e) => {

    setlength(Math.ceil(data.list.length/recordPerPage));
    } 
    const popup = (e) => { console.log('e', e)
    seterrordata(e);
    e.map((item) => { 
      
        setShowmodal(true)
     
    }
    )
   console.log(errordata,"errordata")
}
    console.log("dataaa:", users)
    const handleClose = () => {setShowmodal(false);
       
     
       
      }
         const handleShow = () => {setShowmodal(true)
          };
    return (
        <>
            {show &&  
                <div>
                    <div>
                        {Search && <>
                            <i class="bi bi-search"></i>
                            <input id='search-box' class="sear" placeholder='Global Filter' onChange={(e) => searchFunction(e.target.value)} /></>}
                    </div>
                    <div class="table-scroll">
                        <Table striped bordered hover>
                        <thead>
                        <tr>

                         
                                <th>
                                    <div className='tab-card-new'>First_Name
                                      </div></th>
                                      <th>
                                      <div className='tab-card-new'>Last_Name
                                        </div></th>
                                        <th>
                                        <div className='tab-card-new'>Email
                                          </div></th>
                                          <th>
                                          <div className='tab-card-new'>Password
                                            </div></th>
                                            <th>
                                            <div className='tab-card-new'>Role
                                              </div></th>
                                              <th>
                                              <div className='tab-card-new'>Course
                                                </div></th>
                                                <th>
                                                <div className='tab-card-new'>Department
                                                  </div></th>
                                                  <th>
                                                  <div className='tab-card-new'>Phone
                                                    </div></th>
                                                    <th>
                                                    <div className='tab-card-new'>Status
                                                      </div></th>
                            


                        </tr>
                    </thead>
                            <tbody class="tab-gf">
{data.list?.map((user, index) => (
                              <tr>

<td>{user.First_Name}</td>
<td>{user.Last_Name}</td>
<td>{user.Email}</td>
<td>{user.Password}</td>
<td>{user.Role}</td>
<td>{user.Course}</td>
<td>{user.Department}</td>
<td>{user.Phone}</td>
{user.Errormessage.length > 0 && <td style={{textAlign:"center"}}>   <Tooltip content={user.Errormessage.length + "Error!"}><FaTimes  style={{color:"red"}} onClick={(e) => popup(user.Errormessage)}/></Tooltip>
</td>}
{user.Errormessage.length == 0 && <td style={{textAlign:"center"}}>   <Tooltip content="0Error!"><FaCheck style={{color:"green"}} onClick={(e) => popup(user.Errormessage)}/></Tooltip></td>}



                                        </tr> 
                                    ))}
    
                            </tbody>
                    
                            {isFooter &&
                                <tfoot>
                                    <tr>
                                        <td>TOTAL</td>
                                        <td><span>$</span> <p className='fl-r'>{data.total}</p></td>
                                        <td><p className='fl-r'>{data.percentage}<span>%</span></p></td>
                                    </tr>
                                </tfoot>}

                        </Table>
                    </div>
                    <div>
                    {
                       pagination &&



        <Pagination 
       // count={Math.ceil(data.list.length/recordPerPage)}
        className={"paginate"}
    
       //</div> onChange={handlePageChange}
       >
                            </Pagination>    
                            }

                    </div>
   
                </div>
            }
            <Modal show={showmodal} onHide={handleClose} size="sm" backdrop="static" keyboard={false} className="upload" > 
         
            <Modal.Header>
            
           <Modal.Title>  Warning</Modal.Title>
           
            </Modal.Header>
            
         <Modal.Body>
      
               
                {errordata?.map((item)=>{
                  console.log(item);
                 return (
                       
                     <div style={{marginTop:"1em", backgroundColor: "#ffecb3",color: "#7f6003", height:"56px" ,padding: '10px' ,border: '1px solid #ffc107',borderWidth: "0 0 0 6px",borderRadius:"4px"}} ><FaInfoCircle style={{color:"#7f6003" }} />&nbsp;&nbsp;{item.msg}</div>
                     
                     )
                     
                 })}
       
     
         </Modal.Body>
        
            <Modal.Footer>
              <Button variant="primary" onClick={handleClose} style={{position:"relative",left:"-31px"}} >
                Ok
              </Button>
             
            </Modal.Footer>
          </Modal>
        </>
    )
}

export default Uploadexceltable;