import 'bootstrap/dist/css/bootstrap.min.css'
import ListGroup from 'react-bootstrap/ListGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { DateRange, DateRangePicker } from 'react-date-range';
import React, { useState} from "react";
import Moment from 'moment';
import { useRef,useEffect} from 'react';
import { ConstructOutline } from 'react-ionicons';




function DateRangeList({ setedate, setsdate ,setdatepickershow,state,setSelectedRange,selectedRange}) {
  //const ref = useRef(null)
  useEffect(() => {  }, [selectedRange]);

  const [isOpen,setIsOpen]=useState(false)
  const [loading, setLoading] = useState(true);
  const today = new Date();
 
  const datee = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
const [selectedDepartment_active,setselectedDepartment_active]=useState(null)
  const setPeriod = (id) => {
    setselectedDepartment_active(id)
    setdatepickershow(false)
    
    let sdate;
    let edate;
    const today = new Date();
    if (id === 1) {
      sdate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      edate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      setSelectedRange(Moment(new Date()).format('LL')+' - '+Moment(new Date()).format('LL'));      
    }
    if(id === 2){ var date = new Date(); date.setDate(date.getDate() - 1);
      sdate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
      edate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
     setSelectedRange(Moment(sdate).format('LL')+' - '+Moment(edate).format('LL')); }
    
    
    else if (id === 3) {
      var date = new Date();
      date.setDate(date.getDate() - 7);
      sdate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
      edate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      setSelectedRange(Moment(sdate).format('LL')+' - '+Moment(edate).format('LL'));
    } else if (id === 4) {
      var date = new Date();
      date.setDate(date.getDate() - 30);
      sdate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
      edate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      setSelectedRange(Moment(sdate).format('LL')+' - '+Moment(edate).format('LL'));
    } else if (id === 5) {
      var date = new Date();
      sdate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + 1;
      edate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      setSelectedRange(Moment(sdate).format('LL')+' - '+Moment(edate).format('LL'));
    } else if (id === 6) {
      var date = new Date();
      date.setMonth(date.getMonth() - 1);
      sdate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + 1;
      edate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
      setSelectedRange(Moment(sdate).format('LL')+' - '+Moment(edate).format('LL'));
    
     
    } 
    

     
  

    setedate(edate)
    setsdate(sdate)
  }




  

  return (
    <div className='col-xs-12 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2 R-list'>
      <div className='container-d'>
        <h4>Select Time Period : </h4>
        <div className='p&l-Dropdown rangedate'>




          <Dropdown>
            <Dropdown.Toggle id="dropdown-autoclose-outside" className='drop-ml ' variant="secondary">
              <i class="bi bi-calendar3"></i> <span>{selectedRange}</span>
             

            </Dropdown.Toggle>

            <Dropdown.Menu variant="dark">
              {/* <Dropdown.Item  active>
              Today
              </Dropdown.Item> */}
              <Dropdown.Item onClick={() => setPeriod(1)}  className={selectedDepartment_active===1?'active':""}>Today</Dropdown.Item>
              <Dropdown.Item onClick={() => setPeriod(2)} className={selectedDepartment_active===2?'active':""}>Yesterday</Dropdown.Item>
              
              <Dropdown.Item onClick={() => setPeriod(3)} className={selectedDepartment_active===3?'active':""}>Last 7 Days</Dropdown.Item>
              <Dropdown.Item onClick={() => setPeriod(4)} className={selectedDepartment_active===4?'active':""}>Last 30 Days</Dropdown.Item>
              <Dropdown.Item onClick={() => setPeriod(5)} className={selectedDepartment_active===5?'active':""}>This Month</Dropdown.Item>
              <Dropdown.Item onClick={() => setPeriod(6)} className={selectedDepartment_active===6?'active':""}>Last Month</Dropdown.Item>
              <Dropdown.Item   onClick={() => setdatepickershow(true)
   } >Custom Range</Dropdown.Item>
              

         
   
            </Dropdown.Menu>
          </Dropdown>




          
        </div>
      </div>
    </div>

  );
}
export default DateRangeList;
