
import Table from 'react-bootstrap/Table';
import '../TableComponent'
import { useState } from 'react';
import moment from 'moment'
import CurrencyFormat from "react-currency-format";

const BookedTenderedRoundsTable= ({handleActualRound, header, bookedRoundData}) => {


 
    return (
  
      <div className='table-scroll table-responsive table1 '>
        <Table striped bordered hover>
          <thead>
            <tr>
          
            {header?.map((head, index) =>              
                <th > <div className='tab-card-new'>{head.column}</div></th>
            )}

            </tr>
            
          </thead>
          <tbody>
            
            {bookedRoundData?.map(item =>
              {

              {console.log('k',new Date(item.date.split(' ')[0].split('-')[2]+'-'+item.date.split(' ')[0].split('-')[1]+'-'+item.date.split(' ')[0].split('-')[0]))}

              return <tr>
                 <td>
                 <span > {moment(item.date.split(' ')[0].split('-')[2]+'-'+item.date.split(' ')[0].split('-')[1]+'-'+item.date.split(' ')[0].split('-')[0]).format('dddd')} <br /><i class="bi bi-calendar3"></i> {`${moment(item.date.split(' ')[0].split('-')[2]+'-'+item.date.split(' ')[0].split('-')[1]+'-'+item.date.split(' ')[0].split('-')[0]).format('MMM')} ${moment(item.date.split(' ')[0].split('-')[2]+'-'+item.date.split(' ')[0].split('-')[1]+'-'+item.date.split(' ')[0].split('-')[0]).format('D')},${moment(item.date.split(' ')[0].split('-')[2]+'-'+item.date.split(' ')[0].split('-')[1]+'-'+item.date.split(' ')[0].split('-')[0]).format('yyyy')}`}</span>
                    </td>
               
                <td> <p className=" fl-in">
                
                <input type='text'
                    className="input-edit-monthly"
                    // {
                    //   cat.Editable_Status === 1
                    //     ? "input-edit-monthly"
                    //     : "non-edit-cs"
                    // }
                   
                    id={item.Actual_Rounds}
                    name={item.Actual_Rounds}
                    value={item.Actual_Rounds}                   
                    disabled={item.Actual_Editable_Status==0?true:false}
                    onChange={(e) =>
                      handleActualRound(item.date, e.target.value)
                    }
                  />
                  </p></td>
                <td>{item.Forecast_Rounds}</td>
               
              </tr>
})}         
  
          </tbody>
  
        
          
        </Table>
  
      </div>
  
    );
  }

export default BookedTenderedRoundsTable;