// import Table1 from '../snapshot/table1'
import Tab from "react-bootstrap/Tab";
import React from 'react';
import Tabs from "react-bootstrap/Tabs";
import "../Home/Home.css";
import Form from 'react-bootstrap/Form';
import SnapshotBudget from "./SnapshotBudget";
import "../snapshot/snapshot.css";
import Dashboard from "./Dashboard";
import Dropdown from 'react-bootstrap/Dropdown';
import BookedTenderedRounds from "./BookedTenderedRounds ";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { mainAxios, FobeAxios } from "../middleware/interceptor";
import { Spinner } from '../Component/Spinner'
import { selectUnstyledClasses } from "@mui/base";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    &#x25bc;
  </a>
));

const drop = [
  {
    Coursename: "Blue Sky",
    Budget_exit_status: 1,
    Courseid: 1381,
  },
  {
    Coursename: "Country Club of Ocala",
    Budget_exit_status: 1,
    Courseid: 897,
  },
  {
    Coursename: "DEMO",
    Budget_exit_status: 0,
    Courseid: 1453,
  },
  {
    Coursename: "Eagle Harbor",
    Budget_exit_status: 1,
    Courseid: 952,
  },
  {
    Coursename: "Elizabethton",
    Budget_exit_status: 0,
    Courseid: 1978,
  },
  {
    Coursename: "Esplanade at Azario Lakewood Ranch",
    Budget_exit_status: 1,
    Courseid: 1811,
  },
  {
    Coursename: "Everglades",
    Budget_exit_status: 1,
    Courseid: 878,
  },
  {
    Coursename: "Falcons Fire",
    Budget_exit_status: 1,
    Courseid: 888,
  },
  {
    Coursename: "Hampshire",
    Budget_exit_status: 0,
    Courseid: 1982,
  },
  {
    Coursename: "Hampshire",
    Budget_exit_status: 0,
    Courseid: 2341,
  },
  {
    Coursename: "Harbor Hills",
    Budget_exit_status: 0,
    Courseid: 1975,
  },
  {
    Coursename: "Jacksonville Golf and Country Club",
    Budget_exit_status: 0,
    Courseid: 1986,
  },
  {
    Coursename: "Laurel Oak",
    Budget_exit_status: 1,
    Courseid: 1534,
  },
  {
    Coursename: "Metro West",
    Budget_exit_status: 1,
    Courseid: 901,
  },
  {
    Coursename: "Northland",
    Budget_exit_status: 1,
    Courseid: 889,
  },
  {
    Coursename: "Palencia",
    Budget_exit_status: 1,
    Courseid: 855,
  },
  {
    Coursename: "Pipestone",
    Budget_exit_status: 0,
    Courseid: 1991,
  },
  {
    Coursename: "Piqua",
    Budget_exit_status: 1,
    Courseid: 893,
  },
  {
    Coursename: "Plantation",
    Budget_exit_status: 1,
    Courseid: 868,
  },
  {
    Coursename: "Region 01",
    Budget_exit_status: 0,
    Courseid: 1122,
  },
  {
    Coursename: "River Hall",
    Budget_exit_status: 1,
    Courseid: 916,
  },
  {
    Coursename: "Southern Hills Plantation",
    Budget_exit_status: 1,
    Courseid: 882,
  },
  {
    Coursename: "St. Cloud Country Club",
    Budget_exit_status: 0,
    Courseid: 2102,
  },
  {
    Coursename: "Stillwater",
    Budget_exit_status: 0,
    Courseid: 1880,
  },
  {
    Coursename: "Stone Creek",
    Budget_exit_status: 1,
    Courseid: 896,
  },
  {
    Coursename: "Stonegate Country Club",
    Budget_exit_status: 1,
    Courseid: 869,
  },
  {
    Coursename: "Summer Glen",
    Budget_exit_status: 0,
    Courseid: 860,
  },
  {
    Coursename: "Tennesse National",
    Budget_exit_status: 0,
    Courseid: 1874,
  },
  {
    Coursename: "The Club at Del Webb Naples",
    Budget_exit_status: 1,
    Courseid: 891,
  },
  {
    Coursename: "The Links at Spruce Creek",
    Budget_exit_status: 0,
    Courseid: 1980,
  },
  {
    Coursename: "Trophy Apalachee",
    Budget_exit_status: 0,
    Courseid: 1717,
  },
  {
    Coursename: "Verandah",
    Budget_exit_status: 1,
    Courseid: 861,
  },
  {
    Coursename: "Victoria Hills",
    Budget_exit_status: 0,
    Courseid: 904,
  },
  {
    Coursename: "ZBoss",
    Budget_exit_status: 0,
    Courseid: 957,
  },
];
function Snapshot() {
  const [course, setCourse] = useState([]);
  const [year, setYear] = useState();
  const [selectedCourse, setSelectedCourse] = useState();
  const userDetail = JSON.parse(localStorage.getItem('UserClaims'));
  
  const [roundsYear, setRoundsYear] = useState([]);

  const [loading, setLoading] = useState(true);
  const [tabs, setTab] = useState(3);


  useEffect(() => {   
    getData();
  }, []);

  const getCourse = (value) => {   
    setSelectedCourse(value)
  };

  
  const getData = async () => {
    if(userDetail.Member_Type==4){

    
    await mainAxios.get("Api/Hampton/GetAllCouseTypes").then((resp) => {
      if (resp) {
        console.log("okkkbye", resp.data);
        setCourse(resp.data["AllUserTypes"]);
        setSelectedCourse(resp.data["AllUserTypes"][0].Coursename);
        
      }
    });
  }
  else{
    setSelectedCourse(userDetail.Company)
  }

    await mainAxios.get("Api/Hampton/getRoundsYear").then((resp) => {
      if (resp) {     
        setRoundsYear(resp.data["YearList"].map(x => x.Year));
        setYear(resp.data["YearList"][0].Year);
        setLoading(false);
      }
    });
  };

  const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      const [value, setValue] = useState('');

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <Form.Control
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value),
            )}
          </ul>
        </div>
      );
    },
  );


  return (

    <div className="container">
       <ToastContainer autoClose={3000}/>
      {loading ? <Spinner /> : (
        <div className="row">
          <div className="tab-sections">


            {/* <select
              onChange={(e) => {
                getCourse(e.target.value);
              }}
            >
              {course.map((item) => (
                <option value={item.Coursename} selected={item.Coursename === selectedCourse ? true : false}>{item.Coursename}</option>
              ))}
            </select>

            <select>
              <option>2022</option>
            </select> */}
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6 col-sm-5">
                  <Tabs

                    defaultActiveKey={tabs}
                    activeKey={tabs}
                    onSelect={(k) => setTab(k)}

                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >{(userDetail.Member_Type==4||(((userDetail.Special_user == 0 || userDetail.Special_user == 2) && userDetail.Account_Type == 0) &&userDetail.Member_Type!=2 )) ?(
                    <Tab eventKey={1} title="Budget" onClick={() => setTab(1)} >
                      <SnapshotBudget tabs={tabs} dropdowndata={userDetail.Member_Type==4?selectedCourse:userDetail.Company} />
                    </Tab>):null}
                    <Tab eventKey={2} title="Booked/Tendered Rounds ">
                     <BookedTenderedRounds tabs={tabs} dropdowndata={userDetail.Member_Type==4?selectedCourse:userDetail.Company}  />
                    </Tab>

                    <Tab eventKey={3} title="Dashboard">
                    <Dashboard dropdowndata={userDetail.Member_Type==4?selectedCourse:userDetail.Company} tabs={tabs} />

                    </Tab>
                    <Tab></Tab>
                  </Tabs>
                </div>
                <div class="col-md-6 col-sm-7" style={{ marginTop: '15px' }}>
                  <div class="row">
                    {userDetail.Account_Type===0 && <>
                    <div class="col-md-2 col-sm-2 col-xs-3">
                      <p style={{ marginTop: '10px', float: 'right' }}>Course:</p></div>
                    <div class="col-md-4 col-sm-4 col-xs-3">

                      <Dropdown style={{ border: '1px solid #0000004f', borderRadius: '5px', width: '100%' }}>
                        <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary" style={{ width: '100%' }}>
                          {selectedCourse}
                        </Dropdown.Toggle>

                        <Dropdown.Menu as={CustomMenu} className='Drop-down' style={{overflowY: "scroll"}}>
                          {course.map(x => 
                             <Dropdown.Item eventKey={x.Coursename} active={x.Coursename===selectedCourse?true:false} onClick={() => getCourse(x.Coursename)}>{x.Coursename}</Dropdown.Item>
                            )}                        
                          
                        </Dropdown.Menu>
                      </Dropdown>
                    </div></>}
                    <div class="col-md-2 col-sm-2 col-xs-2">
                      <p style={{ marginTop: '10px', float: 'right' }}>Year:</p></div>
                    <div class="col-md-4 col-sm-4 col-xs-4">
                      <Dropdown>
                        <Dropdown.Toggle id="dropdown-button-dark-example1" className='drop-ml ' variant="secondary">
                          {year}
                        </Dropdown.Toggle>

                        <Dropdown.Menu variant="dark">
                        {roundsYear.map(year => 
                          <Dropdown.Item onClick={() => setYear(+year)}>{year}</Dropdown.Item>)}
                          
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>{" "}
        </div>
      )}
    </div>
  );
}

export default Snapshot;
