import { useRef, useState } from "react";
import "../Importteam/importteam.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Home/Home.css";
import TableComponent from "../Component/Tables/TableComponent";
import Uploadexceltable from "../Component/Tables/Uploadexceltable";
import * as XLSX from "xlsx";
import { mainAxios } from "../middleware/interceptor";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const sample = [];

const column = [
  { column: "First_Name" },
  { column: "Last_Name" },
  { column: "Email" },
  { column: "Password" },
  { column: "Role" },
  { column: "Course" },
  { column: "Department" },
  { column: "Phone" },
];

function Importteam() {
  let [data, setData] = useState(sample);
  const [search, setSearch] = useState(sample);
  const [col, setColumn] = useState(column);
const[savedata,setSavedata]=useState([]);
  const onChange = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);
      console.log(data);
      setSavedata(data)
      if(data.length!=0){
        ValidateUser(data);}
    };
    reader.readAsBinaryString(file);
  };

  const searchFunction = (searchText) => {
    console.log(searchText)
    const query = searchText;
    let user = data.filter((item) => {
      return item.Role.toLowerCase().startsWith(query.toLowerCase()) ||
        item.lastName.toLowerCase().startsWith(searchText.toLowerCase()) ||
        item.email.toLowerCase().startsWith(searchText.toLowerCase()) ||
        item.firstName.trim().toLowerCase().startsWith(searchText.toLowerCase())

    })
    if (user.length > 0) {
      setSearch(user)
      console.log(user, "Users")
    }
    else {

    }
  }

  const getPage = () => {};

  const toastId = useRef(null);


  const ValidateUser = async (e) => {
    toastId.current = toast.loading("Please Wait");
    try {
      console.log(data, "EXel");

      await mainAxios.post("Api/Hampton/griddisplay", e).then((res) => {
        console.log(res.data.Final_result, "res1");
  
  setData(res.data.Final_result);
  setSearch(res.data.Final_result);
        if (res) {
          toast.update(toastId.current, {
            render: "Excel Upoaded Successfully",
            type: "success",
            isLoading: false,
            autoClose: 2000,
          });
        } else {
          toast.update(toastId.current, {
            render: "Error in Uploading Exel",
            isLoading: false,
            type: "error",
            autoClose: 2000,
          });
        }
      });
    } catch (err) {
      toast.update(toastId.current, {
        render: "something went wrong",
        isLoading: false,
        type: "error",
        autoClose: 2000,
      });
      console.log(err);
    }
    console.log(data, "resex");
  };
  const Saveusers = async () => {
    toastId.current = toast.loading("Please Wait");
    try {
      console.log(data, "EXel");

      await mainAxios.post("Api/Hampton/UploadValidation", savedata).then((res) => {
        console.log(res.data.Final_result, "res1");
  
  // setData(res.data.Final_result);
  // setSearch(res.data.Final_result);
        if (res.data.Pass == savedata.length) {
          toast.update(toastId.current, {
            render: "All Users are added",
            type: "success",
            isLoading: false,
            autoClose: 2000,
          });
        } else {
          toast.update(toastId.current, {
            render: "All Users are not added",
            isLoading: false,
            type: "error",
            autoClose: 2000,
          });
        }
      });
    } catch (err) {
      toast.update(toastId.current, {
        render: "something went wrong",
        isLoading: false,
        type: "error",
        autoClose: 2000,
      });
      console.log(err);
    }
    console.log(data, "resex");
  };

  return (
    <>
      <ToastContainer autoClose={3000} />
      <div class="container">
        <div class="temp-down">
          <h1 class="tit-le">Import Team Member</h1>
          <a
            class="download"
            href="https://s3.amazonaws.com/fobesoft/assets/hampton_connect/assets/Import_User_List_Test.xlsx"
          >
            <i class="bi bi-file-earmark-arrow-down-fill"></i> Download Team
            Member Roster Template{" "}
          </a>
        </div>
        <div class="upload-btn-wrapper">
          <button class="b-tn">
            <i class="bi bi-cloud-arrow-up-fill"></i> Upload
          </button>
          <input type="file" name="myfile" onChange={(e) => onChange(e)} />
        </div>

        <div class="table-sections">
          <Uploadexceltable
            header={col}
            data={{ list: search }}
            nestedColumn={false}
            Search={true}
            searchFunction={searchFunction}
            pagination={true}
            getPage={getPage}
          />
        </div>
        <div class="col-sm-12">
          <button className="btn btn-success s-txt-ctr">
            <img
              class="igm"
              src="https://d2a7u30t6wbkim.cloudfront.net/assets/hampton_connect/assets/images/import-icon.png"
            ></img>
            <span onClick={Saveusers}>Create All Team Member</span>
          </button>
        </div>
      </div>
    </>
  );
}

export default Importteam;
