import "bootstrap/dist/css/bootstrap.min.css";
import "./SnapshotTable.css";
import Table from "react-bootstrap/Table";
import { Open } from "react-ionicons";

const category = [
  { id: 1, name: "Labor" },
  { id: 2, name: "COGS" },
  { id: 3, name: "Expense" },
];

function SnapshotTable2({ Opentobuy }) {
  return (
    <div class="table-responsive snap-shot-table te_new">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th className="bg_table">
              <p style={{ textAlign: "-webkit-center" }}>Category </p>{" "}
            </th>
            <th className="bg_table">
              {" "}
              <p style={{ textAlign: "-webkit-center" }}> Item </p>{" "}
            </th>
            <th className="bg_table">
              {" "}
              <p style={{ textAlign: "-webkit-center" }}>MTD Actual </p>{" "}
            </th>
            <th className="bg_table">
              {" "}
              <p style={{ textAlign: "-webkit-center" }}>
                Total Monthly Budget{" "}
              </p>{" "}
            </th>
            <th className="bg_table">
              {" "}
              <p style={{ textAlign: "-webkit-center" }}>Remaining </p>
            </th>
          </tr>
        </thead>
        <tbody>
          {category?.map((cat) => {
            let open = Opentobuy?.filter((x) => x.id === cat.id);

            {
              console.log(open, "opeenn");
            }
            // {console.log(open[0].catname)}
            return (
              <>
                {open?.length > 0 && (
                  <tr>
                    <td rowSpan={open?.length} className="bg_table">
                      <p className="">
                        <div class="tab-card-new-val-1 table_grey  tble_width">
                          {cat.name}
                        </div>
                      </p>
                    </td>

                    <td>
                      <div class="tab-card-new-val-1 tbl_sub table_grey">
                        <span className="th_hd">{open[0]?.catname}</span>{" "}
                      </div>
                    </td>
                    <td>
                      <div class="tab-card-new-val-1 table_grey tbl_sub">
                        {" "}
                        <span className="th_hd">$ {open[0]?.mtdactual}</span>
                      </div>{" "}
                    </td>
                    <td>
                      {" "}
                      <div class="tab-card-new-val-1 tbl_sub table_grey">
                        {" "}
                        <span className="th_hd"></span>$ {open[0]?.mtdbuget}
                      </div>{" "}
                    </td>
                    {/* <td><div class={(open[0]?.balance<0)?'tab-card-new-val-1 val-2 tbl_sub' : 'tab-card-new-val-1 tbl_sub'}> <span className='th_hd'>$ {open[0]?.balance}</span></div> </td> */}
                    <td>
                      <div
                        class={
                          open[0]?.balance < 0
                            ? "tab-card-new-val-1 val-2 tbl_sub"
                            : open[0]?.balance > 0
                            ? "tab-card-new-val-1 tbl_sub"
                            : "tab-card-new-val-1 tbl_sub table_grey"
                        }
                      >
                        {" "}
                        <span className="th_hd">$ {open[0]?.balance}</span>
                      </div>{" "}
                    </td>
                  </tr>
                )}
                {open?.length > 1 &&
                  open?.slice(1).map((rec) => (
                    <tr>
                      <td>
                        <div class="tab-card-new-val-1 tbl_sub table_grey">
                          <span className="th_hd">{rec?.catname}</span>{" "}
                        </div>
                      </td>
                      <td>
                        <div class="tab-card-new-val-1 table_grey tbl_sub">
                          {" "}
                          <span className="th_hd">$ {rec?.mtdactual}</span>
                        </div>{" "}
                      </td>
                      <td>
                        {" "}
                        <div class="tab-card-new-val-1 tbl_sub table_grey">
                          {" "}
                          <span className="th_hd"></span>$ {rec?.mtdbuget}
                        </div>{" "}
                      </td>
                      {/* <td><div class={(rec?.balance<0)?'tab-card-new-val-1 val-2 tbl_sub' : 'tab-card-new-val-1 tbl_sub'}> <span className='th_hd'>$ {rec?.balance}</span></div> </td> */}
                      <td>
                        <div
                          class={
                            rec?.balance < 0
                              ? "tab-card-new-val-1 val-2 tbl_sub"
                              : rec?.balance > 0
                              ? "tab-card-new-val-1 tbl_sub"
                              : "tab-card-new-val-1 tbl_sub table_grey"
                          }
                        >
                          {" "}
                          <span className="th_hd">$ {rec?.balance}</span>
                        </div>{" "}
                      </td>
                    </tr>
                  ))}
              </>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}
export default SnapshotTable2;
