
import 'bootstrap/dist/css/bootstrap.min.css'
import './TableComponent.css';
import Table from 'react-bootstrap/Table';
import { useState, useEffect } from 'react';
import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import CurrencyFormat from "react-currency-format";



const MonthlyViewTable = ({monthIndex,editMonthTotal, showtotal, selectDepartment,editMonthData,editMonthDatapercentage,error,menuId,data, title, isBudget }) => {
  const[items,setitems]=useState(JSON.parse(localStorage.getItem('UserClaims')));


//  console.log("Revmonthprofit", data.list[0].push(data.list[0].shift()))

  return (
    <>

    {!error?   <div className='table-scroll table-responsive table2 table-revenue'>


        <Table striped bordered hover>
          <thead>

            <tr>
              <th rowSpan={2}><div className='title-tab'>  {title} </div></th>
              {data?.map(item =>

                <th colSpan={2}><div className='tab-card-new'>{item.Month}</div></th>
              )}

            </tr>

            <tr>
              {data?.map(item =>
                <>
                  <th><div className='tab-card-new'> {isBudget ? 'BUDGETED' : 'ROUND'}</div></th><th><div className='tab-card-new'>PER(%)</div></th>
                </>
              )}

            </tr>
          </thead>
          <tbody>

            {
              data[0]?.List.map((item, index) => {
                let temp = [];
                data?.map(x => {
                  temp.push({ ...x.List[index] });
                })

                return <tr><td>{temp[0].Cat_Name}</td> {temp.map((cat,index) => {
                  
                  return <>
                    <td><span className='fl-lf'>$</span> <p className='fl-r'>
                    
                      <CurrencyFormat
                      
                      className={cat.Editable_Status==1?'input-edit':'non-edit-cs'}
                        decimalScale={2}
                        name={cat.Cat_id}
                        id={cat.Cat_id}
                        fixedDecimalScale={2}
                        disabled={cat.Editable_Status==1 ? false:true}
                        value={cat.Cat_amt}

                        thousandSeparator={true}

                        onValueChange={(value, name) => editMonthData(cat.Cat_id, value,monthIndex+index+1,menuId)}
                      />
                      {/* <CurrencyInput
                      className='input-edit-monthly'
                                        id={cat.Cat_id}
                                        name={cat.Cat_id}
                                        defaultValue={cat.Cat_amt}
                                        decimalsLimit={2}
                                        disabled={ ((items?.Special_user==1||items?.Special_user==0) && items?.Account_Type!=0) ? false : true}
                                        onValueChange={(value, name) => changeData(cat.Cat_id, value)}
                                    /> */}
                    </p></td>
                    <td><p className='fl-r'>

                      <CurrencyFormat
                
                    className={cat.Editable_Status==1?'input-edit':'non-edit-cs'}
                        decimalScale={2}
                        name={cat.Cat_id}
                        id={cat.Cat_id}
                       value={cat.Cat_Per}
                        fixedDecimalScale={2}
                      disabled={cat.Editable_Status==1 ? false:true}
                        thousandSeparator={true}

                        onValueChange={(value, name) => editMonthDatapercentage(cat.Cat_id, value,monthIndex+index+1,menuId)}
                      />
                      {/* <CurrencyInput
                      className='input-edit-monthly'
                                        id={cat.Cat_id}
                                        name={cat.Cat_id}
                                        defaultValue={cat.Cat_Per}
                                        decimalsLimit={2}
                                        // disabled={(cat.Editable_Status == 1 ) ? false : true}
                                        onValueChange={(value, name) => changeData(cat.Cat_id, value)}
                                    /> */}
                      <span className='fl-ri'>%</span></p></td>
                  </>
                })}</tr>

              })
            }






          </tbody>
          {showtotal && 
          <tfoot>
            <tr>
              <td>TOTAL</td>
              {data.map((item ,index) =>
                <>
                  <td> <span className='fl-lf'>$</span><p className='fl-r'>
                    <CurrencyFormat
                      className={ ((items?.Special_user==1||items?.Special_user==0 ) && items?.Account_Type!=0 && title=='Revenue' || title=='Turf Care Expenses' )  ? 'input-edit':'non-edit-cs' }
                      value={item.Total}
                      fixedDecimalScale={2}
                      onValueChange={(value, name) => editMonthTotal(item.Tota, value,monthIndex+index+1,menuId)}
                      decimalScale={2}
                      disabled={(title=='Controllable'||title=='Cost of Goods' || title=='Non Controllable' || title=='Labor')?true:false}
                   // prefix={"$"}
                    /></p>
                  </td>
                  <td><p className='fl-r'>
                  <CurrencyFormat
                    className='non-edit-per-cs'
                    value={item.Month_totper} fixedDecimalScale={2}
                    disabled={true}
                    decimalScale={2}

                  /> <span className='fl-ri'>%</span></p></td>
                </>
              )}

            </tr>
          </tfoot> }
        </Table>
              
      </div>:null}
    </>
  );
}
export default MonthlyViewTable;