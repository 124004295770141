import "../Profile/profile.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { mainAxios } from "../middleware/interceptor";
import { Phone, Update } from "@material-ui/icons";
import { ToastContainer, toast } from "react-toastify";

function Profile() {
  const [profile, setProfile] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    Phone: "",
  });
  const [update, setupdate] = useState();
  const [isLoading, setIsLoading] = useState(false);
  let result = [];

  const getData = async () => {
    let Member_Type = items.Member_Type;
    let splUser = items.Special_user;
    let Acc_type = items.Account_Type;
    let company = items.Company;
    let url = "";
    console.log(Member_Type, "Member Type");

    if (Member_Type == 2) {
      SetPosid(1);
      url = `api/Hampton/GetTeamMemberProfile`;
    } else {
      url = `api/Hampton/GetUserProfile`;
      //tm
    }

    const { data } = await mainAxios.get(url);
    let c = "";
    let d = "";
    data.UserDetails.map((x) => {
      c = x.RestaurantName ? x.RestaurantName : "";
      d = x.DepartmentName ? x.DepartmentName : "";
    });

    let datas = {
      FirstName: data.UserDetails[0].FirstName,
      LastName: data.UserDetails[0].LastName,
      Email: data.UserDetails[0].Email,
      Phone: data.UserDetails[0].Phone,
      Course_Address: data.UserDetails[0].Course_Address,
      Course_Location: data.UserDetails[0].Course_Location,
      Course_Street_number: data.UserDetails[0].Course_Street_number,
      Course_City: data.UserDetails[0].Course_City,
      Course_State: data.UserDetails[0].Course_State,
      Course_State: data.UserDetails[0].Course_State,
      Course_Zipcode: data.UserDetails[0].Course_Zipcode,
      Course: c,
      Department: d,
    };
    setupdate(datas);
    console.log("data", data);
    setProfile(datas);
  };
  useEffect(() => {
    getData();
  }, []);

  let courseornot = false;
  let deptornot = false;
  let Roles = [];
  const items = JSON.parse(localStorage.getItem("UserClaims"));
  const [Role, setRole] = useState(Roles);
  const [showAddcourse, setshowAddcourse] = useState(false);
  const [course, setCourse] = useState(null);
  const [dept, setdept] = useState();
  const [CourseValue, SetCourseValue] = useState();
  const [comid, setcomid] = useState();
  const [DeptValue, SetDeptValue] = useState();
  const [posid, SetPosid] = useState();

  useEffect(() => {
    let Member_Type = items.Member_Type;
    let splUser = items.Special_user;
    let Acc_type = items.Account_Type;
    let company = items.Company;
    let url;
    console.log(Member_Type, "Member Type");
    if (Member_Type == 4) {
      SetPosid(4);
      //super
    } else if (Member_Type == 2) {
      SetPosid(1);
    } else if ((splUser == 0 || splUser == 2) && Acc_type == 0) {
      SetPosid(2);
      //geb
    } else if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      SetPosid(2);
      //de
    } else {
      //tm
    }
  }, []);

  function handleFirstNameChange(e) {
    setProfile((prevState) => ({
      ...prevState,
      FirstName: e.target.value,
    }));
  }

  function handleLastnameChange(e) {
    setProfile((prevState) => ({
      ...prevState,
      LastName: e.target.value,
    }));
  }

  function handleEmailChange(e) {
    setProfile((prevState) => ({
      ...prevState,
      Email: e.target.value,
    }));
  }

  function handlephoneChange(e) {
    setProfile((prevState) => ({
      ...prevState,
      Phone: e.target.value,
    }));
  }

  const [updtprofile, setupdtprofile] = useState({});

  const updatedata = (event) => {
    event.preventDefault();

    if (
      profile.Phone === "" ||
      profile.FirstName === "" ||
      profile.Email === "" ||
      profile.LastName === ""
    ) {
      handlePhoneNumber();
      handleFirstName();
      handleEmail();
      handleLastName();
    }

    console.log(update, "updatee");

    let usrNew = {
      PosId: posid,
      FirstName: profile.FirstName,
      LastName: profile.LastName,
      Email: profile.Email,
      Phone: profile.Phone,
      Course: profile.Course,
      Department: profile.Department,
      Course_Location: profile.Course_Location,
      Course_Street_number: profile.Course_Street_number,
      Course_Address: profile.Course_Address,
      Course_City: profile.Course_City,
      Course_State: profile.Course_State,
      Course_Country: profile.Course_Country,
      Course_Zipcode: profile.Course_Zipcode,
    };

    mainAxios.post(`Api/hampton/UpdateUserDetils`, usrNew).then((res) => {
      console.log(usrNew, "usrnew");
      let Message = "";

      if (res.data.length > 0) {
        res.data.map((x) => {
          toast.success(x.Message);
        });
      }
    });
  };

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [LastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  function handleEmail(e) {
    const value = e.target.value;
    const regex = /^[^@\s]+@[^\s]+\.[^\s]+$/;
    const specialChars = value.match(/[^\w\s]/g) || [];
    if (specialChars.length > 2) {
      setError("Only two special characters are allowed");
    } else if (regex.test(value)) {
      setEmail(value);
      setError("");
    } else {
      setError("Please enter a valid email address");
    }
  }
  const handlePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
  };
  const validatePhoneNumber = () => {
    const regex = /^\(\d{3}\) \d{3}-\d{4}$/;
    if (!regex.test(phoneNumber)) {
      setPhoneNumberError("Please enter a valid 10-digit phone number");
    } else {
      setPhoneNumberError("");
    }
  };

  function validateFirstName(FirstName) {
    const regex = /^[a-zA-Z\s]*$/; // Only letters and spaces are allowed
    if (!FirstName) {
      return "First name is required";
    } else if (FirstName.length < 2) {
      return "First name must be at least 2 characters long";
    } else if (!regex.test(FirstName)) {
      return "First name must contain only letters and spaces";
    } else {
      return "";
    }
  }
  function handleFirstName(event) {
    const name = event.target.value;
    setFirstName(name);
    setFirstNameError(validateFirstName(name));
  }
  function validateLastName(LastName) {
    const regex = /^[a-zA-Z\s]*$/; // Only letters and spaces are allowed
    if (!LastName) {
      return "Last name is required";
    } else if (LastName.length < 2) {
      return "Last name must be at least 2 characters long";
    } else if (!regex.test(LastName)) {
      return "Last name must contain only letters and spaces";
    } else {
      return "";
    }
  }
  function handleLastName(event) {
    const name = event.target.value;
    setLastName(name);
    setLastNameError(validateLastName(name));
  }

  return (
    <div class="container">
      <ToastContainer autoClose={2000} />
      <form>
        <div class="row r-card">
          <h2 class="r-card-title text-center">Profile</h2>
          <div class="col-sm-12">
          <div class="col-sm-6 form-group">
            <label for="name-f" class="lab-el">
              First Name *
            </label>
            <input
              type="text"
              value={profile != null ? profile.FirstName : ""}
              onChange={(event) => {
                {
                  handleFirstNameChange(event);
                }
                {
                  handleFirstName(event);
                }
              }}
              class="form-control fm"
              name="fname"
              id="name-f"
              placeholder="Enter First Name"
              required
            />
            {firstNameError && (
              <div style={{ color: "red" }}> {firstNameError}</div>
            )}
          </div>
          <div class="col-sm-6 form-group">
            <label for="name-l" class="lab-el">
              Last name *
            </label>
            <input
              type="text"
              value={profile != null ? profile.LastName : ""}
              onChange={(event) => {
                {
                  handleLastnameChange(event);
                }
                {
                  handleLastName(event);
                }
              }}
              class="form-control fm"
              name="lname"
              id="name-l"
              placeholder="Enter Last Name"
              required
            />
             {lastNameError && (
              <div style={{ color: "red" }}> {lastNameError}</div>
            )}
            </div>
          </div>
          <div class="col-sm-6 form-group">
            <label for="email" class="lab-el">
              Email *
            </label>
            <input
              type="email"
              value={profile != null ? profile.Email : ""}
              onChange={(event) => {
                {
                  handleEmailChange(event);
                }
                {
                  handleEmail(event);
                }
              }}
              class="form-control fm"
              name="email"
              id="email"
              placeholder="Enter Email"
              required
            />
             {error && (<div style={{ color: "red" }}>{error}</div>)}
          </div>
          <div class="col-sm-6 form-group">
            <label for="address-1" class="lab-el">
              Phone Number *
            </label>
            <input
              type="address"
              value={profile != null ? profile.Phone : ""}
              onChange={(event) => {
                {
                  handlephoneChange(event);
                }
                {
                  handlePhoneNumber(event);
                }
              }}
              onBlur={validatePhoneNumber}
              maxLength={10}
              class="form-control fm"
              name="Locality"
              id="address-1"
              placeholder="Enter Phone Number"
              required
            />
            {phoneNumberError && (
              <div style={{ color: "red" }}>{phoneNumberError}</div>
            )}
          </div>
          <div class="row">
          <div class="col-sm-12" >
          <div class="col-sm-6 form-group">
            <label for="address-1" class="lab-el">
              Course Name *
            </label>
            <input
              type="address"
              class="form-control fm"
              value={profile != null ? profile.Course : ""}
              disabled={Update}
              name="Locality"
              id="address-1"
              placeholder="Enter Course Name"
              required
            />
          </div>
          <div class="col-sm-6 form-group">
            <label for="address-1" class="lab-el">
              Department Name *
            </label>
            <input
              type="address"
              class="form-control fm"
              value={profile != null ? profile.Department : ""}
              disabled={Update}
              name="Locality"
              id="address-1"
              placeholder="Enter Department Name"
              required
            />
          </div>
          </div>
          </div>

          <div class="col-sm-12 bt-pr">
            <button class="btn btn-success txt-ctr"
             
             onClick={(e) => {
               updatedata(e);
             }}
            >
                
              <span class="up-pr">Update Profile</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Profile;
