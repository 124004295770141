import 'bootstrap/dist/css/bootstrap.min.css'
import './SnapshotTable.css';
import Table from 'react-bootstrap/Table';
import { useEffect ,useState} from 'react';
import { Spinner } from '../../../Component/Spinner'

function SnapshotTable1({ Rounds_Daily_mtd,Rounds_mtd,Revenue_mtd }) {
    const[show,setshow] = useState(false)
    const[dailyRound,setDailyRounds] = useState()


    useEffect(() => {

        // const sum = Rounds_Daily_mtd?.reduce((accumulator, object) => {
        //     return accumulator + object.Actual_Rounds;
        //   }, 0);
        //   setDailyRounds(sum);

        // const timer = setTimeout(() => {
  
        //   console.log('This will run after 1 second!')
  
        //     setshow(true);
  
        // }, 1000);
  
        // return () => clearTimeout(timer);
  
      }, []);


    return (
        <>
          {show ? <Spinner /> : (
        <div class="table-scroll snap-shot-table table-responsive te_new">
            <Table>
                <thead>
                    <tr>
                        <th> </th>

                        <th className='bg_table'> <p style={{ textAlign: '-webkit-center' }}>MTD</p>  </th>
                        <th className='bg_table'> <p style={{ textAlign: '-webkit-center' }}>MTD Budget</p> </th>
                        <th className='bg_table'> <p style={{ textAlign: '-webkit-center' }}>Variance</p>  </th>
                        <th className='bg_table'> <p style={{ textAlign: '-webkit-center' }}>Variance % </p></th>

                    </tr>
                </thead>
                <tbody >
                    <tr>
                        <td className='bg_table'><p className='td_hd'> Rounds</p></td>
                        {Rounds_mtd?.map(round => <>
                            <td><div class={round?.Roundsmtd>=0?"tab-card-new-val-1":"tab-card-new-val-1 val-2"}>{round.Roundsmtd}</div></td>
                            <td><div class="tab-card-new-val-1 table_grey">{round.Roundsbudget}</div> </td>
                            <td> <div class={round?.Roundsvarience>=0?"tab-card-new-val-1":"tab-card-new-val-1 val-2"}>{round.Roundsvarience}</div> </td>
                            <td> <div class={round?.Roundsvarienceper>=0?"tab-card-new-val-1":"tab-card-new-val-1 val-2"}>{round.Roundsvarienceper}</div> </td>
                        </>)}
                    </tr>
                    {Revenue_mtd?.map(rev => <>
                    <tr>                  
                        <td className='bg_table'><p className='td_hd'>{rev.Name}</p></td>                       
                        <td> <div class={rev?.mtd>=0?"tab-card-new-val-1":"tab-card-new-val-1 val-2"}>{rev.mtd}</div> </td>
                        <td><div class="tab-card-new-val-1 table_grey">{rev.budget}</div> </td>
                        <td><div class={rev?.variance>=0?"tab-card-new-val-1":"tab-card-new-val-1 val-2"}>{rev.variance}</div>  </td>
                        <td><div class={rev?.variance_per>=0?"tab-card-new-val-1":"tab-card-new-val-1 val-2"}>{rev.variance_per}</div> </td>                        
                    </tr>
                    </>)}
                   
                </tbody>
            </Table>
        </div>)
    }
    </>
    );
}
export default SnapshotTable1;