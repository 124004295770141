import '../Manageteam/manageteam.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Tab_user from '../Tab_manageuser/AddTeamMember';
import ViewTeamMember from '../Tab_manageuser/ViewTeamMembers'
import { useState, useEffect } from 'react';
import {mainAxios} from '../middleware/interceptor';
import { ToastContainer } from 'react-toastify';


function Manageteam() {

  const [tab, setTab] = useState('Add');
  const [editUser, setEditUser] = useState(null);
  


  return (
    <div className='container'>
<ToastContainer autoClose={3000} />
      <div className='tab-sections'>

        <Tabs
          defaultActiveKey={tab}
          activeKey={tab}
          onSelect={(k) => setTab(k)}
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="Add" title={editUser?"Update User":"Add User"}>
            <Tab_user setTab={setTab} editUser={editUser} setEditUser={setEditUser} />
          </Tab>
          <Tab eventKey="view" title="View Team Member">
            <ViewTeamMember tab={tab} setTab={setTab} setEditUser={setEditUser} />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default Manageteam;