import "bootstrap/dist/css/bootstrap.min.css";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BsExclamationTriangle } from "react-icons/bs";
import Popup from "reactjs-popup";
import Tab from "react-bootstrap/Tab";
import Dropdown from "react-bootstrap/Dropdown";
import "../Home/Home.css";
import "./Budget.css";
import Button from "@mui/material/Button";
import YearTable from "../Component/Tables/YearTable";
import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
// import Button from 'react-bootstrap/Button';
import MonthlyViewTable from "../Component/Tables/MonthlyViewTable";
import axios from "axios";
import { fobeAxios, mainAxios } from "../middleware/interceptor";
import { Spinner } from "../Component/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CurrencyFormat from "react-currency-format";
import { getStepButtonUtilityClass, TextField, Zoom } from "@mui/material";
import AddCategoryPagination from './TableComponent_AddCategory';

import Table from "react-bootstrap/Table";
import zIndex from "@mui/material/styles/zIndex";
import { ZoomIn } from "@material-ui/icons";

import DatePicker from "react-widgets/DatePicker";
import "react-widgets/styles.css";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const itemschangeorder = [
  { id: 1, name: "Catering" },
  { id: 2, name: "Food" },
  { id: 3, name: "Wine" },
  { id: 4, name: "Beer" },
  { id: 5, name: "Liquor" },
  { id: 6, name: "Liquor" },
  { id: 7, name: "Liquor" },
  { id: 8, name: "Liquor" },
];

const Revenue = [
  { column: "REVENUE" },
  { column: "BUDGETED AMOUNT" },
  { column: "PER(%)" },
];

const AdminExpenses = [
  { column: "Admin Expenses" },
  { column: "BUDGETED AMOUNT" },
  { column: "PER(%)" },
];
const TurfMaintenance = [
  { column: "Turf & Maintenance" },
  { column: "BUDGETED AMOUNT" },
  { column: "PER(%)" },
];
const FitnessExpenses = [
  { column: "Fitness Expenses" },
  { column: "BUDGETED AMOUNT" },
  { column: "PER(%)" },
];
const TennisExpenses = [
  { column: "Tennis Expenses" },
  { column: "BUDGETED AMOUNT" },
  { column: "PER(%)" },
];
const TurfCareExpenses = [
  { column: " TurfCare  " },
  { column: "BUDGETED AMOUNT" },
  { column: "PER(%)" },
];

const CostOfGoods = [
  { column: " Cost Of Goods " },
  { column: "BUDGETED AMOUNT" },
  { column: "PER(%)" },
];

const Labor = [
  { column: " Labor " },
  { column: "BUDGETED AMOUNT" },
  { column: "PER(%)" },
];

const Controllable = [
  { column: " Controllable " },
  { column: "BUDGETED AMOUNT" },
  { column: "PER(%)" },
];

const NonControllable = [
  { column: " Non Controllable " },
  { column: "BUDGETED AMOUNT" },
  { column: "PER(%)" },
];

const ProfitLoss = [
  { column: " Profit/Loss " },
  { column: "BUDGETED AMOUNT" },
  { column: "PER(%)" },
];

let type_budget;
const yearlist = [];

const cr = [
  {
    Cat_id: 0,
    Cat_name: "LABOR",
    Cat_amt: "",
    Cat_per: "",
    Editable_Status: "",
    Master_id: "",
    cat_type: "Labor",
    Default_addon: "",
  },
  {
    Cat_id: 67,
    Cat_name: "Workers Comp % Of Total Payroll",
    Cat_amt: "",
    Cat_per: 1.85,
    Editable_Status: 0,
    Master_id: "3",
    cat_type: "Labor",
    Default_addon: 1,
  },
  {
    Cat_id: 68,
    Cat_name: "Workers Comp % Of Total Sales",
    Cat_amt: "",
    Cat_per: 0,
    Editable_Status: 0,
    Master_id: "3",
    cat_type: "Labor",
    Default_addon: 1,
  },
  {
    Cat_id: 66,
    Cat_name: "Payroll Tax",
    Cat_amt: "",
    Cat_per: 3.63,
    Editable_Status: 0,
    Master_id: "3",
    cat_type: "Labor",
    Default_addon: 1,
  },
  {
    Cat_id: 0,
    Cat_name: "CONTROLLABLE",
    Cat_amt: "",
    Cat_per: "",
    Editable_Status: "",
    Master_id: "",
    cat_type: "Controllable",
    Default_addon: "",
  },
  {
    Cat_id: 97,
    Cat_name: "Management / Franchise Fees",
    Cat_amt: 0,
    Cat_per: 0,
    Editable_Status: 0,
    Master_id: "4",
    cat_type: "Controllable",
    Default_addon: 1,
  },
  {
    Cat_id: 111,
    Cat_name: "Telephone",
    Cat_amt: 0,
    Cat_per: 0,
    Editable_Status: 0,
    Master_id: "4",
    cat_type: "Controllable",
    Default_addon: 1,
  },
  {
    Cat_id: 113,
    Cat_name: "Utilities - Water",
    Cat_amt: 0,
    Cat_per: 0,
    Editable_Status: 0,
    Master_id: "4",
    cat_type: "Controllable",
    Default_addon: 1,
  },
  {
    Cat_id: 114,
    Cat_name: "Utilities - Disposal",
    Cat_amt: 0,
    Cat_per: 0,
    Editable_Status: 0,
    Master_id: "4",
    cat_type: "Controllable",
    Default_addon: 1,
  },
  {
    Cat_id: 115,
    Cat_name: "Utilities - Electric",
    Cat_amt: 0,
    Cat_per: 0,
    Editable_Status: 0,
    Master_id: "4",
    cat_type: "Controllable",
    Default_addon: 1,
  },
  {
    Cat_id: 116,
    Cat_name: "Utilities - Gas",
    Cat_amt: 0,
    Cat_per: 0,
    Editable_Status: 0,
    Master_id: "4",
    cat_type: "Controllable",
    Default_addon: 1,
  },
  {
    Cat_id: 0,
    Cat_name: "NON CONTROLLABLE",
    Cat_amt: "",
    Cat_per: "",
    Editable_Status: "",
    Master_id: "",
    cat_type: "NonControllable",
    Default_addon: "",
  },
  {
    Cat_id: 125,
    Cat_name: "Bank Charges",
    Cat_amt: 0,
    Cat_per: 0,
    Editable_Status: 0,
    Master_id: "5",
    cat_type: "NonControllable",
    Default_addon: 1,
  },
  {
    Cat_id: 127,
    Cat_name: "Credit Card Discount",
    Cat_amt: 0,
    Cat_per: 0,
    Editable_Status: 0,
    Master_id: "5",
    cat_type: "NonControllable",
    Default_addon: 1,
  },
  {
    Cat_id: 130,
    Cat_name: "Insurance - General Liab",
    Cat_amt: 0,
    Cat_per: 0,
    Editable_Status: 0,
    Master_id: "5",
    cat_type: "NonControllable",
    Default_addon: 1,
  },
  {
    Cat_id: 131,
    Cat_name: "Payroll Fees",
    Cat_amt: 0,
    Cat_per: 0,
    Editable_Status: 0,
    Master_id: "5",
    cat_type: "NonControllable",
    Default_addon: 1,
  },
  {
    Cat_id: 151,
    Cat_name: "Rent",
    Cat_amt: 0,
    Cat_per: 0,
    Editable_Status: 0,
    Master_id: "6",
    Default_addon: 1,
  },
  {
    Cat_id: 152,
    Cat_name: "CAM Charges",
    Cat_amt: 0,
    Cat_per: 0,
    Editable_Status: 0,
    Master_id: "6",
    Default_addon: 1,
  },
];

// --------------------MONTHLY VIEW TABLE--------------------------//
function Tab1({
  setshowCourses,
  course_id,
  Courseflage,
  refreshgetdata,
  bossid,
  setbossid,
  selectDepartment,
  SetselectDepartment,
  settriggervariable,
  triggervariable,
  setBudgetlength,
  Budgetlength,
  setcheckingForNoBudget,
  checkingForNoBudget,
  tabss,
  alldepartments,
  setbudget_type,
  budget_type,
  selectedCourse
}) 
{

  let items = JSON.parse(localStorage.getItem("UserClaims"));
  const[bos,setbos]=useState(bossid)
  const [showCourse, setShowCourse] = useState(false);
  const [currentPage,setCurrentPage]=useState(1)
  const [postPerPage]=useState(5)
  const [deletecat, setCatDelete] = useState(false);
  const [delete_cat, setDeleteCAt] = useState("");
  let newDate = new Date();
  const [selectYear, setSelectYear] = useState(newDate.getFullYear());
  const [selectedOption, setSelectedOption] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [showtotal, setshowtotal] = useState(true);
  const [tabs, setTab] = useState("");
  const [inputValue, setInputValue] = useState();
  const [show, setShow] = useState(false);
  const [showtable, setshowtable] = useState(false);
  const [addcategory, setaddcategory] = useState([]);
  const [revenue, setRevenue] = useState([]);
  const [revMonth, setRevMonth] = useState([]);
  const [text, setText] = useState("");
  const [BudgetRevenuelength, setBudgetRevenuelength] = useState();
  const [showRevenueBudget, setshowRevenueBudget] = useState(true);
  const userDetail = JSON.parse(localStorage.getItem("UserClaims"));
  const [Monthtotal, setMonthtotal] = useState(0);
  const [LabelList, setLabelList] = useState([]);
  const [Total, setTotal] = useState(0);
  const [prev, setprev] = useState();
  const [next, setnext] = useState();
  const [editRowId, setEditRowId] = useState(null);
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  const [d, setd] = useState(date);
  const [m, setm] = useState(month);
  const [ye, sety] = useState(year);
  const [Edit, setEdit] = useState(false);
  const [showCat, setShowCat] = useState(true);

  let splUser = items.Special_user;
  let Acc_type = items.Account_Type;

  const [startDate, setStartDate] = useState("");
  let month_new;
  let date_new;

  let date_picker;
  if(startDate===""){
    date_picker=2022 + "-" + 12 + "-" + 27;
  }
  else{
    if (startDate.getMonth() + 1 < 10) {
      month_new = "0" + (startDate.getMonth() + 1);
    } else {
      month_new =startDate.getMonth() + 1;
    }
  
    if (startDate.getDate() < 10) {
      date_new = "0" + startDate.getDate();
    } else {
      date_new = startDate.getDate();
    }
    date_picker = startDate.getFullYear() + "-" + month_new + "-" + date_new; 
  }


  const [Year_List, setYear_List] = useState([]);
  const [Plan_List, setPlan_List] = useState([]);
  const [Revenue_List, setRevenue_List] = useState([]);
  const[hideCategoriesandconstant, sethideCategoriesandConstant]=useState(true)
  const [isLoading, setIsLoading] = useState(true);
  const[loader,setloader]=useState(true)
  const [dept, setdept] = useState();
  const [department, setdepartment] = useState();
  const [y, setY] = useState(yearlist);

  const [data, setData] = useState({ list: [], total: 0, percentage: 0 });
  // const[Rev,SetRev]=useState({ list: sample, total: 432423, percentage: 98 });
  const [isMonthView, setIsMonthView] = useState(false);
  const [monthNavi, setMonthNavi] = useState(false);
  const [error, seterror] = useState(false);
  const [monthData, setMonthData] = useState({
    data: revMonth,
    label: "Revenue Monthly View",
    title: "Revenue",
  });

  const getData = async () => {
    let GetLabelURL;
    setIsLoading(true);
    setloader(true)
    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      GetLabelURL = `/Api/Common/getLabel`;
    } else {
      GetLabelURL = "Api/Common/getBossLabel/" + bossid + "/" + ye;
    }
    const { data } = await fobeAxios.get(GetLabelURL);
    // setbudget_type(data.LabelAndYear[1].Year_List[0].budget_type)
    let GetBudgetURL, GetRevenueMonthurl;

    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      if (budget_type == "1") {
        GetBudgetURL = "Api/BudgetPeriod/GetBudget/" + selectYear;
        GetRevenueMonthurl = "Api/BudgetPeriod/GetRevenueMonth/" + selectYear;
      } else {
        GetBudgetURL = `Api/Budget/GetBudget/` + selectYear;
        GetRevenueMonthurl = `Api/Budget/GetRevenueMonth/` + selectYear;
      }
    } else {
      GetBudgetURL = `/api/Boss/GetBossBudget/` + bossid + "/" + ye;
      GetRevenueMonthurl = `api/Boss/GetBossRevenueMonth/` + bossid + "/" + ye;
    }

   
    //console.log(labelName, "labelName");

    await fobeAxios.get(GetBudgetURL).then((res) => {
      setBudgetRevenuelength(res.data.BudgetRevenue.length);
      setIsLoading(false);
      setloader(false)
      if (res.data.BudgetRevenue.length > 0) {
        setIsLoading(false);
        setYear_List(res.data.BudgetRevenue[0].Year_List);
        setPlan_List(res.data.BudgetRevenue[1].Plan_List);
        setPlan_List(res.data.BudgetRevenue[2].Revenue_List);
        /////////////////For Recurring/////////////
        console.log(res.data.BudgetRevenue[2].Total, "Recurring");
        settotalbudgetamount(res.data.BudgetRevenue[2].Total);
        //////////////////////////////////////////////////
        setaddcategory(res.data.BudgetRevenue[2].Revenue_List);
        setRevenue(res.data.BudgetRevenue[2]);
        setData({
          ...data,
          list: res.data.BudgetRevenue[2].Revenue_List,
          total: res.data.BudgetRevenue[2].Total,
          percentage: res.data.BudgetRevenue[2].Per,
        });
        setInputValue(res.data.BudgetRevenue[2].Total);
        if (res.data.BudgetRevenue) {
          if (res.data.BudgetRevenue[1]) {
            if (
              res.data.BudgetRevenue[1].Plan_List
                ? res.data.BudgetRevenue[1].Plan_List[0]
                : false
            ) {
              setTotal(res.data.BudgetRevenue[1].Plan_List[0].Total);
            }
          }
        }
      } else {
        setYear_List([""]);
        setPlan_List([""]);
        setPlan_List([""]);

        setRevenue([""]);
        setData({ ...data, list: [], total: 0, percentage: 0 });
        setTotal("");
      }
    });

    await fobeAxios.get(GetRevenueMonthurl).then((res) => {
      console.log("Revmonth", res.data.Revenue_Thingies);
      setRevMonth(res.data.Revenue_Thingies);
      seterror(res.data.Revenue_Thingies.length > 0 ? false : true);
      setMonthData({
        data: res.data.Revenue_Thingies,
        label: data.LabelAndYear[0].Labelfirst[0].Label_Name + " Monthly View",
        title: data.LabelAndYear[0].Labelfirst[0].Label_Name,
      });
   
      if (res.data.Revenue_Thingies[0]) {
        setMonthtotal(res.data.Revenue_Thingies[0].Total);
      }
    });
    //}
    // else if(labelName=="Turf CareExpenses"){
  };

  const getdeptdata = async () => {
    const localItems = JSON.parse(localStorage.getItem("UserClaims"));
    if (userDetail.Member_Type != "4") {
      course_id = userDetail.Restaurant_id;
    }
    setSelectedDept(localItems?.Account_Name, "localItems?.Account_id");
    mainAxios.get(`Api/User/GetAllUserTypes?id=5`).then((res) => {
      setdept(res.data.AllUserTypes);
    });
    mainAxios
      .get("api/Common/getRestaurantList/" + course_id + "/" + ye)
      .then((res) => {
        console.log(res.data.Restaurants, "Restaurants");
        setdepartment(res.data.Restaurants);
      });
  };

  const getLabel = async () => {
    let GetLabelURL;
    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      GetLabelURL = `/Api/Common/getLabel`;
    } else {
      GetLabelURL = "Api/Common/getBossLabel/" + bossid + "/" + ye;
    }
    const { data } = await fobeAxios.get(GetLabelURL);
    
    // setbudget_type(data.LabelAndYear[1].budget_type)
    console.log(data.LabelAndYear[0].Labelfirst, "LabelAndYear");
    setTab(data.LabelAndYear[0].Labelfirst[0].Label_Name);

    setLabelList(data.LabelAndYear[0].Labelfirst);
    setnext(data.LabelAndYear[0].Labelfirst[1].Label_Name);
    setprev("");

    // getData(data.LabelAndYear[0].Labelfirst[0].Label_Name);
    console.log(data.LabelAndYear[1].Year_List, "datafor Label");
    setY(data.LabelAndYear[1].Year_List);
  };
  const [selectedDept, setSelectedDept] = useState();
  useEffect(() => {
    setIsLoading(true);
    getData();
    getdeptdata();
    getLabel();
 
    if (items) {
      if (items.Member_Type == "4") {
        setshowtable(false);
      } else {
        setshowtable(true);
      }
      if (
        items.Member_Type == 4 ||
        ((items.Special_user == 0 || items.Special_user == 2) &&
          items.Account_Type == 0)
      ) {
        setShowCourse(true);
      } else {
        setShowCourse(false);
      }
      if (
        (items.Special_user == 0 || items.Special_user == 1) &&
        items.Account_Type != 0
      ) {
        setshowcheckbox(true);
      } else {
        setshowcheckbox(false);
      }

      if (JSON.parse(localStorage.getItem("UserClaims")).budget_type == "1") {
        setSelectedOption("option1");
      } else {
        setSelectedOption("option2");
      }
    }

  }, [selectYear, bossid, course_id, refreshgetdata,triggervariable,tabss,budget_type]);
  const changeData = async (id, label_title, index) => {
    sethideCategoriesandConstant(true)
    setshowCourses(true);
  
    setIsLoading(true);
    let GetLabelURL;
    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      GetLabelURL = `/Api/Common/getLabel`;
    } else {
      GetLabelURL = "Api/Common/getBossLabel/" + bossid + "/" + ye;
    }
    const { data } = await fobeAxios.get(GetLabelURL);

    // console.log(items, "item")
    let geturl,
      GetRevenueMnthurl,
      GetGetcogurl,
      GetcogMonthurl,
      GetLaboururl,
      GetLaborMonthurl,
      GetControurl,
      GetControlMonthurl,
      GetNonControurl,
      GetNonControlMonthurl,
      GetProfitLossurl,
      GetProfitLossMonthurl;

    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      if (budget_type == "1") {
        geturl = "Api/BudgetPeriod/GetBudget/" + selectYear;
        GetRevenueMnthurl = "Api/BudgetPeriod/GetRevenueMonth/" + selectYear;
        GetGetcogurl = "Api/BudgetPeriod/GetCog/" + selectYear;
        GetcogMonthurl = "Api/BudgetPeriod/GetcogMonth/" + selectYear;
        GetLaboururl = "Api/BudgetPeriod/GetLabour/" + selectYear;
        GetLaborMonthurl = "Api/BudgetPeriod/GetlaborMonth/" + selectYear;
        GetControurl = "Api/BudgetPeriod/GetContro/" + selectYear;
        GetControlMonthurl = "Api/BudgetPeriod/GetControlMonth/" + selectYear;
        GetNonControurl = "Api/BudgetPeriod/GetNoncontro/" + selectYear;
        GetNonControlMonthurl =
          "Api/BudgetPeriod/GetnonControlMonth/" + selectYear;
        GetProfitLossurl = "Api/BudgetPeriod/GetProfitLoss/" + selectYear;
        GetProfitLossMonthurl =
          "Api/BudgetPeriod/GetProfitLossMonth/" + selectYear;
      } else {
        geturl = `Api/Budget/GetBudget/` + selectYear;
        GetRevenueMnthurl = `Api/Budget/GetRevenueMonth/` + selectYear;
        GetGetcogurl = `Api/Budget/GetCog/` + selectYear;
        GetcogMonthurl = `Api/Budget/GetCogMonth/` + selectYear;
        GetLaboururl = `Api/Budget/GetLabour/` + selectYear;
        GetLaborMonthurl = `Api/Budget/GetlaborMonth/` + selectYear;
        GetControurl = `Api/Budget/GetContro/` + selectYear;
        GetControlMonthurl = `Api/Budget/GetControlMonth/` + selectYear;
        GetNonControurl = `Api/Budget/GetNoncontro/` + selectYear;
        GetNonControlMonthurl = `Api/Budget/GetnonControlMonth/` + selectYear;
        GetProfitLossurl = `Api/Budget/GetProfitLoss/` + selectYear;
        GetProfitLossMonthurl = `Api/Budget/GetProfitLossMonth/` + selectYear;
      }
    } else {
      geturl = `api/Boss/GetBossBudget/` + bossid + "/" + ye;
      GetRevenueMnthurl = `api/Boss/GetBossRevenueMonth/` + bossid + "/" + ye;
      GetGetcogurl = `api/Boss/GetBossCog/` + bossid + "/" + ye;
      GetcogMonthurl = `api/Boss/GetBossCogMonth/` + bossid + "/" + ye;
      GetLaboururl = `api/Boss/GetBossLabor/` + bossid + "/" + ye;
      GetLaborMonthurl = `api/Boss/GetBossLaborMonth/` + bossid + "/" + ye;
      GetControurl = `api/Boss/GetBossControllable/` + bossid + "/" + ye;
      GetControlMonthurl =
        `api/Boss/GetBossControllableMonth/` + bossid + "/" + ye;
      GetNonControurl = `api/Boss/GetBossNoncontrollable/` + bossid + "/" + ye;
      GetNonControlMonthurl =
        `api/Boss/GetBossNoncontrollableMonth/` + bossid + "/" + ye;
      GetProfitLossurl = `api/Boss/GetBossProfitLoss/` + bossid + "/" + ye;
      GetProfitLossMonthurl =
        `api/Boss/GetBossProftLossMonth/` + bossid + "/" + ye;
    }

    if (id === 1) {
      if (items.Member_Type == 4) {
        setshowCourses(true);
      } else {
        setshowCourses(false);
      }

      setshowRevenueBudget(true);
      setShowCat(true);
      setIsLoading(true);
      // setData({list:"",total:"",percentage:""});
      setIsMonthView(monthNavi ? true : false);

      await fobeAxios.get(geturl).then((res) => {
        setIsLoading(false);
        setData({
          ...data,
          list: res.data.BudgetRevenue[2].Revenue_List,
          total: res.data.BudgetRevenue[2].Total,
          percentage: res.data.BudgetRevenue[2].Per,
        });
        setaddcategory(res.data.BudgetRevenue[2].Revenue_List);
        if (typeof res.data.BudgetRevenue[1].Plan_List !== "undefined") {
          setTotal(res.data.BudgetRevenue[1].Plan_List[0].Total);
        }
      });

      await fobeAxios.get(GetRevenueMnthurl).then((res) => {
        // console.log("Revmonth", res.data.Revenue_Thingies);

        setMonthData({
          data: res.data.Revenue_Thingies,
          label: label_title + " Monthly View",

          title: label_title,
        });
        setshowtotal(true);
      });
      setIsLoading(false);
    } else if (id === 2) {
      setshowCourses(false);
      setIsMonthView(false);
      setshowRevenueBudget(true);
      setShowCat(false);
      // setMonthData({data:turfMonth,label:'Turfcare Monthly View',title:'Turf Care'})
      //setData({ list: tableTurfCare, total: 432423, percentage: 98 })
    } else if (id === 3) {
      setIsLoading(true);
      setshowRevenueBudget(true);
      setShowCat(false);
      setshowCourses(false);
      // setData({list:"",total:"",percentage:""});
      setIsMonthView(monthNavi ? true : false);
      await fobeAxios.get(GetGetcogurl).then((res) => {
        setData({
          list: res.data.BudgetCOG[2].Cog_List,
          total: res.data.BudgetCOG[2].Total,
          percentage: res.data.BudgetCOG[2].Per,
        });
        setaddcategory(res.data.BudgetCOG[2].Cog_List);
      });

      await fobeAxios.get(GetcogMonthurl).then((res) => {
        // console.log("Revmonth", res.data.Cog_Things);

        setMonthData({
          data: res.data.Cog_Things,
          label: label_title + " Monthly View",
          title: label_title,
        });
        setshowtotal(true);
      });
      //setMonthData({data:cogMonth,label:'Cost of Goods Monthly View',title:'Cost of Goods'})
      setIsLoading(false);
    } else if (id === 4) {
      setshowCourses(false);
      setIsLoading(true);
      setshowRevenueBudget(true);
      setShowCat(false);
      // setData({list:"",total:"",percentage:""});
      setIsMonthView(monthNavi ? true : false);
      await fobeAxios.get(GetLaboururl).then((res) => {
        setData({
          list: res.data.BudgetLabour[2].Labour_List,
          total: res.data.BudgetLabour[2].Total,
          percentage: res.data.BudgetLabour[2].Per,
        });
        setaddcategory(res.data.BudgetLabour[2].Labour_List);
      });

      await fobeAxios.get(GetLaborMonthurl).then((res) => {
        // console.log("Revmonth", res.data.Labor_Thingies);

        setMonthData({
          data: res.data.Labor_Thingies,
          label: label_title + " Monthly View",
          title: label_title,
        });
        setshowtotal(true);
      });

      // setMonthData({data:LabourMonth,label:'Labour Monthly View',title:'Labour'})
      setIsLoading(false);
    } else if (id === 5) {
      setshowCourses(false);
      setshowRevenueBudget(true);
      setShowCat(false);
      setIsLoading(true);
      setIsMonthView(monthNavi ? true : false);
      await fobeAxios.get(GetControurl).then((res) => {
        setData({
          list: res.data.BudgetControllable[2].Controllable_List,
          total: res.data.BudgetControllable[2].Total,
          percentage: res.data.BudgetControllable[2].Per,
        });
        setaddcategory(res.data.BudgetControllable[2].Controllable_List);
      });

      await fobeAxios.get(GetControlMonthurl).then((res) => {
        // console.log("Revmonth", res.data.Controllable_Thingies);

        setMonthData({
          data: res.data.Controllable_Thingies,
          label: label_title + " Monthly View",
          title: label_title,
        });
        setshowtotal(true);
      });
      //setMonthData({data:ControllableMonth,label:'Controllable Monthly View',title:'Controllable'})
      setIsLoading(false);
    } else if (id === 6) {
      setshowCourses(false);
      setshowRevenueBudget(true);
      setShowCat(false);
      setIsLoading(true);
      setIsMonthView(monthNavi ? true : false);
      await fobeAxios.get(GetNonControurl).then((res) => {
        setData({
          list: res.data.BudgetNControllable[2].NonControllable_List,
          total: res.data.BudgetNControllable[2].Total,
          percentage: res.data.BudgetNControllable[2].Per,
        });
        setaddcategory(res.data.BudgetNControllable[2].NonControllable_List);
      });

      await fobeAxios.get(GetNonControlMonthurl).then((res) => {
        // console.log("Revmonth", res.data.NonControllable_Thingies);

        setMonthData({
          data: res.data.NonControllable_Thingies,
          label: label_title + " Monthly View",
          title: label_title,
        });
        setshowtotal(true);
      });
      //setMonthData({data:NonControllableMonth,label:'Non Controllable Monthly View',title:'Non Controllable'})
      setIsLoading(false);
    } else if (id === 7) {
      setshowCourses(false);
      sethideCategoriesandConstant(false)
      setshowRevenueBudget(true);
      setShowCat(false);
      setIsLoading(true);
      setIsMonthView(monthNavi ? true : false);
      await fobeAxios.get(GetProfitLossurl).then((res) => {
        setData({ list: res.data.Profit_Loss });
        // setTotal(res.data.Profit_Loss[1].Plan_List[0].Total)
      });

      await fobeAxios.get(GetProfitLossMonthurl).then((res) => {
        console.log("Revmonth", res.data.Profit_Loss);
        setshowtotal(false);
        setMonthData({
          data: res.data.Profit_Loss,
          label: label_title + " Monthly View",
          title: label_title,
        });
      });
      //setMonthData({data:profitMonth,label:'Profit/Lose Monthly View',title:'Profit/Lose'})
      setIsLoading(false);
    }

    setnext(data.LabelAndYear[0].Labelfirst[index + 1].Label_Name);
    setprev(data.LabelAndYear[0].Labelfirst[index - 1].Label_Name);
  };

  const editYearDatapercentage = (id, value, menuId) => {
    console.log("id:", id, "value:", value, "menuId:", menuId);
    let temp = data.list.map((x) => {
      if (x.Cat_id == id) {
        x.Cat_per = value.value;

        return x;
      }
      return x;
    });
    data.total = 0;

    data.total = temp.reduce(
      (total, obj) => (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + total,
      0
    );
    data.percentage = (data.total / Total) * 100;
    if (menuId == 1) {
      temp = temp.map((x) => {
        if (x.Cat_id == id) {
          console.log("x.Total:", data.total);
          x.Cat_amt = (x.Cat_per / 100) * Total;
          data.total = temp.reduce(
            (total, obj) => (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + total,
            0
          );
          data.percentage = (data.total / Total) * 100;
          // console.log("x.catper:", x.Cat_per)
          return x;
        }
        return x;
      });
      setData({ ...data, list: temp });
    } else if (menuId == 2) {
      temp = temp.map((x) => {
        if (x.Cat_id == id) {
          let Budget = revenue.Revenue_List.find(
            (rev) => rev.Cat_name == x.Cat_name
          ).Cat_amt;
          x.Cat_amt = (Budget * x.Cat_per) / 100;

          // console.log("x.catper:", x.Cat_per)
          data.total = temp.reduce(
            (total, obj) => (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + total,
            0
          );
          data.percentage = (data.total / Total) * 100;
          return x;
        }
        return x;
      });
      setData({
        ...data,
        list: temp,
      });
    } else if (
      menuId == 3 ||
      menuId == 4 ||
      menuId == 5 ||
      menuId == 6 ||
      menuId == 7
    ) {
      temp = temp.map((x) => {
        if (x.Cat_id == id) {
          x.Cat_amt = (x.Cat_per / 100) * Total;
          data.total = temp.reduce(
            (total, obj) => (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + total,
            0
          );
          data.percentage = (data.total / Total) * 100;
          // console.log("x.catper:", x.Cat_per)
          return x;
        }
        return x;
      });
      setData({
        ...data,
        list: temp,
      });
    }
  };

  const editYearData = (id, value, menuId) => {
    let temp = data.list.map((x) => {
      if (x.Cat_id == id) {
        x.Cat_amt = value.value;
        return x;
      }
      return x;
    });

    data.total = 0;

    data.total = temp.reduce(
      (total, obj) => (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + total,
      0
    );

    if (menuId == 1) {
      temp = temp.map((x) => {
        if (x.Cat_id == id) {
          x.Cat_per = (x.Cat_amt / data.total) * 100;
          // console.log("x.catper:", x.Cat_per)
          return x;
        }
        return x;
      });
      setData({ ...data, list: temp });
    } else if (menuId == 2) {
      temp = temp.map((x) => {
        if (x.Cat_id == id) {
          let revCogBudget = revenue.Revenue_List.find(
            (rev) => rev.Cat_name == x.Cat_name
          ).Cat_amt;
          x.Cat_per = (x.Cat_amt / revCogBudget) * 100;
          // console.log("x.catper:", x.Cat_per)
          return x;
        }
        return x;
      });
      setData({
        ...data,
        list: temp,
        percentage: (data.total / revenue.Total) * 100,
      });
    } else if (menuId == 3 || menuId == 4 || menuId == 5) {
      temp = temp.map((x) => {
        if (x.Cat_id == id) {
          x.Cat_per = (x.Cat_amt / revenue.Total) * 100;
          // console.log("x.catper:", x.Cat_per)
          return x;
        }
        return x;
      });
      setData({
        ...data,
        list: temp,
        percentage: (data.total / revenue.Total) * 100,
      });
    }
  };

  const editMonthData = (id, value, Month_id, menuId) => {
    console.log("Month_id:", Month_id, menuId);
    if (menuId == "Revenue") {
      let temp = monthData.data.map((x) => {
        if (x.Month_id == Month_id) {
          x.List.map((item) => {
            if (item.Cat_id == id) {
              item.Cat_amt = value.value;
              x.Total = x.List.reduce(
                (Total, obj) =>
                  (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
                0
              );
              item.Cat_Per = (item.Cat_amt / x.Total) * 100;
              return item;
            }
          });
        }
        return x;
      });
      setMonthData({ ...monthData, data: temp });
    } else if (menuId == "Cost Of Goods") {
      let temp = monthData.data.map((x) => {
        if (x.Month_Id == Month_id) {
          let revList = revMonth.find((rev) => rev.Month_id == Month_id).List;
          let revTotal = revMonth.find((rev) => rev.Month_id == Month_id).Total;

          x.List.map((item) => {
            if (item.Cat_id == id) {
              item.Cat_amt = value.value;
              let revItemTotal = revList.find(
                (revItem) => revItem.Cat_Name == item.Cat_Name
              ).Cat_amt;

              x.Total = x.List.reduce(
                (Total, obj) =>
                  (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
                0
              );
              item.Cat_Per = (item.Cat_amt / revItemTotal) * 100;
              x.Month_totper = (x.Total / revTotal) * 100;
              return item;
            }
          });
        }
        return x;
      });
      setMonthData({ ...monthData, data: temp });
    } else if (
      menuId == "Labor" ||
      menuId == "Controllable" ||
      menuId == "Non Controllable"
    ) {
      let temp = monthData.data.map((x) => {
        if (x.Month_id == Month_id) {
          let revTotal = revMonth.find((rev) => rev.Month_id == Month_id).Total;

          x.List.map((item) => {
            if (item.Cat_id == id) {
              item.Cat_amt = value.value;

              x.Total = x.List.reduce(
                (Total, obj) =>
                  (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
                0
              );
              item.Cat_Per = (item.Cat_amt / revTotal) * 100;
              x.Month_totper = (x.Total / revTotal) * 100;
              return item;
            }
          });
        }
        return x;
      });
      setMonthData({ ...monthData, data: temp });
    }
  };
  const editMonthDatapercentage = (id, value, Month_id, menuId) => {
    console.log("value", value.formattedValue);
    if (menuId == "Revenue") {
      let temp = { ...monthData };
      temp = temp.data.map((x) => {
        let revList = revMonth.find((rev) => rev.Month_id == Month_id).List;
        let revTotal = revMonth.find((rev) => rev.Month_id == Month_id).Total;

        if (x.Month_id == Month_id) {
          x.List.map((item) => {
            if (item.Cat_id == id) {
              item.Cat_Per = value.formattedValue;

              item.Cat_amt = (value.formattedValue / 100) * Monthtotal;

              x.Total = x.List.reduce(
                (Total, obj) =>
                  (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
                0
              );
              x.Month_totper = (x.Total / Monthtotal) * 100;
              return item;
            }
            return item;
          });
        }
        return x;
      });
      setMonthData({ ...monthData, data: temp });
    } else if (menuId == "Cost Of Goods") {
      let revList = revMonth.find((rev) => rev.Month_id == Month_id).List;
      let revTotal = revMonth.find((rev) => rev.Month_id == Month_id).Total;
      let temp = monthData.data.map((x) => {
        if (x.Month_Id == Month_id) {
          x.List.map((item) => {
            if (item.Cat_id == id) {
              let revItemTotal = revList.find(
                (revItem) => revItem.Cat_Name == item.Cat_Name
              ).Cat_amt;

              item.Cat_Per = value.formattedValue;

              item.Cat_amt = (revItemTotal * item.Cat_Per) / 100;

              x.Total = x.List.reduce(
                (Total, obj) =>
                  (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
                0
              );
              x.Month_totper = (x.Total / Monthtotal) * 100;
              return item;
            }
          });
        }
        return x;
      });
      setMonthData({ ...monthData, data: temp });
    } else if (
      menuId == "Labor" ||
      menuId == "Controllable" ||
      menuId == "Non Controllable"
    ) {
      let revList = revMonth.find((rev) => rev.Month_id == Month_id).List;
      let revTotal = revMonth.find((rev) => rev.Month_id == Month_id).Total;
      let temp = monthData.data.map((x) => {
        if (x.Month_id == Month_id) {
          x.List.map((item) => {
            if (item.Cat_id == id) {
              item.Cat_Per = value.formattedValue;
              // x.Total = x.List.reduce(
              //   (Total, obj) =>
              //     (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
              //   0
              // );

              item.Cat_amt = (value.formattedValue / 100) * revTotal;

              x.Total = x.List.reduce(
                (Total, obj) =>
                  (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
                0
              );
              x.Month_totper = (x.Total / revTotal) * 100;
              return item;
            }
          });
        }
        return x;
      });
      setMonthData({ ...monthData, data: temp });
    }
  };
  const editMonthTotal = (value, Month_id, menuId) => {
    console.log("valueee", value);
    console.log("Month_id", Month_id.value);
    console.log("menuId", menuId);
    console.log(monthData.data, " monthData");

    let temp = monthData.data.map((x) => {
      if (x.Month_id == menuId) {
        x.Total = Month_id.value;
      }
      return x;
    });
    setMonthData({ ...monthData, data: temp });

    // else if (menuId == "Cost Of Goods") {
    //   let revList = revMonth.find((rev) => rev.Month_id == Month_id).List;
    //   let revTotal = revMonth.find((rev) => rev.Month_id == Month_id).Total;
    //   let temp = monthData.data.map((x) => {
    //     if (x.Month_Id == Month_id) {
    //       x.List.map((item) => {
    //         if (item.Cat_id == id) {
    //           let revItemTotal = revList.find(
    //             (revItem) => revItem.Cat_Name == item.Cat_Name
    //           ).Cat_amt;

    //           item.Cat_Per = value.formattedValue;

    //           item.Cat_amt = (revItemTotal * item.Cat_Per) / 100;

    //           x.Total = x.List.reduce(
    //             (Total, obj) =>
    //               (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
    //             0
    //           );
    //           x.Month_totper = (x.Total / Monthtotal) * 100;
    //           return item;
    //         }
    //       });
    //     }
    //     return x;
    //   });
    //   setMonthData({ ...monthData, data: temp });
    // } else if (
    //   menuId == "Labor" ||
    //   menuId == "Controllable" ||
    //   menuId == "Non Controllable"
    // ) {
    //   let revList = revMonth.find((rev) => rev.Month_id == Month_id).List;
    //   let revTotal = revMonth.find((rev) => rev.Month_id == Month_id).Total;
    //   let temp = monthData.data.map((x) => {
    //     if (x.Month_id == Month_id) {
    //       x.List.map((item) => {
    //         if (item.Cat_id == id) {
    //           item.Cat_Per = value.formattedValue;
    //           // x.Total = x.List.reduce(
    //           //   (Total, obj) =>
    //           //     (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
    //           //   0
    //           // );

    //           item.Cat_amt = (value.formattedValue / 100) * revTotal;

    //           x.Total = x.List.reduce(
    //             (Total, obj) =>
    //               (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
    //             0
    //           );
    //           x.Month_totper = (x.Total / revTotal) * 100;
    //           return item;
    //         }
    //       });
    //     }
    //     return x;
    //   });
    //   setMonthData({ ...monthData, data: temp });
    // }
  };

  const saveYearData = async (menuId) => {

    const index = LabelList.findIndex((user) => user.Label_Name === menuId);

    menuId = LabelList[index - 1].Label_Name;
   
    if((menuId == "Controllable")){
     
    }
    setIsLoading(true);
    let url, msg;
    if (
      menuId == "Revenue" ||
      menuId == "Admin Expenses" ||
      menuId== "Turf & Maintenance" ||
      menuId == "Fitness Expenses" ||
      menuId == "Tennis Expenses" ||
      menuId == "Turf CareExpenses"
    ) {
      if (budget_type == "1") {
        url = "Api/BudgetPeriod/addRevenueYearly";
      } else {
        url = "Api/Budget/addRevenueYearly";
      }
      msg = "Revenue Added Successfully";
    } else if (menuId.trim().toLowerCase() == "cost of goods") {
      if (budget_type == "1") {
        url = "Api/BudgetPeriod/addCogYearly";
      } else {
        url = "Api/Budget/addCogYearly";
      }
      msg = "Cost of Goods Added Successfully";
    } else if (menuId.trim().toLowerCase()  == "labor") {
      if (budget_type == "1") {
        url = "Api/BudgetPeriod/addLaberYearly";
      } else {
        url = "Api/Budget/addLaberYearly";
      }
      msg = "Labor Added Successfully";
    } else if (menuId.trim().toLowerCase() == "controllable") {

      if (budget_type == "1") {
        url = "Api/BudgetPeriod/addControllableYearly";
      } else {
        url = "Api/Budget/addControllableYearly";
      }
      msg = "Controllable Added Successfully";
    } else if (menuId.trim().toLowerCase()  == "non controllable") {
      if (budget_type == "1") {
        url = "Api/BudgetPeriod/addNonControllableYearly";
      } else {
        url = "Api/Budget/addNonControllableYearly";
      }
      msg = "Non Controllable Added Successfully";
    }

    let budget = {
      Budget: data.list,
      Budget_details: [
        { budget_year: userDetail.budget_end_year },
        { budget_type: userDetail.budget_type },
        { Restaurant_id: userDetail.Restaurant_id },
      ],
    };
    await fobeAxios
      .post(url, budget)
      .then((resp) => {
        toast.success(msg);
      })
      .catch((err) => {
        toast.error("Failed");
        setIsLoading(false);
      });

    setIsLoading(false);
  };

  const [showCheckbox, setshowcheckbox] = useState(false);
  const saveMonthData = async (menuId) => {
    setIsLoading(true);
    const index = LabelList.findIndex((user) => user.Label_Name === menuId);

    menuId = LabelList[index - 1].Label_Name;
    let url, msg;
    if (
      menuId == "Revenue" ||
      menuId == "Admin Expenses" ||
      menuId=="Turf & Maintenance" ||
      menuId == "Fitness Expenses" ||
      menuId == "Tennis Expenses" ||
      menuId == "Turf CareExpenses"
    ) {
      if (budget_type == "1") {
        url = "Api/BudgetPeriod/addRevenueMonthly";
      } else {
        url = "Api/Budget/addRevenueMonthly";
      }

      msg = "Revenue Monthly Added Successfully";
    } else if (menuId == "Cost Of Goods") {
      if (budget_type == "1") {
        url = "Api/BudgetPeriod/addCogMonthly";
      } else {
        url = "Api/Budget/addCogMonthly";
      }
      msg = "Cost of Goods Monthly Added Successfully";
    } else if (menuId == "Labor") {
      if (budget_type == "1") {
        url = "Api/BudgetPeriod/addLaborMonthly";
      } else {
        url = "Api/Budget/addLaborMonthly";
      }
      msg = "Labor Monthly Added Successfully";
    } else if (menuId == "Controllable") {
      if (budget_type == "1") {
        url = "Api/BudgetPeriod/addControllablerMonthly";
      } else {
        url = "Api/Budget/addControllablerMonthly";
      }
      msg = "Controllable Monthly Added Successfully";
    } else if (menuId == "Non Controllable") {
      if (budget_type == "1") {
        url = "Api/BudgetPeriod/addNonControllableMonthly";
      } else {
        url = "Api/Budget/addNonControllableMonthly";
      }
      msg = "Non Controllable Monthly Added Successfully";
    }

    let budget = {
      Budget: monthData.data,
      Budget_details: [
        { budget_year: userDetail.budget_end_year },
        { budget_type: userDetail.budget_type },
        { Restaurant_id: userDetail.Restaurant_id },
      ],
    };
    await fobeAxios
      .post(url, budget)
      .then((resp) => {
        toast.success(msg);
        getData();
      })
      .catch((err) => {
        toast.error("Failed");
        setIsLoading(false);
      });
    setIsLoading(false);
  };
  const changeDatadup = async (label_title) => {
    sethideCategoriesandConstant(true);
    setIsLoading(true);
    let GetLabelURL;
    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      GetLabelURL = `/Api/Common/getLabel`;
    } else {
      GetLabelURL = "Api/Common/getBossLabel/" + bossid + "/" + ye;
    }
    const { data } = await fobeAxios.get(GetLabelURL);

    const index = data.LabelAndYear[0].Labelfirst.findIndex(
      (user) => user.Label_Name === label_title
    );

    // console.log(items, "item")
    let geturl,
      GetRevenueMnthurl,
      GetGetcogurl,
      GetcogMonthurl,
      GetLaboururl,
      GetLaborMonthurl,
      GetControurl,
      GetControlMonthurl,
      GetNonControurl,
      GetNonControlMonthurl,
      GetProfitLossurl,
      GetProfitLossMonthurl;

    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      if (budget_type == "1") {
        geturl = "Api/BudgetPeriod/GetBudget/" + selectYear;
        GetRevenueMnthurl = "Api/BudgetPeriod/GetRevenueMonth/" + selectYear;
        GetGetcogurl = "Api/BudgetPeriod/GetCog/" + selectYear;
        GetcogMonthurl = "Api/BudgetPeriod/GetcogMonth/" + selectYear;
        GetLaboururl = "Api/BudgetPeriod/GetLabour/" + selectYear;
        GetLaborMonthurl = "Api/BudgetPeriod/GetlaborMonth/" + selectYear;
        GetControurl = "Api/BudgetPeriod/GetContro/" + selectYear;
        GetControlMonthurl = "Api/BudgetPeriod/GetControlMonth/" + selectYear;
        GetNonControurl = "Api/BudgetPeriod/GetNoncontro/" + selectYear;
        GetNonControlMonthurl =
          "Api/BudgetPeriod/GetnonControlMonth/" + selectYear;
        GetProfitLossurl = "Api/BudgetPeriod/GetProfitLoss/" + selectYear;
        GetProfitLossMonthurl =
          "Api/BudgetPeriod/GetProfitLossMonth/" + selectYear;
      } else {
        geturl = `Api/Budget/GetBudget/` + selectYear;
        GetRevenueMnthurl = `Api/Budget/GetRevenueMonth/` + selectYear;
        GetGetcogurl = `Api/Budget/GetCog/` + selectYear;
        GetcogMonthurl = `Api/Budget/GetCogMonth/` + selectYear;
        GetLaboururl = `Api/Budget/GetLabour/` + selectYear;
        GetLaborMonthurl = `Api/Budget/GetlaborMonth/` + selectYear;
        GetControurl = `Api/Budget/GetContro/` + selectYear;
        GetControlMonthurl = `Api/Budget/GetControlMonth/` + selectYear;
        GetNonControurl = `Api/Budget/GetNoncontro/` + selectYear;
        GetNonControlMonthurl = `Api/Budget/GetnonControlMonth/` + selectYear;
        GetProfitLossurl = `Api/Budget/GetProfitLoss/` + selectYear;
        GetProfitLossMonthurl = `Api/Budget/GetProfitLossMonth/` + selectYear;
      }
    } else {
      geturl = `api/Boss/GetBossBudget/` + bossid + "/" + ye;
      GetRevenueMnthurl = `api/Boss/GetBossRevenueMonth/` + bossid + "/" + ye;
      GetGetcogurl = `api/Boss/GetBossCog/` + bossid + "/" + ye;
      GetcogMonthurl = `api/Boss/GetBossCogMonth/` + bossid + "/" + ye;
      GetLaboururl = `api/Boss/GetBossLabor/` + bossid + "/" + ye;
      GetLaborMonthurl = `api/Boss/GetBossLaborMonth/` + bossid + "/" + ye;
      GetControurl = `api/Boss/GetBossControllable/` + bossid + "/" + ye;
      GetControlMonthurl =
        `api/Boss/GetBossControllableMonth/` + bossid + "/" + ye;
      GetNonControurl = `api/Boss/GetBossNoncontrollable/` + bossid + "/" + ye;
      GetNonControlMonthurl =
        `api/Boss/GetBossNoncontrollableMonth/` + bossid + "/" + ye;
      GetProfitLossurl = `api/Boss/GetBossProfitLoss/` + bossid + "/" + ye;
      GetProfitLossMonthurl =
        `api/Boss/GetBossProftLossMonth/` + bossid + "/" + ye;
    }

    if (
      label_title == "Revenue" ||
      label_title == "Admin Expenses" ||
      label_title == "Turf & Maintenance" ||
      label_title == "Tennis Expenses" ||
      label_title == "Turf CareExpenses" ||
      label_title == "Fitness Expenses"
    ) {
   
      setshowCourses(true);
      setshowRevenueBudget(true);
      setShowCat(true);
      setIsLoading(true);
      // setData({list:"",total:"",percentage:""});
      setIsMonthView(monthNavi ? true : false);

      await fobeAxios.get(geturl).then((res) => {
        setIsLoading(false);
        setData({
          ...data,
          list: res.data.BudgetRevenue[2].Revenue_List,
          total: res.data.BudgetRevenue[2].Total,
          percentage: res.data.BudgetRevenue[2].Per,
        });
        setaddcategory(res.data.BudgetRevenue[2].Revenue_List);
        if (typeof res.data.BudgetRevenue[1].Plan_List !== "undefined") {
          setTotal(res.data.BudgetRevenue[1].Plan_List[0].Total);
        }
      });

      await fobeAxios.get(GetRevenueMnthurl).then((res) => {
        // console.log("Revmonth", res.data.Revenue_Thingies);

        setMonthData({
          data: res.data.Revenue_Thingies,
          label: label_title + " Monthly View",

          title: "Revenue",
        });
        setshowtotal(true);
      });
      setIsLoading(false);
    } else if (label_title == "Turf Care") {
      setshowCourses(false);
      setIsMonthView(false);
      setshowRevenueBudget(true);
      setShowCat(true);
      // setMonthData({data:turfMonth,label:'Turfcare Monthly View',title:'Turf Care'})
      //setData({ list: tableTurfCare, total: 432423, percentage: 98 })
    } else if (label_title == "Cost Of Goods") {
      setIsLoading(true);
      setshowRevenueBudget(true);
      setShowCat(false);
      setshowCourses(false);
      // setData({list:"",total:"",percentage:""});
      setIsMonthView(monthNavi ? true : false);
      await fobeAxios.get(GetGetcogurl).then((res) => {
        setData({
          list: res.data.BudgetCOG[2].Cog_List,
          total: res.data.BudgetCOG[2].Total,
          percentage: res.data.BudgetCOG[2].Per,
        });
        setaddcategory(res.data.BudgetCOG[2].Cog_List);
      });

      await fobeAxios.get(GetcogMonthurl).then((res) => {
        // console.log("Revmonth", res.data.Cog_Things);

        setMonthData({
          data: res.data.Cog_Things,
          label: label_title + " Monthly View",
          title: "Cost of Goods",
        });
        setshowtotal(true);
      });
      //setMonthData({data:cogMonth,label:'Cost of Goods Monthly View',title:'Cost of Goods'})
      setIsLoading(false);
    } else if (label_title == "Labor") {
      setshowCourses(false);
      setIsLoading(true);
      setshowRevenueBudget(true);
      setShowCat(false);
      // setData({list:"",total:"",percentage:""});
      setIsMonthView(monthNavi ? true : false);
      await fobeAxios.get(GetLaboururl).then((res) => {
        setData({
          list: res.data.BudgetLabour[2].Labour_List,
          total: res.data.BudgetLabour[2].Total,
          percentage: res.data.BudgetLabour[2].Per,
        });
        setaddcategory(res.data.BudgetLabour[2].Labour_List);
      });

      await fobeAxios.get(GetLaborMonthurl).then((res) => {
        // console.log("Revmonth", res.data.Labor_Thingies);

        setMonthData({
          data: res.data.Labor_Thingies,
          label: label_title,
          title: "Labour",
        });
        setshowtotal(true);
      });

      // setMonthData({data:LabourMonth,label:'Labour Monthly View',title:'Labour'})
      setIsLoading(false);
    } else if (label_title == "Controllable") {
      setshowCourses(false);
      setshowRevenueBudget(true);
      setShowCat(false);
      setIsLoading(true);
      setIsMonthView(monthNavi ? true : false);
      await fobeAxios.get(GetControurl).then((res) => {
        setData({
          list: res.data.BudgetControllable[2].Controllable_List,
          total: res.data.BudgetControllable[2].Total,
          percentage: res.data.BudgetControllable[2].Per,
        });
        setaddcategory(res.data.BudgetControllable[2].Controllable_List);
      });

      await fobeAxios.get(GetControlMonthurl).then((res) => {
        // console.log("Revmonth", res.data.Controllable_Thingies);

        setMonthData({
          data: res.data.Controllable_Thingies,
          label: label_title + " Monthly View",
          title: "Controllable",
        });
        setshowtotal(true);
      });
      //setMonthData({data:ControllableMonth,label:'Controllable Monthly View',title:'Controllable'})
      setIsLoading(false);
    } else if (label_title == "Non Controllable") {
      setshowCourses(false);
      setshowRevenueBudget(true);
      setShowCat(false);
      setIsLoading(true);
      setIsMonthView(monthNavi ? true : false);
      await fobeAxios.get(GetNonControurl).then((res) => {
        setData({
          list: res.data.BudgetNControllable[2].NonControllable_List,
          total: res.data.BudgetNControllable[2].Total,
          percentage: res.data.BudgetNControllable[2].Per,
        });
        setaddcategory(res.data.BudgetNControllable[2].NonControllable_List);
      });

      await fobeAxios.get(GetNonControlMonthurl).then((res) => {
        // console.log("Revmonth", res.data.NonControllable_Thingies);

        setMonthData({
          data: res.data.NonControllable_Thingies,
          label: label_title + " Monthly View",
          title: "Non Controllable",
        });
        setshowtotal(true);
      });
      //setMonthData({data:NonControllableMonth,label:'Non Controllable Monthly View',title:'Non Controllable'})
      setIsLoading(false);
    } else if (label_title == "Profit/Loss") {
      sethideCategoriesandConstant(false);
      setshowCourses(false);
      setshowRevenueBudget(true);
      setShowCat(false);
      setIsLoading(true);
      setIsMonthView(monthNavi ? true : false);
      await fobeAxios.get(GetProfitLossurl).then((res) => {
        setData({ list: res.data.Profit_Loss });
        // setTotal(res.data.Profit_Loss[1].Plan_List[0].Total)
      });

      await fobeAxios.get(GetProfitLossMonthurl).then((res) => {
        console.log("Revmonth", res.data.Profit_Loss);
        setshowtotal(false);
        setMonthData({
          data: res.data.Profit_Loss,
          label: label_title + " Monthly View",
          title: "Profit/Loss",
        });
      });
      //setMonthData({data:profitMonth,label:'Profit/Lose Monthly View',title:'Profit/Lose'})
      setIsLoading(false);
    }

    setnext(data.LabelAndYear[0].Labelfirst[index + 1].Label_Name);
    setprev(data.LabelAndYear[0].Labelfirst[index - 1].Label_Name);
  };
  const rl =
    y.length > 0
      ? y.map((x) => (
          <Dropdown.Item value={x.Year} eventKey={x.Year} className="active">
            {x.Year}
          </Dropdown.Item>
        ))
      : "";
      const[selectedDepartment,setselectedDepartment]=useState(null)
  const handleSelectdrp = (eventKey, event) => {
    if (eventKey.split("`")[1] === "All Department") {
      setbossalldepart(1);
    } else {
      setbossalldepart(0);
    }
    setcheckingForNoBudget(false);
    setselectedDepartment(eventKey);
    SetselectDepartment(eventKey.split("`")[1]);
    setbossid(eventKey.split("`")[0]);
    setTab("Revenue");
    setEdit(false);
    setshowtable(true);
  };

  ///////////////////////////Ad Category////////////////////////////////

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const onSubmit = async () => {
    setIsLoading(true);
    //e.preventDefault(); // prevent page refresh
    let data;
let adbudgeturl;
    if (selectedOption == "option1") {
      adbudgeturl="/Api/BudgetPeriod/addBudgetPlan"
      data = {
        Start_date: date_picker,
        budget_amount: inputValue,
        budget_type: 1,
        budget_year: ye,
        template_type: 2,
      };
    } else {
      adbudgeturl="/Api/Budget/addBudgetPlan"
      data = {
        Start_date: "2022-12-31",
        budget_amount: inputValue,
        budget_type: 2,
        budget_year: ye,
        template_type: 2,
      };
    }

    await fobeAxios
      .post(adbudgeturl, data)
      .then((resp) => {
        toast.success("Budget Added Successfully");
        if (resp) {
          settriggervariable(true);
          mainAxios
            .get("/Api/User/GetUserClaims")
            .then((respp) => {
              localStorage.setItem("UserClaims", JSON.stringify(respp.data));
              items = respp.data;
              budget_type = items.budget_type;
              setbudget_type(items.budget_type);

              splUser = items.Special_user;
              Acc_type = items.Account_Type;
              console.log(splUser, Acc_type, budget_type, "items");
              if(budget_type==1){
                setSelectedOption("option1")
              }else{  setSelectedOption("option2")}
              if (respp) {
                getData();
              }
              // window.location.reload();
              setShow(false);
            })
            .catch((error) => {
              setIsLoading(false);
              return Promise.reject("invalid");
            });
        }
      })
      .catch((err) => {
        toast.error("Failed");
        setIsLoading(false);
      });
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
    // if(BudgetRevenuelength!=0 && data.total!=inputValue){
    // setShow(true)}
    // else{
    //   setShow(false)
    // }
  };
  const handleInputChangeforcategory = (event) => {
    setText(event.target.value);
  };
  const submitcategory = async () => {

    setIsLoading(true);
    let controllername;
    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      if (budget_type == "1") {
        controllername = "BudgetPeriod";
      } else {
        controllername = "Budget";
      }
    }

    let budget = {
      Budget: {
        category_name: text,
        category_tax: false,
      },
      Budget_details: [
        { budget_year: userDetail.budget_end_year },
        { budget_type: userDetail.budget_type },
      ],
    };
    let addtoastdata = text + " " + "Category Added Successfully!";
    if (
      tabs == "Revenue" ||
      tabs == "Admin Expenses" ||
      tabs == "Turf & Maintenance" ||
      tabs == "Tennis Expenses" ||
      tabs == "Turf CareExpenses" ||
      tabs == "Fitness Expenses"
    ) {
      await fobeAxios
        .post("Api/" + controllername + "/addRevenueCategory", budget)
        .then((resp) => {
          if (resp) {
            changeDatadup(tabs);
            setText("");
            if(resp.data==1){
              toast.success(addtoastdata);
            }
            else{
              toast.error("Failed");
            }
          }
        })
        .catch((err) => {
          toast.error("Failed");
          setIsLoading(false);
        });
    } else if (tabs == "Cost of Goods") {
      await fobeAxios
        .post("Api/" + controllername + "/addCogCategory", budget)
        .then((resp) => {
          if (resp) {
            changeDatadup(tabs);
            setText("");
            if(resp.data==1){
              toast.success(addtoastdata);
            }
            else{
              toast.error("Failed");
            }
          }
        })
        .catch((err) => {
          toast.error("Failed");
          setIsLoading(false);
        });
    } else if (tabs == "Labor") {
      let taxvalue;
      if (isChecked == true) {
        taxvalue = 1;
      } else {
        taxvalue = 0;
      }
      budget = {
        Budget: {
          category_name: text,
          category_tax: isChecked,
        },
        Budget_details: [
          { budget_year: userDetail.budget_end_year },
          { budget_type: userDetail.budget_type },
          { tax: taxvalue },
        ],
      };
      await fobeAxios
        .post("Api/" + controllername + "/addLaborCategory", budget)
        .then((resp) => {
          if (resp) {
            changeDatadup(tabs);
            
            setText("");
            setIsChecked(false);
            console.log(resp, "resp");
            if(resp.data==1){
              toast.success(addtoastdata);
            }
            else{
              toast.error("Failed");
            }
          }
        })
        .catch((err) => {
          toast.error("Failed");
          setIsLoading(false);
        });
    } else if (tabs == "Controllable") {
      await fobeAxios
        .post("Api/" + controllername + "/addCtrlblCategory", budget)
        .then((resp) => {
          if (resp) {
            changeDatadup(tabs);
            setText("");
            if(resp.data==1){
              toast.success(addtoastdata);
            }
            else{
              toast.error("Failed");
            }
          }
        })
        .catch((err) => {
          toast.error("Failed");
        
          setIsLoading(false);
        });
    } else if (tabs == "Non Controllable") {
      await fobeAxios
        .post("Api/" + controllername + "/addNonCtrlblCategory", budget)
        .then((resp) => {
          if (resp) {
            changeDatadup(tabs);
         
            setText("");
            if(resp.data==1){
              toast.success(addtoastdata);
            }
            else{
              toast.error("Failed");
            }
          }
        })
        .catch((err) => {
          toast.error("Failed");
          setIsLoading(false);
        });
    }
    setCurrentPage(1);
  };
  const handleDelete = async (data) => {

    setIsLoading(true);
    console.log(data, "data");
    setEditRowId(null);
    let Dltcontrollername;
    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      if (budget_type == "1") {
        Dltcontrollername = "BudgetPeriod";
      } else {
        Dltcontrollername = "Budget";
      }
    }
    let budget = {
      Budget: {
        Cat_id: data.Cat_id,
        Cat_name: data.Cat_name,
        Cat_amt: data.Cat_amt,
        Cat_per: data.Cat_per,
        Editable_Status: data.Editable_Status,
        Default_addon: data.Default_addon,
      },
      Budget_details: [
        { budget_year: userDetail.budget_end_year },
        { budget_type: userDetail.budget_type },
      ],
    };
    let deltoastdata = data.Cat_name + " " + "Category Deleted Successfully!";
    if (
      tabs == "Revenue" ||
      tabs == "Admin Expenses" ||
      tabs == "Turf & Maintenance" ||
      tabs == "Tennis Expenses" ||
      tabs == "Turf CareExpenses" ||
      tabs == "Fitness Expenses"
    ) {
      await fobeAxios
        .post("Api/" + Dltcontrollername + "/deleteRevenueCategory", budget)
        .then((resp) => {
          if (resp) {
            changeDatadup(tabs);
            toast.success(deltoastdata);
            setText("");
          }
        })
        .catch((err) => {
          toast.error("Failed");
          setIsLoading(false);
        });
    } else if (tabs == "Cost of Goods") {
      await fobeAxios
        .post("Api/" + Dltcontrollername + "/deleteCogCategory", budget)
        .then((resp) => {
          if (resp) {
            changeDatadup(tabs);
            toast.success(deltoastdata);
            setText("");
          }
        })
        .catch((err) => {
          toast.error("Failed");
          setIsLoading(false);
        });
    } else if (tabs == "Labor") {
      await fobeAxios
        .post("Api/" + Dltcontrollername + "/deleteLaborCategory", budget)
        .then((resp) => {
          if (resp) {
            changeDatadup(tabs);
            toast.success(deltoastdata);
            setText("");
          }
        })
        .catch((err) => {
          toast.error("Failed");
          setIsLoading(false);
        });
    } else if (tabs == "Controllable") {
      await fobeAxios
        .post("Api/" + Dltcontrollername + "/deleteCtrlblCategory", budget)
        .then((resp) => {
          if (resp) {
            changeDatadup(tabs);
            toast.success(deltoastdata);
            setText("");
          }
        })
        .catch((err) => {
          toast.error("Failed");
          setIsLoading(false);
        });
    } else if (tabs == "Non Controllable") {
      await fobeAxios
        .post("Api/" + Dltcontrollername + "/deleteNonCtrlblCategory", budget)
        .then((resp) => {
          if (resp) {
            changeDatadup(tabs);
            toast.success(deltoastdata);
            setText("");
          }
        })
        .catch((err) => {
          toast.error("Failed");
          setIsLoading(false);
        });
    }
    setCurrentPage(1)
  };
  const handleEdit = async (data) => {
    console.log(data, "data");
    setEditRowId(data.Cat_id);
  };
  const updateTableData = (id, key, value) => {
    const newData = addcategory.map((item) => {
      if (item.Cat_id == id) {
        item.Cat_name = value;
        return item;
      }
      return item;
    });
    setaddcategory(newData);
  };
  const updatecategory = async (data) => {

    setIsLoading(true);
    console.log(data, "data");
    setEditRowId(null);
    let updatecontollername;
    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      if (budget_type == "1") {
        updatecontollername = "BudgetPeriod";
      } else {
        updatecontollername = "Budget";
      }
    }
    let budget = {
      Budget: {
        Cat_id: data.Cat_id,
        Cat_name: data.Cat_name,
        Cat_amt: data.Cat_amt,
        Cat_per: data.Cat_per,
        Editable_Status: data.Editable_Status,
        Default_addon: data.Default_addon,
      },
      Budget_details: [
        { budget_year: userDetail.budget_end_year },
        { budget_type: userDetail.budget_type },
      ],
    };
    let toastdata = data.Cat_name + " " + "Category Updated Successfully!";
    if (
      tabs == "Revenue" ||
      tabs == "Admin Expenses" ||
      tabs == "Turf & Maintenance" ||
      tabs == "Tennis Expenses" ||
      tabs == "Turf CareExpenses" ||
      tabs == "Fitness Expenses"
    ) {
      await fobeAxios
        .post("Api/" + updatecontollername + "/updateRevenueCategory", budget)
        .then((resp) => {
          if (resp) {
            changeDatadup(tabs);
            toast.success(toastdata);

            setText("");
          }
        })
        .catch((err) => {
          toast.error("Failed");
          setIsLoading(false);
        });
    } else if (tabs == "Cost of Goods") {
      await fobeAxios
        .post("Api/" + updatecontollername + "/updateCogCategory", budget)
        .then((resp) => {
          if (resp) {
            changeDatadup(tabs);
            toast.success(toastdata);
            setText("");
          }
        })
        .catch((err) => {
          toast.error("Failed");
          setIsLoading(false);
        });
    } else if (tabs == "Labor") {
      await fobeAxios
        .post("Api/" + updatecontollername + "/updateLaborCategory", budget)
        .then((resp) => {
          if (resp) {
            changeDatadup(tabs);
            toast.success(toastdata);
            setText("");
          }
        })
        .catch((err) => {
          toast.error("Failed");
          setIsLoading(false);
        });
    } else if (tabs == "Controllable") {
      await fobeAxios
        .post("Api/" + updatecontollername + "/updateCtrlblCategory", budget)
        .then((resp) => {
          if (resp) {
            changeDatadup(tabs);
            toast.success(toastdata);
            setText("");
          }
        })
        .catch((err) => {
          toast.error("Failed");
          setIsLoading(false);
        });
    } else if (tabs == "Non Controllable") {
      await fobeAxios
        .post("Api/" + updatecontollername + "/updateNonCtrlblCategory", budget)
        .then((resp) => {
          if (resp) {
            changeDatadup(tabs);
            toast.success(toastdata);
            setText("");
          }
        })
        .catch((err) => {
          toast.error("Failed");
          setIsLoading(false);
        });
    }
    setCurrentPage(1)
  };
  function handleCheckboxChange() {
    setIsChecked(!isChecked);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////

  // constant recurring expenses

  const [showpopup, setShowpopup] = useState(false);
  const [showdeletepopup, setShowdeletepopup] = useState(false);
  const [constantRecurring, setConstantRecurring] = useState([]);
  const [adCategory, setadCategory] = useState("");
  const [totalbudgetamount, settotalbudgetamount] = useState();
  const [checbox1, setCheckbox1] = useState(false);
  const [checbox2, setCheckbox2] = useState(false);
  const [bossalldepart, setbossalldepart] = useState(1);
  const [callRecurring, setCallRecurring] = useState(false);
  const [deleteItemvalue, setdeleteItemvalue] = useState();
  
  const handleTaxCheckbox = (e, i, value) => {
    let temp = [...constantRecurring];
    let index = temp.findIndex((b) => b.Cat_id == value.Cat_id);

    if (index == 1) {
      temp[index].Cat_per = 0.07;
      temp[index + 1].Cat_per = 0;
    } else if (index == 2) {
      temp[index].Cat_per = 0.07;
      temp[index - 1].Cat_per = 0;
    }
    setConstantRecurring(temp);
  };
  const handleChangeValue = (e, value, index) => {
    let temp = [...constantRecurring];
    if (e !== undefined && e !== "") {
      temp[index].Cat_amt = e;
      temp[index].Cat_per = (e / totalbudgetamount) * 100;
    } else {
      temp[index].Cat_amt = 0;
      temp[index].Cat_per = 0;
    }
    setConstantRecurring(temp);
  };

  const handletaxper = (e, value, index) => {
    let temp = [...constantRecurring];
    if (e !== undefined && e !== "") {
      temp[index].Cat_per = e;
    } else {
      temp[index].Cat_per = 0;
    }
    setConstantRecurring(temp);
  };
  const handleChangePer = (e, value, index) => {
    console.log(e);
    let temp = [...constantRecurring];
    if (e !== undefined && e !== "") {
      temp[index].Cat_per = e;
      temp[index].Cat_amt = (e / 100) * totalbudgetamount;
    } else {
      temp[index].Cat_per = 0;
      temp[index].Cat_amt = 0;
    }

    setConstantRecurring(temp);
  };

  const handleSaveRecurring = async (e,user) => {
    e.preventDefault();
    try {
      const payload = {
        Budget: constantRecurring,
        Budget_details: [
          {
            budget_year: items.budget_start_year,
          },
          {
            budget_type: items.budget_type,
          },
        ],
      };
      let constantRecurringbudget='';
   
      if(user===1){
        if(budget_type == "1"){
          constantRecurringbudget="BudgetPeriod/UpdateBossRecurringPeriod/"+items.budget_start_year+"/"+bossid+"/";
        }
        else{
          constantRecurringbudget="Boss/UpdateBossRecurring/"+items.budget_start_year+"/"+bossid+"/";
        }
     
      }
      else{
        if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
          if (budget_type == "1") {
            constantRecurringbudget="Budgetperiod/updateRecuring/";
          } else {
            constantRecurringbudget="Budget/updateRecuring/";
          }
        }
      }
     
      await fobeAxios
        .post("api/"+constantRecurringbudget, payload)
        .then((e) => {
          console.log(e, "kkdi");
          if (e.data === 1) {
            toast.success("Saved Scuessfully");
            setCallRecurring(!callRecurring);
          } else {
            toast.error("Failed");
          }
        })
        .catch(() => {
          toast.error("Failed");
        });
    } catch (err) {}
  };

  const handleClosepopup = () => {
    setadCategory("");
    setCheckbox1(false);
    setCheckbox2(false);
    setShowpopup(false);
  };
  const handleClosedeletepopup = () => {
    setShowdeletepopup(false);
    setdeleteItemvalue();
  };
  const handleClosedeletepopupConfirm = async () => {
    try {
      if (deleteItemvalue !== undefined) {
        const payloadelete = {
          Recurring: deleteItemvalue,
          Budget_details: [
            {
              budget_year: items.budget_start_year,
            },
            {
              budget_type: items.budget_type,
            },
          ],
        };
        let constantRecurringbudget='';
        if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
          if (budget_type == "1") {
            constantRecurringbudget="Api/Budgetperiod/deleteRecurringCategory";
          } else {
            constantRecurringbudget="Api/Budget/deleteRecurringCategory";
          }
        }
        else{
          if (budget_type == "1") {
            constantRecurringbudget="api/Boss/deleteBossPeriodRecurringCategory/"+ye+'/'+bossid;
          }
          else {
            constantRecurringbudget="api/Boss/deleteBossRecurringCategory/"+ye+'/'+bossid;
          }
        }
        await fobeAxios
          .post(constantRecurringbudget, payloadelete)
          .then((x) => {
            setdeleteItemvalue();
            if (x.data === 1) {
              toast.success("Deleted Successfully");
              setCallRecurring(!callRecurring);
            } else {
              toast.success("Deleted Failed");
            }
          })
          .catch(() => {
            setdeleteItemvalue();
            toast.success("Deleted Failed");
          });
      }
    } catch (err) {}
    setShowdeletepopup(false);
  };

  const handleclosedeletecat = () => {
    setCatDelete(false);
  };

  const handleDeletecat = () => {
    setCatDelete(false);
    handleDelete(delete_cat);
  };

  const handle2delete = (x) => {
    setDeleteCAt("");
    setCatDelete(true);

    setDeleteCAt(x);
    {
      console.log(x, "x value");
    }
  };
  const getConstantRecurring = async () => {
    setIsLoading(true);
    let Url = "";
    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      if (budget_type == "1") {
        Url="Api/Budgetperiod/GetRecurring/"+ye;
      } else {
        Url = "Api/Budget/GetRecurring/"+ye;
      }
     
    } else {
      if (bossalldepart === 1) {
        let c=""
        if(selectedCourse){
c=selectedCourse;
        }else{
          c=items?.Company
        }
        Url="api/Boss/GetBossRecurringForAllDepartMents/"+ye+"/"+c+"/"
      } else {
        Url = "Api/Boss/GetBossRecurring/" + ye + "/" + bossid + "/";
      }
    }
    try {
      await fobeAxios
        .get(Url)
        .then((response) => {
          console.log(response.data.Recurring, "forthedatafromconstants");
          setConstantRecurring(response.data.Recurring);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const handleCancelAdCat = (e) => {
    e.preventDefault();
    setadCategory("");
    setCheckbox1(false);
    setCheckbox2(false);
  };
  const handleAdCategory = (e) => {
    setadCategory(e);
  };
  const handleCat_Click = async (e) => {
    e.preventDefault();
    if (
      adCategory !== undefined &&
      adCategory !== "" &&
      (checbox1 || checbox2)
    ) {
      const payload = {
        Recurring: {
          cat_name: adCategory,
          cat_type: checbox1
            ? "Controllable"
            : checbox2
            ? "NonControllable"
            : "",
        },
        Budget_details: [
          {
            budget_year: items.budget_start_year,
          },
          {
            budget_type: items.budget_type,
          },
        ],
      };
      let constantRecurringbudget='';
      if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
        if (budget_type == "1") {
          constantRecurringbudget="Api/Budgetperiod/addRecurringCategory";
        } else {
          constantRecurringbudget="Api/Budget/addRecurringCategory";
        }
      }
      else{
        if (budget_type == "1") {
          constantRecurringbudget="api/Boss/addBossPeriodRecurringCategory/"+ye+"/"+bossid
        }
        else{
          constantRecurringbudget=  "api/Boss/addBossRecurringCategory/"+ye+"/"+bossid
        }
        }
      
      await fobeAxios
        .post(constantRecurringbudget, payload)
        .then((x) => {
          if (x.data === 1) {
            setCheckbox1(false);
            setCheckbox2(false);
            setadCategory("");
            toast.success("Added Successfully");
            setCallRecurring(!callRecurring);
          } else {
            toast.error("failed");
          }
        })
        .catch(() => {
          toast.error("failed");
        });
    } else {
    }
  };

  useEffect(() => {
    getConstantRecurring();
  }, [callRecurring]);
useEffect(() => {

  if((splUser == 0 || splUser == 1) && Acc_type != 0){

    setbossalldepart(0);
  }
  else{

    setbossalldepart(1);
  }
}, [selectedCourse]);
  ////////////////////////////////////////////////////////////////

  //////////////////changeOrder/////////////////////
  const [list, setList] = useState([]);

  const onDragStart = (e, id) => {
    e.dataTransfer.setData("text/plain", id);
  };

  const onDrop = (e, newIndex) => {
    const id = e.dataTransfer.getData("text/plain");
    const item = list.find((item) => item.Cat_id.toString() === id);
    const oldIndex = list.findIndex((item) => item.Cat_id.toString() === id);

    if (oldIndex !== -1) {
      const newItems = [...list];
      newItems.splice(oldIndex, 1); // remove old item
      newItems.splice(newIndex, 0, item); // insert item at new index
      const reorderedList = newItems.map((item, index) => {
        return { ...item }; //, orderid: index + 1
      });
      setList(reorderedList);
    }
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const handleSaveOrders = async (e) => {
    e.preventDefault();
    const payload={
      type:tabs,
      year:items.budget_start_year,
      Details:list,
    }
    await fobeAxios.post('api/Budget/SaveOrderdetails',payload).then((y)=>{
      if(y.data==="OrderId Inserted successfully"){
          toast.success("Data Submitted Successfully");
          getLabel();
          getData();
      }

      else{
 toast.error("Failed");
      }
    }).catch((z)=>{
      toast.error("Failed");
    })
  };
  const getorderDetails = async () => {
    await fobeAxios
      .get(
        "api/Budget/GetOrderdetails/" + new Date().getFullYear() + "/" + tabs
      )
      .then((x) => {
        console.log(x, "forGettingApi");
        setList(x.data.Orderdetails);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getorderDetails();
  }, [tabs]);
  ///////////////////////////////////////////////////

  const indexOfLastPost=currentPage*postPerPage;
  const indexOfFirstPost=indexOfLastPost-postPerPage;
  const currentPosts=addcategory.slice(indexOfFirstPost,indexOfLastPost);

  const handlePageChange = (event, value) => {
  {console.log(value,"page number")}
  setCurrentPage(value);
  };

  /////////////////////////////////////////////

  return (
    <>
      {showRevenueBudget ? (
        <div className="container">
          {(splUser == 0 || splUser == 1) && Acc_type != 0 ? (
            <>
              <div
                className="col-xs-12 header-panel newres"
                style={{ marginTop: "8px", marginBottom: "14px", padding: "0" }}
              >
                <div className="col-xs-12 p-0">
                  <div className="row">
                    {showCat && budget_type!=0 ? (
                      <div className="col-sm-12 col-lg-5">
                        <h2
                          style={{
                            fontSize: "26px",
                            marginTop: "0px",
                            color: "#7b7b7b",
                          }}
                        >
                          Customer Budget{" "}
                          <span className="h2-title">
                          {budget_type == 1 ? "for 13 -4 Week Periods"
                                        : "for 12 Months"}
                            {/* {
                             JSON.parse(localStorage.getItem("UserClaims"))
                              budget_type == "2"
                              ? "for 12 Months"
                              : "for 13 -4 Week Periods"} */}
                          </span>
                        </h2>
                      </div>
                    ) : (
                      <div className="col-sm-12 col-lg-5"> <h2   style={{
                        fontSize: "26px",
                        marginTop: "0px",
                        color: "#7b7b7b",
                      }}><br></br><span></span></h2></div>
                    )}
                    {showCat ? (
                      <div className="col-sm-12 col-md-2">
                        <Form>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formPlaintextPassword"
                          >
                            <Col sm="12">
                              <Dropdown>
                                <Dropdown.Toggle
                                  id="dropdown-button-dark-example1"
                                  className="drop-ml "
                                  variant="secondary"
                                >
                                  {selectYear}
                                </Dropdown.Toggle>

                                <Dropdown.Menu variant="dark">
                                  {rl}
                                </Dropdown.Menu>
                              </Dropdown>
                            </Col>
                          </Form.Group>
                        </Form>
                      </div>
                    ) : (
                      <div className="col-sm-12 col-md-2"></div>
                    )}
                    {hideCategoriesandconstant &&<>
                    <div className="col-md-2 col-sm-12">
                      <a
                        data-toggle="modal"
                        data-target="#modalone"
                        style={{
                          fontSize: "17px",
                          color: "rgb(25, 167, 63)",
                          float: "right",
                          textDecoration: "none",
                        }}
                      >
                        Categories{" "}
                        <i
                          className="bi bi-plus-circle-fill"
                          style={{
                            color: "#19a73f",
                            position: "relative",
                            fontSize: "22px",
                          }}
                        ></i>
                      </a>
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <div className="row">
                        <div className="col-xs-12">
                          <a
                            style={{
                              fontSize: "13px",
                              color: "rgb(243, 244, 255)",
                              textDecoration: "none",
                            }}
                          >
                            <button
                              className="btn recurringbtn"
                              type="button"
                              onClick={() => {
                                setCallRecurring(!callRecurring);
                                setShowpopup(true);
                              }}
                            >
                              CONSTANT RECURRING EXPENSES
                            </button>
                          </a>
                        </div>
                      </div>
                    </div></>}
                  </div>
                </div>

                {showCheckbox && showCat && (
                  <div
                    className="col-xs-12 sm-0"
                    style={{
                      paddingTop: "20px",
                      paddingLeft: "0",
                      paddingBottom: "20px",
                      borderTop: "2px solid #c5c4c4",
                      borderBottom: "2px solid #c5c4c4",
                    }}
                  >
                    <form>
                      <div className="row">
                        <div
                          className="col-md-5 col-sm-12"
                          style={{
                            padding: "0",
                            borderRight: "2px solid #adadad",
                          }}
                        >
                          <div className="col-xs-12 col-sm-12 col-md-12">
                            <h5
                              style={{
                                fontSize: "17px",
                                marginTop: "0px",
                                color: "rgb(0, 0, 0)",
                                padding: "0",
                                marginBottom: "20px",
                              }}
                            >
                              Please select the format of the budget{" "}
                            </h5>
                            <div
                              className="col-xs-12 col-sm-12 col-md-12"
                              style={{ fontSize: "14px", paddingLeft: "0px" }}
                            >
                              <div
                                className="col-xs-12 col-sm-6 col-md-6"
                                style={{ padding: "0" }}
                              >
                                <input
                                  type="radio"
                                  value="option1"
                                  checked={selectedOption === "option1"}
                                  onChange={handleOptionChange}
                                  // checked={
                                  //   JSON.parse(localStorage.getItem("UserClaims"))
                                  //     .budget_type == "1"
                                  // }
                                  disabled={BudgetRevenuelength > 0 && selectedOption != "option1"?true:false}
                                />
                                13 - 4 Week Periods
                              </div>
                              <div className="col-xs-12 col-sm-12 col-md-6">
                                <input
                                  type="radio"
                                  value="option2"
                                  checked={selectedOption === "option2"}
                                  onChange={handleOptionChange}
                                  // checked={
                                  //   JSON.parse(localStorage.getItem("UserClaims"))
                                  //     .budget_type == "2"
                                  // }
                                   disabled={BudgetRevenuelength > 0 && selectedOption != "option2"?true:false}
                                />
                                12 Months
                              </div>

                        { /*    {selectedOption === "option1" && (
                                <div
                                  className="col-xs-12 col-sm-6 col-md-6"
                                  style={{ padding: "0" }}
                                >
                                  <div className="date-picker">
                                    <DatePicker
                                      
                                      placeholder="27/12/2022"
                                      onChange={(date) => setStartDate(date)}
                                    />
                                  </div>
                                </div>
                        )} */ }
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7 col-sm-12">
                          <div className="col-xs-12 col-sm-12 col-md-12">
                            <h5
                              style={{
                                fontSize: "17px",
                                marginTop: "0px",
                                color: "rgb(0, 0, 0)",
                                padding: "0",
                                marginBottom: "20px",
                              }}
                            >
                              Your Estimated Revenue
                            </h5>
                          </div>
                          <div className="col-md-6 col-sm-3 col-xs-6">
                            {/*<CurrencyFormat className="input_field fl-r form-control" id="Cat_amt" name="Cat_amt" />*/}
                            $
                            <CurrencyFormat
                              fixedDecimalScale={2}
                              className="input_field fl-r form-control"
                              decimalScale={2}
                              value={inputValue}
                              thousandSeparator={true}
                              onChange={handleInputChange}
                              
                              // disabled={true}
                            />
                          </div>
                          <div className="col-xs-6 col-sm-3 col-md-6 text-left">
                            <button
                              className="btn recurringbtn"
                              style={{ backgroundColor: "#19a73f", color: "" }}
                              type="button"
                              onClick={() => {
                                  if (BudgetRevenuelength > 0) {
                                    handleShow();
                                  } else {
                                    onSubmit();
                                  }
                              }}
                               disabled={BudgetRevenuelength > 0?true:false}
                            >
                              SUBMIT
                            </button>
                          </div>
                        </div>
                      </div>
                      <Modal show={show} size="sm" onHide={handleClose}>
                        <Modal.Header style={{ border: "none" }}>
                          <Modal.Title
                            style={{
                              textAlign: "center",
                              background: "rgb(236 173 27)",
                              padding: "0.5em",
                            }}
                          >
                            <BsExclamationTriangle
                              style={{ marginRight: "0.2em", top: "2px" }}
                            />
                            Warning
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body
                          style={{
                            textAlign: "center",
                            fontSize: "1.01em",
                            padding: "0.3em 1em",
                          }}
                        >
                          <p>
                            Resetting your estimated revenue figure will clear
                            all budgeted information.
                          </p>
                          <p>
                            To update your estimated revenue for the year please
                            update your monthly revenue expectations located at
                            the bottom of this page.
                          </p>
                          <p>Are you sure you want to reset your account?</p>
                        </Modal.Body>
                        <Modal.Footer
                          style={{
                            textAlign: "center",
                            marginRight: "0em",
                            border: "none",
                          }}
                        >
                          <button
                            className="btn btn-danger"
                            onClick={handleClose}
                            style={{ marginRight: "0.5em" }}
                          >
                            CANCEL
                          </button>
                          <button
                            className="btn btn-success"
                            onClick={onSubmit}
                          >
                            CONFIRM
                          </button>
                        </Modal.Footer>
                      </Modal>
                    </form>
                  </div>
                )}
              </div>
              <div className="col-xs-12 text-center">
                <h3
                  style={{
                    margin: "18px 0px",
                    color: "#666",
                    fontSize: "25px",
                    display: "none",
                  }}
                >
                  Estimated Revenue for 2023
                </h3>
              </div>
              <div className="col-md-12" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-md-8" style={{ paddingBottom: "20px" }}>
                    <button
                      className="btn recurringbtn"
                      style={{ backgroundColor: "#19a73f" }}
                      type="button"
                      data-toggle="modal"
                      data-target="#changemodal"
                      onClick={(e)=>{e.preventDefault();getorderDetails();}}
                    >
                      CHANGE ORDER
                    </button>
                  </div>
                </div>
              </div>{" "}
            </>
          ) : null}

          {(splUser !== 0 || splUser !== 1) && Acc_type == 0 ? (
            <>
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6">

                  {budget_type!=0 ?
                  <h2
                    style={{
                      fontSize: "26px",
                      marginTop: "0px",
                      color: "#000",
                    }}
                  >
                    Customer Budget{" "}
                    <span className="h2-title" style={{ color: "#7b7b7b" }}>
                      {budget_type == 1 ? "for 13 -4 Week Periods"
                                        : "for 12 Months"}
                    </span>
                  </h2>:<h2   style={{
                            fontSize: "26px",
                            marginTop: "0px",
                           
                          }}><br></br><span></span></h2>}
                </div>

                
                <div className="col-sm-12 col-md-6">
                  <div className="row">
                    <div className="col-xs-12">
                      <a
                        style={{
                          fontSize: "13px",
                          color: "rgb(243, 244, 255)",
                          textDecoration: "none",
                          float: "right",
                        }}
                      >
                        <button
                          className="btn recurringbtn"
                          type="button"
                          onClick={() => {
                            setCallRecurring(!callRecurring);
                            setShowpopup(true);
                          }}
                        >
                          CONSTANT RECURRING EXPENSES
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
              <div className="col-sm-12 col-md-2">
                  <Form>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Col sm="12">
                        <Dropdown>
                          <Dropdown.Toggle
                            id="dropdown-button-dark-example1"
                            className="drop-ml "
                            variant="secondary"
                          >
                            {selectYear}
                          </Dropdown.Toggle>

                          <Dropdown.Menu variant="dark">{rl}</Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Form.Group>
                  </Form>
                </div>
                {showCourse && showCat && (
                  <div className="col-sm-12 col-md-10">
                    <Form>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Col sm="8">
                          <Form.Label
                            style={{
                              float: "right",
                              marginTop: "5.5px",
                              color: "#666",
                              fontWeight: "500",
                            }}
                          >
                            Select Department
                          </Form.Label>
                        </Col>
                        <Col sm="4">
                          <Dropdown onSelect={handleSelectdrp} >
                            <Dropdown.Toggle
                              id="dropdown-button-dark-example1"
                              className="drop-ml "
                              variant="secondary"
                            >
                              {selectDepartment
                                ? selectDepartment.slice(0, 23)
                                : "All Department"}
                            </Dropdown.Toggle>

                        <Dropdown.Menu variant="dark"  className='responsedrop'>

                              <Dropdown.Item
                                eventKey={
                                  alldepartments + "`" + "All Department"
                                }
                              >
                                All Department
                              </Dropdown.Item>
                              {department?.map((x) => (
                                <Dropdown.Item
                                  eventKey={x.Rest_id + "`" + x.Rest_Name}
                                  className={selectedDepartment === x.Rest_id + "`" + x.Rest_Name?'active':""}
                                >
                                  {x.Rest_Name}
                                </Dropdown.Item>
                              ))}

                              {/* <Dropdown.Item  active>Metro West-Golf Operations</Dropdown.Item>
                <Dropdown.Item>Metro West-Administration</Dropdown.Item>
                <Dropdown.Item>Metro West-Turf & Maintenance</Dropdown.Item>
                <Dropdown.Item>Metro West-Food & Beverage</Dropdown.Item> */}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Form.Group>
                    </Form>
                  </div>
                )}
                <div
                  class="col-xs-12"
                  style={{
                    borderTop: "1px solid #ddd",
                    paddingTop: "25px",
                    paddingBottom: "25px",
                  }}
                >
                  <div class="col-xs-12">
                    <label
                      class="control-label"
                      style={{
                        fontSize: "18px",
                        textAlign: "left",
                        color: "#666",
                        fontWeight: "500",
                      }}
                    >
                      Total Estimated Revenue:{" "}
                      <span
                        class="label label-success"
                        style={{
                          position: "relative",
                          top: "-2px",
                          fontSize: "16px",
                        }}
                      >
                     $ <CurrencyFormat fixedDecimalScale={2} className='non-edit-cs' decimalScale={2} value={Budgetlength > 0 ? inputValue :"0" } thousandSeparator={true} disabled={true} />
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          <div
            class="modal fade"
            id="modalone"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div
                class="modal-content"
                style={{
                  position: "relative",
                  float: "left",
                  backgroundColor: "#fefefe",
                  margin: "15% auto",
                  padding: "20px",
                  border: "1px solid #888",
                }}
              >
                <div
                  class="col-xs-12 modal-header"
                  style={{
                    border: "none",
                    backgroundColor: "#19a73f",
                    padding: "5px",
                  }}
                >
                  <button
                    type="button"
                    class="close modaloneclose"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    &times;
                  </button>
                  <h3
                    class="modal-title text-center"
                    id="exampleModalLabel"
                    style={{ color: "white" }}
                  >
                    Categories
                  </h3>
                </div>
                <div
                  class="col-xs-12 modal-body table-responsive"
                  style={{
                    background: "#fff",
                    padding: "0",
                    border: "2px solid #4f4f4f",
                    marginBottom: "18px",
                  }}
                >
                  <p class="text-center">
                    You may add or delete the below list of categories as needed
                  </p>
                  <div
                className="table-responsive table-scroll-budget"
                style={{ maxHeight: "300px", marginTop: "2em" }}
              >
                  <table
                    class="table tabble "
                    style={{ width: "100%", margin: "auto" }}
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "35%" }}>
                          <div class="tab-card-new">{tabs} CATEGORY</div>
                        </th>
                        {tabs == "Labor" && (
                          <th style={{ width: "35%" }}>
                            <div class="tab-card-new">TAXES</div>
                          </th>
                        )}
                        <th style={{ width: "35%" }}>
                          <div class="tab-card-new">DELETE</div>
                        </th>
                        <th>
                          <div class="tab-card-new">EDIT</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentPosts.map((x, index) => (
                        <tr key={x.Cat_id}>
                          {editRowId === x.Cat_id ? (
                            <input
                              style={{
                                padding: "4px",
                                margin: "7px",
                                color: "#202020",
                                fontSize: "14px",
                                textTransform: "capitalize",
                                fontWeight: "600",
                                border: "none",
                                backgroundColor: "rgb(255, 222, 131)",
                              }}
                              type="text"
                              value={x.Cat_name}
                              onChange={(e) =>
                                updateTableData(
                                  x.Cat_id,
                                  "Cat_name",
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            <td style={{ color: "#202020", fontSize: "16px" }}>
                              <p
                                class="text-left"
                                style={{
                                  padding: "4px",
                                  margin: "0",
                                  color: "#202020",
                                  fontSize: "14px",
                                  textTransform: "capitalize",
                                  fontWeight: "600",
                                }}
                              >
                                {x.Cat_name}
                              </p>
                            </td>
                          )}
                          {tabs == "Labor" && (
                            <td style={{ textAlign: "center" }}>
                              <i
                                className={
                                  x.Cat_tax == 1 ? "bi bi-check-lg" : "bi bi-x"
                                }
                                style={{
                                  color: x.Cat_tax == 1 ? "green" : "orange",
                                }}
                              ></i>
                            </td>
                          )}

                          {editRowId === x.Cat_id ? (
                            <td style={{ textAlign: "center" }}>
                              <i
                                class="bi bi-check-lg"
                                style={{ color: "green" }}
                                onClick={() => updatecategory(x)}
                              ></i>
                            </td>
                          ) : (
                            <td style={{ textAlign: "center" }}>
                              <i
                                class="bi bi-trash-fill"
                                style={{ color: "red" }}
                                onClick={
                                  () => handle2delete(x)
                                  // handleDelete(x)
                                }
                              ></i>
                            </td>
                          )}

                          {editRowId === x.Cat_id ? (
                            <td style={{ textAlign: "center" }}>
                              <i
                                class="bi bi-x"
                                style={{ color: "red" }}
                                onClick={() => setEditRowId(null)}
                              ></i>
                            </td>
                          ) : (
                            x.Default_addon === 0 && (
                              <td style={{ textAlign: "center" }}>
                                <i
                                  class="bi bi-pencil-fill"
                                  style={{ color: "green" }}
                                  onClick={() => handleEdit(x)}
                                ></i>
                              </td>
                            )
                          )}
                          <td></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  </div>
                  <form
                    class="table-responsive"
                    style={{
                      margin: "auto",
                      border: "2px solid #4f4f4f",
                      overflowX: "hidden",
                    }}
                  >
                    <div
                      class="row"
                      style={{
                        marginLeft: "0px !important",
                        marginRight: "0px !important",
                      }}
                    >
                       <div class="pagination">

{isLoading ? <Spinner /> :
          <AddCategoryPagination 
           data={addcategory} 
           nestedColumn={false} 
           pagination={true} 
           handlePageChange={handlePageChange}
           currentPage={currentPage}
           postsPerPage={postPerPage}


  />}        
          </div>    
                      <div
                        class="col-md-6"
                        style={{ paddingTop: "22px", textAlign: "right" }}
                      >
                        <p>Enter New {tabs} Categories</p>
                      </div>
                      <div class="col-md-6" style={{ paddingTop: "22px" }}>
                        <input
                          type="text"
                          value={text}
                          onChange={handleInputChangeforcategory}
                        />
                      </div>
                    </div>
                    {tabs == "Labor" && (
                      <div class="row">
                        <div class="col-md-6" style={{ marginLeft: "21px" }}>
                          <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                          />
                          <label style={{ color: "#726a6a" }}>
                            &nbsp;&nbsp;Incl.Taxes?
                          </label>
                        </div>
                      </div>
                    )}
                    <div
                      class="text-center"
                      style={{ textAlign: "center", padding: "5px 0" }}
                    >
                      <button
                        type="button"
                        onClick={submitcategory}
                        class="btn btn-success"
                        style={{
                          background: "#19a73f",
                          color: "#fff",
                          margin: "0px 25px",
                          marginTop: "15px",
                        }}
                      >
                        ADD
                      </button>
                      <button
                        type="button"
                        class="btn btn-default"
                        data-dismiss="modal"
                        style={{ marginTop: "15px" }}
                      >
                        CLOSE
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="container">
        <div className="table-sections">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={tabs}
            activeKey={tabs}
            onSelect={(k) => {
              setTab(k);
            }}
          >
            <Row>

{
Budgetlength > 0 ?
                   <>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                  {LabelList?.map((x, index) => {
                    if (x.Label_Name == "Revenue") {
                      return (
                        <>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="Revenue"
                              onClick={() => changeData(1, "Revenue", index)}
                            >
                              Revenue
                            </Nav.Link>
                          </Nav.Item>
                        </>
                      );
                    }

                    if (x.Label_Name == "Admin Expenses") {
                      // eventKey_1="Revenue"
                      // eventKey_1="Admin Expenses"
                      // {console.log(eventKey_1,"Event key")}
                      return (
                        <>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="Admin Expenses"
                              onClick={() =>
                                changeData(1, "Admin Expenses", index)
                              }
                            >
                              Admin Expenses
                            </Nav.Link>
                          </Nav.Item>
                        </>
                      );
                    }
                      if (x.Label_Name == "Turf & Maintenance") {
                      // eventKey_1="Revenue"
                      // eventKey_1="Turf & Maintenance"
                      // {console.log(eventKey_1,"Event key")}
                      return (
                        <>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="Turf & Maintenance"
                              onClick={() =>
                                changeData(1, "Turf & Maintenance", index)
                              }
                            >
                            Turf & Maintenance
                            </Nav.Link>
                          </Nav.Item>
                        </>
                      );
                    }

                    if (x.Label_Name == "Fitness Expenses") {
                      // eventKey_1="Revenue"
                      return (
                        <>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="Fitness Expenses"
                              onClick={() =>
                                changeData(1, "Fitness Expenses", index)
                              }
                            >
                              Fitness Expenses
                            </Nav.Link>
                          </Nav.Item>
                        </>
                      );
                    }

                    if (x.Label_Name == "Tennis Expenses") {
                      return (
                        <>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="Tennis Expenses"
                              onClick={() =>
                                changeData(1, "Tennis Expenses", index)
                              }
                            >
                              Tennis Expenses
                            </Nav.Link>
                          </Nav.Item>
                        </>
                      );
                    }
                    if (x.Label_Name == "Turf CareExpenses") {
                      return (
                        <>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="Turf CareExpenses"
                              onClick={() =>
                                changeData(1, "Turf Care Expenses", index)
                              }
                            >
                              Turf CareExpenses
                            </Nav.Link>
                          </Nav.Item>
                        </>
                      );
                    }
                    if (x.Label_Name == "Cost Of Goods") {
                      return (
                        <>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="Cost Of Goods"
                              onClick={() =>
                                changeData(3, "Cost Of Goods", index)
                              }
                            >
                              Cost Of Goods
                            </Nav.Link>
                          </Nav.Item>
                        </>
                      );
                    }
                    if (x.Label_Name == "Labor") {
                      return (
                        <>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="Labor"
                              onClick={() => changeData(4, "Labor", index)}
                            >
                              Labor
                            </Nav.Link>
                          </Nav.Item>
                        </>
                      );
                    }
                    if (x.Label_Name.trim() == "Controllable") {
                      return (
                        <>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="Controllable"
                              onClick={() =>
                                changeData(5, "Controllable", index)
                              }
                            >
                              Controllable
                            </Nav.Link>
                          </Nav.Item>
                        </>
                      );
                    }
                    if (x.Label_Name == "Non Controllable") {
                      return (
                        <>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="Non Controllable"
                              onClick={() =>
                                changeData(6, "Non Controllable", index)
                              }
                            >
                              Non Controllable
                            </Nav.Link>
                          </Nav.Item>
                        </>
                      );
                    }
                    if (x.Label_Name == "Profit/Loss") {
                      return (
                        <>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="Profit/Loss"
                              onClick={() =>
                                changeData(7, "Profit/Loss", index)
                              }
                            >
                              Profit / Loss
                            </Nav.Link>
                          </Nav.Item>
                        </>
                      );
                    }
                  })}
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="Revenue">
                    <div className="col-md-12 pd-0">
                      <YearTable
                      bossid={bossid}
                      year={ye}
                      tabsname={tabs}
                        tabs={true}
                        Edit={Edit}
                        selectDepartment={selectDepartment}
                        menuId={1}
                        editYearData={editYearData}
                        editYearDatapercentage={editYearDatapercentage}
                        header={Revenue}
                        data={data}
                        nestedColumn={false}
                        isFooter={true}
                        Search={false}
                        pagination={false}
                      />
                    </div>
                    <div className="col-md-12 pd-0 rig-alg">
                      <Button
                        className="mat-btn mat-save"
                        disabled={
                          tabs === "Revenue" &&
                          Object.keys(revenue).length &&
                          Math.round(data.total, 2) !=
                            Math.round(revenue?.Total, 2)
                            ? true
                            : false
                        }
                        variant="contained"
                        onClick={() => saveYearData(next)}
                      >
                        Save
                      </Button>
                      <Button
                        className="mat-btn mat-next"
                        variant="contained"
                        onClick={() => {
                          setTab(next);
                          changeDatadup(next);
                        }}
                      >
                        {next}
                      </Button>
                    </div>
                  </Tab.Pane>

                  {tabs === "Revenue" &&
                    Object.keys(revenue).length !== 0 &&
                    revenue[0] !== "" &&
                    Math.round(data.total, 2) !==
                      Math.round(revenue?.Total, 2) && (
                      <p style={{ color: "#e46262" }}>
                        Total Amount Should not be less or More than $
                        {revenue?.Total}
                      </p>
                    )}

                  {tabs === "Revenue" &&
                    Object.keys(revenue).length !== 0 &&
                    revenue[0] !== "" &&
                    Math.round(data.percentage) !== 100 && (
                      <p style={{ color: "#e46262" }}>
                        Total Percentage Should not be less or More than 100%
                      </p>
                    )}

                  <Tab.Pane eventKey="Admin Expenses">
                    <div className="table-sections">
                      {/* <div className="col-md-12 pd-0"> */}
                      <YearTable
                      bossid={bossid}
                      year={ye}
                      tabsname={tabs}
                        tabs={true}
                        header={AdminExpenses}
                        data={data}
                        editYearData={editYearData}
                        editYearDatapercentage={editYearDatapercentage}
                        nestedColumn={false}
                        isFooter={true}
                        selectDepartment={selectDepartment}
                        Search={false}
                        pagination={false}
                        Edit={Edit}
                        menuId={1}
                      />
                    </div>

                    <div className="col-md-12 pd-0 rig-alg">
                      {prev ? (
                        <Button
                          className="mat-btn mat-next fl-lf"
                          variant="contained"
                          onClick={() => {
                            setTab(prev);
                            changeDatadup(prev);
                          }}
                        >
                          {prev}
                        </Button>
                      ) : null}
                      <Button
                        className="mat-btn mat-save"
                        variant="contained"
                        onClick={() => saveYearData(next)}
                      >
                        Save
                      </Button>
                      <Button
                        className="mat-btn mat-next"
                        variant="contained"
                        onClick={() => {
                          setTab(next);
                          changeDatadup(next);
                        }}
                      >
                        {next}
                      </Button>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Turf & Maintenance">
                  <div className="table-sections">
                    {/* <div className="col-md-12 pd-0"> */}
                    <YearTable
                    bossid={bossid}
                    year={ye}
                    tabsname={tabs}
                      tabs={true}
                      header={TurfMaintenance}
                      data={data}
                      editYearData={editYearData}
                      editYearDatapercentage={editYearDatapercentage}
                      nestedColumn={false}
                      isFooter={true}
                      selectDepartment={selectDepartment}
                      Search={false}
                      pagination={false}
                      Edit={Edit}
                      menuId={1}
                    />
                  </div>

                  <div className="col-md-12 pd-0 rig-alg">
                    {prev ? (
                      <Button
                        className="mat-btn mat-next fl-lf"
                        variant="contained"
                        onClick={() => {
                          setTab(prev);
                          changeDatadup(prev);
                        }}
                      >
                        {prev}
                      </Button>
                    ) : null}
                    <Button
                      className="mat-btn mat-save"
                      variant="contained"
                      onClick={() => saveYearData(next)}
                    >
                      Save
                    </Button>
                    <Button
                      className="mat-btn mat-next"
                      variant="contained"
                      onClick={() => {
                        setTab(next);
                        changeDatadup(next);
                      }}
                    >
                      {next}
                    </Button>
                  </div>
                </Tab.Pane>

                  <Tab.Pane eventKey="Fitness Expenses">
                    <div className="table-sections">
                      {/* <div className="col-md-12 pd-0"> */}
                      <YearTable
                      bossid={bossid}
                      year={ye}
                      tabsname={tabs}
                        header={FitnessExpenses}
                        tabs={true}
                        data={data}
                        editYearData={editYearData}
                        editYearDatapercentage={editYearDatapercentage}
                        nestedColumn={false}
                        isFooter={true}
                        selectDepartment={selectDepartment}
                        Search={false}
                        pagination={false}
                        Edit={Edit}
                        menuId={1}
                      />
                    </div>

                    <div className="col-md-12 pd-0 rig-alg">
                      {prev ? (
                        <Button
                          className="mat-btn mat-next fl-lf"
                          variant="contained"
                          onClick={() => {
                            setTab(prev);
                            changeDatadup(prev);
                          }}
                        >
                          {prev}
                        </Button>
                      ) : null}
                      <Button
                        className="mat-btn mat-save"
                        variant="contained"
                        onClick={() => saveYearData(next)}
                      >
                        Save
                      </Button>
                      <Button
                        className="mat-btn mat-next"
                        variant="contained"
                        onClick={() => {
                          setTab(next);
                          changeDatadup(next);
                        }}
                      >
                        {next}
                      </Button>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="Tennis Expenses">
                    <div className="table-sections">
                      {/* <div className="col-md-12 pd-0"> */}
                      <YearTable
                      bossid={bossid}
                      year={ye}
                      tabsname={tabs}
                        header={TennisExpenses}
                        tabs={true}
                        data={data}
                        editYearData={editYearData}
                        editYearDatapercentage={editYearDatapercentage}
                        nestedColumn={false}
                        isFooter={true}
                        selectDepartment={selectDepartment}
                        Search={false}
                        pagination={false}
                        Edit={Edit}
                        menuId={1}
                      />
                    </div>

                    <div className="col-md-12 pd-0 rig-alg">
                      {prev ? (
                        <Button
                          className="mat-btn mat-next fl-lf"
                          variant="contained"
                          onClick={() => {
                            setTab(prev);
                            changeDatadup(prev);
                          }}
                        >
                          {prev}
                        </Button>
                      ) : null}
                      <Button
                        className="mat-btn mat-save"
                        variant="contained"
                        onClick={() => saveYearData(next)}
                      >
                        Save
                      </Button>
                      <Button
                        className="mat-btn mat-next"
                        variant="contained"
                        onClick={() => {
                          setTab(next);
                          changeDatadup(next);
                        }}
                      >
                        Cost Of Goods
                      </Button>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="Turf CareExpenses">
                    <div className="table-sections">
                      <YearTable
                      bossid={bossid}
                      year={ye}
                      tabsname={tabs}
                        header={TurfCareExpenses}
                        data={data}
                        nestedColumn={false}
                        isFooter={true}
                        selectDepartment={selectDepartment}
                        Search={false}
                        pagination={false}
                        Edit={Edit}
                        menuId={1}
                        tabs={true}
                        editYearData={editYearData}
                        editYearDatapercentage={editYearDatapercentage}
                      />
                    </div>
                    <div className="col-md-12 pd-0 rig-alg">
                      {prev ? (
                        <Button
                          className="mat-btn mat-next fl-lf"
                          variant="contained"
                          onClick={() => {
                            setTab(prev);
                            changeDatadup(prev);
                          }}
                        >
                          {prev}
                        </Button>
                      ) : null}
                      <Button
                        className="mat-btn mat-save"
                        variant="contained"
                        onClick={() => saveYearData(next)}
                      >
                        Save
                      </Button>
                      <Button
                        className="mat-btn mat-next"
                        variant="contained"
                        onClick={() => {
                          setTab(next);
                          changeDatadup(next);
                        }}
                      >
                        {next}
                      </Button>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="Cost Of Goods">
                    <div className="table-sections">
                      <YearTable
                      bossid={bossid}
                      year={ye}
                        tabs={true}
                        tabsname={tabs}
                        selectDepartment={selectDepartment}
                        Edit={Edit}
                        menuId={2}
                        editYearData={editYearData}
                        editYearDatapercentage={editYearDatapercentage}
                        header={CostOfGoods}
                        data={data}
                        nestedColumn={false}
                        isFooter={true}
                        Search={false}
                        pagination={false}
                      />
                    </div>
                    <div className="col-md-12 pd-0 rig-alg">
                      <Button
                        className="mat-btn mat-next fl-lf"
                        variant="contained"
                        onClick={() => {
                          setTab(prev);
                          changeDatadup(prev);
                        }}
                      >
                        {prev}
                      </Button>
                      <Button
                        className="mat-btn mat-save"
                        variant="contained"
                        onClick={() => saveYearData(next)}
                      >
                        Save
                      </Button>
                      <Button
                        className="mat-btn mat-next"
                        variant="contained"
                        onClick={() => {
                          setTab(next);
                          changeDatadup(next);
                        }}
                      >
                        {next}
                      </Button>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="Labor">
                    <div className="table-sections">
                      <YearTable
                      bossid={bossid}
                      year={ye}
                        tabs={true}
                        tabsname={tabs}
                        Edit={Edit}
                        selectDepartment={selectDepartment}
                        menuId={3}
                        editYearData={editYearData}
                        editYearDatapercentage={editYearDatapercentage}
                        header={Labor}
                        data={data}
                        nestedColumn={false}
                        isFooter={true}
                        Search={false}
                        pagination={false}
                      />
                    </div>
                    <div className="col-md-12 pd-0 rig-alg">
                      <Button
                        className="mat-btn mat-next fl-lf"
                        variant="contained"
                        onClick={() => {
                          setTab(prev);
                          changeDatadup(prev);
                        }}
                      >
                        {prev}
                      </Button>
                      <Button
                        className="mat-btn mat-save"
                        variant="contained"
                        onClick={() => saveYearData(next)}
                      >
                        Save
                      </Button>
                      <Button
                        className="mat-btn mat-next"
                        variant="contained"
                        onClick={() => {
                          setTab(next);
                          changeDatadup(next);
                        }}
                      >
                        {next}
                      </Button>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="Controllable">
                    <div className="table-sections">
                      <YearTable
                      bossid={bossid}
                      year={ye}
                        tabs={true}
                        tabsname={tabs}
                        menuId={4}
                        selectDepartment={selectDepartment}
                        Edit={Edit}
                        editYearData={editYearData}
                        editYearDatapercentage={editYearDatapercentage}
                        header={Controllable}
                        data={data}
                        nestedColumn={false}
                        isFooter={true}
                        Search={false}
                        pagination={false}
                      />
                    </div>
                    <div className="col-md-12 pd-0 rig-alg">
                      <Button
                        className="mat-btn mat-next fl-lf"
                        variant="contained"
                        onClick={() => {
                          setTab(prev);
                          changeDatadup(prev);
                        }}
                      >
                        {prev}
                      </Button>
                      <Button
                        className="mat-btn mat-save"
                        variant="contained"
                        onClick={() => saveYearData(next)}
                      >
                        Save
                      </Button>
                      <Button
                        className="mat-btn mat-next"
                        variant="contained"
                        onClick={() => {
                          setTab(next);
                          changeDatadup(next);
                        }}
                      >
                        {next}
                      </Button>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="Non Controllable">
                    <div className="table-sections">
                      <YearTable
                      bossid={bossid}
                      year={ye}
                        tabs={true}
                        tabsname={tabs}
                        Edit={Edit}
                        selectDepartment={selectDepartment}
                        menuId={5}
                        editYearData={editYearData}
                        editYearDatapercentage={editYearDatapercentage}
                        header={NonControllable}
                        data={data}
                        nestedColumn={false}
                        isFooter={true}
                        Search={false}
                        pagination={false}
                      />
                    </div>
                    <div className="col-md-12 pd-0 rig-alg">
                      <Button
                        className="mat-btn mat-next fl-lf"
                        variant="contained"
                        onClick={() => {
                          setTab(prev);
                          changeDatadup(prev);
                        }}
                      >
                        {prev}
                      </Button>
                      <Button
                        className="mat-btn mat-save"
                        variant="contained"
                        onClick={() => saveYearData(next)}
                      >
                        Save
                      </Button>
                      <Button
                        className="mat-btn mat-next"
                        variant="contained"
                        onClick={() => {
                          setTab(next);
                          changeDatadup(next);
                        }}
                      >
                        {next}
                      </Button>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="Profit/Loss">
                    <div className="table-sections">
                      <YearTable
                      bossid={bossid}
                      year={ye}
                      tabsname={tabs}
                        tabs={false}
                        Edit={Edit}
                        selectDepartment={selectDepartment}
                        header={ProfitLoss}
                        data={data}
                        nestedColumn={false}
                        isFooter={true}
                        Search={false}
                        pagination={false}
                      />
                    </div>
                    <div className="col-md-12 pd-0 rig-alg">
                      <Button
                        className="mat-btn mat-next fl-lf"
                        variant="contained"
                        onClick={() => {
                          setTab(prev);
                          changeDatadup(prev);
                        }}
                      >
                        {prev}
                      </Button>
                      <Button className="mat-btn mat-save" variant="contained">
                        Save
                      </Button>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
              </> : 
              
              <>
              <div className="col-xs-12 text-center">
      <div className="col-xs-12 ">
        <figure className="info-img">
        <img src="https://d2a7u30t6wbkim.cloudfront.net/assets/img/budget-flat.png" alt="Budget Image" style={{width:"64px"}} />
         
        </figure>
      </div>
      <div className="col-xs-12  info-title">
        <h5 className="" style={{fontWeight:"900"}}>No Budget available..</h5>
        <div className="col-xs-12">
          <p className="awesome">
            <span className="info-span1" style={{fontSize:"13px",fontStyle:"italic",fontWeight:"bold",color:"#1C9F6F"}}>"Build your budget to improve your Restaurant"</span>
            
          </p>
        </div>
      </div>
      <p className="res-tag"  style={{background:"#d0d8d8",padding:"7px 12px",borderRadius:"7px",color:"#000",letterSpacing:"1px",fontSize:"12px",display:"inline-block"}}>
        <a href="#/services/budget" onClick={() => {}} style={{color:"#2196f3"}}>
        Click
        </a>{' '}
         here to budget!
      </p>
    </div>
              
              </>
              
              
              }
            </Row>
          </Tab.Container>
        </div>

        <div className="col-md-12">
          <hr
            style={{
              border: "1px solid #c5c4c4",
              marginTop: "23px",
              marginBottom: "23px",
            }}
          />
        </div>
        {isLoading  ? (
          <Spinner />
        ) : (
          <div className="col-md-12 pd-0">
            <Button
              className="mat-btn mat-save review-btn"
              variant="contained"
              onClick={() => {
                setIsMonthView(!isMonthView);
                setMonthNavi(!isMonthView);
              }}
            >
              {monthData.label}
            </Button>
          </div>
        )}
        <div className="col-md-12 pd-0">
          {isMonthView && (
            <>
              <MonthlyViewTable
                menuId={tabs}
                selectDepartment={selectDepartment}
                editMonthData={editMonthData}
                editMonthDatapercentage={editMonthDatapercentage}
                monthIndex={0}
                editMonthTotal={editMonthTotal}
                data={monthData.data.slice(0, 6)}
                title={monthData.title}
                isBudget={true}
                error={error}
                showtotal={showtotal}
              />
              <MonthlyViewTable
                menuId={tabs}
                selectDepartment={selectDepartment}
                editMonthData={editMonthData}
                editMonthDatapercentage={editMonthDatapercentage}
                editMonthTotal={editMonthTotal}
                monthIndex={6}
                data={monthData.data.slice(6, 12)}
                title={monthData.title}
                isBudget={true}
                error={error}
                showtotal={showtotal}
              />
              <div className="col-md-12 pd-0 rig-alg">
                {(tabs === "Revenue" ||
                  tabs === "Turf CareExpenses" ||
                  tabs === "Admin Expenses" ||
                  tabs === "Turf & Maintenance" ||
                  tabs === "Fitness Expenses" ||
                  tabs === "Tennis Expenses") && (
                  <>
                    {prev ? (
                      <Button
                        className="mat-btn mat-next fl-lf"
                        variant="contained"
                        onClick={() => {
                          setTab(prev);
                          changeDatadup(prev);
                        }}
                      >
                        {prev}
                      </Button>
                    ) : null}
                    <Button
                      className="mat-btn mat-save"
                      variant="contained"
                      onClick={() => saveMonthData(next)}
                    >
                      Save
                    </Button>
                    <Button
                      className="mat-btn mat-next"
                      variant="contained"
                      onClick={() => {
                        setTab(next);
                        changeDatadup(next);
                        setIsMonthView(true);
                      }}
                    >
                      {next}
                    </Button>
                  </>
                )}

                {tabs === "Cost Of Goods" && (
                  <>
                    <Button
                      className="mat-btn mat-next fl-lf"
                      variant="contained"
                      onClick={() => {
                        setTab(prev);
                        changeDatadup(prev);
                      }}
                    >
                      {prev}
                    </Button>
                    <Button
                      className="mat-btn mat-save"
                      variant="contained"
                      onClick={() => saveMonthData(next)}
                    >
                      Save
                    </Button>
                    <Button
                      className="mat-btn mat-next"
                      variant="contained"
                      onClick={() => {
                        setTab(next);
                        changeDatadup(next);
                      }}
                    >
                      {next}
                    </Button>
                  </>
                )}

                {tabs === "Labor" && (
                  <>
                    <Button
                      className="mat-btn mat-next fl-lf"
                      variant="contained"
                      onClick={() => {
                        setTab(prev);
                        changeDatadup(prev);
                      }}
                    >
                      {prev}
                    </Button>
                    <Button
                      className="mat-btn mat-save"
                      variant="contained"
                      onClick={() => saveMonthData(next)}
                    >
                      Save
                    </Button>
                    <Button
                      className="mat-btn mat-next"
                      variant="contained"
                      onClick={() => {
                        setTab(next);
                        changeDatadup(next);
                      }}
                    >
                      {next}
                    </Button>
                  </>
                )}

                {tabs === "Controllable" && (
                  <>
                    <Button
                      className="mat-btn mat-next fl-lf"
                      variant="contained"
                      onClick={() => {
                        setTab(prev);
                        changeDatadup(prev);
                      }}
                    >
                      {prev}
                    </Button>
                    <Button
                      className="mat-btn mat-save"
                      variant="contained"
                      onClick={() => saveMonthData(next)}
                    >
                      Save
                    </Button>
                    <Button
                      className="mat-btn mat-next"
                      variant="contained"
                      onClick={() => {
                        setTab(next);
                        changeDatadup(next);
                      }}
                    >
                      {next}
                    </Button>
                  </>
                )}

                {tabs === "Non Controllable" && (
                  <>
                    <Button
                      className="mat-btn mat-next fl-lf"
                      variant="contained"
                      onClick={() => {
                        setTab(prev);
                        changeDatadup(prev);
                      }}
                    >
                      {prev}
                    </Button>
                    <Button
                      className="mat-btn mat-save"
                      variant="contained"
                      onClick={() => saveMonthData(next)}
                    >
                      Save
                    </Button>
                    <Button
                      className="mat-btn mat-next"
                      variant="contained"
                      onClick={() => {
                        setTab(next);
                        changeDatadup(next);
                      }}
                    >
                      {next}
                    </Button>
                  </>
                )}

                {tabs === "Profit/Loss" && (
                  <>
                    <Button
                      className="mat-btn mat-next fl-lf"
                      variant="contained"
                      onClick={() => {
                        setTab(prev);
                        sethideCategoriesandConstant(false);
                        changeDatadup(prev);
                      }}
                    >
                      {prev}
                    </Button>
                    <Button className="mat-btn mat-save" variant="contained">
                      Save
                    </Button>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {/*  */}
      <Modal
        show={showpopup}
        size="large"
        onHide={handleClosepopup}
        style={{ paddingTop: "10em" }}
      >
        <Modal.Header style={{ border: "none" }}>
          <Modal.Title
            style={{
              textAlign: "center",
              color: "white",
              background: "#19A73F",
              padding: "0.5em",
            }}
          >
            Recurring Constant Expenses{" "}
            <i
              class="fa fa-times"
              aria-hidden="true"
              style={{
                cursor: "pointer",
                color: "white",
                fontSize: "1em",
                float: "right",
                marginTop: "3px",
              }}
              onClick={handleClosepopup}
            ></i>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            textAlign: "center",
            fontSize: "1.01em",
            padding: "0.3em 1em",
          }}
        >
        
              <div class="row">
                <div class="col-md-4 col-xs-5" style={{ top: "5px" }}>
                  <p>Enter New Category </p>
                </div>
                <div
                  class="col-md-8 col-xs-7"
                  style={{ textAlign: "left", paddingLeft: "0em" }}
                >
                  <div class="row">
                    <div class="col-md-12 col-xs-12">
                      <input
                        type="text"
                        name="Details"
                        style={{ width: "70%" }}
                        className="form-control"
                        value={adCategory}
                        onChange={(e) => {
                          handleAdCategory(e.target.value);
                        }}
                      />
                    </div>
                    <div
                      class="col-md-12 col-xs-12"
                      style={{ padding: "0em", marginTop: "1em" }}
                    >
                      <div
                        class="col-md-4 col-xs-6"
                        style={{ paddingLeft: "0.5em" }}
                      >
                        <div class="radio-item1">
                          <input
                            type="radio"
                            id="ritema"
                            name="ritem"
                            checked={checbox1}
                            value={"1"}
                            onChange={(e) => {
                              setCheckbox1(e.target.checked);
                            }}
                          />
                          <label for="ritema">Controllable</label>
                        </div>
                      </div>
                      <div
                        class="col-md-8 col-xs-6"
                        style={{ textAlign: "left", padding: "0em" }}
                      >
                        <div class="radio-item1">
                          <input
                            type="radio"
                            id="ritemb"
                            name="ritem"
                            checked={checbox2}
                            value={"2"}
                            onChange={(e) => {
                              setCheckbox2(e.target.checked);
                            }}
                          />
                          <label for="ritemb">Non Controllable</label>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-md-12 col-xs-12"
                      style={{ paddingLeft: "2em", marginTop: "1em" }}
                    >
                      <button
                      disabled={bossalldepart === 1 ? true : false}
                        className="btn btn-primary"
                        style={{ marginRight: "1em" }}
                        onClick={handleCat_Click}
                      >
                        Add{" "}
                        <i
                          class="fa fa-plus"
                          aria-hidden="true"
                          style={{ marginLeft: "0.3em", fontSize: "1em" }}
                        ></i>
                      </button>
                      <button
                        className="btn btn-mute"
                        onClick={handleCancelAdCat}
                      >
                        Cancel{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
          
         

          <div class="row">
            <div class="col-md-12 col-xs-12">
              <div
                className="table-responsive table-scroll-budget"
                style={{ maxHeight: "400px", marginTop: "2em" }}
              >
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th className="scrollview">
                        <div className="tab-card-new-budgetpopup">
                          Controllable
                        </div>
                      </th>
                      <th>
                        <div className="tab-card-new-budgetpopup">
                          Budget Amount
                        </div>
                      </th>
                      <th>
                        <div className="tab-card-new-budgetpopup">Per(%)</div>
                      </th>
                      {/* <th><div className="tab-card-new-budgetpopup" style={{backgroundColor:"white"}}/></th> */}
                    </tr>
                  </thead>
                  <tbody class="tab-gf">
                    {constantRecurring?.map((x, index) => {
                      return (
                        <>
                          {x.Cat_id == 0 ? (
                            <tr>
                              <td>{x.Cat_name}</td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          ) : null}
                          {x.Cat_id !== 0 &&
                          x.Cat_amt === "" &&
                          (x.Cat_id == 67 || x.Cat_id == 68)  ? (
                            <tr>
                              <td className="tab-card-new-budgetpopup-val-1">
                                {" "}
                                {/* <div class="radio-item1"> */}
                                  <input
                                    type="radio"
                                   style={{ marginRight:"0.5em", cursor: "pointer"}}
                                    id={x.Cat_id}
                                     checked={x.Cat_per !== 0}
                                    name={x.Cat_id}
                                    onClick={(e) => {
                                      handleTaxCheckbox(
                                        e.target.checked,
                                        index,
                                        x
                                      );
                                    }}
                                  />
                                  <label for={x.Cat_id}> {x.Cat_name}</label>
                                  {/* </div> */}
                              </td>
                              <td></td>
                              <td>
                                <p class="fl-r">
                                  <input
                                    class={
                                      bossalldepart===0
                                        ?
                                         "input-edit"
                                        : "non-edit-css"
                                    }
                                    name="1"
                                    id="1"
                                    type="text"
                                    value={x.Cat_per || 0}
                                    onChange={(e) => {
                                      // if (
                                      //   (splUser == 0 || splUser == 1) &&
                                      //   Acc_type != 0
                                      // ) {
                                        handletaxper(e.target.value, x, index);
                                     // }
                                    }}
                                    fdprocessedid="6pqots"
                                  />
                                  <span>%</span>
                                </p>
                              </td>
                              <td>
                                {x.Cat_id != 0 &&
                                x.Cat_id != 152 &&
                                x.Cat_id != 151 &&
                                x.Cat_id != 68 &&
                                x.Cat_id != 67 &&
                                x.Cat_id != 66 
                                // (splUser == 0 || splUser == 1) &&
                                // Acc_type != 0
                                 ? (
                                  <i
                                   disabled={bossalldepart === 1 ? true : false}
                                    class="fa fa-trash"
                                    aria-hidden="true"
                                    style={{
                                      color: "red",
                                      fontSize: "1.2em",
                                      padding: "1em",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      if(bossalldepart!==1){
                                        setdeleteItemvalue(x);
                                        setShowdeletepopup(true);
                                      }
                                    }}
                                  ></i>
                                ) : null}
                              </td>
                            </tr>
                          ) : null}

                          {x.Cat_id !== 0 &&
                          x.Cat_amt === "" &&
                          x.Cat_id !== 67 &&
                          x.Cat_id !== 68 ? (
                            <tr>
                              <td className="tab-card-new-budgetpopup-val-1">
                                {" "}
                                {x.Cat_name}
                              </td>
                              <td></td>
                              <td>
                                <p class="fl-r">
                                  <input
                                  class={
                                    bossalldepart===0
                                      ?
                                       "input-edit"
                                      : "non-edit-css"
                                  }
                                    name="1"
                                    id="1"
                                    type="text"
                                    value={x.Cat_per || 0}
                                    onChange={(e) => {
                                      // if (
                                      //   (splUser == 0 || splUser == 1) &&
                                      //   Acc_type != 0
                                      // ) {
                                        handletaxper(e.target.value, x, index);
                                     // }
                                    }}
                                    fdprocessedid="6pqots"
                                  />
                                  <span>%</span>
                                </p>
                              </td>
                              <td>
                                {x.Cat_id != 0 &&
                                x.Cat_id != 152 &&
                                x.Cat_id != 151 &&
                                x.Cat_id != 68 &&
                                x.Cat_id != 67 &&
                                x.Cat_id != 66 && ( items.Member_Type=="4"|| ((splUser == 0 || splUser == 2) && Acc_type == 0))
                                //&&
                                // (splUser == 0 || splUser == 1) &&
                                // Acc_type != 0 
                                ? (
                                  <i
                                  disabled={bossalldepart === 1 ? true : false}
                                    class="fa fa-trash"
                                    aria-hidden="true"
                                    style={{
                                      color: "red",
                                      fontSize: "1.2em",
                                      padding: "1em",
                                      cursor: "pointer",
                                    }}
                                       
                                    onClick={() => {
                                      if(bossalldepart!==1){
                                        setdeleteItemvalue(x);
                                        setShowdeletepopup(true);
                                      }
                                    }}
                                  ></i>
                                ) : null}
                              </td>
                            </tr>
                          ) : null}

                          {x.Cat_id !== 0 &&
                          x.Cat_amt !== "" &&
                          x.Cat_per !== "" 
                          ? (
                            <tr>
                              <td className="tab-card-new-budgetpopup-val-1">
                                {" "}
                                {x.Cat_name}
                              </td>
                              <td>
                                {" "}
                                <span>$</span>
                                <p class="fl-r">
                                  <input
                                  class={
                                    bossalldepart===0
                                      ?
                                       "input-edit"
                                      : "non-edit-css"
                                  }
                                    name="1"
                                    id="1"
                                    type="text"
                                    value={x.Cat_amt ||0}
                                    onChange={(e) => {
                                      if (
                                        // (splUser == 0 || splUser == 1) &&
                                        // Acc_type != 0
                                        true
                                      ) {
                                        handleChangeValue(
                                          e.target.value,
                                          x,
                                          index
                                        );
                                      }
                                    }}
                                  />
                                </p>
                              </td>
                              <td>
                                <p class="fl-r">
                                  <input
                                  class={
                                    bossalldepart===0
                                      ?
                                       "input-edit"
                                      : "non-edit-css"
                                  }
                                    name="1"
                                    id="1"
                                    type="text"
                                    value={x.Cat_per ||0} 
                                    onChange={(e) => {
                                      if (
                                        // (splUser == 0 || splUser == 1) &&
                                        // Acc_type != 0
                                        true
                                      ) {
                                        handleChangePer(
                                          e.target.value,
                                          x,
                                          index
                                        );
                                      }
                                    }}
                                  />
                                  <span>%</span>
                                </p>
                              </td>
                              <td>
                                {x.Cat_id != 0 &&
                                x.Cat_id != 152 &&
                                x.Cat_id != 151 &&
                                x.Cat_id != 68 &&
                                x.Cat_id != 67 &&
                                x.Cat_id != 66 
                            
                                 ? (
                                  <i
                                  disabled={bossalldepart === 1 ? true : false}
                                    class="fa fa-trash"
                                    aria-hidden="true"
                                    style={{
                                      color: "red",
                                      fontSize: "1.2em",
                                      padding: "1em",
                                      cursor: "pointer",
                                    }}
                                   
                                    onClick={() => {
                                      if(bossalldepart!==1){
                                        setdeleteItemvalue(x);
                                        setShowdeletepopup(true);
                                      }
                                 
                                    }}
                                  ></i>
                                ) : null}
                              </td>
                            </tr>
                          ) : null}
                        </>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{ textAlign: "center", marginRight: "0em", border: "none" }}
        >
          <button
          disabled={bossalldepart === 1 ? true : false}
            className="btn btn-success"
            onClick={(e)=>{
              if(
              //   (splUser == 0 || splUser == 1) &&
              // Acc_type != 0
              true)
              {
                if( (splUser == 0 || splUser == 1) && Acc_type != 0){
                  handleSaveRecurring(e,0)
                }
                else{
                  handleSaveRecurring(e,1)
                }
              
              }
              
             }}
            style={{ marginRight: "0.5em" }}
          >
            Save <i class="fa fa-thumbs-o-up" aria-hidden="true"></i>
          </button>
          <button className="btn btn-mute" onClick={handleClosepopup}>
            Cancel{" "}
          </button>
        </Modal.Footer>
      </Modal>
      {/* Delete Popup */}
      <Modal show={showdeletepopup} size="sm" onHide={handleClosedeletepopup}>
        <Modal.Header style={{ border: "none" }}>
          <Modal.Title
            style={{
              textAlign: "center",
              background: "rgb(236 173 27)",
              padding: "0.5em",
            }}
          >
            <BsExclamationTriangle
              style={{ marginRight: "0.2em", top: "2px" }}
            />{" "}
            Warning
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            textAlign: "center",
            fontSize: "1.01em",
            padding: "0.3em 1em",
          }}
        >
          {" "}
          Are you sure you want to delete?
        </Modal.Body>
        <Modal.Footer
          style={{ textAlign: "center", marginRight: "0em", border: "none" }}
        >
          <button
            className="btn btn-danger"
            onClick={handleClosedeletepopup}
            style={{ marginRight: "0.5em" }}
          >
            {" "}
            CANCEL{" "}
          </button>
          <button
            className="btn btn-success"
            onClick={handleClosedeletepopupConfirm}
          >
            {" "}
            CONFIRM{" "}
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={deletecat} size="sm" onHide={handleclosedeletecat}>
        <Modal.Header style={{ border: "none" }}>
          <Modal.Title
            style={{
              textAlign: "center",
              background: "rgb(236 173 27)",
              padding: "0.5em",
            }}
          >
            <BsExclamationTriangle
              style={{ marginRight: "0.2em", top: "2px" }}
            />{" "}
            Warning
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            textAlign: "center",
            fontSize: "1.01em",
            padding: "0.3em 1em",
          }}
        >
          {" "}
          Are you sure you want to delete the category?
        </Modal.Body>
        <Modal.Footer
          style={{ textAlign: "center", marginRight: "0em", border: "none" }}
        >
          <button
            className="btn btn-danger"
            onClick={handleclosedeletecat}
            style={{ marginRight: "0.5em" }}
          >
            {" "}
            CANCEL{" "}
          </button>
          <button className="btn btn-success" onClick={handleDeletecat}>
            {" "}
            CONFIRM{" "}
          </button>
        </Modal.Footer>
      </Modal>

      <div
        class="modal fade"
        id="changemodal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div
            class="modal-content"
            style={{
              backgroundColor: "#fefefe",
              margin: "40% auto",
              padding: "0px",
              border: "1px solid #888",
            }}
          >
            <div class="modal-header">
              <h4
                class="modal-title"
                id="exampleModalLabel"
                style={{ color: "#fff" }}
              >
                REVENUE
              </h4>
              <button
                type="button"
                class="close modaloneclose"
                data-dismiss="modal"
                aria-label="Close"
              >
                &times;
              </button>
            </div>

            <div class="modal-body">
              <div>
                <h2
                  style={{
                    textAlign: "center",
                    color: "#19a73f",
                    fontSize: "18px",
                    marginTop: "0px",
                  }}
                >
                  Drag and Drop Categories
                </h2>
                <ul>
                  {list.map((item, index) => (
                    <li
                      key={item.Cat_id}
                      draggable={true}
                      onDragStart={(e) => onDragStart(e, item.Cat_id)}
                      onDrop={(e) => onDrop(e, index)}
                      onDragOver={(e) => onDragOver(e)}
                    >
                      {item.Cat_name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="submit"
                class="btn btn-success"
                data-dismiss="modal"
                style={{ backgroundColor: "#19a73f", marginRight: "15px" }}
                onClick={handleSaveOrders}
              >
                SAVE ORDER
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Tab1;
