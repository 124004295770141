import "bootstrap/dist/css/bootstrap.min.css";

import "../Tab_manageuser/Tab_user.css";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Pagination from "react-bootstrap/Pagination";
import TableComponent1 from "../Component/Tables/TableComponent1";
import { useState, useEffect } from "react";
import axios from "axios";
import { fobeAxios, mainAxios } from "../middleware/interceptor";
import { Spinner } from "../Component/Spinner";

const sample = [
  {
    firstName: "Nagaraj",
    lastName: "Vanaraj",
    email: "nagaraj@jetzerp.com",
    password: "river",
    course: "cse",
    department: "it",
    Role: "Team Member",
    Status: "1",
    FobesoftStatus: "Not Applicable",
  },
];
const column = [
  { column: "First Name" },
  { column: "Last Name" },
  { column: "Email" },
  { column: "Password" },
  { column: "Course" },
  { column: "Department" },
  { column: "Role" },
  { column: "Status" },

];

function InvoiceDashboard({ tab, setTab, setEditUser, setDeleteUser }) {
  const items = JSON.parse(localStorage.getItem("UserClaims"));
  const [bossid, setbossid] = useState(items.Restaurant_id);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const[userupdated,setUserUpdated]=useState(false);
  const [duplicate, setduplicate] = useState([]);
  //HANDLE DROPDOWN CHANGE

  const [dropdown, setdropdown] = useState("");

  const [fromdate, setfromdate] = useState(() => {
    return new Date(new Date().setDate(new Date().getDate() - 7))
      .toISOString()
      .split("T")[0];
  });
  const [todate, settodate] = useState(() => {
    return new Date().toISOString().split("T")[0];
  });
  function handleDateChange(event) {
    if (event.target.name === "fromdate") {
      setfromdate(event.target.value);
    } else if (event.target.name === "todate") {
      settodate(event.target.value);
    }
  }
  const getData = async () => {
    setIsLoading(true);
    let result = [];
    const { data } = await fobeAxios.get("api/Budget/Getinvoice_updated_details?data="+fromdate+','+todate+'&Rest_id='+bossid);
    if(data){
      setIsLoading(false);
    }
   // const { data } = await fobeAxios.get("api/Budget/Getinvoice_updated_details?data="+fromdate+','+todate+'&Rest_id=3580');
    console.log("UserList", data.Invoice_Details);

     result = data.Invoice_Details.map((item) => ({
      Amount: item.rest_details.Amount,
      CreatedDate: item.rest_details.CreatedDate,
      Inv_num: item.rest_details.Inv_num,
      Purveyor_name: item.rest_details.Purveyor_name,
      Rest_id: item.rest_details.Rest_id,
      UpdatedDate: item.rest_details.UpdatedDate,
      Upload_id: item.rest_details.Upload_id,
      
      file_status: item.rest_details.file_status,
      filename: item.rest_details.filename,
      invoice_from: item.rest_details.invoice_from,
      invoice_status: item.rest_details.invoice_status,
      rawdata : item.rest_details.rawdata,
    })).reverse();
   
    setData(result);
    setSearch(result);
    setduplicate(result)
    
    // console.log(data.getResultList);
  };
  const handleDropdownChange = (event) => {
   
    let filter=[];
    setdropdown(event.target.value);
    if(event.target.value==="5"){
      filter = duplicate.filter( (item) => item.invoice_status == "5" || item.invoice_status == "1" || item.invoice_status == "6"); 
console.log(filter,"filter");
    }
  
  else  if(event.target.value=="2"){
       filter = duplicate.filter(
        (item) => item.invoice_status == event.target.value
      );
     
       }
 else   if(event.target.value=="3"){
      filter = duplicate.filter(
        (item) => item.invoice_status == event.target.value
      );

    }
    else if(event.target.value=="0"){
      filter = duplicate.filter(
        (item) => item.invoice_status != event.target.value
      );
   
    }
    setData(filter);
    setSearch(filter);
    console.log(filter,"filter");
  };
  useEffect(() => {
    setSearchText("");
    getData();
   
  }, []);
  
  useEffect(()=>{
    getData();
    if(searchText!==""){
      searchFunction(searchText)
    }
  },[userupdated])
useEffect(()=>{
console.log(data,"swathi")
},[data])
  const searchFunction = (searchText) => {
    setSearchText(searchText);
    console.log(searchText);
 if (searchText != "" && searchText != null) {
      const user = data.filter((record) => {
        const values = Object.values(record).join("").toLowerCase();
        return values.includes(searchText.toLowerCase());
      });
    
        if (user.length > 0) {
      setSearch(user);
      console.log(user, "Users");
    } else {
      setSearch([]);
    }
  }
  }

  const getPage = () => {};

  const editUser = (user) => {
    console.log("user:", user);
    // setEditUser(user);

  };

  return (
    <div class="table-sections">
      {isLoading ? (
        <Spinner />
      ) : (
        <TableComponent1
          
          data={{ list: search }}
          nestedColumn={false} 
          Search={true}
          searchFunction={searchFunction}
          searchstate={searchText}
          pagination={true}
          fromdate={fromdate}
          todate={todate}
          handleDateChange={handleDateChange}
          loader={setIsLoading}
          getData={getData}
          setdropdown={setdropdown}
          dropdown={dropdown}
          handleDropdownChange={handleDropdownChange}
         
        />
      )}
    </div>
  );
}
export default InvoiceDashboard;
