import { useState, useEffect, useRef } from "react";
import Pagination from "@mui/material/Pagination";



const AddCategoryPagination = ({
    data,
    nestedColumn,
    pagination,
    handlePageChange,
    currentPage,
    postsPerPage,

}) => {


  const [show, setShow] = useState(true);
  return (
    <>
      {data.length > 0 ? (
        <div>
          {show && (
            <div>        
              <div style={{ marginLeft: "-800px"}}>
                {pagination && (
                  <Pagination
                    count={Math.ceil(data.length / postsPerPage)}
                     className={"paginate"}
                    onChange={handlePageChange}
                  ></Pagination>
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
              <div>
                <span
                  style={{
                    fontWeight: "bolder",
                    marginLeft: "50px",
                    fontSize: "15px",
                    opacity: "0.6",
                    fontFamily: "FontAwesome",
                  }}
                >
                  No Data Found
                </span>
              </div>
            </div>   
      )}
    
    </>
  );
};

export default AddCategoryPagination;
