import "bootstrap/dist/css/bootstrap.min.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./Tabcontend.css";
import Dropdown from "react-bootstrap/Dropdown";
import DatesPaginator from "../Component/Datepagenator";
import Tablespanrow from "../Component/Tables/Tablespanrow";
import TableInvoice from "../Component/Tables/TableInvoice";
import LinearProgress from '@mui/material/LinearProgress';
import numeral from 'numeral';
import Nav from "react-bootstrap/Nav";

import Form from "react-bootstrap/Form";
import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Button from "@mui/material/Button";
import { fobeAxios, mainAxios } from "../middleware/interceptor";
import ReactLoading from "react-loading";
import { Spinner } from "../Component/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableInvoice_report from "../Component/Tables/TableInvoice_report";
import Modal from "react-bootstrap/Modal";
import { BsExclamationTriangle } from "react-icons/bs";
import { TabPane } from "react-bootstrap";
import { set } from "react-hook-form";
import { CodeSharp } from "@material-ui/icons";



const header = [{ column: "DAILY" }, { column: "MTD" }, { column: "BUDGET" }];

let newDate = new Date();
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
const revenue = {
  list: [
    {
      Name: "Daily",
      List: [],
      Total: 0,
      per: 0,
    },
    {
      Name: "MDT",
      List: [],
      Total: 0,
      per: 0,
    },
    {
      Name: "Budget",
      List: [],
      Total: 0,
      per: 0,
    },
  ],
  Date: 11,
  Month: 9,
  Year: 2022,
};

const DailySales = ({
  bossid,
  setcheckingForNoBudget,
  checkingForNoBudget,
  setbossid,
  course_id,
  selectDepartment,
  SetselectDepartment,

  settriggervariable,
  triggervariable,
  Budgetlength,
  setBudgetlength,
  tabss,
  setbudget_type,
  budget_type
}) => {

  //const [activeNoBudget, setActiveNoBudget] = useState(checkingForNoBudget);
  const[countt,setcountt]=useState(0)
  const[hideprogressbar,sethideprogressbar]=useState(true)
  const [id, setid] = useState(0);
  const [show, setShow] = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const [showCourse, setShowCourse] = useState(false);
  const [selectDay, SetselectDay] = useState("Day 1")
  const [selectMonth, SetselectMonth] = useState("Period 1")
  const [ActivePeriodpage, setActivePeriodpage] = useState("")
  const [ActiveMonthpage, setActiveMonthpage] = useState("")
  const [logoimage, setLogoImage] = useState([]);
  const [myFiles, setMyFiles] = useState([]);
  const [filepath, setFilepath] = useState([]);
  const [filedname, setFileDName] = useState([]);
  const [size, setSize] = useState(0);
  const [showuploadinvoice, setshowuploadinvoice] = useState(false)
  const cFile = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  const compressImage = null; // replace with actual implementation
  let GetLabelURL;
  const userDetail = JSON.parse(localStorage.getItem("UserClaims"));
  const [nodata, setnodata] = useState(false)
  let splUser = userDetail.Special_user;
  let Acc_type = userDetail.Account_Type;

  const [d, setd] = useState(date);
  const [date_1, setdate1] = useState("01");
  const [Month, setMonth] = useState("01");
  { console.log(Month, "diei im month") }
  const [m, setm] = useState(month);
  const [y, sety] = useState(year);

  let gatedate = async () => {
    let defaultperiod, data_3;
    //get default period(period and day)
    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      data_3 = await fobeAxios.get('/Api/Common/getLabel');

      defaultperiod = data_3.data.LabelAndYear[1].Year_List.find(
        (budget) => budget.Year === y
      );
    }
    else {
      data_3 = await mainAxios.get("Api/DailyPeriod/GetPeriodDetails/" + y + '/' + bossid);
      defaultperiod = data_3.data

    }

    if (data_3) {
      if (defaultperiod.budget_period == 0 && defaultperiod.budget_period_day == 0) {
        setActiveMonthpage("01");
        setActivePeriodpage("01");
        setMonth("01");
        setdate1("01");
      }
      else {
        if (defaultperiod.budget_period < 10) {
          setActiveMonthpage("0" + defaultperiod.budget_period);
          setMonth("0" + defaultperiod.budget_period)
        }
        else {
          setActiveMonthpage(defaultperiod.budget_period);
        }
        setMonth(defaultperiod.budget_period)

        if (defaultperiod.budget_period_day < 10) {
          setActivePeriodpage("0" + defaultperiod.budget_period_day);
          setdate1("0" + defaultperiod.budget_period_day)
        }
        else {
          setActivePeriodpage(defaultperiod.budget_period_day);
          setdate1(defaultperiod.budget_period_day)
        }
      }
    }
  }

  useEffect(() => {
    gatedate();
  }, [tabss, bossid, course_id])

  const [tabs, setTab] = useState();




  const [department, setdepartment] = useState();


  const [label_second, setLabel] = useState([]);
  //console.log(date,month,year,"djbwekjf")


  const getData_1 = async () => {
    const localItems = JSON.parse(localStorage.getItem("UserClaims"));
    if (userDetail.Member_Type != "4") {
      course_id = localItems.Restaurant_id;
    }
    mainAxios
      .get("api/Common/getRestaurantList/" + course_id + "/" + y)
      .then((res) => {
        console.log(res.data.Restaurants, "Restaurants");
        setdepartment(res.data.Restaurants);
      });

    let get_daily_sales;
    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      get_daily_sales = `/Api/Common/getLabel`;
    } else {
      get_daily_sales = "Api/Common/getBossLabel/" + bossid + "/" + y;
    }

    await fobeAxios.get(get_daily_sales).then((res) => {
      {
        console.log(
          res.data.LabelAndYear[0].Labelsecond,
          "response label second"
        );
      }
      console.log(
        res.data.LabelAndYear[0]?.Labelsecond[0]?.Label_Name,
        "DataLabel"
      );
      // setbudget_type(res.data.LabelAndYear[1].Year_List[0].budget_type)
      console.log(res.data.LabelAndYear[1].Year_List[0].budget_type, "budget type")
      if (
        res.data.LabelAndYear[0].Labelsecond[0].Label_Name == "Revenue" ||
        res.data.LabelAndYear[0].Labelsecond[0].Label_Name ==
        "Admin Expenses" ||
        res.data.LabelAndYear[0].Labelsecond[0].Label_Name ==
        "Tennis Expenses" ||
        res.data.LabelAndYear[0].Labelsecond[0].Label_Name ==
        "Turf CareExpenses"
      ) {
        setTab(1);
        handleSelect(1);
      } else if (
        res.data.LabelAndYear[0].Labelsecond[0].Label_Name == "Invoice"
      ) {
        setTab(4);
        handleSelect(4);
      } else if (
        res.data.LabelAndYear[0].Labelsecond[0].Label_Name == "Cost Of Goods"
      ) {
        setTab(3);
        handleSelect(3);
      } else if (
        res.data.LabelAndYear[0]?.Labelsecond[0]?.Label_Name == "Labor"
      ) {
        setTab(4);
        handleSelect(4);
      } else if (
        res.data.LabelAndYear[0]?.Labelsecond[0]?.Label_Name == "Controllable"
      ) {
        setTab(5);
        handleSelect(5);
      } else if (
        res.data.LabelAndYear[0]?.Labelsecond[0]?.Label_Name ==
        "Non Controllable"
      ) {
        setTab(6);
        handleSelect(6);
      } else if (
        res.data.LabelAndYear[0]?.Labelsecond[0]?.Label_Name == "PROFIT/LOSS"
      ) {
        setTab(7);
        handleSelect(7);
      }
      let label = Object.entries(res.data.LabelAndYear[0].Labelsecond);

      setLabel(label);
    });
  };

  useEffect(() => {


    getData_1();
    if (
      userDetail.Member_Type == 4 ||
      ((userDetail.Special_user == 0 || userDetail.Special_user == 2) &&
        userDetail.Account_Type == 0)
    ) {
      setShowCourse(true);
    } else {
      setShowCourse(false);
    }
    if
      (((userDetail.Special_user == 0 || userDetail.Special_user == 1) && userDetail.Account_Type != 0)) {
      setshowuploadinvoice(true);
    }
    else {
      setshowuploadinvoice(false);
    }
  }, [d, date_1, Month, bossid, triggervariable, tabss]);

  const [data, setData] = useState(revenue);

  const [DataInvoice, setDataInvoice] = useState([]);
  const [options, setoptions] = useState([]);
  // console.log(data,"testConsole");
  const handleSelect = async (id) => {
    let getrevenueSalesurl,
      GetCogSalesurl,
      GetLabourSalesurl,
      GetControSalesurl,
      GetNoncontroSalesurl,
      GetProLossDailyurl,
      GetInvoice;

    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {



      if (budget_type == 1) {

        setActivePeriodpage(date_1)


        getrevenueSalesurl =
          `/api/DailyPeriod/GetrevenueSales/` + y + `/` + Month + `/` + date_1;
        GetNoncontroSalesurl =
          `/api/DailyPeriod/GetNoncontroSales/` + y + `/` + Month + `/` + date_1;
        GetCogSalesurl = `/api/DailyPeriod/GetCogSales/` + y + `/` + Month + `/` + date_1;
        GetLabourSalesurl = `/api/DailyPeriod/GetLabourSales/` + y + `/` + Month + `/` + date_1;
        GetControSalesurl = `/api/DailyPeriod/GetControSales/` + y + `/` + Month + `/` + date_1;
        GetProLossDailyurl =
          `/api/DailyPeriod/GetProLossDaily/` + y + `/` + Month + `/` + date_1;
        GetInvoice = `/api/DailyPeriod/GetInvoice/` + y + `/` + Month + `/` + date_1;
      }
      else {
        getrevenueSalesurl =
          `/api/Daily/GetrevenueSales/` + y + `/` + m + `/` + d;
        GetNoncontroSalesurl =
          `/api/Daily/GetNoncontroSales/` + y + `/` + m + `/` + d;
        GetCogSalesurl = `/api/Daily/GetCogSales/` + y + `/` + m + `/` + d;
        GetLabourSalesurl = `/api/Daily/GetLabourSales/` + y + `/` + m + `/` + d;
        GetControSalesurl = `/api/Daily/GetControSales/` + y + `/` + m + `/` + d;
        GetProLossDailyurl =
          `/api/Daily/GetProLossDaily/` + y + `/` + m + `/` + d;
        GetInvoice = `/api/Daily/GetInvoice/` + y + `/` + m + `/` + d;
      }
    }
    else {


      if (budget_type == 1) {

        setActivePeriodpage(date_1)
        { console.log("hello", Month, date_1) }
        getrevenueSalesurl =
          `/api/Boss/GetBossRevenueSales/` + y + `/` + Month + `/` + date_1 + "/" + bossid;
        GetCogSalesurl =
          `/api/Boss/GetBossCogSales/` + y + `/` + Month + `/` + date_1 + "/" + bossid;
        GetLabourSalesurl =
          `/api/Boss/GetBossLaborSales/` + y + `/` + Month + `/` + date_1 + "/" + bossid;
        GetControSalesurl =
          `/api/Boss/GetBossControSales/` + y + `/` + Month + `/` + date_1 + "/" + bossid;
        GetNoncontroSalesurl =
          `/api/Boss/GetBossNonControSales/` + y + `/` + Month + `/` + date_1 + "/" + bossid;
        GetProLossDailyurl =
          `/api/Boss/GetBossProfitLossDaily/` +
          y +
          `/` +
          Month +
          `/` +
          date_1 +
          "/" +
          bossid;
        GetInvoice =
          `/api/Boss/GetBossInvoice/` + y + `/` + Month + `/` + date_1 + "/" + bossid;
      }
      else {


        getrevenueSalesurl =
          `/api/Boss/GetBossRevenueSales/` + y + `/` + m + `/` + d + "/" + bossid;
        GetCogSalesurl =
          `/api/Boss/GetBossCogSales/` + y + `/` + m + `/` + d + "/" + bossid;
        GetLabourSalesurl =
          `/api/Boss/GetBossLaborSales/` + y + `/` + m + `/` + d + "/" + bossid;
        GetControSalesurl =
          `/api/Boss/GetBossControSales/` + y + `/` + m + `/` + d + "/" + bossid;
        GetNoncontroSalesurl =
          `/api/Boss/GetBossNonControSales/` +
          y +
          `/` +
          m +
          `/` +
          d +
          "/" +
          bossid;
        GetProLossDailyurl =
          `/api/Boss/GetBossProfitLossDaily/` +
          y +
          `/` +
          m +
          `/` +
          d +
          "/" +
          bossid;
        GetInvoice =
          `/api/Boss/GetBossInvoice/` + y + `/` + m + `/` + d + "/" + bossid;
      }
    }
    if (id == 1) {
      setTab(id);
      setIsLoading(true);
      await fobeAxios.get(getrevenueSalesurl).then((res) => {
        console.log(res.data.Revenue_Dailysales.length, "SwathidAILY");
        if (res.data.Revenue_Dailysales.length > 0) {
          setcheckingForNoBudget(true);
        } else {
          setcheckingForNoBudget(false);
        }

        setData({ list: res.data.Revenue_Dailysales });
      });
      setIsLoading(false);
    } else if (id == 2) {
      setTab(id);
      setIsLoading(true);
      await mainAxios.get(GetInvoice).then((res) => {
        setIsLoading(false);
        if (res.data.Invoice_details.length == 0) {
          setnodata(true)
        }
        else {
          setnodata(false)
        }
        console.log(res.data.Invoice_details, "dAILY");
        {
          console.log(res.data.Invoice_details.length, "response of invoice");
        }
        initReportrows(res.data.Invoice_report);
        setTotalInvoice(res.data.Total);
        const dataValue = res.data.Invoice_details;
        let temp1 = [];
        let temp = [];
        dataValue.map((x, index) => {
          temp1 = [
            ...temp1,
            {
              id: "",
              item_amount: x.item_amount,
              item_details: x.item_details,
              item_purveyor: x.item_purveyor,
              item_name: x.item_name,
            },
          ];
        });
        initRow(temp1);
        if (dataValue != undefined || dataValue != "") {
          dataValue[0].List.map((x, index) => {
            temp = [...temp, { id: index, value: x.Cat_id, label: x.Cat_name }];
          });
        }
        setoptions(temp);
        console.log(options, "options");
        console.log(DataInvoice, dataValue, temp1, options, "DataInvoice");
        console.log("temp1", temp);
      });
      setIsLoading(false);
    } else if (id == 3) {
      setTab(id);
      setIsLoading(true);
      await fobeAxios.get(GetCogSalesurl).then((res) => {
        //console.log(res.data.Cog_Dailysales,"dAILY");
        setData({ list: res.data.Cog_Dailysales });
      });
      setIsLoading(false);
    } else if (id == 4) {
      setTab(id);
      setIsLoading(true);

      await fobeAxios.get(GetLabourSalesurl).then((res) => {
        //console.log(res.data.Labor_Dailysales,"dAILY");
        if (res.data.Labor_Dailysales.length > 0) {
          setcheckingForNoBudget(true);
        } else {
          setcheckingForNoBudget(false);
        }
        setData({ list: res.data.Labor_Dailysales });
      });
      setIsLoading(false);
    } else if (id == 5) {
      setTab(id);
      setIsLoading(true);
      await fobeAxios.get(GetControSalesurl).then((res) => {
        //console.log(res.data.Controllable_Dailysales,"dAILY");
        setData({ list: res.data.Controllable_Dailysales });
      });
      setIsLoading(false);
    } else if (id == 6) {
      setTab(id);
      setIsLoading(true);
      await fobeAxios.get(GetNoncontroSalesurl).then((res) => {
        //console.log(res.data.Nonccontrol_Dailysales,"dAILY");
        setData({ list: res.data.Nonccontrol_Dailysales });
      });
      setIsLoading(false);
    } else if (id == 7) {
      setTab(id);
      setIsLoading(true);
      await fobeAxios.get(GetProLossDailyurl).then((res) => {
        //console.log(res.data.Revenue_Dailysales,"Profit and loss");
        setData({ list: res.data.Revenue_Dailysales });
      });
      setIsLoading(false);
    }
  };

  const [selectDepartment_active, setselectedDepartment_active] =
    useState(null);
  const handleSelectdrp = (eventKey, event) => {
    setcheckingForNoBudget(false)
    setselectedDepartment_active(eventKey);
    console.log(eventKey, "eeeeeee");
    SetselectDepartment(eventKey.split("`")[1]);
    setbossid(eventKey.split("`")[0]);
  };




  const handledatechange = (eventKey, event) => {

    let new_day;
    if (eventKey.split(" ")[1] < 10) {
      new_day = "0" + eventKey.split(" ")[1];
    }
    else {
      new_day = eventKey.split(" ")[1];
    }

    setdate1(new_day)
    setActivePeriodpage(new_day)
    SetselectDay(eventKey);

  };



  const handlemonthchange = (eventKey, event) => {

    if (eventKey < 10) {
      eventKey = "0" + eventKey;
    }

    setMonth(eventKey)

    setActiveMonthpage(eventKey)
    SetselectMonth("Period" + " " + eventKey)

  };


  const editDailySales = (id, value, name) => {
    let type = name == "Daily" ? 0 : 1;

    let temp = data?.list.map((item, index) => {
      if (type == index)
        item.List.map((x) => {
          if (x.Cat_id == id) {
            x.Cat_amt = value.value;
          }
        });
      return item;
    });

    setData({ ...data, list: temp });
  };

  const saveDailyData = async (menuId) => {
    setIsLoading(true);
    let url, msg;
    if (menuId == 1) {
      if (budget_type === 1) {
        url = "Api/DailyPeriod/addRevenueDsr";
        msg = "Revenue Updated Successfully";
      }
      else {
        url = "Api/Daily/addRevenueDsr";
        msg = "Revenue Updated Successfully";
      }
    }
    // else if (menuId == 3) {  
    //   if(budget_type===1){
    //     url = "Api/DailyPeriod/addCogMonthly";
    //     msg = "Cost of Goods Monthly Updated Successfully";}
    //   else{
    //   url = "Api/Daily/addCogMonthly";
    //   msg = "Cost of Goods Monthly Updated Successfully";}
    // }
    // else if (menuId == 3) {  
    //   url = 'Api/Budget/addCogMonthly';
    //   msg = 'Cost of Goods Monthly Added SuccessFully';
    // }
    else if (menuId == 4) {

      if (budget_type === 1) {
        url = "Api/DailyPeriod/addLaberDsr";
        msg = "Labor Updated Successfully";
      }
      else {
        url = "Api/Daily/addLaberDsr";
        msg = "Labor Updated Successfully";
      }

    }

    // else if (menuId == 5) {
    //   if(budget_type===1){
    //     url = "Api/DailyPeriod/addControllablerMonthly";
    //     msg = "Controllable Updated Successfully";
    //   }
    //   else{
    //   url = "Api/Daily/addControllablerMonthly";
    //   msg = "Controllable Updated Successfully";}
    // }
    // else if (menuId == 5) {
    //   url = 'Api/Budget/addControllablerMonthly';
    //   msg = 'Controllable Monthly Added SuccessFully';
    // }
    // else if (menuId == 6) {
    //   if(budget_type===1){
    //        url = "Api/DailyPeriod/addNonControllableMonthly";
    //          msg = "Non Controllable Updated Successfully";
    //     }
    //    else{
    //      url = "Api/Daily/addNonControllableMonthly";
    //      msg = "Non Controllable Updated Successfully";}
    //      }

    // else if (menuId == 6) {
    //   url = 'Api/Budget/addNonControllableMonthly';
    //   msg = 'Non Controllable Monthly Added SuccessFully';
    // }

    let budget;
    if (budget_type === 1) {
      budget = {
        Budget: data.list,
        Budget_details: [
          { budget_year: y },
          { budget_month: Month },
          { budget_date: date_1 },
          { Restaurant_id: userDetail.Restaurant_id },
        ],
      };
    }
    else {
      budget = {
        Budget: data.list,
        Budget_details: [
          { budget_year: y },
          { budget_month: m },
          { budget_date: d },
          { Restaurant_id: userDetail.Restaurant_id },
        ],
      };
    }
    await fobeAxios
      .post(url, budget)
      .then((resp) => {
        toast.success(msg);
        handleSelect(menuId);
      })
      .catch((err) => {
        toast.error("Failed");
        setIsLoading(false);
      });
    setIsLoading(false);
  };
  // invoice
  const [rows, initRow] = useState([]);
  const [Reportrows, initReportrows] = useState([]);
  const [TotalInvoice, setTotalInvoice] = useState();
  const [addRow, setAddRow] = useState(false);
  const [Index, setIndex] = useState([]);
  const [items, setitems] = useState(
    JSON.parse(localStorage.getItem("UserClaims"))
  );
  const [editFormData, setEditFormData] = useState({
    Cat_amt: "",
    Cat_name: "",
    Details: "",
    Purveyor: "",
    Invoice_id: "",
    Cat_id: "",
  });
  const [editContactId, setEditContactId] = useState(null);
  const [showinvoiceupload, setshowinvoiceupload] = useState(false);
  const invoiceuploadhandleCloses = () => {
    setshowinvoiceupload(false);
    setLogoImage([])
    setMyFiles([]);
    setFilepath([]);
    setSize(0);

  }
  const invoiceuploadhandleShows = () => setshowinvoiceupload(true);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [fileSize, setFileSize] = useState(0);

  const [blurpopup, setblurpopup] = useState(false);
  const handleInputChange = async (event) => {
    sethideprogressbar(true)
    setcountt(10)
    const file = event.target.files;
    console.log(file, "file");
    let check;
    for (let i = 0; i < cFile.length; i++) {
      check = false;
      if (file[0].type === cFile[i]) {
       
        const formData = new FormData();
        formData.append("image", file[0], file[0].name);
        if (file[0].size / 1000000 > 1 && ((file[0].type !== 'application/pdf') && (file[0].type !== 'image/png'))) {
          compressImage.compress(file[0]).then(data => {
            setMyFiles([...myFiles, data]);
            setFilepath([...filepath, logoimage]);
            setFileDName([...filedname, data.name]);
            setSize(size + (data.size / 1000000));
          });
        } else {
          setMyFiles([...myFiles, file[0]]);
          setFilepath([...filepath, logoimage]);
          setFileDName([...filedname, file[0].name]);
          setSize(size + (file[0].size / 1000000));
        }
            
   
        await mainAxios
          .post('/api/Daily/predict_blur_invoice', formData)
          .then((data) => {

            console.log("datadata", data);
            if (data.data.prediction === '0') {             
          setblurpopup(true);
            } else if (data.data.prediction == '1') {     
     setcountt(100)       
            } else {
              setMyFiles([]);
              setSize(0);
               toast.warning('Invoice is not uploaded')
            }
          });
        i = cFile.length;
        check = true;
      }
    }
    if (!check) {
      toast.warning('Supported files only accepted');
    }
    console.log('file', myFiles, filepath);

 
  };

const reload=()=>{  
  sethideprogressbar(false)
  if (myFiles.length == 0) {
    setSize(0);
  }
  else {
    setSize(size - (myFiles[myFiles.length - 1].size / 1000000));
  }
setMyFiles(myFiles.slice(0, myFiles.length - 1));
}
  // const handleDrop = (event) => {
  //   event.preventDefault();
  //   const files = event.dataTransfer.files;
  //   // filter files to only include PDF, PNG, JPG file types
  //   const filteredFiles = Array.from(files).filter(file => file.type === 'application/pdf' || file.type === 'image/png' || file.type === 'image/jpeg');
  //   for (let i = 0; i < files.length; i++) {
  //     filteredFiles.push(files[i]);
  //   }
  //   setSelectedFiles(filteredFiles);
  // };
  // const handleDragOver = (event) => {
  //   event.preventDefault();
  // };



  // const uploadFiles = () => { alert("hkjhkj")
  //   // TODO: implement file upload logic here
  //   console.log(selectedFiles);
  // };
  const uploadFiles = async () => {
    setIsLoading(true);
    console.log('vvvvvvvvvvvvv', myFiles, filepath);
    const frmData1 = new FormData();
    frmData1.append('uploadimage', 'c:/temp/abc.png');
    if (id !== '') {
      frmData1.append('uploadid', id);
    } else {
      frmData1.append('uploadid', id);
    }
    for (let i = 0; i < myFiles.length; i++) {
      frmData1.append('invoicedetails', myFiles[i], myFiles[i].name);
    }
    console.log('file uploaded data', frmData1);

    await mainAxios
      .post('/Api/daily/upload_invoiceinfo/', frmData1)
      .then((resp) => {
        setIsLoading(false);
        setMyFiles([]);
        setFilepath([]);
        setSize(0);
        setshowinvoiceupload(false);
        console.log('resp', resp);
        if (resp.data == 1) {

          toast.success('File uploaded successfully');

        }
        else {
          toast.error('File uploaded failed');
        }
      })
  };
  // removeFile(i,size) {
  //   console.log('asdfj;asdlfk',i,size);
  //   this.logoimage='';
  //   if(this.myFiles.length == 1 ){
  //     this.size = 0 ;
  //   }else{
  //     this.size -= size/1000000;  
  //   }
  //   this.myFiles.splice(i,1);
  //   this.filepath.splice(i,1);
  //  }

  const removeFile = (i) => {
    sethideprogressbar(false);
    setLogoImage([])
    if (myFiles.length == 0) {
      setSize(0);
    }
    else {
      setSize(size - (myFiles[i].size / 1000000));
    }
    setMyFiles(myFiles.filter((file, index) => index !== i))
    // let newPaths = this.state.filepath.filter((path, index) => index !== i);
    // let newPaths = this.state.filepath.filter((path, index) => index !== i);
    // setFilepath(filepath.filter((path, index) => index !== i));
    // setFileDName(filedname.filter((name, index) => index !== i));
    // const fileInput = document.querySelector('input[type="file"]');
    // fileInput.value = ''; 
  }
  // Invoice table
  const addRowTable = () => {
    setAddRow(true);
    const data = {
      id: "",
      item_amount: 0,
      item_details: "",
      item_purveyor: "",
      item_name: "",
    };
    console.log("Add data", data, ...rows);
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
    console.log("EditRemove", index);
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);
  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);
  };
  const SaveInvoice = async () => {
    setIsLoading(true);
    let Savedata = [];
    rows.map((x, index) => {
      Savedata = [
        ...Savedata,
        {
          id: "",
          item_amount: x.item_amount,
          item_details: x.item_details,
          item_purveyor: x.item_purveyor,
          item_name: x.item_name.label,
        },
      ];
    });
    {
      console.log("hello 5");
    }
    {
      console.log(Savedata, "Savedata");
    }
    let count = 0;
    let index_1 = [];

    for (let i = 0; i < Savedata.length; i++) {
      if (
        Savedata[i].item_amount !== 0 &&
        typeof Savedata[i].item_amount !== "undefined" &&
        Savedata[i].item_details !== "" &&
        typeof Savedata[i].item_details !== "undefined" &&
        Savedata[i].item_purveyor !== "" &&
        typeof Savedata[i].item_purveyor !== "undefined" &&
        typeof Savedata[i].item_name !== "undefined"
      ) {
        count = 1;
        break;
      }
    }
    for (let i = 0; i < Savedata.length; i++) {
      if (
        (Savedata[i].item_amount !== 0 &&
          typeof Savedata[i].item_amount !== "undefined") ||
        (Savedata[i].item_details !== "" &&
          typeof Savedata[i].item_details !== "undefined") ||
        (Savedata[i].item_purveyor !== "" &&
          typeof Savedata[i].item_purveyor !== "undefined") ||
        typeof Savedata[i].item_name !== "undefined"
      ) {
        if (
          !(
            Savedata[i].item_amount !== 0 &&
            typeof Savedata[i].item_amount !== "undefined" &&
            Savedata[i].item_details !== "" &&
            typeof Savedata[i].item_details !== "undefined" &&
            Savedata[i].item_purveyor !== "" &&
            typeof Savedata[i].item_purveyor !== "undefined" &&
            typeof Savedata[i].item_name !== "undefined"
          )
        ) {
          count = 1;

          index_1.push(i);
        }
      }
    }

    if (count === 0) {
      toast.error("Please fill the data");
      setIsLoading(false);
    } else if (index_1.length !== 0) {
      toast.error("Please fill the entire row");
      setIsLoading(false);
    } else {
      let budget = {
        Budget: Savedata,
        Budget_details: [
          { budget_year: y },
          { budget_month: m },
          { budget_date: d },
        ],
      };
      await fobeAxios
        .post("/Api/DailyPeriod/addInvoiceDsr", budget)
        .then((resp) => {
          console.log("resp", resp.data);
          if (resp.data == 1) {
            toast.success("Invoice Updated Successfully");
            handleSelect(2);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => {
          toast.error("Failed");
          setIsLoading(false);
        });
    }
  };
  // Invoice report
  const handleDeleteClick = (index) => {
    setShow(true);
    setIndex(index);
    console.log("Delete", index);
  };
  const handleEditClick = (event, row) => {
    event.preventDefault();
    setEditContactId(row.Invoice_id);
    const formValues = {
      Cat_amt: row.Cat_amt,
      Cat_name: row.Cat_name,
      Details: row.Details,
      Purveyor: row.Purveyor,
      Invoice_id: row.Invoice_id,
      Cat_id: row.Cat_id,
    };
    setEditFormData(formValues);
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = async (index) => {
    console.log("Index", index);
    setIsLoading(true);
    setShow(false);
    const dataRow = [...rows];
    let budget = {
      Budget: index,
      Budget_details: [
        { budget_year: y },
        { budget_month: m },
        { budget_date: d },
      ],
    };
    await fobeAxios
      .post("/Api/DailyPeriod/deleteInvoice", budget)
      .then((resp) => {
        console.log("resp", resp.data);
        if (resp.data == 1) {
          toast.success("Deleted Successfully");
          dataRow.splice(index, 1);
          handleSelect(2);
          setIsLoading(false);
        } else {
          toast.error("Somethimg wrong");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error("Failed");
        setIsLoading(false);
      });
    initReportrows(dataRow);
  };
  const handleEditFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData);
  };
  const handleCancelClick = () => {
    setEditContactId(null);
  };
  const EditFormSave = async (data) => {
    setIsLoading(true);
    let UpdateInvoice = {
      Budget: data,
      Budget_details: [
        { budget_year: y },
        { budget_month: m },
        { budget_date: d },
      ],
    };
    await fobeAxios
      .post("Api/DailyPeriod/updateInvoice", UpdateInvoice)
      .then((resp) => {
        console.log("resp", resp.data);
        if (resp.data == 1) {
          toast.success("Update Successfully");
          handleSelect(2);
          setIsLoading(false);
        } else {
          toast.error("Somethimg wrong");

          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error("Failed");
        setIsLoading(false);
      });
    console.log("edit", data);
    handleCancelClick();
  };
  return (
    <>
      <div className="container pd-0">

        <div className="col-md-12 col-sm-12 col-xs-12" style={{ padding: '10px 0px 10px 0px' }}>
          <div className="row">
            {(budget_type === 1) ?
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="row">
                  <div class="col-md-4 col-sm-4 col-xs-4">
                    <p style={{ marginTop: '10px', float: 'right' }}>Select Period</p></div>
                  <div class="col-md-8 col-sm-8 col-xs-8">
                    <Dropdown onSelect={handlemonthchange}>
                      <Dropdown.Toggle id="dropdown-button-dark-example1" className='drop-ml' variant="secondary">
                        {ActiveMonthpage
                          ? ActiveMonthpage < 10 ? "Period" + " " + ActiveMonthpage[1] : "Period" + " " + ActiveMonthpage
                          : "Period 1"}

                      </Dropdown.Toggle>

                      <Dropdown.Menu variant="dark" className="responsedrop">
                        <Dropdown.Item eventKey="1" className={ActiveMonthpage == 1 ? "active" : ""}>Period 1</Dropdown.Item>
                        <Dropdown.Item eventKey="2" className={ActiveMonthpage == 2 ? "active" : ""}>Period 2</Dropdown.Item>
                        <Dropdown.Item eventKey="3" className={ActiveMonthpage == 3 ? "active" : ""}>Period 3</Dropdown.Item>
                        <Dropdown.Item eventKey="4" className={ActiveMonthpage == 4 ? "active" : ""}>Period 4</Dropdown.Item>
                        <Dropdown.Item eventKey="5" className={ActiveMonthpage == 5 ? "active" : ""}>Period 5</Dropdown.Item>
                        <Dropdown.Item eventKey="6" className={ActiveMonthpage == 6 ? "active" : ""}>Period 6</Dropdown.Item>
                        <Dropdown.Item eventKey="7" className={ActiveMonthpage == 7 ? "active" : ""}>Period 7</Dropdown.Item>
                        <Dropdown.Item eventKey="8" className={ActiveMonthpage == 8 ? "active" : ""} >Period 8</Dropdown.Item>
                        <Dropdown.Item eventKey="9" className={ActiveMonthpage == 9 ? "active" : ""}>Period 9</Dropdown.Item>
                        <Dropdown.Item eventKey="10" className={ActiveMonthpage == 10 ? "active" : ""}>Period 10</Dropdown.Item>
                        <Dropdown.Item eventKey="11" className={ActiveMonthpage == 11 ? "active" : ""}>Period 11</Dropdown.Item>
                        <Dropdown.Item eventKey="12" className={ActiveMonthpage == 12 ? "active" : ""}>Period 12</Dropdown.Item>
                        <Dropdown.Item eventKey="13" className={ActiveMonthpage == 13 ? "active" : ""}>Period 13</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div> : null}
            {(budget_type === 1) ?
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="row">
                  <div class="col-md-4 col-sm-4 col-xs-4">
                    <p style={{ marginTop: '10px', float: 'right' }}>Select Day</p></div>
                  <div class="col-md-8 col-sm-8 col-xs-8">
                    {console.log(ActivePeriodpage, "hello again")}
                    <Dropdown onSelect={handledatechange}>


                      <Dropdown.Toggle id="dropdown-button-dark-example1" className='drop-ml' variant="secondary">
                        {ActivePeriodpage
                          ? ActivePeriodpage < 10 ? "Day" + " " + ActivePeriodpage[1] : "Day" + " " + ActivePeriodpage
                          : "Day 1"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu variant="dark" className="responsedrop">
                        <Dropdown.Item eventKey="Day 1" className={ActivePeriodpage == 1 ? "active" : ""} >Day 1</Dropdown.Item>
                        <Dropdown.Item eventKey="Day 2" className={ActivePeriodpage == 2 ? "active" : ""}>Day 2</Dropdown.Item>
                        <Dropdown.Item eventKey="Day 3" className={ActivePeriodpage == 3 ? "active" : ""}>Day 3</Dropdown.Item>
                        <Dropdown.Item eventKey="Day 4" className={ActivePeriodpage == 4 ? "active" : ""}>Day 4</Dropdown.Item>
                        <Dropdown.Item eventKey="Day 5" className={ActivePeriodpage == 5 ? "active" : ""}>Day 5</Dropdown.Item>
                        <Dropdown.Item eventKey="Day 6" className={ActivePeriodpage == 6 ? "active" : ""}>Day 6</Dropdown.Item>
                        <Dropdown.Item eventKey="Day 7" className={ActivePeriodpage == 7 ? "active" : ""}>Day 7</Dropdown.Item>
                        <Dropdown.Item eventKey="Day 8" className={ActivePeriodpage == 8 ? "active" : ""}>Day 8</Dropdown.Item>
                        <Dropdown.Item eventKey="Day 9" className={ActivePeriodpage == 9 ? "active" : ""}>Day 9</Dropdown.Item>
                        <Dropdown.Item eventKey="Day 10" className={ActivePeriodpage == 10 ? "active" : ""}>Day 10</Dropdown.Item>
                        <Dropdown.Item eventKey="Day 11" className={ActivePeriodpage == 11 ? "active" : ""}>Day 11</Dropdown.Item>
                        <Dropdown.Item eventKey="Day 12" className={ActivePeriodpage == 12 ? "active" : ""}>Day 12</Dropdown.Item>
                        <Dropdown.Item eventKey="Day 13" className={ActivePeriodpage == 13 ? "active" : ""}>Day 13</Dropdown.Item>
                        <Dropdown.Item eventKey="Day 14" className={ActivePeriodpage == 14 ? "active" : ""}>Day 14</Dropdown.Item>
                        <Dropdown.Item eventKey="Day 15" className={ActivePeriodpage == 15 ? "active" : ""}>Day 15</Dropdown.Item>
                        <Dropdown.Item eventKey="Day 16" className={ActivePeriodpage == 16 ? "active" : ""}>Day 16</Dropdown.Item>
                        <Dropdown.Item eventKey="Day 17" className={ActivePeriodpage == 17 ? "active" : ""}>Day 17</Dropdown.Item>
                        <Dropdown.Item eventKey="Day 18" className={ActivePeriodpage == 18 ? "active" : ""}>Day 18</Dropdown.Item>
                        <Dropdown.Item eventKey="Day 19" className={ActivePeriodpage == 19 ? "active" : ""}>Day 19</Dropdown.Item>
                        <Dropdown.Item eventKey="Day 20" className={ActivePeriodpage == 20 ? "active" : ""}>Day 20</Dropdown.Item>
                        <Dropdown.Item eventKey="Day 21" className={ActivePeriodpage == 21 ? "active" : ""}>Day 21</Dropdown.Item>
                        <Dropdown.Item eventKey="Day 22" className={ActivePeriodpage == 22 ? "active" : ""}>Day 22</Dropdown.Item>
                        <Dropdown.Item eventKey="Day 23" className={ActivePeriodpage == 23 ? "active" : ""}>Day 23</Dropdown.Item>
                        <Dropdown.Item eventKey="Day 24" className={ActivePeriodpage == 24 ? "active" : ""}>Day 24</Dropdown.Item>
                        <Dropdown.Item eventKey="Day 25" className={ActivePeriodpage == 25 ? "active" : ""}>Day 25</Dropdown.Item>
                        <Dropdown.Item eventKey="Day 26" className={ActivePeriodpage == 26 ? "active" : ""}>Day 26</Dropdown.Item>
                        <Dropdown.Item eventKey="Day 27" className={ActivePeriodpage == 27 ? "active" : ""}>Day 27</Dropdown.Item>
                        <Dropdown.Item eventKey="Day 28" className={ActivePeriodpage == 28 ? "active" : ""}>Day 28</Dropdown.Item>

                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>

              </div> : null}


            {(budget_type === 2) ?
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="row">
                  <div class="col-md-4 col-sm-4 col-xs-4">

                  </div>
                </div>
              </div> : null}
            {(budget_type === 2) ?
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="row">
                  <div class="col-md-4 col-sm-4 col-xs-4">

                  </div>
                </div>

              </div> : null}


            {showuploadinvoice ?
              <button
                className='btn'
                onClick={invoiceuploadhandleShows}
                style={{
                  backgroundColor: "#19a73f", padding: "5px 9px", color: "white", borderColor: "#19a73f", marginLeft: "135px"
                }}
              >
                Invoice Upload
              </button> : null}
          </div>
        </div>
        <row className="col-md-12">
          <div className="col-md-6"></div>
          {showCourse && (
            <div className="col-md-6">
              <Form>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Col md="7" sm="8">
                    <Form.Label
                      style={{
                        float: "right",
                        marginRight: "10px",
                        marginTop: "5.7px",
                      }}
                    >
                      Select Department
                    </Form.Label>
                  </Col>
                  {console.log(selectDepartment, "deii im dept")}
                  <Col md="5" sm="4">
                    <Dropdown onSelect={handleSelectdrp}>
                      <Dropdown.Toggle
                        id="dropdown-button-dark-example1"
                        className="drop-ml drop-mbl"
                        variant="secondary"
                      >
                        {selectDepartment
                          ? selectDepartment.slice(0, 23)
                          : "All Department"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu variant="dark" className="responsedrop">
                        <Dropdown.Item
                          eventKey={userDetail.Boss_id + "`" + "All Department"}
                        >
                          All Department
                        </Dropdown.Item>
                        {department?.map((x) => (
                          <Dropdown.Item
                            eventKey={x.Rest_id + "`" + x.Rest_Name}
                            className={
                              selectDepartment_active ===
                                x.Rest_id + "`" + x.Rest_Name
                                ? "active"
                                : ""
                            }
                          >
                            {x.Rest_Name}
                          </Dropdown.Item>
                        ))}

                        {/* <Dropdown.Item  active>Metro West-Golf Operations</Dropdown.Item>
<Dropdown.Item>Metro West-Administration</Dropdown.Item>
<Dropdown.Item>Metro West-Turf & Maintenance</Dropdown.Item>
<Dropdown.Item>Metro West-Food & Beverage</Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Form.Group>
              </Form>
            </div>
          )}
        </row>
        <row className="col-md-12">
          <div className="col-md-12">
            {(budget_type === 1) ? <DatesPaginator dt={setdate1} dm={setm} ds={sety} budget_type={budget_type} date={date_1} t={ActivePeriodpage} /> : <DatesPaginator dt={setd} dm={setm} ds={sety} />}
          </div>
        </row>

        {checkingForNoBudget ? (
          <>
            <div className="DailySales-tabsections">
              <Tabs
                defaultActiveKey={tabs}
                activeKey={tabs}
                id="uncontrolled-tab-example"
                className="mb-3"
                onSelect={handleSelect}
              >
                {label_second?.map((x) => {
                  let i;
                  if (
                    x[1].Label_Name === "Revenue" ||
                    x[1].Label_Name === "Admin Expenses" ||
                    x[1].Label_Name === "Fitness Expenses" ||
                    x[1].Label_Name === "Tennis Expenses" ||
                    x[1].Label_Name === "Turf CareExpenses"
                  ) {
                    return (
                      <Tab eventKey={1} title={x[1].Label_Name}>
                        <Tablespanrow
                          editDailySales={editDailySales}
                          menuId={1}
                          data={data ? data : null}
                          title="REVENUE"
                          header={header}
                          isBudget={false}
                        />
                        <div className="col-md-12 pd-0 rig-alg dily-s">
                          {data.list
                            ?.find((item) => item.Name === "Daily")
                            .List?.filter((x) => x.Editable_Status == "1")
                            .length != 0 && (
                              <Button
                                className="mat-btn mat-save"
                                variant="contained"
                                onClick={() => saveDailyData(1)}
                              >
                                Save
                              </Button>
                            )}
                          {label_second.map((x) => {
                            if (
                              x[1].Label_Name === "Revenue" ||
                              x[1].Label_Name === "Admin Expenses" ||
                              x[1].Label_Name === "Fitness Expenses" ||
                              x[1].Label_Name === "Tennis Expenses" ||
                              x[1].Label_Name === "Turf CareExpenses"
                            ) {
                              if (
                                (parseInt(x[0]) === 0 || parseInt(x[0]) > 0) &&
                                parseInt(x[0]) + 1 < label_second.length
                              ) {
                                i = parseInt(x[0]) + 1;
                                // {console.log(i,"i value")
                                // console.log(label_second[i])}
                                if (
                                  label_second[i][1].Label_Name === "Invoice"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(2);
                                        handleSelect(2);
                                      }}
                                    >
                                      Invoice{" "}
                                      <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i>
                                    </Button>
                                  );
                                } else if (
                                  label_second[i][1].Label_Name ===
                                  "Cost Of Goods"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(3);
                                        handleSelect(3);
                                      }}
                                    >
                                      Cost Of Goods{" "}
                                      <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i>
                                    </Button>
                                  );
                                } else if (
                                  label_second[i][1].Label_Name === "Labor"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(4);
                                        handleSelect(4);
                                      }}
                                    >
                                      Labor{" "}
                                      <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i>
                                    </Button>
                                  );
                                } else if (
                                  label_second[i][1].Label_Name ===
                                  "Controllable"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(5);
                                        handleSelect(5);
                                      }}
                                    >
                                      Controllable{" "}
                                      <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i>
                                    </Button>
                                  );
                                } else if (
                                  label_second[i][1].Label_Name ===
                                  "Non Controllable"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(6);
                                        handleSelect(6);
                                      }}
                                    >
                                      Non Controllable{" "}
                                      <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i>
                                    </Button>
                                  );
                                } else if (
                                  label_second[i][1].Label_Name ===
                                  "PROFIT/LOSS"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(7);
                                        handleSelect(7);
                                      }}
                                    >
                                      Profit/Loss{" "}
                                      <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i>
                                    </Button>
                                  );
                                }
                              }
                            }
                          })}
                        </div>
                        <hr />
                      </Tab>
                    );
                  }

                  if (x[1].Label_Name === "Invoice") {
                    return (
                      <Tab eventKey={2} title="Invoice">
                        <TableInvoice
                          menuId={2}
                          rows={rows}
                          options={options}
                          addRow={addRow}
                          addRowTable={addRowTable}
                          onValUpdate={onValUpdate}
                          tableRowRemove={tableRowRemove}
                          title="Invoice"
                          nodata={nodata}
                        />
                        <div class="row">
                          <div
                            class="col-md-12 col-xs-12 rig-alg dily-s"
                            style={{ marginBottom: "2em", marginTop: "-0.5em" }}
                          >
                            <span
                              onClick={addRowTable}
                              style={{ cursor: "pointer", color: "#099cd8" }}
                            >
                              <i
                                class="fa fa-plus-circle"
                                aria-hidden="true"
                                style={{
                                  marginRight: "0.5em",
                                  color: "#19a73f",
                                  fontSize: "1.5em",
                                }}
                              ></i>
                              Add new Category
                            </span>
                          </div>
                        </div>
                        <div class="row">
                          <div
                            class="col-md-6 col-xs-6"
                            style={{ float: "left", marginBottom: "2em" }}
                          >
                            {label_second.map((x) => {
                              if (x[1].Label_Name === "Invoice") {
                                if (parseInt(x[0]) > 0) {
                                  i = parseInt(x[0]) - 1;
                                  if (
                                    label_second[i][1].Label_Name ===
                                    "Revenue" ||
                                    label_second[i][1].Label_Name ===
                                    "Admin Expenses" ||
                                    label_second[i][1].Label_Name ===
                                    "Fitness Expenses" ||
                                    label_second[i][1].Label_Name ===
                                    "Tennis Expenses" ||
                                    label_second[i][1].Label_Name ===
                                    "Turf CareExpenses"
                                  ) {
                                    return (
                                      <Button
                                        className="mat-btn mat-next fl-lf"
                                        variant="contained"
                                        onClick={() => {
                                          setTab(1);
                                          handleSelect(1);
                                        }}
                                      >
                                        <i
                                          class="fa fa-arrow-left"
                                          style={{ marginRight: "1em" }}
                                          aria-hidden="true"
                                        ></i>{" "}
                                        Revenue
                                      </Button>
                                    );
                                  }
                                }
                              }
                            })}
                          </div>
                          <div className="col-md-6 col-xs-6 rig-alg dily-s">
                            <button
                              className="btn btn-success"
                              style={{ marginRight: "1em" }}
                              onClick={() => SaveInvoice()}
                            >
                              Save{" "}
                              <i
                                class="fa fa-thumbs-o-up"
                                aria-hidden="true"
                              ></i>
                            </button>

                            {label_second.map((x) => {
                              if (x[1].Label_Name === "Invoice") {
                                if (
                                  (parseInt(x[0]) === 0 ||
                                    parseInt(x[0]) > 0) &&
                                  parseInt(x[0]) + 1 < label_second.length
                                ) {
                                  i = parseInt(x[0]) + 1;

                                  if (
                                    label_second[i][1].Label_Name ===
                                    "Cost Of Goods"
                                  ) {
                                    return (
                                      <Button
                                        className="mat-btn mat-next"
                                        variant="contained"
                                        onClick={() => {
                                          setTab(3);
                                          handleSelect(3);
                                        }}
                                      >
                                        Cost Of Goods{" "}
                                        <i
                                          class="fa fa-arrow-right"
                                          style={{ marginLeft: "1em" }}
                                          aria-hidden="true"
                                        ></i>
                                      </Button>
                                    );
                                  } else if (
                                    label_second[i][1].Label_Name === "Labor"
                                  ) {
                                    return (
                                      <Button
                                        className="mat-btn mat-next"
                                        variant="contained"
                                        onClick={() => {
                                          setTab(4);
                                          handleSelect(4);
                                        }}
                                      >
                                        Labor{" "}
                                        <i
                                          class="fa fa-arrow-right"
                                          style={{ marginLeft: "1em" }}
                                          aria-hidden="true"
                                        ></i>
                                      </Button>
                                    );
                                  } else if (
                                    label_second[i][1].Label_Name ===
                                    "Controllable"
                                  ) {
                                    return (
                                      <Button
                                        className="mat-btn mat-next"
                                        variant="contained"
                                        onClick={() => {
                                          setTab(5);
                                          handleSelect(5);
                                        }}
                                      >
                                        Controllable{" "}
                                        <i
                                          class="fa fa-arrow-right"
                                          style={{ marginLeft: "1em" }}
                                          aria-hidden="true"
                                        ></i>
                                      </Button>
                                    );
                                  } else if (
                                    label_second[i][1].Label_Name ===
                                    "Non Controllable"
                                  ) {
                                    return (
                                      <Button
                                        className="mat-btn mat-next"
                                        variant="contained"
                                        onClick={() => {
                                          setTab(6);
                                          handleSelect(6);
                                        }}
                                      >
                                        Non Controllable{" "}
                                        <i
                                          class="fa fa-arrow-right"
                                          style={{ marginLeft: "1em" }}
                                          aria-hidden="true"
                                        ></i>
                                      </Button>
                                    );
                                  } else if (
                                    label_second[i][1].Label_Name ===
                                    "PROFIT/LOSS"
                                  ) {
                                    return (
                                      <Button
                                        className="mat-btn mat-next"
                                        variant="contained"
                                        onClick={() => {
                                          setTab(7);
                                          handleSelect(7);
                                        }}
                                      >
                                        Profit/Loss{" "}
                                        <i
                                          class="fa fa-arrow-right"
                                          style={{ marginLeft: "1em" }}
                                          aria-hidden="true"
                                        ></i>
                                      </Button>
                                    );
                                  }
                                }
                              }
                            })}
                          </div>
                        </div>
                        <TableInvoice_report
                          menuId={2}
                          rows={Reportrows}
                          editContactId={editContactId}
                          handleCancelClick={handleCancelClick}
                          show={show}
                          handleDeleteClick={handleDeleteClick}
                          Total={TotalInvoice}
                          handleEditClick={handleEditClick}
                          EditFormSave={EditFormSave}
                          editFormData={editFormData}
                          handleEditFormChange={handleEditFormChange}
                          title="Invoice"
                        />
                      </Tab>
                    );
                  }

                  if (x[1].Label_Name === "Cost Of Goods") {
                    return (
                      <Tab eventKey={3} title="Cost Of Goods">
                        <Tablespanrow
                          editDailySales={editDailySales}
                          menuId={3}
                          data={data}
                          title="Cost Of Goods"
                          header={header}
                          isBudget={false}
                        />

                        <div className="col-md-12 pd-0 rig-alg dily-s">
                          {label_second.map((x) => {
                            if (x[1].Label_Name === "Cost Of Goods") {
                              if (parseInt(x[0]) > 0) {
                                i = parseInt(x[0]) - 1;
                                if (
                                  label_second[i][1].Label_Name === "Revenue" ||
                                  label_second[i][1].Label_Name ===
                                  "Admin Expenses" ||
                                  label_second[i][1].Label_Name ===
                                  "Fitness Expenses" ||
                                  label_second[i][1].Label_Name ===
                                  "Tennis Expenses" ||
                                  label_second[i][1].Label_Name ===
                                  "Turf CareExpenses"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next fl-lf"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(1);
                                        handleSelect(1);
                                      }}
                                    >
                                      <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Revenue
                                    </Button>
                                  );
                                }
                                if (
                                  label_second[i][1].Label_Name === "Invoice"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next fl-lf"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(2);
                                        handleSelect(2);
                                      }}
                                    >
                                      <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Invoice
                                    </Button>
                                  );
                                }
                              }
                            }
                          })}

                          {data.list
                            .find((item) => item.Name === "Daily")
                            .List.filter((x) => x.Editable_Status == "1")
                            .length != 0 && (
                              <Button
                                className="mat-btn mat-save"
                                variant="contained"
                              >
                                Save
                              </Button>
                            )}
                          {label_second.map((x) => {
                            if (x[1].Label_Name === "Cost Of Goods") {
                              if (
                                (parseInt(x[0]) === 0 || parseInt(x[0]) > 0) &&
                                parseInt(x[0]) + 1 < label_second.length
                              ) {
                                i = parseInt(x[0]) + 1;
                                if (label_second[i][1].Label_Name === "Labor") {
                                  return (
                                    <Button
                                      className="mat-btn mat-next"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(4);
                                        handleSelect(4);
                                      }}
                                    >
                                      Labor{" "}
                                      <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i>
                                    </Button>
                                  );
                                } else if (
                                  label_second[i][1].Label_Name ===
                                  "Controllable"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(5);
                                        handleSelect(5);
                                      }}
                                    >
                                      Controllable{" "}
                                      <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i>
                                    </Button>
                                  );
                                } else if (
                                  label_second[i][1].Label_Name ===
                                  "Non Controllable"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(6);
                                        handleSelect(6);
                                      }}
                                    >
                                      Non Controllable{" "}
                                      <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i>
                                    </Button>
                                  );
                                } else if (
                                  label_second[i][1].Label_Name ===
                                  "PROFIT/LOSS"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(7);
                                        handleSelect(7);
                                      }}
                                    >
                                      Profit/Loss{" "}
                                      <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i>
                                    </Button>
                                  );
                                }
                              }
                            }
                          })}
                        </div>
                      </Tab>
                    );
                  }

                  if (x[1].Label_Name === "Labor") {
                    return (
                      <Tab eventKey={4} title="Labor">
                        <Tablespanrow
                          editDailySales={editDailySales}
                          menuId={4}
                          data={data}
                          title="Labor"
                          header={header}
                          isBudget={false}
                        />
                        <div className="col-md-12 pd-0 rig-alg dily-s">
                          {label_second.map((x) => {
                            if (x[1].Label_Name === "Labor") {
                              if (parseInt(x[0]) > 0) {
                                i = parseInt(x[0]) - 1;

                                if (
                                  label_second[i][1].Label_Name === "Revenue" ||
                                  label_second[i][1].Label_Name ===
                                  "Admin Expenses" ||
                                  label_second[i][1].Label_Name ===
                                  "Fitness Expenses" ||
                                  label_second[i][1].Label_Name ===
                                  "Tennis Expenses" ||
                                  label_second[i][1].Label_Name ===
                                  "Turf CareExpenses"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next fl-lf"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(1);
                                        handleSelect(1);
                                      }}
                                    >
                                      <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Revenue
                                    </Button>
                                  );
                                } else if (
                                  label_second[i][1].Label_Name === "Invoice"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next fl-lf"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(2);
                                        handleSelect(2);
                                      }}
                                    >
                                      <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Invoice
                                    </Button>
                                  );
                                } else if (
                                  label_second[i][1].Label_Name ===
                                  "Cost Of Goods"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next fl-lf"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(3);
                                        handleSelect(3);
                                      }}
                                    >
                                      <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Cost Of Goods
                                    </Button>
                                  );
                                }
                              }
                            }
                          })}

                          {data.list
                            .find((item) => item.Name === "Daily")
                            .List.filter((x) => x.Editable_Status == "1")
                            .length != 0 && (
                              <Button
                                className="mat-btn mat-save"
                                variant="contained"
                                onClick={() => saveDailyData(4)}
                              >
                                Save
                              </Button>
                            )}

                          {label_second.map((x) => {
                            if (x[1].Label_Name === "Labor") {
                              if (
                                (parseInt(x[0]) === 0 || parseInt(x[0]) > 0) &&
                                parseInt(x[0]) + 1 < label_second.length
                              ) {
                                {
                                  console.log(x[0], "i value before increment");
                                }
                                i = parseInt(x[0]) + 1;
                                {
                                  console.log(
                                    label_second[i][1].Label_Name,
                                    " label name"
                                  );
                                }
                                if (
                                  label_second[i][1].Label_Name ===
                                  "Controllable"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(5);
                                        handleSelect(5);
                                      }}
                                    >
                                      Controllable{" "}
                                      <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i>
                                    </Button>
                                  );
                                } else if (
                                  label_second[i][1].Label_Name ===
                                  "Non Controllable"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(6);
                                        handleSelect(6);
                                      }}
                                    >
                                      Non Controllable{" "}
                                      <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i>
                                    </Button>
                                  );
                                } else if (
                                  label_second[i][1].Label_Name ===
                                  "PROFIT/LOSS"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(7);
                                        handleSelect(7);
                                      }}
                                    >
                                      Profit/Loss{" "}
                                      <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i>
                                    </Button>
                                  );
                                }
                              }
                            }
                          })}
                        </div>
                      </Tab>
                    );
                  }

                  if (x[1].Label_Name === "Controllable") {
                    return (
                      <Tab eventKey={5} title="Controllable">
                        <Tablespanrow
                          editDailySales={editDailySales}
                          menuId={5}
                          data={data}
                          title="Controllable"
                          header={header}
                          isBudget={false}
                        />
                        <div className="col-md-12 pd-0 rig-alg dily-s">
                          {label_second.map((x) => {
                            if (x[1].Label_Name === "Controllable") {
                              if (parseInt(x[0]) > 0) {
                                i = parseInt(x[0]) - 1;
                                if (
                                  label_second[i][1].Label_Name === "Revenue" ||
                                  label_second[i][1].Label_Name ===
                                  "Admin Expenses" ||
                                  label_second[i][1].Label_Name ===
                                  "Fitness Expenses" ||
                                  label_second[i][1].Label_Name ===
                                  "Tennis Expenses" ||
                                  label_second[i][1].Label_Name ===
                                  "Turf CareExpenses"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next fl-lf"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(1);
                                        handleSelect(1);
                                      }}
                                    >
                                      <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Revenue
                                    </Button>
                                  );
                                } else if (
                                  label_second[i][1].Label_Name === "Invoice"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next fl-lf"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(2);
                                        handleSelect(2);
                                      }}
                                    >
                                      <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Invoice
                                    </Button>
                                  );
                                } else if (
                                  label_second[i][1].Label_Name ===
                                  "Cost Of Goods"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next fl-lf"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(3);
                                        handleSelect(3);
                                      }}
                                    >
                                      <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Cost Of Goods
                                    </Button>
                                  );
                                } else if (
                                  label_second[i][1].Label_Name === "Labor"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next fl-lf"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(4);
                                        handleSelect(4);
                                      }}
                                    >
                                      <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Labor
                                    </Button>
                                  );
                                }
                              }
                            }
                          })}

                          {data.list
                            .find((item) => item.Name === "Daily")
                            .List.filter((x) => x.Editable_Status == "1")
                            .length != 0 && (
                              <Button
                                className="mat-btn mat-save"
                                variant="contained"
                              >
                                Save
                              </Button>
                            )}

                          {label_second.map((x) => {
                            if (x[1].Label_Name === "Controllable") {
                              if (
                                (parseInt(x[0]) === 0 || parseInt(x[0]) > 0) &&
                                parseInt(x[0]) + 1 < label_second.length
                              ) {
                                i = parseInt(x[0]) + 1;
                                if (
                                  label_second[i][1].Label_Name ===
                                  "Non Controllable"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(6);
                                        handleSelect(6);
                                      }}
                                    >
                                      Non Controllable{" "}
                                      <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i>
                                    </Button>
                                  );
                                } else if (
                                  label_second[i][1].Label_Name ===
                                  "PROFIT/LOSS"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(7);
                                        handleSelect(7);
                                      }}
                                    >
                                      Profit/Loss{" "}
                                      <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i>
                                    </Button>
                                  );
                                }
                              }
                            }
                          })}
                        </div>
                      </Tab>
                    );
                  }

                  if (x[1].Label_Name === "Non Controllable") {
                    return (
                      <Tab eventKey={6} title="Non Controllable">
                        <Tablespanrow
                          editDailySales={editDailySales}
                          menuId={6}
                          data={data}
                          title="Non Controllable"
                          header={header}
                          isBudget={false}
                        />
                        <div className="col-md-12 pd-0 rig-alg dily-s">
                          {label_second.map((x) => {
                            if (x[1].Label_Name === "Non Controllable") {
                              if (parseInt(x[0]) > 0) {
                                i = parseInt(x[0]) - 1;

                                if (
                                  label_second[i][1].Label_Name === "Revenue" ||
                                  label_second[i][1].Label_Name ===
                                  "Admin Expenses" ||
                                  label_second[i][1].Label_Name ===
                                  "Fitness Expenses" ||
                                  label_second[i][1].Label_Name ===
                                  "Tennis Expenses" ||
                                  label_second[i][1].Label_Name ===
                                  "Turf CareExpenses"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next fl-lf"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(1);
                                        handleSelect(1);
                                      }}
                                    >
                                      <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Revenue
                                    </Button>
                                  );
                                } else if (
                                  label_second[i][1].Label_Name === "Invoice"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next fl-lf"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(2);
                                        handleSelect(2);
                                      }}
                                    >
                                      <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Invoice
                                    </Button>
                                  );
                                } else if (
                                  label_second[i][1].Label_Name ===
                                  "Cost Of Goods"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next fl-lf"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(3);
                                        handleSelect(3);
                                      }}
                                    >
                                      <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Cost Of Goods
                                    </Button>
                                  );
                                } else if (
                                  label_second[i][1].Label_Name === "Labor"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next fl-lf"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(4);
                                        handleSelect(4);
                                      }}
                                    >
                                      <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Labor
                                    </Button>
                                  );
                                } else if (
                                  label_second[i][1].Label_Name ===
                                  "Controllable"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next fl-lf"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(5);
                                        handleSelect(5);
                                      }}
                                    >
                                      <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Controllable
                                    </Button>
                                  );
                                }
                              }
                            }
                          })}

                          {data.list
                            .find((item) => item.Name === "Daily")
                            .List.filter((x) => x.Editable_Status == "1")
                            .length != 0 && (
                              <Button
                                className="mat-btn mat-save"
                                variant="contained"
                              >
                                Save
                              </Button>
                            )}
                          {label_second.map((x) => {
                            if (x[1].Label_Name === "Non Controllable") {
                              if (
                                (parseInt(x[0]) === 0 || parseInt(x[0]) > 0) &&
                                parseInt(x[0]) + 1 < label_second.length
                              ) {
                                i = parseInt(x[0]) + 1;
                                if (
                                  label_second[i][1].Label_Name ===
                                  "PROFIT/LOSS"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(7);
                                        handleSelect(7);
                                      }}
                                    >
                                      Profit/Loss{" "}
                                      <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i>
                                    </Button>
                                  );
                                }
                              }
                            }
                          })}
                        </div>
                      </Tab>
                    );
                  }

                  if (x[1].Label_Name === "PROFIT/LOSS") {
                    return (
                      <Tab eventKey={7} title="Profit/Loss">
                        <Tablespanrow
                          menuId={7}
                          data={data}
                          title="PROFIT/LOSS"
                          header={header}
                          isBudget={false}
                        />
                        <div className="col-md-12 pd-0 rig-alg dily-s">
                          {label_second.map((x) => {
                            if (x[1].Label_Name === "PROFIT/LOSS") {
                              if (parseInt(x[0]) > 0) {
                                i = parseInt(x[0]) - 1;
                                if (
                                  label_second[i][1].Label_Name === "Revenue" ||
                                  label_second[i][1].Label_Name ===
                                  "Admin Expenses" ||
                                  label_second[i][1].Label_Name ===
                                  "Fitness Expenses" ||
                                  label_second[i][1].Label_Name ===
                                  "Tennis Expenses" ||
                                  label_second[i][1].Label_Name ===
                                  "Turf CareExpenses"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next fl-lf"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(1);
                                        handleSelect(1);
                                      }}
                                    >
                                      <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Revenue
                                    </Button>
                                  );
                                } else if (
                                  label_second[i][1].Label_Name === "Invoice"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next fl-lf"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(2);
                                        handleSelect(2);
                                      }}
                                    >
                                      <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Invoice
                                    </Button>
                                  );
                                } else if (
                                  label_second[i][1].Label_Name ===
                                  "Cost Of Goods"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next fl-lf"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(3);
                                        handleSelect(3);
                                      }}
                                    >
                                      <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Cost Of Goods
                                    </Button>
                                  );
                                } else if (
                                  label_second[i][1].Label_Name === "Labor"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next fl-lf"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(4);
                                        handleSelect(4);
                                      }}
                                    >
                                      <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Labor
                                    </Button>
                                  );
                                } else if (
                                  label_second[i][1].Label_Name ===
                                  "Controllable"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next fl-lf"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(5);
                                        handleSelect(5);
                                      }}
                                    >
                                      <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Controllable
                                    </Button>
                                  );
                                } else if (
                                  label_second[i][1].Label_Name ===
                                  "Non Controllable"
                                ) {
                                  return (
                                    <Button
                                      className="mat-btn mat-next fl-lf"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(6);
                                        handleSelect(6);
                                      }}
                                    >
                                      {" "}
                                      <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Non Controllable
                                    </Button>
                                  );
                                }
                              }
                            }
                          })}

                          {/* <Button className='mat-btn mat-save' variant="contained">Save</Button> */}
                        </div>
                      </Tab>
                    );
                  }
                })}
              </Tabs>
              <hr />
              {isLoading ? <Spinner /> : <></>}
            </div>
          </>
        ) : (
          <>
            <div className="col-xs-12 text-center">
              <div className="col-xs-12 ">
                <figure className="info-img">
                  <img
                    src="https://d2a7u30t6wbkim.cloudfront.net/assets/img/budget-flat.png"
                    alt="Budget Image"
                    style={{ width: "64px" }}
                  />
                </figure>
              </div>
              <div className="col-xs-12  info-title">
                <h5 className="" style={{ fontWeight: "900" }}>
                  No Budget available..
                </h5>
                <div className="col-xs-12">
                  <p className="awesome">
                    <span
                      className="info-span1"
                      style={{
                        fontSize: "13px",
                        fontStyle: "italic",
                        fontWeight: "bold",
                        color: "#1C9F6F",
                      }}
                    >
                      "Build your budget to improve your Restaurant"
                    </span>
                  </p>
                </div>
              </div>
              <p
                className="res-tag"
                style={{
                  background: "#d0d8d8",
                  padding: "7px 12px",
                  borderRadius: "7px",
                  color: "#000",
                  letterSpacing: "1px",
                  fontSize: "12px",
                  display: "inline-block",
                }}
              >
                <a
                  href="#/services/budget"
                  onClick={() => { }}
                  style={{ color: "#2196f3" }}
                >
                  Click
                </a>{" "}
                here to budget!
              </p>
            </div>
          </>
        )}
      </div>
      {/* warning popup */}
      <Modal size="sm" show={show} onHide={handleClose}>
        <Modal.Header
          className="text-center"
          style={{
            border: "none",
            backgroundColor: "goldenrod",
            padding: "10px",
          }}
        >
          <Modal.Title>
            {" "}
            <BsExclamationTriangle /> Warning
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-2 text-center">
          Do you want to delete this line item?
        </Modal.Body>
        <Modal.Footer style={{ border: "none" }}>
          <Button
            variant="primary"
            style={{
              backgroundColor: "green",
              color: "white",
              marginRight: "1em",
            }}
            onClick={() => handleShow(Index)}
          >
            Confirm
          </Button>
          <Button
            variant="primary"
            style={{ backgroundColor: "red", color: "white" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>



      <Modal show={showinvoiceupload} onHide={invoiceuploadhandleCloses} >
        <Modal.Header style={{ backgroundColor: "#19A73F", color: "white" }} >
          <Modal.Title>Upload Invoice      <i
            class="fa fa-times"
            aria-hidden="true"
            style={{
              cursor: "pointer",
              color: "white",
              fontSize: "1em",
              float: "right",
              marginTop: "3px",
            }}
            onClick={invoiceuploadhandleCloses}
          ></i></Modal.Title>
        </Modal.Header>





        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={6}>
              <div className="text-center">
                <div className="upload-btn-wrapperr">
                  <button class="b-tnn">
                    Select file from your device
                  </button>
                  <input type="file" value={logoimage} multiple onChange={(e) => { { handleInputChange(e) } }} />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12} md={6}>
              <div className="text-center">
                <div className="disclaimer_text" style={{fontSize:"13px"}}>Supports: .PDF, .PNG, .JPG</div>
              </div>
            </Col>

          </Row>
        </Container>
        <Modal.Body>
          <ul>



            {myFiles.map((file, index) => (
              <div className="upld-sec" style={{ marginLeft: "-30px" }}>
                <div className="row all-sec-up">
                  <div className="col-md-8">
                    <div style={{ paddingTop: '3px' ,color:"gray",fontSize:"12px"}}>
                      <span>
                        <i className="fa fa-file-text-o fil-icn-upld1" key={index}></i>
                      </span>
                      {file.name}  
                    </div>
                  </div>
                  <div className="col-md-4  progrss-cls" style={{display:"flex",gap:"15px"}}>
                  <span
                      
                  style={{
                    
                   
                    cursor: 'pointer',
                    border: '1px solid rgba(0,0,0,.38)',
                    borderRadius: '5px',
                    fontSize: '12px',
                    padding: '6px',
                    color:"gray"
                  }}
                >
                {numeral(file.size / 1000000).format('0.00')}MB
                </span>

               
                  {countt==100 &&
                   <span >
                   

                   <i
                   className="fa fa-check phview"
                   style={{
                     fontSize: '13px',
                     border: '2px solid #2196f3',
                     borderRadius: '20px',
                     padding: '4px',
                     color: '#4caf50',
                     marginTop: '4px'
                   }}
                 ></i>

                    </span>  
                  }
               
                    <span
                      id=""
                      style={{
                        float: 'right',
                        paddingTop: '3px',
                        cursor: 'pointer',
                      }}
                    >
                      <i
                        style={{ color: '#898989' }}
                        className="bi bi-x"
                        onClick={() => removeFile(index, file.size)}
                      >
                      </i>
                    </span>
                  </div>
                </div>
                {(index== myFiles.length-1 && hideprogressbar) &&
                <LinearProgress
                variant="determinate"
                value={countt}
                color="primary"
                style={{
                  top: '5px',
                  borderRadius: '5px',
                  height: '6px',
                 
                }}
              />
                }
              </div>
            ))}
          </ul>
          <Container>
            <Row className="justify-content-center">
              <Col xs={12} md={6}>
                <div className="file-size text-center">
                  {myFiles != '' ? (<p>File Size:{size.toFixed(2)}MB</p>) : null}
                </div>
              </Col>
            </Row>
          </Container>

        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end w-100">
            <Button type="button" style={{ backgroundColor: "#f0f0f0", fontSize: "12px", boxShadow: '1px 1px 4px rgba(0,0,0,0.4)', padding: '6px 12px', color: "black", marginRight: "10px" }} variant="secondary" onClick={invoiceuploadhandleCloses}>
              Close
            </Button>
            <Button style={{ backgroundColor: "#03880a", fontSize: "12px", boxShadow: '1px 1px 4px rgba(0,0,0,0.4)', padding: '6px 12px', color: "white" }} onClick={uploadFiles} disabled={myFiles.length == 0 ? true : false} >
              Upload
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* <Modal show={showinvoiceupload} onHide={invoiceuploadhandleCloses} >
        <Modal.Header style={{ backgroundColor: "#19A73F", color: "white" }} >
          <Modal.Title>Upload Invoice      <i
            class="fa fa-times"
            aria-hidden="true"
            style={{
              cursor: "pointer",
              color: "white",
              fontSize: "1em",
              float: "right",
              marginTop: "3px",
            }}
            onClick={invoiceuploadhandleCloses}
          ></i></Modal.Title>
        </Modal.Header>





        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={6}>
              <div className="text-center">
                <div className="upload-btn-wrapperr">
                  <button class="b-tnn">
                    Select file from your device
                  </button>
                  <input type="file" value={logoimage} multiple onChange={(e) => { { handleInputChange(e) } }} />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12} md={6}>
              <div className="text-center">
                <div className="disclaimer_text">Supports: .PDF, .PNG, .JPG</div>
              </div>
            </Col>

          </Row>
        </Container>
        <Modal.Body>
          <ul>



            {myFiles.map((file, index) => (
              <div className="upld-sec" style={{ marginLeft: "-30px" }}>
                <div className="row all-sec-up">
                  <div className="col-md-10">
                    <div style={{ paddingTop: '3px' }}>
                      <span>
                        <i className="fa fa-file-text-o fil-icn-upld" key={index}></i>
                      </span>
                      {file.name} - {file.type} - Size: {numeral(file.size / 1000000).format('0.00')}MB
                    </div>
                  </div>
                  <div className="col-md-2  progrss-cls">
                    <span style={{ float: 'left' }}>
                      <div className="progress blue">
                        <span className="progress-left">
                          <span className="progress-bar"></span>
                        </span>
                        <span className="progress-right">
                          <span className="progress-bar"></span>
                        </span>
                        <div className="progress-value">
                          <i className="fa fa-check"></i>
                        </div>
                      </div>
                    </span>
                    <span
                      id=""
                      style={{
                        float: 'right',
                        paddingTop: '3px',
                        cursor: 'pointer',
                      }}
                    >
                      <i
                        style={{ color: '#898989' }}
                        className="bi bi-x"
                        onClick={() => removeFile(index, file.size)}
                      >
                      </i>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </ul>
          <Container>
            <Row className="justify-content-center">
              <Col xs={12} md={6}>
                <div className="file-size text-center">
                  {myFiles != '' ? (<p>File Size:{size.toFixed(2)}MB</p>) : null}
                </div>
              </Col>
            </Row>
          </Container>

        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end w-100">
            <Button type="button" style={{ backgroundColor: "#f0f0f0", fontSize: "12px", boxShadow: '1px 1px 4px rgba(0,0,0,0.4)', padding: '6px 12px', color: "black", marginRight: "10px" }} variant="secondary" onClick={invoiceuploadhandleCloses}>
              Close
            </Button>
            <Button style={{ backgroundColor: "#03880a", fontSize: "12px", boxShadow: '1px 1px 4px rgba(0,0,0,0.4)', padding: '6px 12px', color: "white" }} onClick={uploadFiles} disabled={myFiles.length == 0 ? true : false} >
              Upload
            </Button>
          </div>
        </Modal.Footer>
      </Modal> */}
      <Modal size="sm" show={blurpopup} onHide={() =>setblurpopup(false)} >
      <Modal.Body style={{border:"none"}}>
          <div class="row">
            <div class="col-md-2 col-sm-2 col-xs-12 selfsed" style={{padding: "5px 0px 0px 20px"}}>
              <div>
              <i class="fa fa-exclamation-circle sodfg" style={{fontSize: "40px", color:"red"}}></i>
            </div>
            </div>
            <div class="col-md-10 col-sm-10  col-xs-12">
              <span>The invoice uploaded is blurry and cannot be scanned.
                Please reupload the invoice with improved clarity.</span>
            </div>
          </div>
          </Modal.Body>         
          <Modal.Footer  style={{textAlign:"center",border:"none"}}>
              <button data-dismiss="modal" class="btn" 
              style={{background: "#24D153",color: "#fff",textTransform: "capitalize",fontSize: "12px",
              padding: "3px 6px 3px 6px"}} onClick={() =>{setblurpopup(false);reload()}}>Re upload</button>
            </Modal.Footer>
</Modal>    </>
  );
};
export default DailySales;
