import 'bootstrap/dist/css/bootstrap.min.css'
import './TableComponent.css';
import Table from 'react-bootstrap/Table';
import Select from 'react-select';
import CurrencyFormat from "react-currency-format";
import React, { useState, Fragment, useRef } from "react";
import { fobeAxios } from '../../middleware/interceptor';
import { ToastContainer, toast } from 'react-toastify';
import ReactToPrint from 'react-to-print';
function EditableRow({
  editFormData,
  handleEditFormChange,
  EditFormSave,
  handleCancelClick }) {
  return (
    <tr>
      <td>
        {editFormData.Cat_name}
      </td>
      <td><span class="fl-lm">$</span>
        <CurrencyFormat
          className="input_field fl-r form-control"
          id="Cat_amt"
          value={editFormData.Cat_amt}
          onChange={handleEditFormChange}
          name="Cat_amt"
        />
      </td>
      <td>
        <input
          type="text"
          value={editFormData.Details}
          onChange={handleEditFormChange}
          name="Details"
          className="form-control"
        />
      </td>
      <td>
        <input
          type="text"
          value={editFormData.Purveyor}
          onChange={handleEditFormChange}
          name="Purveyor"
          className="form-control"
        />
      </td>
      <td style={{ verticalAlign: "middle", textAlign: "center" }}>
        <i class="fa fa-check" aria-hidden="true" style={{ color: "green", fontSize: "1.5em", cursor: "pointer", marginRight: "1em" }} onClick={() => EditFormSave(editFormData)}></i>
        <i class="fa fa-times" aria-hidden="true" style={{ color: "red", fontSize: "1.5em", cursor: "pointer" }} onClick={handleCancelClick}></i>
      </td>
    </tr>
  );
}
const ReadOnlyRow = ({ row, handleEditClick, handleDeleteClick }) => {
  return (
    <tr>
      <td>{row.Cat_name}</td>
      <td><span class="fl-lm">$</span> <CurrencyFormat
        className="input_fieldread fl-r"
        style={{ backgroundColor: "transparent" }}
        id="row.Cat_amt"
        fixedDecimalScale={2}
        decimalScale={2}
        value={row.Cat_amt}
        name="row.Cat_amt"
        ReadOnly
        disabled={true}
      /></td>
      <td>{row.Purveyor}</td>
      <td>{row.Details}</td>
      <td style={{ verticalAlign: "middle", textAlign: "center" }}>
        <i class="fa fa-pencil" aria-hidden="true" style={{ color: "#149dd4", fontSize: "1.5em", cursor: "pointer", marginRight: "1em" }} onClick={(event) => handleEditClick(event, row)}></i>
        <i class="fa fa-trash" aria-hidden="true" style={{ color: "red", fontSize: "1.5em", cursor: "pointer" }} onClick={() => handleDeleteClick(row)}></i>
      </td>
    </tr>
  );
};

const TableInvoice_report = ({ rows, EditFormSave, editContactId, handleDeleteClick, handleEditFormChange, handleCancelClick, Total, handleEditClick, editFormData }) => {
  return (
    <>
      <ToastContainer autoClose={3000} />
      {rows != "" && <div className=' table-responsive table-scroll-2' style={{ maxHeight: "300px" }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th className='scrollviewfront'>
                <div className='tab-card-new'>
                  CATEGORY</div>
              </th>
              <th>
                <div className='tab-card-new'>
                  AMOUNT</div>
              </th>
              <th>
                <div className='tab-card-new'>
                  PURVEYOR</div>
              </th>
              <th>
                <div className='tab-card-new'>
                  DETAILS</div>
              </th>
              <th>
                <div className='tab-card-new'>
                  ACTION</div>
              </th>
            </tr>
          </thead>
          <tbody class="tab-gf">
            {rows.map((row, index) => (
              <Fragment>
                {editContactId === row.Invoice_id ? (
                  <EditableRow
                    editFormData={editFormData}
                    handleEditFormChange={handleEditFormChange}
                    handleCancelClick={handleCancelClick}
                    EditFormSave={EditFormSave}
                  />
                ) : (
                  <ReadOnlyRow
                    row={row}
                    handleEditClick={handleEditClick}
                    handleDeleteClick={handleDeleteClick}
                  />
                )}
              </Fragment>
            ))}
            <tr>
              <td>
                <p>Total</p>
              </td>
              <td><span class="fl-lm">$</span>
                <CurrencyFormat
                  className="input_fieldread fl-r"
                  id="Total"
                  style={{ backgroundColor: "transparent" }}
                  fixedDecimalScale={2}
                  decimalScale={2}
                  value={Total}
                  name="Total"
                  ReadOnly
                  disabled={true}
                />
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </Table>
      </div>}
    </>
  );
}
export default TableInvoice_report;