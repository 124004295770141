import "bootstrap/dist/css/bootstrap.min.css";
import "../Home/Home.css";
 import "./Businessjournal.css";
  import { Container, Row, Col } from 'react-bootstrap';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Popup from "reactjs-popup";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Budget from "../Tab/Budget";
import DailySales from "../Tab/DailySales";
import Reports from "../Tab/Reports";
import Dropdown from "react-bootstrap/Dropdown";
import P_L from "../Tab/P_L";
import { useState, useEffect,useRef  } from "react";

import Form from "react-bootstrap/Form";
import { mainAxios } from "../middleware/interceptor";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { BsExclamationTriangle } from "react-icons/bs";
import { fobeAxios } from "../middleware/interceptor";
import { toast, ToastContainer } from "react-toastify";
import numeral from 'numeral';

import "./../index.css";
function BusinessJournal() {
  const items = JSON.parse(localStorage.getItem("UserClaims"));
  const [local, setlocal] = useState(items.Boss_id);
  const [id, setid] = useState(0);
 const fileInputRef = useRef(null);
  const [bossid, setbossid] = useState(items.Boss_id);
  const [selectDepartment, SetselectDepartment] = useState("All Department");
  const [tab, setTab] = useState("Budget");
  const [selectedCourse, setselectedCourse] = useState(items.Company);
  const [Courseflage, setCourseflage] = useState(true);
  const [Course, SetCourse] = useState();
  const[triggervariable,settriggervariable]=useState(false);
  const [showCourses, setshowCourses] = useState(false);
  const [checkingForNoBudget, setcheckingForNoBudget] = useState(false);
  const [Budgetlength, setBudgetlength] = useState();
  let [budget_type, setbudget_type] = useState(items.budget_type);
  let newDate = new Date();
  let year = newDate.getFullYear();
  const [ye, sety] = useState(year);
  const [selectYear, setSelectYear] = useState(newDate.getFullYear());
  const [Courseid, setCourseid] = useState(items.Restaurant_id);
  const[alldepartments,setalldepartments]=useState(items.Restaurant_id);


  const [logoimage, setLogoImage] = useState([]);
  const [myFiles, setMyFiles] = useState([]);
  const [filepath, setFilepath] = useState([]);
  const [filedname, setFileDName] = useState([]);
  const [size, setSize] = useState(0);
  const cFile = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  const compressImage = null; // replace with actual implementation
  useEffect(() => {
    GetCourse();
    getData();
    //||((items.Special_user == 0 || items.Special_user == 2) && items.Account_Type == 0)
    if (items) {
      if (items.Member_Type == 4) {
        setCourseflage(false);
        setshowCourses(true);
      } else {
        setshowCourses(false);
        setCourseflage(true);
      }
    }
  }, [Courseid, triggervariable,budget_type]);

  useEffect(() => {
    getData();
  }, [Courseid, bossid, Budgetlength]);

  const getData = async (labelName) => {
    let GetBudgetURL, GetRevenueMonthurl,GetLabelURL;
//get label/
if ((items.Special_user == 0 || items.Special_user == 1) && items.Account_Type != 0) {
  GetLabelURL = `/Api/Common/getLabel`;
} else {
  GetLabelURL = "Api/Common/getBossLabel/" + bossid + "/" + ye;
}
const { data } = await fobeAxios.get(GetLabelURL);
setbudget_type(data.LabelAndYear[1].Year_List[0].budget_type)
    if (
      (items.Special_user == 0 || items.Special_user == 1) &&
      items.Account_Type != 0
    ) {
      if (items.budget_type == "1") {
        GetBudgetURL = "Api/BudgetPeriod/GetBudget/" + selectYear;
        GetRevenueMonthurl = "Api/BudgetPeriod/GetRevenueMonth/" + selectYear;
      } else {
        GetBudgetURL = `Api/Budget/GetBudget/` + selectYear;
        GetRevenueMonthurl = `Api/Budget/GetRevenueMonth/` + selectYear;
      }
    } else {
      GetBudgetURL = `Api/Boss/GetBossBudget/` + bossid + "/" + ye;
      GetRevenueMonthurl = `api/Boss/GetBossRevenueMonth/` + bossid + "/" + ye;
    }

    console.log(labelName, "labelName");

    await fobeAxios.get(GetBudgetURL).then((res) => {
      setBudgetlength(res.data.BudgetRevenue.length);
      if(res.data.BudgetRevenue.length>0){
        
      }
      if (res.data.BudgetRevenue.length == 0) {
        setShow(true);
        document.getElementById("app-root").style.filter = "blur(2px)";
      }
    });
  };
  const GetCourse = async () => {
    const localItems = JSON.parse(localStorage.getItem("UserClaims"));
    console.log(localItems, "localItems");
    // setselectedCourse(localItems.Company)
    const { data } = await mainAxios.get(`Api/Hampton/GetAllCouseTypes`);
    console.log(data.AllUserTypes, "fromCourse");
    SetCourse(data.AllUserTypes);
  };
  const [selectedDepartment_active, setselectedDepartment_active] =
    useState(null);
  const handleSelect = (key, event) => {
    setcheckingForNoBudget(false);
    setselectedDepartment_active(key);
    SetselectDepartment("All Department");
    setselectedCourse(key.split("`")[1]);
    setCourseid(key.split("`")[0]);
    setalldepartments(key.split("`")[0]);

    setCourseflage(true);

    setbossid(key.split("`")[0]);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setbossid(items.Boss_id);
    setCourseid(items.Restaurant_id);
    setalldepartments(items.Restaurant_id);
    setselectedCourse(items.Company);
    SetselectDepartment("All Department");
    document.getElementById("app-root").style.filter = "blur(0px)";
  };
  const handleCloses = () => {setShow(false);
    setLogoImage([])
    setMyFiles([]);
    setFilepath([]);
    setSize(0);
 
  }
  const handleShows = () => setShow(true);

  const [selectedFiles, setSelectedFiles] = useState([]);

  const [fileSize, setFileSize] = useState(0);
  const handleInputChange = (event) => {
    const file = event.target.files;
    console.log(file, "file");
    let check;
    for (let i = 0; i < cFile.length; i++) {
      check = false;
      if (file[0].type === cFile[i]) {
        if (file[0].size / 1000000 > 1 && ((file[0].type !== 'application/pdf') && (file[0].type !== 'image/png'))) {
          compressImage.compress(file[0]).then(data => {
            setMyFiles([...myFiles, data]);
            setFilepath([...filepath, logoimage]);
            setFileDName([...filedname, data.name]);
            setSize(size + (data.size / 1000000));
          });
          i = cFile.length;
          check = true;
        } else {
          setMyFiles([...myFiles, file[0]]);
          setFilepath([...filepath, logoimage]);
          setFileDName([...filedname, file[0].name]);
          setSize(size + (file[0].size / 1000000));
          i = cFile.length;
          check = true;
        }
      }
    }
    if (!check) {
      toast.warning ('Supported files only accepted');
    }
    console.log('file', myFiles, filepath);
   
  };

  
  // const handleDrop = (event) => {
  //   event.preventDefault();
  //   const files = event.dataTransfer.files;
  //   // filter files to only include PDF, PNG, JPG file types
  //   const filteredFiles = Array.from(files).filter(file => file.type === 'application/pdf' || file.type === 'image/png' || file.type === 'image/jpeg');
  //   for (let i = 0; i < files.length; i++) {
  //     filteredFiles.push(files[i]);
  //   }
  //   setSelectedFiles(filteredFiles);
  // };
  // const handleDragOver = (event) => {
  //   event.preventDefault();
  // };

 

  // const uploadFiles = () => { alert("hkjhkj")
  //   // TODO: implement file upload logic here
  //   console.log(selectedFiles);
  // };
  const uploadFiles = async () => {

    console.log('vvvvvvvvvvvvv', myFiles, filepath);
    const frmData1 = new FormData();
    frmData1.append('uploadimage', 'c:/temp/abc.png');
    if (id !== '') {
      frmData1.append('uploadid', id);
    } else {
      frmData1.append('uploadid', id);
    }
    for (let i = 0; i < myFiles.length; i++) {
      frmData1.append('invoicedetails', myFiles[i], myFiles[i].name);
    }
    console.log('file uploaded data', frmData1);
  
    await mainAxios
    .post('/Api/daily/upload_invoiceinfo/', frmData1)
    .then((resp) => {
      console.log('resp', resp); 
      if(resp.data == 1){
      toast.success('File uploaded successfully');
        setMyFiles([]);
        setFilepath([]);
        setSize(0);
        setShow(false);
    }
    else{
      toast.error('Something went wrong');
    }
    })
  };
  // removeFile(i,size) {
  //   console.log('asdfj;asdlfk',i,size);
  //   this.logoimage='';
  //   if(this.myFiles.length == 1 ){
  //     this.size = 0 ;
  //   }else{
  //     this.size -= size/1000000;  
  //   }
  //   this.myFiles.splice(i,1);
  //   this.filepath.splice(i,1);
  //  }

   const removeFile = (i) => {
    setLogoImage([])
   if(myFiles.length == 0){
      setSize(0);}
      else{
        setSize(size - (myFiles[i].size / 1000000));}
setMyFiles (myFiles.filter((file, index) => index !== i))
    // let newPaths = this.state.filepath.filter((path, index) => index !== i);
    // let newPaths = this.state.filepath.filter((path, index) => index !== i);
    // setFilepath(filepath.filter((path, index) => index !== i));
    // setFileDName(filedname.filter((name, index) => index !== i));
    // const fileInput = document.querySelector('input[type="file"]');
    // fileInput.value = ''; 
   }

  return (
    <div className="container">
      <ToastContainer autoClose={3000} />
      <div className="tab-sections"></div>
      <div className="row">
        <div className="col-md-8 col-sm-6">
          <div className="tab-sections">
            <Tabs
              defaultActiveKey={tab}
              id="uncontrolled-tab-example"
              onSelect={(k) => setTab(k)}
              className="mb-3"
            >
              <Tab eventKey="Budget" title="Budget">
                <Budget
                  course_id={Courseid}
                  tabss={tab}
                  setCourseflage={setCourseflage}
                  Courseflage={Courseflage}
                  setbossid={setbossid}
                  bossid={bossid}
                  SetselectDepartment={SetselectDepartment}
                  selectDepartment={selectDepartment}
                  setshowCourses={setshowCourses}
                  settriggervariable={settriggervariable}
                  triggervariable={triggervariable}
                  Budgetlength={Budgetlength}
                  setBudgetlength={setBudgetlength}
                  setcheckingForNoBudget={setcheckingForNoBudget}
                  checkingForNoBudget={checkingForNoBudget}
                  alldepartments={alldepartments}
                  setbudget_type={setbudget_type}
                  budget_type={budget_type}
                />
              </Tab>
              <Tab eventKey="Daily Sales" title="Daily Sales">
                <DailySales
                  setbossid={setbossid}
                  bossid={bossid}
                  course_id={Courseid}
                  tabss={tab}
                  SetselectDepartment={SetselectDepartment}
                  selectDepartment={selectDepartment}
                  settriggervariable={settriggervariable}
                  triggervariable={triggervariable}
                  Budgetlength={Budgetlength}
                  setBudgetlength={setBudgetlength}
                  setcheckingForNoBudget={setcheckingForNoBudget}
                  checkingForNoBudget={checkingForNoBudget}
                  setbudget_type={setbudget_type}
                  budget_type={budget_type}
                />
              </Tab>
              {/* <Tab eventKey="Reports" title="Reports">
      <Reports />
      </Tab>  */}
              <Tab eventKey="P-L" title="P & L">
                <P_L
                  tab={tab}
                  setTab={setTab}
                  setbossid={setbossid}
                  bossid={bossid}
                  course_id={Courseid}
                  SetselectDepartment={SetselectDepartment}
                  selectDepartment={selectDepartment}
                  settriggervariable={settriggervariable}
                  triggervariable={triggervariable}
                  Budgetlength={Budgetlength}
                  setBudgetlength={setBudgetlength}
                  setcheckingForNoBudget={setcheckingForNoBudget}
                  checkingForNoBudget={checkingForNoBudget}
                  setbudget_type={setbudget_type}
                  budget_type={budget_type}
                />
              </Tab>
            </Tabs>
          </div>
        </div>
        <div className="col-md-4 col-sm-6">
          {showCourses && (
            <Form>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Col sm="4">
                  <Form.Label style={{ float: "right" }}>Course:</Form.Label>
                </Col>
                <Col sm="8">
                  <Dropdown onSelect={handleSelect}>
                    <Dropdown.Toggle
                      id="dropdown-button-dark-example1"
                      className="drop-ml "
                      variant="secondary"
                    >
                      {selectedCourse ? selectedCourse : "Select Course"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="dark" className="responsedrop">
                      {Course?.map((item, index) => {
                        return (
                          <>
                            <Dropdown.Item
                              eventKey={item.Courseid + "`" + item.Coursename}
                              className={
                                selectedDepartment_active ===
                                item.Courseid + "`" + item.Coursename
                                  ? "active"
                                  : ""
                              }
                            >
                              {item.Coursename}
                            </Dropdown.Item>
                          </>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Form.Group>
            </Form>
          )}
        </div>
    {/*  <button
      className='btn'
      onClick={handleShows}
      style={{backgroundColor:"#19a73f",padding:"5px 9px",color:"white",borderColor:"#19a73f"
    }}
    >
      Invoice Upload
  </button> */}

 
      </div>
    </div>
  );
}
export default BusinessJournal;
