import 'bootstrap/dist/css/bootstrap.min.css'
import './../TableComponent.css';
import Table from 'react-bootstrap/Table';
import { NavItem } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import { useState, useEffect } from 'react';
import React, {useRef} from 'react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import ReactToPrint from 'react-to-print';
import CurrencyFormat from "react-currency-format";
import {Button } from '@mui/material'

const P_L_Table = ({ data, title, header, isBudget ,showexcel}) => {
  const tableRef = useRef([]);
  const componentRef = useRef();
  const changeData = (id, value) => {
  }



  return (
    <>
    {showexcel &&( 
    <ReactToPrint
        trigger={() =><Button style={{backgroundColor:" orange",height:" 35px",marginLeft:"1050px",marginBottom:"10px"}}> <i class="bi bi-printer" style={{color:"white"}}> <span style={{color:"white",fontSize:" 15px",fontStyle:" normal"}}>Print</span></i></Button>}
        content={() => componentRef.current}
      /> )}
   {/* {showexcel &&( 
<div>     <DownloadTableExcel
filename="users table"
sheet="users"
currentTableRef={componentRef.current}
>

<button> Export excel </button>

   </DownloadTableExcel></div> ref={tableRef} )}*/}

      {data ? data[0] ?
        <div className='table-scroll table-responsive table1 '>
          <Table striped bordered hover  ref={componentRef}>
            <thead>
              <tr>
                <th rowSpan={2}><div className='title-tab'>{title}</div></th>
                {header.map((head, index) =>
                  <th colSpan={2}> <div className='tab-card-new'>{head.column}</div></th>
                )}
              </tr>
              <tr>
                {header.slice(0, header.length).map(x =>
                  <><th><div className='tab-card-new'>{isBudget ? 'BUDGETED' : 'AMOUNT'}</div></th><th><div className='tab-card-new'>PER(%)</div></th></>
                )}
              </tr>
            </thead>
            <tbody>

              {
                data ? data[0] ? data[0].List?.map((item, index) => {
                  let temp = [];
                  data.map(x => {
                    temp.push({ ...x.List[index] });
                  })
                  console.log(temp, "tempdata")
                  return <tr><td>{temp[0].Cat_name}</td> {temp.map((cat,i) => {

                    const catAmtColorforrevenue = (Math.round(temp[1].Cat_amt) >Math.round( temp[0].Cat_amt)) ? 'red' : 'green';
                const  catAmtColorForothers= (temp[0].Cat_name =="Total Revenue") ? Math.round(temp[1].Cat_amt) <= Math.round(temp[0].Cat_amt) ? 'green':'red' : Math.round(temp[1].Cat_amt) >= Math.round(temp[0].Cat_amt) ? 'green':'red';

                const catAmtColorforrevenueper = (Math.round(temp[1].Cat_per )>Math.round( temp[0].Cat_per)) ? 'red' : 'green';
                const  catAmtColorForothersper= (temp[0].Cat_name =="Total Revenue") ? Math.round(temp[1].Cat_per) <= Math.round(temp[0].Cat_per) ? 'green':'red' : Math.round(temp[1].Cat_per) >= Math.round(temp[0].Cat_per )? 'green':'red';

                    
                    return <>
                      <td style={{color: i==0 && title=="REVENUE"?catAmtColorforrevenue:i==0 && title!="REVENUE"?catAmtColorForothers:'black',fontWeight:"bolder",fontSize:"14px !important"}}><span class="fl-lf">$</span>

                        <CurrencyFormat
                        
                          className={(cat.Editable_Status == 1) ? 'input-edit-monthly fl-r' : 'non-edit-cs fl-r'}
                          id={cat.Cat_id}
                          name={cat.Cat_id}
                          value={Math.round(cat.Cat_amt)} // Round off the value before displaying
                          fixedDecimalScale={2}
                          decimalScale={2}
                          disabled={(cat.Editable_Status == 1) ? false : true}
                          onValueChange={(value, name) => changeData(cat.Cat_id, value)}
                        />
                      </td>
                      <td     style={{color: i==0 && title=="REVENUE"?catAmtColorforrevenueper:i==0 && title!="REVENUE"?catAmtColorForothersper:'black',fontWeight:"bolder"}}><p className='fl-r'>
                        <CurrencyFormat
                      
                          className={(cat.Editable_Status == 1) ? 'input-edit-monthly' : 'non-edit-cs'}
                          id={cat.Cat_id}
                          name={cat.Cat_id}
                          fixedDecimalScale={2}
                          value={Math.round(cat.Cat_per)} // Round off the value before displaying
                          decimalScale={2}
                          disabled={(cat.Editable_Status == 1) ? false : true}
                          onValueChange={(value, name) => changeData(cat.Cat_id, value)}
                        />
                        <span class="fl-ri">%</span></p></td>
                    </>
                  })}</tr>;
                }) : null : null
              }



            </tbody>

{

  title!="PROFIT/LOSS"&&
            <tfoot>
              <tr>
                <td>TOTAL</td>

                <>
                  {console.log(data.list, "3rd Element")}
                  <td  style={{
                    color:
                      (title === "REVENUE" && Math.round(data[0].Total) >=  Math.round(data[1].Total)) ? 'green'
                      : (title !== "REVENUE" && Math.round( data[0].Total) <= Math.round( data[1].Total)) ? 'green'
                      : (Math.round(data[0].Total) === Math.round(data[1].Total)) ? 'green' 
                      : 'red'
                  }}><span>$</span> <p className='fl-r'><CurrencyFormat     fixedDecimalScale={2} className='non-edit-cs' value={data[0].Total == undefined ? "" : Math.round(data[0].Total)} decimalScale={2} /></p></td>
                
                
                  <td  style={{
                    color:
                      (title === "REVENUE" && Math.round(data[0].per) >=  Math.round(data[1].per)) ? 'green'
                      : (title !== "REVENUE" && Math.round( data[0].per) <= Math.round( data[1].per)) ? 'green'
                      : (Math.round(data[0].per) === Math.round(data[1].per)) ? 'green'
                      : 'red'
                  }} ><p className='fl-r'><CurrencyFormat   fixedDecimalScale={2} className='non-edit-cs' value={data[0].per == undefined ? "" : Math.round(data[0].per)} decimalScale={2} /><span>%</span></p></td>
 

                  <td  ><span>$</span> <p className='fl-r'><CurrencyFormat  fixedDecimalScale={2} className='non-edit-cs' value={data[1].Total == undefined ? "" : Math.round(data[1].Total)} decimalScale={2} /></p></td>
                
                
                  <td ><p className='fl-r'><CurrencyFormat fixedDecimalScale={2}  className='non-edit-cs' value={data[1].per == undefined ? "" : Math.round(data[1].per)} decimalScale={2} /><span>%</span></p></td>



                 
                </>

              </tr>
            </tfoot>
}
          </Table>

        </div>
        : null : null}
    </>
  );
}
export default P_L_Table;