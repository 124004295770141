import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import "../Datepagenator.css";
import "./TableComponent.css";
import { ToastContainer, toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import { useState, useEffect } from "react";
import CurrencyInput from "react-currency-input-field";
import CurrencyFormat from "react-currency-format";
import { fobeAxios, mainAxios } from "../../middleware/interceptor";
import Modal from "react-bootstrap/Modal";
import { yellow } from "@material-ui/core/colors";
const TableComponent = ({
  tabs,
  menuId,
  editYearData,
  selectDepartment,
  header,
  data,
  nestedColumn,
  searchFunction,
  Search,
  pagination,
  year,
  bossid,
  getPage,
  tabsname,
  isFooter,
  editYearDatapercentage,
}) => {
  const [items, setitems] = useState(
    JSON.parse(localStorage.getItem("UserClaims"))
  );
  const [isLoading, setIsLoading] = useState(false);
  const [showpop, setShowpop] = useState(false);
  const [label, setLabel] = useState(header);
  const[cadid,setcadid]=useState()
  const[dat,setdata]=useState([])
  const handleCloses = () => {
    setShowpop(false);
  };
  const handleShows = async (e) => {
    setcadid(e)
    let controllerName, url;
    if ((e == 63 && tabsname == "Labor" &&  items.Member_Type!=4) || (e == 64 && tabsname == "Labor" &&  items.Member_Type!=4)) {
        setShowpop(true)


      if (
        (items.Special_user == 0 || items.Special_user == 1) &&
        items.Account_Type != 0
      ) {
      
        if (e == 63) {
          if (budget_type == "2") {
            url = "/Api/Budget/getMgntFront/" + year;
          } else {
            url = "/Api/Budget/getMgntFront/" + year;
          }
        } else if (e == 64) {
          if (budget_type == "2") {
            url = "/Api/Budget/getMngtKitchen/" + year;
          } else {
            url = "/Api/Budget/getMngtKitchen/" + year;
          }
        }
      } else {
        if (e == 63) {
          url = "/Api/Boss/getBossMgntFront/" + bossid + "/" + year;
        } else if (e == 64) {
          url = "/Api/Boss/getBossMngtKitchen/" + bossid + "/" + year;
        }

      
      }
    }
    await fobeAxios.get(url).then((res) => {
        let key
        if(e==63){
            setdata(res.data.Mngt_Front);
        }
        else if(e==64){
            setdata(res.data.Mngt_Kitchen);
        }
        console.log(res.data, "managersalary");
      });
  };
  
  const [show, setShow] = useState(true);

  let [budget_type, setbudget_type] = useState(items.budget_type);
const changeData=(id,value)=>{
    console.log(id,value,"value")
    let temp = dat?.map((x) => {
        if (x.Cat_id == id) {
          x.Cat_amt = value.value;
  
          return x;
        }
        return x;
      });
        setdata(temp)
        console.log(dat,"temp")
}
const save = async () => {
    let controllerName, url;
    if(budget_type=="2"){
        if(cadid==63){
            url="/Api/Budget/updateMgntFront"
        }
        else if(cadid==64){
            url="/Api/Budget/updateMgntKitchen"
        }
    
    }
    else{

        if(cadid==63){
            url="/Api/BudgetPeriod/updateMgntFront"
        }
        else if(cadid==64){
            url="/Api/BudgetPeriod/updateMgntKitchen"
        }
    }
    let budget = {
        Budget: dat,
        Budget_details: [
          { budget_year: items.budget_end_year },
          { budget_type: items.budget_type },
          { Restaurant_id: items.Restaurant_id },
        ],
      };
      await fobeAxios
        .post(url, budget)
        .then((resp) => {
            if(resp.data){
    toast.success("updated Successfully");
            }
            console.log(resp.data,"resp")
     
        })
        .catch((err) => {
      toast.error("updated Failed");
        //   setIsLoading(false);
        });
}
  useEffect(() => {}, []);

  return (
    <>
      {show && (
        <div>
          <div>
            {Search && (
              <>
                <i class="bi bi-search"></i>
                <input
                  id="search-box"
                  class="sear"
                  placeholder="Global Filter"
                  onChange={(e) => searchFunction(e.target.value)}
                />
              </>
            )}
          </div>
          <div class="table-scroll table-revenue">
            <Table striped bordered hover>
              <thead>
                <tr>
                  {!nestedColumn &&
                    header.map((head) => (
                      <th>
                        <div className="tab-card-new">{head.column}</div>
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody class="tab-gf">
                {data.list?.map((item) => (
                  <tr>
                    <td
                      style={{
                        color:
                          (item.Cat_id == 63 && tabsname == "Labor" && items.Member_Type!=4) ||
                          (item.Cat_id == 64 && tabsname == "Labor" && items.Member_Type!=4 )
                            ? "green"
                            : "black",
                      }}
                      onClick={() => handleShows(item.Cat_id)}
                    >
                      {item.Cat_name}
                    </td>
                    <td>
                      {" "}
                      <span>$</span>
                      <p className="fl-r">
                        <CurrencyFormat
                          className={
                            (items?.Special_user == 1 ||
                              items?.Special_user == 0) &&
                            items?.Account_Type != 0
                              ? item.Editable_Status == 1
                                ? "input-edit"
                                : "non-edit-cs"
                              : "non-edit-cs"
                          }
                          decimalScale={2}
                          //   fixedDecimalScale={2}
                          name={item.Cat_id}
                          id={item.Cat_id}
                          disabled={
                            (items?.Special_user == 1 ||
                              items?.Special_user == 0) &&
                            items?.Account_Type != 0
                              ? item.Editable_Status == 1
                                ? false
                                : true
                              : true
                          }
                          value={item.Cat_amt || 0}
                          onValueChange={(value, name) =>
                            editYearData(item.Cat_id, value, menuId, "amt")
                          }
                          thousandSeparator={true}

                          // onValueChange={(value, name) =>  changeData(item.Cat_id, value)}
                        />

                        {/* <td>{item.Cat_amt}</td> */}
                        {/* <CurrencyInput
                                        className={(item.Editable_Status == 1 && item.Default_addon==1) ? 'input-edit' : 'non-edit-cs'}
                                        id={item.Cat_id}
                                        name={item.Cat_id}
                                        defaultValue={item.Cat_amt}
                                        decimalsLimit={2}
                                        disabled={(item.Editable_Status == 1 && item.Default_addon==1) ? false : true}
                                        onValueChange={(value, name) => changeData(item.Cat_id, value)}
                                    /> */}
                      </p>
                    </td>
                    <td>
                      <p className="fl-r">
                        {/* <td>{item.Cat_per}</td> */}
                        <CurrencyFormat
                          className={
                            (items?.Special_user == 1 ||
                              items?.Special_user == 0) &&
                            items?.Account_Type != 0
                              ? item.Editable_Status == 1
                                ? "input-edit"
                                : "non-edit-cs"
                              : "non-edit-cs"
                          }
                          decimalScale={2}
                          fixedDecimalScale={2}
                          name={item.Cat_id}
                          id={item.Cat_id}
                          value={item.Cat_per}
                          onValueChange={(value, name) =>
                            editYearDatapercentage(
                              item.Cat_id,
                              value,
                              menuId,
                              "per"
                            )
                          }
                          disabled={
                            (items?.Special_user == 1 ||
                              items?.Special_user == 0) &&
                            items?.Account_Type != 0
                              ? item.Editable_Status == 1
                                ? false
                                : true
                              : true
                          }
                          thousandSeparator={true}

                          // onValueChange={(value, name) => changeData(item.Cat_id, value)}
                        />
                        {/* <CurrencyInput
                                        className={(item.Editable_Status == 1 && item.Default_addon==2) ? 'input-edit' : 'non-edit-cs'}
                                        id={item.Cat_id}
                                        name={item.Cat_id}
                                        defaultValue={item.Cat_per}
                                        decimalsLimit={2}
                                        disabled={(item.Editable_Status == 1 && item.Default_addon==2) ? false : true}
                                        onValueChange={(value, name) => changeData(item.Cat_id, value)}
                                    /> */}
                        <span>%</span>
                      </p>
                    </td>
                  </tr>
                ))}
                {/* {data.list=""} */}
                {/* {testMethods(data)} */}
              </tbody>
              {tabs && (
                <tfoot>
                  <tr>
                    <td>TOTAL</td>
                    <td>
                      <span>$</span>{" "}
                      <p className="fl-r">
                        {" "}
                        <CurrencyFormat
                          fixedDecimalScale={2}
                          className="non-edit-cs"
                          decimalScale={2}
                          value={data.total}
                          thousandSeparator={true}
                          disabled={true}
                        />
                      </p>
                    </td>
                    <td>
                      <p className="fl-r">
                        <CurrencyFormat
                          className="non-edit-cs"
                          fixedDecimalScale={2}
                          decimalScale={2}
                          value={data.percentage}
                          disabled={true}
                        />
                        <span>%</span>
                      </p>
                    </td>
                  </tr>
                </tfoot>
              )}
            </Table>
          </div>
          <div>
            {pagination && (
              <Pagination className="pag">
                <Pagination.Prev />
                <Pagination.Item onClick={() => getPage()}>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Item>{3}</Pagination.Item>
                <Pagination.Item>{4}</Pagination.Item>
                <Pagination.Item>{5}</Pagination.Item>
                <Pagination.Next />
              </Pagination>
            )}
          </div>
          <Modal show={showpop} onHide={handleCloses}>
            <Modal.Header
              style={{ backgroundColor: "#19A73F", color: "white" }}
            >
              <Modal.Title>
                Update Your Management Values{" "}
                <i
                  class="fa fa-times"
                  aria-hidden="true"
                  style={{
                    cursor: "pointer",
                    color: "white",
                    fontSize: "1em",
                    float: "right",
                    marginTop: "3px",
                  }}
                  onClick={handleCloses}
                ></i>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body style={{ maxHeight: "400px", overflowY: "scroll" }}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>
                      <div className="tab-card-new">Cateogry</div>
                    </th>
                    <th>
                      <div className="tab-card-new">Amount</div>
                    </th>
                  </tr>
                </thead>
                <tbody class="tab-gf">
                  {dat?.map((item) => (
                    <tr>
                      <td
                        style={{
                          color:
                            (item.Cat_id == 63 && tabsname == "Labor") ||
                            (item.Cat_id == 64 && tabsname == "Labor")
                              ? "green"
                              : "black",
                        }}
                        onClick={() => handleShows(item.Cat_id)}
                      >
                        {item.Cat_name}
                      </td>

                      <CurrencyFormat
                      className='input-edit'
                      decimalScale={2}
                   
                      name={item.Cat_id}
                      id={item.Cat_id}
                      value={item.Cat_amt}
                    //   onValueChange={(value, name) =>
                    //     editYearDatapercentage(
                    //       item.Cat_id,
                    //       value,
                    //       menuId,
                    //       "per"
                    //     )
                    //   }
                      
                      thousandSeparator={true}

                      onValueChange={(value, name) => changeData(item.Cat_id, value)}
                    />
                    </tr>
                  ))}
                  {/* {data.list=""} */}
                  {/* {testMethods(data)} */}
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex justify-content-end w-100">
                <Button
                  type="button"
                  variant="secondary"
                  onClick={handleCloses}
                >
                  Close
                </Button>
                <Button onClick={save}>Save</Button>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
};

export default TableComponent;
