import "bootstrap/dist/css/bootstrap.min.css";
import "./TableComponent.css";
import Table from "react-bootstrap/Table";
import { NavItem } from "react-bootstrap";
import { useState } from "react";
import ReactLoading from "react-loading";

import CurrencyFormat from "react-currency-format";

const Tablespanrow = ({
  editDailySales,
  menuId,
  data,
  title,
  header,
  isBudget,
}) => {
  const [items, setitems] = useState(
    JSON.parse(localStorage.getItem("UserClaims"))
  );
  const [totalAmtColorforrevenue, settotalAmtColorforrevenue] = useState(
    data.list[2].Total > data.list[1].Total ? "red" : "green"
  );
  const [totalAmtColorForothers, settotalAmtColorForothers] = useState(
    data.list[2].Total > data.list[1].Total ? "green" : "red"
  );

  const [totalAmtColorforrevenueper, settotalAmtColorforrevenueper] = useState(
    data.list[2].per > data.list[1].per ? "red" : "green"
  );
  const [totalAmtColorForothersper, settotalAmtColorForothersper] = useState(
    data.list[2].per > data.list[1].per ? "green" : "red"
  );

  return (
    // <>
    // {load ?
    <div className="table-scroll table-responsive table1 ">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th rowSpan={2}>
              <div className="title-tab">{title}</div>
            </th>
            {header.map((head, index) => (
              <th colSpan={2}>
                {" "}
                <div className="tab-card-new">{head.column}</div>
              </th>
            ))}
          </tr>
          <tr>
            {header.slice(0, header.length).map((x) => (
              <>
                <th>
                  <div className="tab-card-new">
                    {isBudget ? "BUDGETED" : "AMOUNT"}
                  </div>
                </th>
                <th>
                  <div className="tab-card-new">PER(%)</div>
                </th>
              </>
            ))}
          </tr>
        </thead>

        <tbody>
          {/* {data.list?.map(item =>
            <tr>
              <td>{item.category}</td>
              <td><span>$</span> <p className='fl-r'>{item.dailyAmt}</p></td>
              <td><p className='fl-r'>{item.dailyPer}<span>%</span></p></td>
              <td><span>$</span> <p className='fl-r'>{item.monthAmt}</p></td>
              <td><p className='fl-r'>{item.monthPer}<span>%</span></p></td>
              <td><span>$</span> <p className='fl-r'>{item.budgetAmt}</p></td>
              <td><p className='fl-r'>{item.budgetPer}<span>%</span></p></td>
            </tr>
          )}          */}

          {data?.list[0].List?.map((item, index) => {
            let temp = [];
            data?.list?.map((x) => {
              temp.push({ ...x.List[index], type: x.Name });
            });
            console.log(temp, "tempdata");
            console.log(temp[0].Cat_name, "tempdata");
            return (
              <tr>
                <td>{temp[0].Cat_name}</td>{" "}
                {temp.map((cat, i) => {
                  const catAmtColorforrevenue =
                    Math.round(temp[2].Cat_amt) > Math.round(temp[1].Cat_amt)
                      ? "red"
                      : "green";
                  const catAmtColorForothers =
                    temp[0].Cat_name == "Total Revenue"
                      ? Math.round(temp[2].Cat_amt) <=
                        Math.round(temp[1].Cat_amt)
                        ? "green"
                        : "red"
                      : Math.round(temp[2].Cat_amt) >=
                        Math.round(temp[1].Cat_amt)
                      ? "green"
                      : "red";

                  const catAmtColorforrevenueper =
                    Math.round(temp[2].Cat_per) > Math.round(temp[1].Cat_per)
                      ? "red"
                      : "green";
                  const catAmtColorForothersper =
                    temp[0].Cat_name == "Total Revenue"
                      ? Math.round(temp[2].Cat_per) <=
                        Math.round(temp[1].Cat_per)
                        ? "green"
                        : "red"
                      : Math.round(temp[2].Cat_per) >=
                        Math.round(temp[1].Cat_per)
                      ? "green"
                      : "red";

                  return (
                    <>
                      <td
                        style={{
                          color:
                            i == 1 && title == "REVENUE"
                              ? catAmtColorforrevenue
                              : i == 1 && title != "REVENUE"
                              ? catAmtColorForothers
                              : "black",
                        }}
                      >
                        <span class="fl-lf">$</span>

                        <CurrencyFormat
                          className={
                            (items?.Special_user == 1 ||
                              items?.Special_user == 0) &&
                            items?.Account_Type != 0
                              ? cat.Editable_Status == 1 && cat.type === "Daily"
                                ? "input-edit-monthly fl-r"
                                : "non-edit-cs fl-r"
                              : "non-edit-cs fl-r"
                          }
                          id={cat.Cat_id}
                          name={cat.Cat_id}
                          fixedDecimalScale={2}
                          
                          
                      value={Math.abs(Math.round((cat.Cat_amt)))}
                          disabled={
                            (items?.Special_user == 1 ||
                              items?.Special_user == 0) &&
                            items?.Account_Type != 0
                              ? cat.Editable_Status == 1
                                ? false
                                : true
                              : true
                          }
                          onChange={(e) => {
                            editDailySales(cat.Cat_id, e.target, cat.type);
                          }}
                           style={title=="PROFIT/LOSS" && Math.round(cat.Cat_amt)< 0?{color:"red"}:{}}
                     prefix={title === "PROFIT/LOSS" && Math.round(cat.Cat_amt) < 0 ? "( " : ""}
                     suffix={title === "PROFIT/LOSS" && Math.round(cat.Cat_amt) < 0 ? ")" : ""}
                        />
                      </td>
                      <td
                        style={{
                          color:
                            i == 1 && title == "REVENUE"
                              ? catAmtColorforrevenueper
                              : i == 1 && title != "REVENUE"
                              ? catAmtColorForothersper
                              : "black",
                        }}
                      >
                        <p className="fl-r">
                          <CurrencyFormat
                            className="non-edit-cs" //{(cat.Editable_Status == 1) ? 'input-edit-monthly' : 'non-edit-cs'}
                            id={cat.Cat_id}
                            name={cat.Cat_id}
                            fixedDecimalScale={2}
                             value={Math.abs(Math.round(cat.Cat_per))}
                            decimalScale={2}
                      disabled={ ((items?.Special_user==1||items?.Special_user==0) && items?.Account_Type!=0) ? cat.Editable_Status ==1?false:true: true}
                      style={title=="PROFIT/LOSS" && Math.round(cat.Cat_amt)< 0?{color:"red"}:{}}
                      prefix={title === "PROFIT/LOSS" && Math.round(cat.Cat_amt) < 0 ? "( " : ""}
                      suffix={title === "PROFIT/LOSS" && Math.round(cat.Cat_amt) < 0 ? ")" : ""}
                            
                            // onValueChange={(value, name) => changeData(cat.Cat_id, value)}
                          />
                          <span class="fl-ri">%</span>
                        </p>
                      </td>
                    </>
                  );
                })}
              </tr>
            );
          })}
        </tbody>

        {menuId != 7 && (
          <tfoot>
            <tr>
              <td>TOTAL</td>
              <>
                <td>
                  <span>$</span>{" "}
                  <p className="fl-r">
                    {" "}
                    <CurrencyFormat
                      fixedDecimalScale={2}
                      className="non-edit-cs"
                      value={
                        data.list[0].Total == undefined
                          ? ""
                          : data.list[0].Total
                      }
                      decimalScale={2}
                      disabled={true}
                    />
                  </p>
                </td>
                <td>
                  <p className="fl-r">
                    <CurrencyFormat
                      fixedDecimalScale={2}
                      className="non-edit-cs"
                      value={
                        data.list[0].per == undefined
                          ? ""
                          : Math.round(data.list[0].per)
                      }
                      decimalScale={2}
                      disabled={true}
                    />

                    <span>%</span>
                  </p>
                </td>

                <td>
                  <span>$</span>{" "}
                  <p className="fl-r">
                    <CurrencyFormat
                      fixedDecimalScale={2}
                      className="non-edit-cs"
                      style={{
                        color:
                          title === "REVENUE" &&
                          Math.round(data.list[1].Total) >=
                            Math.round(data.list[2].Total)
                            ? "green"
                            : title !== "REVENUE" &&
                              Math.round(data.list[1].Total) <=
                                Math.round(data.list[2].Total)
                            ? "green"
                            : Math.round(data.list[1].Total) ===
                              Math.round(data.list[2].Total)
                            ? "green"
                            : "red",
                      }}
                      value={
                        data.list[1].Total == undefined
                          ? ""
                          : Math.round(data.list[1].Total)
                      }
                      disabled={true}
                      decimalScale={2}
                    />
                  </p>
                </td>
                <td>
                  <p className="fl-r">
                    <CurrencyFormat
                      fixedDecimalScale={2}
                      className="non-edit-cs"
                      style={{
                        color:
                          title === "REVENUE" &&
                          Math.round(data.list[1].per) >=
                            Math.round(data.list[2].per)
                            ? "green"
                            : title !== "REVENUE" &&
                              Math.round(data.list[1].per) <=
                                Math.round(data.list[2].per)
                            ? "green"
                            : Math.round(data.list[1].per) ===
                              Math.round(data.list[2].per)
                            ? "green"
                            : "red",
                      }}
                      disabled={true}
                      value={
                        data.list[1].per == undefined
                          ? ""
                          : Math.round(data.list[1].per)
                      }
                      decimalScale={2}
                    />
                    <span>%</span>
                  </p>
                </td>

                <td>
                  <span>$</span>{" "}
                  <p className="fl-r">
                    <CurrencyFormat
                      fixedDecimalScale={2}
                      className="non-edit-cs"
                      disabled={true}
                      value={
                        data.list[2].Total == undefined
                          ? ""
                          : data.list[2].Total
                      }
                      decimalScale={2}
                    />
                  </p>
                </td>
                <td>
                  <p className="fl-r">
                    <CurrencyFormat
                      fixedDecimalScale={2}
                      className="non-edit-cs"
                      disabled={true}
                      value={
                        data.list[2].per == undefined ? "" : data.list[2].per
                      }
                      decimalScale={2}
                    />
                    <span>%</span>
                  </p>
                </td>
              </>
            </tr>
          </tfoot>
        )}
      </Table>
    </div>

    //   :<div className='Ball-Loading'><span className='Ball-inner'><ReactLoading type={'balls'} color={'Green'} height={30} width={50} /> </span></div>
    // }
    // </>
  );
};
export default Tablespanrow;
