import '../Header/header.css'
import 'bootstrap/dist/css/bootstrap.css';
import { NavLink ,useNavigate} from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { LocalStorageService } from '../Services/LocalStorageService';
import { useEffect } from 'react';
import { useState } from 'react';
// import { history } from './Services/history';


const  Header = () => {
  const [token,setToken]=useState(null);

  const navigate=useNavigate();
  const home=()=>{
    navigate('/')
  }

  const homeorlogin=(e)=>{
      navigate('/')
  }
  useEffect(()=>{
    const localStorageService = LocalStorageService.getService();
    setToken(localStorageService.getAccessToken("auth-token"));
  },[])
 

    return (
      <>
      <nav className="navbar navbar-static-top navbar-inverse bg-inverse mt-2">
  <div className="container-fluid">
    <div className='container'>  
    <div className='row'>
    <div className="navbar-header">
      <button className="navbar-toggle" type="button" data-toggle="collapse" data-target=".navbarSupportedContent">    
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>   
      </button>
      <div class="col-md-3 col-sm-3 col-xs-12 brand-logo">
                        <a title="Fobegolf-Logo" onClick={homeorlogin} >
                            <img src="https://d2a7u30t6wbkim.cloudfront.net/assets/fobegolf/image/logo.png" class="img100" alt="FobeGolf-Logo" title="FobeGolf-Logo"/>
                        </a>
                    </div>
    </div>
    <div class="info-cnt">
                                <div class="info-mble"><a href="tel:9045380730" class="num-a">904-538-0780</a></div>
                                <span class="lin-br"></span>
                                <div class="info-email">SUPPORT@FOBEGOLF.COM</div>
                                {/* <div class="info-social">
                                    <li class=""> <a href="#"><i class="bi bi-facebook social_icon" title="facebook"></i></a></li>
                                    <li class=""><a href="#"><i class="bi bi-youtube social_icon" title="youtube"></i> </a></li>
                                    <li class=""><a href="#"><i class="bi bi-linkedin social_icon" title="linkedin"></i></a></li>
                                    <li class=""> <a href="#"><i class="bi bi-instagram social_icon" title="instagram"></i></a></li>
                                   
                                
                                
                               
                                </div> */}
                                
                                    {/* <div><i class="fab fa-facebook-f"></i></div>
                                    <div><i class="fab fa-youtube"></i></div>
                                    <div><i class="fab fa-linkedin-in"></i></div>
                                    <div><i class="fab fa-instagram"></i></div> */}
                            </div>
    <div className="collapse navbar-collapse navbarSupportedContent">
    <ul class="nav navbar-nav">
                                        <li><a  className='link' href="https://dev.fobegolf.com/services.html">Services</a></li>
                                        <li><a  className='link' href="https://dev.fobegolf.com/Blog">Blog</a></li>
                                        <li><a  className='link' href="https://dev.fobegolf.com/about-us.html">About Us</a></li>
                                        <li><a  href="https://dev.fobegolf.com/contact-us.html" type="button" class="btn btn-appstore btn-success btn_whit" >Schedule Demo</a></li>
                                        <li><a  href="https://dev.fobegolf.com/contact-us.html" type="button" class="btn btn-appstore btn-success btn_whit" >Request Info</a></li>
                                        <li><a className='link' onClick={()=>{home()}}>LOGIN</a></li>
                                        
              
                                    </ul>
                                    </div> 
                                    </div> 
    </div>
  </div>
</nav> 
     </> 
    );
};

export default Header;