import "bootstrap/dist/css/bootstrap.min.css";
import "../Tab_manageuser/Tab_user.css";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Pagination from "react-bootstrap/Pagination";
import TableComponent from "../Component/Tables/TableComponent";
import { useState, useEffect } from "react";
import axios from "axios";
import { mainAxios } from "../middleware/interceptor";
import { Spinner } from "../Component/Spinner";

const sample = [
  {
    firstName: "Nagaraj",
    lastName: "Vanaraj",
    email: "nagaraj@jetzerp.com",
    password: "river",
    course: "cse",
    department: "it",
    Role: "Team Member",
    Status: "1",
    FobesoftStatus: "Not Applicable",
  },
];
const column = [
  { column: "First Name" },
  { column: "Last Name" },
  { column: "Email" },
  { column: "Password" },
  { column: "Course" },
  { column: "Department" },
  { column: "Role" },
  { column: "Status" },
  { column: "Action" },
];

function ViewTeamMembers({ tab, setTab, setEditUser, setDeleteUser }) {
  const [data, setData] = useState(sample);
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const[userupdated,setUserUpdated]=useState(false);


  const getData = async () => {
    let result = [];
    const { data } = await mainAxios.get(`Api/Hampton/GetUsersListnew`);
    console.log("UserList", data.getResultList);

    let Role = "";
    data.getResultList.map((x) => {
      if (x.Posid == "0") {
        Role = "General Manager";
      }
      if (x.Posid == "2") {
        Role = "Team member";
      }
      if (x.Posid == "1") {
        Role = "Department Head";
      }
      if (x.Posid == "4") {
        Role = "Super User";
      }
      result.push({
        Userid: x.UserId,
        firstName: x.Fname,
        lastName: x.Lname,
        email: x.Email,
        password: x.Password,
        course: x.Course,
        department: x.DeptName,
        Courseid: x.Course,
        Deptid: x.Deptid,
        selectRole: x.Posid,
        Role: Role,
        status_d: x.Business_Status,
        Status: x.status == 1 ? true : false,
        FobesoftStatus: "Not Appliacable",
        Phone: x.Phone,
        Posid: x.Posid,
      });
    });
    setData(result);
    setSearch(result);
    setIsLoading(false);
    // console.log(data.getResultList);
  };

  useEffect(() => {
    setSearchText("");
    getData();
   
  }, [tab]);
  useEffect(()=>{
    getData();
    if(searchText!==""){
      searchFunction(searchText)
    }
  },[userupdated])

  const searchFunction = (searchText) => {
    setSearchText(searchText);
    console.log(searchText);
    let user = data.filter((item) => {
      return(
        item.Role.toLowerCase().startsWith(searchText.toLowerCase()) || item.email.toLowerCase().startsWith(searchText.toLowerCase()) || item.course.trim().toLowerCase().startsWith(searchText.toLowerCase()))});
  console.log(user,"SearchingDetails")
        if (user.length > 0) {
      setSearch(user);
      console.log(user, "Users");
    } else {
      setSearch([]);
    }
  };

  const getPage = () => {};

  const editUser = (user) => {
    console.log("user:", user);
    setEditUser(user);
    setTab("Add");
  };

  return (
    <div class="table-sections">
      {isLoading ? (
        <Spinner />
      ) : (
        <TableComponent
          header={column}
          data={{ list: search }}
          nestedColumn={false}
          editUser={editUser}
          Search={true}
          searchFunction={searchFunction}
          searchstate={searchText}
          pagination={true}
          user_up={userupdated}
          loader={setIsLoading}
          set_user_up={setUserUpdated}
          getPage={getPage}
        />
      )}
    </div>
  );
}
export default ViewTeamMembers;
