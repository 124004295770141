import 'bootstrap/dist/css/bootstrap.min.css'
import DatesPaginator from '../Component/Datepagenator'
import './Rounds.css';
import BookedTenderedRoundsTable from '../Component/Tables/SnapshotDashboardTable/BookedTenderedRoundsTable';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { mainAxios } from '../middleware/interceptor';
import { Spinner } from '../Component/Spinner'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const header =

   [{ column: 'DAYS' }, { column: 'ACTUAL/FORECAST ' }, { column: 'BUDGET' }];

const sample1 =
{
   list: [{ date: '9/19/2022 12:00:00 AM', dailyAmt: '0', budgetAmt: '3' }]
}
const sample = ''


const BookedTenderedRounds = ({ dropdowndata,tabs }) => {


   let newDate = new Date()
   let cdate = newDate.getDate();
   let month = newDate.getMonth() + 1;
   let year = newDate.getFullYear();

   const [loading, setLoading] = useState(true)

   // const[sample,setsample]=useState(sample)
   const [d, setd] = useState(cdate)
   const [m, setm] = useState(month)
   const [y, sety] = useState(year)

   const [bookedRounds, setbookedRounds] = useState();
   const [bookedRoundData, setbookedRoundData] = useState([]);
   const [show, setshow] = useState(false);
   const [date, datee] = useState(new Date().getUTCFullYear() + '/' + (new Date().getMonth() + 1) + '/' + new Date().getDate() + '/');


   useEffect(() => {
      setLoading(true);
      mainAxios.get('/Api/Hampton/getBookedRounds/' + date + dropdowndata+"/").then(resp => {
         if (resp) {
            setshow(true);
            setbookedRoundData(resp.data['bookedRoundData'])
            setbookedRounds(resp.data);
            console.log("okbyedates",resp.data['bookedRoundData'])
         }
         setLoading(false);
      })
   }, [dropdowndata,tabs]);

   useEffect(() => {
      setLoading(true);
      let dt = y + '/' + m + '/' + d + '/'
      mainAxios.get('/Api/Hampton/getBookedRounds/' + dt + dropdowndata+"/").then(resp => {
         if (resp) {
            setshow(true);
            setbookedRoundData(resp.data['bookedRoundData'])
            setbookedRounds(resp.data);
            console.log(resp.data,"forbuycourse");
         }
         console.log(resp,"forbuycourse");
         setLoading(false);
      })
   }, [d]);

   const handleActualRound = (date, value) => {
      console.log('handleActualRound:',date,value)
      let rounds = [...bookedRoundData];
      rounds.map(x => {
         if (x.date == date)
         if(value !==undefined && value !==""){
            x.Actual_Rounds = parseInt(value);
         }
         else{
            x.Actual_Rounds=0;
         }
            
      })
      setbookedRoundData(rounds)
   }

   const saveBookRound = async () => {
      
      let roundsData={...bookedRounds,bookedRoundData:bookedRoundData}
      console.log(roundsData,"forbuyrounds")    
      await mainAxios
      .post("Api/Hampton/UpdateBookedRounds",roundsData)
      .then((resp) => {
          toast.success("Rounds Updated Successfully");
      }).catch(err => {
          toast.error("Rounds Update Failed");
      })       
      
  }




   return (
      <>

         {loading && <Spinner />}
         {/* <ToastContainer autoClose={3000}/> */}
         {show &&
            <div className='container'>

               <DatesPaginator dt={setd} dm={setm} ds={sety} tab={tabs}/>

               <div className='row'>
                  <div class="table-sections">
                     <BookedTenderedRoundsTable handleActualRound={handleActualRound} bookedRoundData={bookedRoundData} header={header} />

                  </div>
                  <div class="col-sm-12 bt-pr">
                     <button class="btn btn-success cxt-ctr" onClick={() => saveBookRound()}><span class="up-pr">Submit</span></button>
                  </div>
               </div>
            </div>}
      </>
   );
}
export default BookedTenderedRounds;