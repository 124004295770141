import React, { useState, Component,useRef, useEffect } from "react";
import DatePagination from '../Datepagination/index';
import './Datepagenator.css';
import Moment from 'moment';



const DatesPaginator = ({ dt, dm, ds,budget_type,date,tab,t,}) => {

  const [activePage, setActivePage] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());

  {console.log(t,"data123")}
useEffect(() => {
  if(t){
    setActivePage(parseInt(t))
    {console.log(dt,"dei im dt")}

  }
},[t])
  var arr = new Array();
  var rt = new Date();
  let daysArray;

  
  useEffect(() => {
    if(budget_type===1){

    }
    else{
    getCalenderdate()
    dm(selectedDate.getMonth() + 1);
    ds(selectedDate.getFullYear());
    dt(selectedDate.getDate());}
  }, [selectedDate,budget_type])


  const getCalenderdate=()=>{
    if(daysArray.length>0){
 daysArray.map((item, i)=>{
  if(item.date.toString() === selectedDate.toString()){
        setActivePage(i+1);
       }
    });
    }
  }
  const Dateref = useRef(null);
  const[open,setopen]=useState(false)


  useEffect(()=>{
    daysArray.map((x, index) => {
      console.log(x,'x vlue')
      if (Moment(x.date).format("YYYY-MM-DD") == Moment(new Date()).format("YYYY-MM-DD"))
        setActivePage(index + 1);
    })

  }, [tab,budget_type])


   useEffect(()=>{
    const handleClickOutside = (event) => {
      if (Dateref.current &&!event.target.className.toString().includes("react-datepicker") ) {
        console.log(event.target.className.toString().includes("react-datepicker"),"datedbyref")
       setopen(false)
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
   },[open])

  const handlePageChange = (pageNumber) => {

    console.log("pageNumber:", pageNumber)
    setActivePage(pageNumber);

    const s = Moment(daysArray[pageNumber - 1].date).format("YYYY-MM-DD");
    const date = s.split('-');

    dt(date[2]);
    dm(date[1]);
    ds(date[0]);
  
  };

  var startDate;
  var endDate;

  if(budget_type===1){
    startDate = new Date("2023-01-01"); 
 
    endDate = new Date("2023-01-28");
  }
  else{
      startDate = new Date("2018-01-01"); 
 
      endDate = new Date("2023-12-31");} 
 

  var getDateArray = function (start, end) {
    // var arr = new Array();
    var dt = new Date(start);
    var i = 0;
    while (dt <= end) {
      i = i + 1;
   
      if (new Date(Moment(dt).format("YYYY-MM-DD")) == Moment(rt).format("YYYY-MM-DD")) {
    
        setActivePage(i);
      }
      arr.push({ date: new Date(Moment(dt).format("YYYY-MM-DD")) });


      dt.setDate(dt.getDate() + 1);
    }
    console.log(arr, "fordatafound")
    return arr;
  }


  daysArray = getDateArray(startDate, endDate);
  console.log(daysArray, "daysArray")


 
  return (
    <div className="datepaginator">

      {daysArray.length &&
        <DatePagination
          activePage={activePage}
          itemsCountPerPage={1}
          totalItemsCount={arr.length > 0 ? arr.length : 1}
          days={arr.length > 0 ? arr : []}
          pageRangeDisplayed={7}
          onChange={handlePageChange}
          setSelectedDate={setSelectedDate}
          itemClass="item213"
          activeClass="asdf"
          DateRef={Dateref}
          open={open}
          setopen={setopen}
          budget_type={budget_type}
          
        />}
    </div>
  );
};

export default DatesPaginator;