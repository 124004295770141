import Table from "react-bootstrap/Table";
import { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import "../Datepagenator.css";
import "./TableComponent.css";
import "../../index.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { mainAxios } from "../../middleware/interceptor";
import Pagination from "@mui/material/Pagination";
import { ToastContainer,toast } from "react-toastify";
import { PaginationItem } from "@mui/material";

import image from "../../folder.png";
const TableComponent = ({
  user_up,
  set_user_up,
  header,
  data,
  nestedColumn,
  searchFunction,
  Search,
  pagination,
  getPage,
  isFooter,
  editUser,
  loader,
  DeleteUser,
  searchstate,
}) => {
  console.log(searchstate, "dataa");

  const renderTooltip = (props) => <Tooltip {...props}>Edit</Tooltip>;
  const renderTooltip1 = (props) => <Tooltip {...props}>Delete</Tooltip>;
  const [show, setShow] = useState(false);
  const [recordPerPage, setRecordPerPage] = useState(5);
  const [users, setUser] = useState(false);
  const [deleteitem, setitem] = useState();
  let [length, setlength] = useState(recordPerPage);

  useEffect(() => {

    if (data.list.length > 0) {
      setUser(data.list.slice(0, recordPerPage));
      console.log("data:", users);
      setShow(true);
    }
    else{
      setShow(false);
    }
  }, [data, length]);

  useEffect(() => {
    setUser(
      data.list.slice(0 * recordPerPage, 0 * recordPerPage + recordPerPage)
    );
  }, [recordPerPage, length]);

  const handlePageChange = (event, value) => {
    value = value - 1;
    setUser(
      data.list.slice(
        value * recordPerPage,
        value * recordPerPage + recordPerPage
      )
    );
  };

  const handlechange = (e) => {
    editUser(e);
    
  };

  const handledelete = async (e) => {
    loader(true)
    console.log("DElete", e)
    var usrNew = {
      UserId: e.Userid,
      Posid: e.Posid,
      status: 3, 
    };

  
    mainAxios.post(`Api/Hampton/DeleteUser`, usrNew).then((res) => {
      if (res.data.length > 0) {
        res.data.map((x) => {
          setShow(true);
        
          
          toast.success(x.Message);
          setTimeout(()=>{
            set_user_up(!user_up)
            loader(false)
          },500)
     
        });
      }

     
    }).catch(()=>{
      loader(false)
    });
  };

const ActiveorDeActive=(e,x)=>{
  loader(true);
  console.log(e,"changingActive")
  console.log("DElete", x)
  var usrNew = {
    UserId: x.Userid,
    Posid: x.Posid,
    status: e ?1: 2, 
  };

  
  mainAxios.post(`Api/Hampton/DeleteUser`, usrNew).then((res) => {
    if (res.data.length > 0) {
      res.data.map((x) => {
        setShow(true);

        toast.success(x.Message);
        setTimeout(()=>{
          set_user_up(!user_up);
          loader(false);
        },500)
      }).catch(()=>{
        loader(false)
      });
    }

   
  });
}

  const [shows, setShows] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(false);

  return (
    <>
     
        <div>
      
            <div>
              <div class="row">
                <div class="col-sm-6" style={{ float: "left" }}>
                  <span>
                    {" "}
                    <b>Show</b>
                  </span>
                  <Form.Select
                    className="dr-sel"
                    aria-label="Default select example"
                    onChange={(e) => setRecordPerPage(+e.target.value)}
                  >
                    <option value="5">5</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                  </Form.Select>
                  <span>
                    <b>Entries</b>
                  </span>
                </div>

                <div class="col-sm-6">
                  {Search && (
                    <div class="row">
                      <div class="col-sm-6">
                        <i
                          class="bi bi-search"
                          style={{
                            marginLeft: "0em",
                            float: "right",
                            marginRight: "1em",
                            color: "#686464",
                          }}
                        />
                      </div>
                      <div class="col-sm-6">
                        <input
                          id="search-box"
                          value={searchstate}
                          class="sear"
                          placeholder="Global Filter"
                          onChange={(e) => searchFunction(e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {show ? ( <div class="table-scroll">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      {!nestedColumn &&
                        header.map((head) => (
                          <th style={{ zIndex: 1 }}>
                            <div className="tab-card-new">{head.column}</div>
                          </th>
                        ))}
                    </tr>
                  </thead>
                  <tbody class="tab-gf">
                    {users?.map((item) => {
                      console.log(item,"fromitemt")
                      let user = Object.assign({}, item);
                      delete user["Phone"];
                      delete user["Courseid"];

                      delete user["Deptid"];
                      delete user["selectRole"];
                      delete user["Userid"];
                      delete user["Posid"];
                      delete user["status_d"];
                      delete user["FobesoftStatus"]
                      // console.log("ditem:",item);

                      return (
                        <tr>
                          {Object.keys(user).map((key) => {
                            let color;
                            if (item[key].toString().toUpperCase().replace(/\s/g, '') === "SUPERUSER") {
                              color = "label-success";
                            }
                            if (item[key].toString().toUpperCase().replace(/\s/g, '') === "GENERALMANAGER") {
                              color = "label-info";
                            }
                            if (item[key].toString().toUpperCase().replace(/\s/g, '') === "DEPARTMENTHEAD") {
                              color = "label-primary";
                            }
                            if (item[key].toString().toUpperCase().replace(/\s/g, '') === "TEAMMEMBER") {
                              color = "label-warning";
                            } 

                            return (
                              <>
                                <td>
                                  {key === "Role" && (
                                    <span class={"label " + color}>
                                      {item[key]}
                                    </span>
                                  )}

                                  {/* {key === "FobesoftStatus" && (
                                    <span class="label label-default">
                                      {item[key]}
                                    </span>
                                  )} */}

                                  {key === "Status" ? (
                                    <BootstrapSwitchButton
                                      checked={item[key]}
                                      size="sm"
                                      onChange={(e)=>{ActiveorDeActive(e,item);searchFunction("");}}
                                    />
                                  ) : (
                                    key !== "Role" &&
                                    key !== "Status" &&
                                    key !== "FobesoftStatus" &&
                                    item[key]
                                  )}
                                </td>{" "}
                              </>
                            );
                          })}
                          <OverlayTrigger
                            placement="top"
                            overlay={renderTooltip}
                          >
                            <i
                              class="bi-pencil-fill"
                              onClick={() => handlechange(item)}
                            ></i>
                          </OverlayTrigger>{" "}
                          <OverlayTrigger
                            placement="top"
                            overlay={renderTooltip1}
                          >
                            <i
                              class="bi-trash"
                              onClick={() => {
                                setShows(true);
                                setitem(item);
                              }}
                            ></i>
                          </OverlayTrigger>
                          {/* <Modal
                            show={shows}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                          >
                            <Modal.Header>
                              <Modal.Title></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              Are you sure you want to delete account?
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                variant="secondary"
                                onClick={(e) => {
                                  handleClose(e);
                                  setShows(false);
                                  setShow(true);
                                }}
                              >
                                No
                              </Button>

                              <Button
                                variant="primary"
                                onClick={(e) => {
                                  handledelete(item);
                                  handleClose(e);
                                  setShows(false);
                                }}
                              >
                                Yes
                              </Button>
                            </Modal.Footer>
                          </Modal> */}
                        </tr>
                      );
                    })}
                  </tbody>

                  {isFooter && (
                    <tfoot>
                      <tr>
                        <td>TOTAL</td>
                        <td>
                          <span>$</span> <p className="fl-r">{data.total}</p>
                        </td>
                        <td>
                          <p className="fl-r">
                            {data.percentage}
                            <span>%</span>
                          </p>
                        </td>
                      </tr>
                    </tfoot>
                  )}
                </Table>
              </div>
              ) :
               (
                <div>
                
                    <div class="table-scroll" style={{ overflow: "hidden" }}>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            {!nestedColumn &&
                              header.map((head) => (
                                <th>
                                  <div className="tab-card-new">{head.column}</div>
                                </th>
                              ))}
                          </tr>
                        </thead>
                      </Table>
        
                      <img src={image} className="img" />
        
                      <div>
                        <span
                          style={{
                            fontWeight: "bolder",
                            marginLeft: "503px",
                            fontSize: "15px",
                            opacity: "0.6",
                            fontFamily: "FontAwesome",
                          }}
                        >
                          No Data Found
                        </span>
                      </div>
                    </div>
                  
                </div>
              )}
              <div style={{ marginLeft: "-394px", marginBottom: "17px" }}>
                {pagination && (
                  <Pagination
                    count={Math.ceil(data.list.length / recordPerPage)}
                    className={"paginate"}
                    //variant="outlined"
                    //shape="rounded"
                    onChange={handlePageChange}
                  ></Pagination>
                )}
              </div>
            </div>
       
        </div>
      
      <Modal size="sm" show={shows} onHide={handleClose}>
        <Modal.Body className='p-2 text-center' style={{ fontSize: "1.2em" }}> Are you sure you want to delete account?</Modal.Body>
        <Modal.Footer style={{ border: "none", textAlign: "center" }}>
          <Button variant="mute" style={{ marginRight: "1em", background: "red", color: "white", padding: "0.2em 1em" }} onClick={(e) => {
            handleClose(e);
            setShows(false);
            setShow(true);
          }}>
            No
          </Button>
          <Button variant="mute" style={{ marginRight: "1em", background: "green", color: "white", padding: "0.2em 1em" }} onClick={(e) => {handledelete(deleteitem); handleClose(e);   setShows(false); searchFunction(""); }} 
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TableComponent;
