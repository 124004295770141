import "./Login.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { LocalStorageService } from "./Services/LocalStorageService";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { mainAxios, FobeAxios } from "./middleware/interceptor";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { event } from "jquery";
import * as Yup from "yup";

const loginSchema = yup.object().shape({
userName: Yup.string().email("Invalid email format").required("Email is required"),
  password: yup.string().min(3).max(32).required(),
});

function Login({ setIsActive }) {
  const key = "user_details";
  let navigate = useNavigate();
  const [pwd, setPwd] = useState("password");
  const [cls, setcls] = useState("bi bi-eye-slash-fill");
  const [items, setitems] = useState();
  const [Remember, setRemember] = useState(false);
  // function setWithExpiry(key, value,ttl)
  function setWithExpiry(key, value) {
    // const now = new Date()
    const item = {
      value: value,
      // expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }

  function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);

    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    // const now = new Date()

    // if (now.getTime() > item.expiry) {

    //     localStorage.removeItem(key)
    //     return null
    // }
    return item.value;
  }

  let user = getWithExpiry(key);

  // console.log(user)
  let user_name;
  let password;

  if (user !== null) {
    user_name = user.userName;
    password = user.password;
  } else {
    user_name = "admin@fobegolf.com";
    password = "Admin@123";
  }

  let {
    register,
    handleSubmit,
    setValue,
    formState: { touched, errors },
  } = useForm({
    defaultValues: {
      userName: user_name,
      password: password,
    },
    resolver: yupResolver(loginSchema),
  });
  // var data = "username=" + combainClienId + "&password=" + password + "&grant_type=password";
  const localStorageService = LocalStorageService.getService();
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    "Accept-Encoding": "gzip, deflate, br",
    Accept: "*/*",
    Connection: "keep-alive",
  };
  const token1 = localStorageService.getAccessToken("auth-token");
  const normalheaders = {
    Authorization: "Bearer " + token1,
    "Content-Type": "application/json",
  };

  const onSubmit = async (data) => {
    setIsActive(true);

    localStorage.clear();
    var datas =
      "username=" +
      data.userName +
      "-100" +
      "&password=" +
      data.password +
      "&grant_type=password";
    if (Remember) {
      var user_info = {
        userName: data.userName,
        password: data.password,
      };

      // var ttl=10000000000;

      // setWithExpiry(key,user_info,10000000000);

      setWithExpiry(key, user_info);
    }
    return await mainAxios
      .post("/token", datas, {
        headers: headers,
      })
      .then((res) => {
        localStorageService.setToken(res.data.access_token);
        if (res.data.access_token) {
          mainAxios
            .get("/Api/User/GetUserClaims")
            .then((resp) => {
              localStorage.setItem("UserClaims", JSON.stringify(resp.data));
              setIsActive(false);
              navigate("/home");
            })
            .catch((error) => {
              return Promise.reject("invalid");
            });

          console.log("login:", res);
        } else {
          setIsActive(false);
        }
        //  setIsActive(false)

        // console.log("ST",items);
      })
      .catch((error) => {
        setIsActive(false);
        toast.error("username password incorrect", {
          className: "custom-cls",
        });
        return Promise.reject("invalid");
      });
  };
  const toggle = (e) => {
    console.log("Pass");
    if (pwd == "password") {
      setPwd("text");
      setcls("bi bi-eye-fill");
    } else {
      setPwd("password");
      setcls("bi bi-eye-slash-fill");
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <ToastContainer autoClose={3000} />
      <div className="container-fluid page-head">
        <div className="row">
          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <h1 className="text-center">Log In</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid login-sec">
        <div className="row">
          <div className="container">
            <div className="row">
              <form id="loginform" onSubmit={handleSubmit(onSubmit)}>
                <div className="col-lg-4 col-lg-offset-4 col-md-6 col-md-offset-3 col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1 login-wrap">
                  <div className="form-group">
                    <label for="first" className="label_name">
                      Username
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="userName"
                      name="userName"
                      {...register("userName")}
                    />
                    <p className="error-txt">{errors.userName?.message}</p>
                  </div>
                  <div className="form-group">
                    <label for="first" className="label_name">
                      Password
                    </label>
                    <input
                      type={pwd}
                      className="form-control"
                      id="password"
                      name="password"
                      {...register("password")}
                    />
                    <p className="error-txt">{errors.password?.message}</p>
                    <i class={cls} onClick={() => toggle(event)}></i>
                  </div>
                  <div class="form-check" style={{ float: "inherit" }}>
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="defaultCheck1"
                      name="checkbox"
                      value={Remember}
                      onChange={() => setRemember((Remember) => !Remember)}
                    />
                    <label
                      class="form-check-label"
                      key="want"
                      for="defaultCheck1"
                    >
                      Remember Me
                    </label>
                  </div>

                  {/* <div class="form-check" style={{ float: 'inherit' }}>
                                        <input type="checkbox" class="form-check-input" id="defaultCheck1" name="checkbox" value="0" onclick="check()" />
                                        <label class="form-check-label" key="want" for="defaultCheck1">
                                            Remember Me
                                        </label>
                                    </div> */}

                  <a href="#" title="Forgot Password?" className="label_name">
                    <u className="forgetpsd">Forgot Password?</u>
                  </a>
                  {/* <span>Don't have an account?<a href="./sign-up.html" title="Sign Up" className="signupbtn"><u>Sign Up</u></a></span> */}
                  {/* <p className='error-txt'>{errors.password?.message}</p> */}
                  <p className="sppar">
                    Don't have an account?
                    <a href="/Signup" title="Sign Up" className="signupbtn">
                      <u>Sign Up</u>
                    </a>
                  </p>
                  {/* <button type="submit" className="loginbtn" key="Submit" >Log In</button> */}
                  <Button
                    type="submit"
                    className="loginbtn1"
                    onClick={scrollToTop}
                    style={{ background: "#24D153" }}
                    key="Submit"
                    variant="contained"
                  >
                    Log In
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* href="/Signup"  */}
      {/* -----------Footer--------------- */}
    </>
  );
}

export default Login;
