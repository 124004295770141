import 'bootstrap/dist/css/bootstrap.min.css'
import './TableComponent.css';
import Table from 'react-bootstrap/Table';
import Select from 'react-select';
import CurrencyFormat from "react-currency-format";
import React, { useState } from "react";
import { fobeAxios } from '../../middleware/interceptor';
import { ToastContainer, toast } from 'react-toastify';
import image from "../../folder.png";
function TableRows({ rows, tableRowRemove, onValUpdate, options, addRow }) {
  return rows.map((rowsData, index) => {
    const { item_amount, item_details, item_purveyor, item_name } = rowsData;
    return (
      <tr key={index}>
        <td>
          <Select
            id="item_name"
            placeholder='Select Category'
            options={options ? options : []}
            menuPosition={'fixed'}
            value={item_name}
            onChange={selectedOption => {
              console.log("selectedOption", selectedOption)
              let event = { target: { name: 'item_name', value: selectedOption } }
              onValUpdate(index, event)
            }}
            name="item_name"
          />
        </td>
        <td>
          <span class="fl-lm">$</span>
          <CurrencyFormat
            className="input_field fl-r"
            id="item_amount"
            fixedDecimalScale={2}
            decimalScale={2}
            value={item_amount}
            onChange={(event) => onValUpdate(index, event)}
            name="item_amount"
          />
        </td>
        <td style={{}}>
          <input
            type="text"
            id="item_purveyor"
            value={item_purveyor}
            onChange={(event) =>
              onValUpdate(index, event)
            }
            name="item_purveyor"
            className="form-control"
          />  </td>
        <td>
          <input
            type="text"
            value={item_details}
            onChange={(event) =>
              onValUpdate(index, event)}
            name="item_details"
            className="form-control"
          />
        </td>
        <td style={{ verticalAlign: "middle" }}>
          {addRow && index > 3 &&
            <i class="fa fa-trash" aria-hidden="true" onClick={() => tableRowRemove(index)} style={{ color: "red", fontSize: "1.5em", cursor: "pointer" }}></i>}
        </td>
      </tr>
    );
  });
}
const TableInvoice = ({ menuId, addRow, rows, addRowTable, tableRowRemove, options, onValUpdate,nodata }) => {
  return (
    <>
    
      <ToastContainer autoClose={3000} />
     
      {nodata ? 
        <div className=' table-responsive table-scroll' style={{ maxHeight: "300px" }}>
         <Table striped bordered hover>
        <thead>
          <tr>
            <th className='scrollview'>
              <div className='tab-card-new'>
                CATEGORY</div>
            </th>
            <th>
              <div className='tab-card-new'>
                AMOUNT</div>
            </th>
            <th>
              <div className='tab-card-new'>
                PURVEYOR</div>
            </th>
            <th>
              <div className='tab-card-new'>
                DETAILS</div>
            </th>
          </tr>
        </thead>
     </Table>
     
     <img src={image} className="img" />
        
     <div>
       <span
         style={{
           fontWeight: "bolder",
           marginLeft: "503px",
           fontSize: "15px",
           opacity: "0.6",
           fontFamily: "FontAwesome",
         }}
       >
         No Data Found
       </span>
     </div>
          </div>
        
        :  
        <div className=' table-responsive table-scroll' style={{ maxHeight: "300px" }}>
          <Table striped bordered hover>
      <thead>
        <tr>
          <th className='scrollview'>
            <div className='tab-card-new'>
              CATEGORY</div>
          </th>
          <th>
            <div className='tab-card-new'>
              AMOUNT</div>
          </th>
          <th>
            <div className='tab-card-new'>
              PURVEYOR</div>
          </th>
          <th>
            <div className='tab-card-new'>
              DETAILS</div>
          </th>
        </tr>
      </thead>
      <tbody class="tab-gf">
        <TableRows
          rows={rows}
          tableRowRemove={tableRowRemove}
          onValUpdate={onValUpdate}
          options={options}
          addRow={addRow}
        />
      </tbody>
    </Table> 
     

    
       
      </div>}
    </>
  );
}
export default TableInvoice;